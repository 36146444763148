<template>
    <div class="error-page vh-100 d-flex justify-content-center text-center">
        <div class="my-auto">
            <h2>404</h2>
            <p>Oops something went wrong</p>
            <router-link to="/" class="btn">Back to Home <i class="icon ion-md-home"></i></router-link>
        </div>
    </div>
    <app-footer></app-footer>
</template>

<script>
import AppFooter from "@/components/parcel/AppFooter.vue";

export default {
    name: "NotFound",
    components: {AppFooter},
    mounted() {
        document.title = '404 Not Found - Bitrish';
    }
}
</script>

<style scoped>

</style>
