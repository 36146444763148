export const LOADING_STATE = '[mutation] loading state';
export const SET_ON_LOGOUT_DATA_MUTATION = '[mutation] set on logout data mutation';
export const SET_IS_LOGIN_DATA_MUTATION = '[mutation] set is login data mutation';
export const SET_THEME_DATA_MUTATION = '[mutation] set theme data mutation';
export const SET_LANGUAGE_MUTATION = '[mutation] set language mutation';
export const SET_FIAT_CURRENCY_MUTATION = '[mutation] set fiat currency mutation';
export const SET_All_FIAT_CURRENCY_MUTATION = '[mutation] set all fiat currency mutation';
export const SET_NOTIFICATION_STATE_MUTATION = '[mutation] set notification state mutation';
export const SET_ALL_NOTIFICATION_MUTATION = '[mutation] set all notification mutation';
export const REMOVE_SELECTED_NOTIFICATION_MUTATION = '[mutation] remove selected notification mutation';
export const SET_WITHDRAW_DATA_MUTATION = '[mutation] set withdraw DATA mutation';


//All Action
export const GET_All_FIAT_CURRENCY_ACTION = '[action] get all fiat currency action';
export const GET_All_NOTIFICATION_ACTION = '[action] get all notification action';
export const CHANGE_THEME_ACTION = '[action] change theme action';
export const CHECK_USER_INFO_ACTION = '[action] check user info';
export const LOGOUT_ACTION = '[action] logout action';
