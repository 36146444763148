export default class Validations {
    static checkEmail(email) {
        if(email.toString() !== '') {
            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
        } else {
            return false;
        }
    }

    static checkPhone(number) {
        if(number.toString() !== '') {
            return /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(number);
        } else {
            return false;
        }
    }

    static checkPassword(pass) {
        if(pass.toString() !== '') {
            return /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(pass);
        } else {
            return false;
        }
    }

    static checkWalletAddress(address) {
        return address.toString() !== '';
    }

    static checkOTP(OTP) {
        return OTP.toString().length === 6;
    }

    static checkTwitterUserName(userName) {
        if(userName.toString() !== '') {
            return /(^|[^@\w])@(\w{1,15})\b/.test(userName);
        } else {
            return false;
        }
    }
}
