<template>
    <div class="customModal">
        <div class="customModalInner">
            <a href="javascript:void(0)" @click="closeCustomModal" class="closeCustomModal">&times;</a>
            <h2>Choose a currency</h2>
            <hr class="mt-4">
            <div class="modalData">
                <div class="row mx-0 my-3 p-3" id="customModalCurrencies">
                    <div class="col-md-3 col-sm-6 col-xs-12 text-white mb-3 currencyItem p-2" @click="selectCurrency" v-for="(cur, index) in currencyList" :key="index" :value="JSON.stringify(cur)" :selected="cur['fiatID'] === userCurrency['fiatID']">{{cur['fiatName'] }}({{ cur['fiatSymbol'] }})</div>
                </div>
            </div>
        </div>
    </div>
    <dialog id="favDialog">
        <form>
            <p>
                <label>Choose Fiat Currency:
                    <select>
                        <option v-for="(cur, index) in currencyList" :key="index" :value="JSON.stringify(cur)" :selected="cur['fiatID'] === userCurrency['fiatID']">{{
                            cur['fiatName'] }}({{ cur['fiatSymbol'] }})
                        </option>
                    </select>
                </label>
            </p>
            <div>
                <button value="cancel" formmethod="dialog">Cancel</button>
                <button id="confirmBtn" value="default">Submit</button>
            </div>
        </form>
    </dialog>
    <header class="light-bb">
        <nav class="navbar navbar-expand-lg">
            <router-link class="navbar-brand" to="/">
                <img v-if="theme === ''" src="/assets/img/logo-dark.png" alt="logo">
                <img v-else src="/assets/img/logo-light.png" alt="logo">
            </router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#headerMenuCustom"
                    aria-controls="headerMenu" aria-expanded="false" aria-label="Toggle navigation">
                <i class="icon ion-md-menu"></i>
            </button>
            <div class="collapse navbar-collapse" id="headerMenu">
                <ul class="leftMenu">
                    <router-link to="/market_overview" style="margin-right: 22px">Market</router-link>
                    <li>
                        <router-link :to="getTradeRoute('BTC_USDT')" style="margin-right: 22px">Trade</router-link>
                    </li>
                    <li v-if="isLogin">
                        <router-link :to="{path: '/MyProfile', query: {type: 1}}" style="margin-right: 22px">Profile</router-link>
                    </li>
                    <li v-if="isLogin">
                        <router-link to="/MyWallet" style="margin-right: 22px">Wallet</router-link>
                    </li>
                    <li>
                        <router-link to="/AboutUs" style="margin-right: 22px">About</router-link>
                    </li>
                    <li>
                        <router-link to="/DepositFee" style="margin-right: 22px">Fee</router-link>
                    </li>
                </ul>
                <ul class="navbar-nav ml-auto rightMenu" v-if="isLogin">
                    <li class="nav-item">
                        <router-link to="/MyDeposit" class="customButton"><i class="fas fa-download"></i> Deposit</router-link>
                        <!-- <a href="javascript:void(0)" class="nav-link" @click="openCurrencyDialog"><span class="selectedCurrency">{{ userCurrency['fiatName'] }}</span></a> -->
                    </li>
                    <li class="nav-item dropdown header-img-icon">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <img src="../../../public/assets/img/avatar.svg" alt="avatar">
                    </a>
                    <div class="dropdown-menu">
                        <div class="dropdown-header d-flex flex-column align-items-center">
                            <div class="figure mb-3">
                                <img src="../../../public/assets/img/avatar.svg" alt="">
                            </div>
                            <div class="info text-center">
                                <p class="name font-weight-bold mb-0">{{ userName }}</p>
                                <p class="email text-muted mb-3">{{ userEmail }}</p>
                            </div>
                        </div>
                        <div class="dropdown-body">
                            <ul class="profile-nav">
                                <li class="nav-item">
                                    <router-link :to="{path: '/MyProfile', query: {type: 1}}" class="nav-link">
                                        <i class="icon ion-md-person"></i>
                                        <span>Profile</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/MyWallet" class="nav-link">
                                        <i class="icon ion-md-wallet"></i>
                                        <span>My Wallet</span>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <a @click="changeThemeF()" class="nav-link" id="changeThemeDark">
                                        <i class="icon ion-md-moon" v-if="theme === 'dark'"></i><i v-else
                                        class="icon ion-md-sunny"></i>
                                        <span>Theme</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a @click="logoutByUser" class="nav-link red">
                                        <i class="icon ion-md-power"></i>
                                        <span>Log Out</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="nav-item">
                    <a href="javascript:void(0)" class="nav-link" @click="openCurrencyDialog"><span class="selectedCurrency">{{ userCurrency['fiatName'] }}</span></a>
                </li>
                <li class="nav-item header-img-icon">
                    <div class="toggle">
                        <input type="checkbox" checked @click="themeSwitch()" id="darkmode-toggle"/>
                        <label for="darkmode-toggle">
                            <i class="icon ion-md-moon" v-if="theme === ''"></i><i v-else class="icon ion-md-sunny"></i>
                        </label>
                    </div>
                </li>
                </ul>
                <ul class="navbar-nav ml-auto rightMenu" v-if="!isLogin">
                    <router-link to="/signIn" class="btn-1">Sign In</router-link>
                    <router-link to="/signUp" class="btn-2">Sign Up</router-link>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" @click="openCurrencyDialog"><span class="selectedCurrency">{{ userCurrency['fiatName'] }}</span></a>
                    </li>
                    <li class="nav-item header-img-icon">
                        <div class="toggle">
                            <input type="checkbox" checked @click="themeSwitch()" id="darkmode-toggle"/>
                            <label for="darkmode-toggle">
                                <i class="icon ion-md-moon" v-if="theme === ''"></i><i v-else class="icon ion-md-sunny"></i>
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="" id="headerMenuCustom">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#headerMenuCustom" aria-controls="headerMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fas fa-times"></i>
                </button>
                <router-link class="navbar-brand" to="/">
                    <img v-if="theme === ''" src="/assets/img/logo-dark.png" alt="logo">
                    <img v-else src="/assets/img/logo-light.png" alt="logo">
                </router-link>
                <li><router-link to="/market_overview">Market</router-link></li>
                <li><router-link :to="getTradeRoute('BTC_USDT')">Trade</router-link></li>
                <li v-if="isLogin"><router-link :to="{path: '/MyProfile', query: {type: 1}}">Profile</router-link></li>
                <li v-if="isLogin"><router-link to="/MyWallet">Wallet</router-link></li>
                <li><router-link to="/AboutUs">About</router-link></li>
                <li><router-link to="/DepositFee">Fee</router-link></li>
                <div class="d-flex justify-content-between align-items-center headerMenuCustomFooter">
                    <router-link v-if="isLogin" to="/MyDeposit" class="customButton"><i class="fas fa-download"></i> Deposit</router-link>
                    <router-link v-if="!isLogin" to="/signIn" class="btn-1">Sign In</router-link>
                    <router-link v-if="!isLogin" to="/signUp" class="btn-2">Sign Up</router-link>
                    <div class="customDropDown" v-if="isLogin">
                        <a class="nav-link customDropDownToggle" href="#">
                            <i class="fas fa-user"></i> My Account
                        </a>
                        <div class="customDropdownMenu">
                            <div class="dropdown-header d-flex flex-column align-items-center">
                                <div class="figure mb-3">
                                    <img src="../../../public/assets/img/avatar.svg" alt="">
                                </div>
                                <div class="info text-center">
                                    <p class="name font-weight-bold mb-0">{{ userName }}</p>
                                    <p class="email text-muted mb-3">{{ userEmail }}</p>
                                </div>
                            </div>
                            <div class="dropdown-body">
                                <router-link :to="{path: '/MyProfile', query: {type: 1}}">
                                    <i class="icon ion-md-person"></i>
                                    <span>Profile</span>
                                </router-link>
                                <router-link to="/MyWallet">
                                    <i class="icon ion-md-wallet"></i>
                                    <span>My Wallet</span>
                                </router-link>
                                <a @click="changeThemeF()" id="changeThemeDark">
                                    <i class="icon ion-md-moon" v-if="theme === 'dark'"></i><i v-else
                                    class="icon ion-md-sunny"></i>
                                    <span>Theme</span>
                                </a>
                                <a @click="logoutByUser" class="red">
                                    <i class="icon ion-md-power"></i>
                                    <span>Log Out</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <a href="javascript:void(0)" @click="openCurrencyDialog"><span class="selectedCurrency">{{ userCurrency['fiatName'] }}</span></a>
                    <div class="toggle">
                        <input type="checkbox" checked @click="themeSwitch()" id="darkmode-toggle"/>
                        <label for="darkmode-toggle">
                            <i class="icon ion-md-moon" v-if="theme === ''"></i><i v-else class="icon ion-md-sunny"></i>
                        </label>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";
import {
    CHANGE_THEME_ACTION,
    LOGOUT_ACTION,
    SET_FIAT_CURRENCY_MUTATION
} from "@/util/store.constent";
import {log, MsgType} from "@/util/constant";
import router from "@/route/routes";

export default {
    name: "AppHeader",
    computed: {
        ...mapState({
            isLogin: (state) => state.isLogin,
            userName: (state) => state.userName,
            userEmail: (state) => state.userEmail,
            theme: (state) => state.theme,
            currencyList: (state) => state.allCurrency,
            userCurrency: state => state.userCurrency,
        }),
    },
    methods: {
        ...mapActions({
            changeTheme: CHANGE_THEME_ACTION,
            logout: LOGOUT_ACTION,
        }),
        ...mapMutations({
            setFiat: SET_FIAT_CURRENCY_MUTATION,
        }),
        getTradeRoute(pair) {
            // return { name: '/trade', params: { pair } };
            return { path: '/trade/' + pair };
        },
        themeSwitch() {
            log(this.theme, MsgType.log);
            const toggle = document.getElementById('darkmode-toggle');
            toggle.addEventListener('input', (e) => {
                const isChecked = e.target.checked;
                if (isChecked) {
                    this.changeTheme('dark-theme');
                } else {
                    this.changeTheme('');
                }
            });
        },
        changeThemeF() {
            log(this.theme, MsgType.log);
            if (this.theme === "dark") {
                this.changeTheme('');
            } else {
                this.changeTheme('dark-theme');
            }
        },
        logoutByUser() {
            this.logout();
            router.push("/signIn");
        },
        async openCurrencyDialog() {
            // const favDialog = document.getElementById('favDialog');
            let customModal = document.querySelector(".customModal");
            customModal.classList.add('show');

            // const selectEl = favDialog.querySelector('select');
            // const confirmBtn = favDialog.querySelector('#confirmBtn');
            // favDialog.showModal();

            // selectEl.addEventListener('change', () => {
            //     confirmBtn.value = selectEl.value === 'default' ? '' : JSON.parse(selectEl.value);
            // });

            // favDialog.addEventListener('close', () => {
            //     log(favDialog.returnValue, MsgType.log);
            //     this.setFiat(JSON.parse(favDialog.returnValue));
            // });
            // confirmBtn.addEventListener('click', (event) => {
            //     event.preventDefault(); // We don't want to submit this fake form
            //     favDialog.close(selectEl.value); // Have to send the select box value here.
            // });
        },
        closeCustomModal(){
            let customModal = document.querySelector(".customModal");
            customModal.classList.remove('show');
        },selectCurrency(e){
            // console.log(e.target.getAttribute("value"));
            this.setFiat(JSON.parse(e.target.getAttribute("value")));
            this.closeCustomModal();
            //alert(e.target);
        }
    },
}
</script>

<style>

</style>
