import { createI18n } from 'vue-i18n'

const messages = {
    en: {
        landingHeadline: 'A trusted and secure cryptocurrency exchange.',
        signIn: 'Sign In',
    },
    fr: {
        landingHeadline: 'A trusted and secure cryptocurrency exchange',
        signIn: 'Sign In 12',
    },
}

const i18n = createI18n({
    locale: 'en',
    messages,
})

export default i18n