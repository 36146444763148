<template>
    <div class="container">
        <div class="row m-0">
            <div class="col-md-12 my-5 d-flex justify-content-between align-items-center">
                <h1 class="boldHeading">Market Overview</h1>
            </div>
        </div>
    </div>
    <div class="container mb-3">
        <div class="row m-0 highlightedPair">
            <div class="col-md-6 col-lg-3">
                <div class="customListing p-3">
                    <div class="d-flex justify-content-between align-items-center textInvert mb-4 customPad">
                        <small class="textGray d-flex">Highlight Coin</small>
                    </div>
                    <div :data-item-id="highlightCoinList[0]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="highlightCoinList.length >= 1">
                        <div class="d-inline-flex align-items-center"><img :src="highlightCoinList[0]['icon']" class="coinLogo" alt="coin logo"/>
                            <span>{{highlightCoinList[0]['name'] }}</span>
                        </div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(highlightCoinList[0]['price'].toString(), parseInt(highlightCoinList[0]['view'].toString())) }}</span>
                        <span class="text-right textGreen" v-if="isGreaterThanZero(highlightCoinList[0]['changePercent'].toString())">
                            {{ getFixedVal(highlightCoinList[0]['changePercent'].toString(), 2) }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(highlightCoinList[0]['changePercent'].toString(), 2) }}%</span>
                    </div>
                    <div :data-item-id="highlightCoinList[1]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="highlightCoinList.length >= 2">
                        <div class="d-inline-flex align-items-center"><img :src="highlightCoinList[1]['icon']"
                                                                           class="coinLogo" alt="coin logo"/><span>{{
                            highlightCoinList[1]['name']
                            }}</span></div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(highlightCoinList[1]['price'].toString(), parseInt(highlightCoinList[1]['view'].toString())) }}</span>
                        <span class="text-right textGreen"
                              v-if="isGreaterThanZero(highlightCoinList[1]['changePercent'].toString())">{{
                            getFixedVal(highlightCoinList[1]['changePercent'].toString(), 2)
                            }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(highlightCoinList[1]['changePercent'].toString(), 2) }}%</span>
                    </div>
                    <div :data-item-id="highlightCoinList[2]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="highlightCoinList.length >= 3">
                        <div class="d-inline-flex align-items-center"><img :src="highlightCoinList[2]['icon']"
                                                                           class="coinLogo" alt="coin logo"/><span>{{
                            highlightCoinList[2]['name']
                            }}</span></div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(highlightCoinList[2]['price'].toString(), parseInt(highlightCoinList[2]['view'].toString())) }}</span>
                        <span class="text-right textGreen"
                              v-if="isGreaterThanZero(highlightCoinList[2]['changePercent'].toString())">{{
                            getFixedVal(highlightCoinList[2]['changePercent'].toString(), 2)
                            }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(highlightCoinList[2]['changePercent'].toString(), 2) }}%</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="customListing p-3">
                    <div class="listItem d-flex justify-content-between align-items-center textInvert mb-4">
                        <small class="textGray d-flex">New Listing</small>
                        <!--                        <span class="listIcon"><i class="fas fa-chevron-right"></i></span>-->
                    </div>
                    <div :data-item-id="newCoinList[0]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="newCoinList.length >= 1">
                        <div class="d-inline-flex align-items-center"><img :src="newCoinList[0]['icon']"
                                                                           class="coinLogo" alt="coin logo"/><span>{{
                            newCoinList[0]['name']
                            }}</span></div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(newCoinList[0]['price'].toString(), parseInt(newCoinList[0]['view'].toString())) }}</span>
                        <span class="text-right textGreen"
                              v-if="isGreaterThanZero(newCoinList[0]['changePercent'].toString())">{{
                            getFixedVal(newCoinList[0]['changePercent'].toString(), 2)
                            }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(newCoinList[0]['changePercent'].toString(), 2) }}%</span>
                    </div>
                    <div :data-item-id="newCoinList[1]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="newCoinList.length >= 2">
                        <div class="d-inline-flex align-items-center"><img :src="newCoinList[1]['icon']"
                                                                           class="coinLogo" alt="coin logo"/><span>{{
                            newCoinList[1]['name']
                            }}</span></div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(newCoinList[1]['price'].toString(), parseInt(newCoinList[1]['view'].toString())) }}</span>
                        <span class="text-right textGreen"
                              v-if="isGreaterThanZero(newCoinList[1]['changePercent'].toString())">{{
                            getFixedVal(newCoinList[1]['changePercent'].toString(), 2)
                            }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(newCoinList[1]['changePercent'].toString(), 2) }}%</span>
                    </div>
                    <div :data-item-id="newCoinList[2]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="newCoinList.length >= 3">
                        <div class="d-inline-flex align-items-center"><img :src="newCoinList[2]['icon']"
                                                                           class="coinLogo" alt="coin logo"/><span>{{
                            newCoinList[2]['name']
                            }}</span></div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(newCoinList[2]['price'].toString(), parseInt(newCoinList[2]['view'].toString())) }}</span>
                        <span class="text-right textGreen"
                              v-if="isGreaterThanZero(newCoinList[2]['changePercent'].toString())">{{
                            getFixedVal(newCoinList[2]['changePercent'].toString(), 2)
                            }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(newCoinList[2]['changePercent'].toString(), 2) }}%</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="customListing p-3">
                    <div class="listItem d-flex justify-content-between align-items-center textInvert mb-4">
                        <small class="textGray d-flex">Top Gainer Coin</small>
                        <!--                        <span class="listIcon"><i class="fas fa-chevron-right"></i></span>-->
                    </div>
                    <div :data-item-id="gainerCoinList[0]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="gainerCoinList.length >= 1">
                        <div class="d-inline-flex align-items-center"><img :src="gainerCoinList[0]['icon']"
                                                                           class="coinLogo" alt="coin logo"/><span>{{
                            gainerCoinList[0]['name']
                            }}</span></div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(gainerCoinList[0]['price'].toString(), parseInt(gainerCoinList[0]['view'].toString())) }}</span>
                        <span class="text-right textGreen"
                              v-if="isGreaterThanZero(gainerCoinList[0]['changePercent'].toString())">{{
                            getFixedVal(gainerCoinList[0]['changePercent'].toString(), 2)
                            }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(gainerCoinList[0]['changePercent'].toString(), 2) }}%</span>
                    </div>
                    <div :data-item-id="gainerCoinList[1]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="gainerCoinList.length >= 2">
                        <div class="d-inline-flex align-items-center"><img :src="gainerCoinList[1]['icon']"
                                                                           class="coinLogo" alt="coin logo"/><span>{{
                            gainerCoinList[1]['name']
                            }}</span></div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(gainerCoinList[1]['price'].toString(), parseInt(gainerCoinList[1]['view'].toString())) }}</span>
                        <span class="text-right textGreen"
                              v-if="isGreaterThanZero(gainerCoinList[1]['changePercent'].toString())">{{
                            getFixedVal(gainerCoinList[1]['changePercent'].toString(), 2)
                            }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(gainerCoinList[1]['changePercent'].toString(), 2) }}%</span>
                    </div>
                    <div :data-item-id="gainerCoinList[2]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="gainerCoinList.length >= 3">
                        <div class="d-inline-flex align-items-center"><img :src="gainerCoinList[2]['icon']"
                                                                           class="coinLogo" alt="coin logo"/><span>{{
                            gainerCoinList[2]['name']
                            }}</span></div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(gainerCoinList[2]['price'].toString(), parseInt(gainerCoinList[2]['view'].toString())) }}</span>
                        <span class="text-right textGreen"
                              v-if="isGreaterThanZero(gainerCoinList[2]['changePercent'].toString())">{{
                            getFixedVal(gainerCoinList[2]['changePercent'].toString(), 2)
                            }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(gainerCoinList[2]['changePercent'].toString(), 2) }}%</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="customListing p-3">
                    <div class="listItem d-flex justify-content-between align-items-center textInvert mb-4">
                        <small class="textGray d-flex">Top Volume Coin</small>
                        <!--                        <span class="listIcon"><i class="fas fa-chevron-right"></i></span>-->
                    </div>
                    <div :data-item-id="topVolumeCoinList[0]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="topVolumeCoinList.length >= 1">
                        <div class="d-inline-flex align-items-center"><img :src="topVolumeCoinList[0]['icon']"
                                                                           class="coinLogo" alt="coin logo"/><span>{{
                            topVolumeCoinList[0]['name']
                            }}</span></div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(topVolumeCoinList[0]['price'].toString(), parseInt(topVolumeCoinList[0]['view'].toString())) }}</span>
                        <span class="text-right textGreen"
                              v-if="isGreaterThanZero(topVolumeCoinList[0]['changePercent'].toString())">{{
                            getFixedVal(topVolumeCoinList[0]['changePercent'].toString(), 2)
                            }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(topVolumeCoinList[0]['changePercent'].toString(), 2) }}%</span>
                    </div>
                    <div :data-item-id="topVolumeCoinList[1]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="topVolumeCoinList.length >= 2">
                        <div class="d-inline-flex align-items-center"><img :src="topVolumeCoinList[1]['icon']"
                                                                           class="coinLogo" alt="coin logo"/><span>{{
                            topVolumeCoinList[1]['name']
                            }}</span></div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(topVolumeCoinList[1]['price'].toString(), parseInt(topVolumeCoinList[1]['view'].toString())) }}</span>
                        <span class="text-right textGreen"
                              v-if="isGreaterThanZero(topVolumeCoinList[1]['changePercent'].toString())">{{
                            getFixedVal(topVolumeCoinList[1]['changePercent'].toString(), 2)
                            }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(topVolumeCoinList[1]['changePercent'].toString(), 2) }}%</span>
                    </div>
                    <div :data-item-id="topVolumeCoinList[2]['pairName']" class="listItem d-flex justify-content-between align-items-center textInvert"
                         v-if="topVolumeCoinList.length >= 3">
                        <div class="d-inline-flex align-items-center"><img :src="topVolumeCoinList[2]['icon']"
                                                                           class="coinLogo" alt="coin logo"/><span>{{
                            topVolumeCoinList[2]['name']
                            }}</span></div>
                        <span>{{userCurrency['fiatSymbol']}} {{formattedCurrency(topVolumeCoinList[2]['price'].toString(), parseInt(topVolumeCoinList[2]['view'].toString())) }}</span>
                        <span class="text-right textGreen"
                              v-if="isGreaterThanZero(topVolumeCoinList[2]['changePercent'].toString())">{{
                            getFixedVal(topVolumeCoinList[2]['changePercent'].toString(), 2)
                            }}%</span>
                        <span class="text-right textRed"
                              v-else>{{ getFixedVal(topVolumeCoinList[2]['changePercent'].toString(), 2) }}%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row m-0 p-0 pageContent">
        <div class="container">
            <div class="col-md-12 p-4">
                <div class="datatable-container">
                    <DataTable
                            class="display textInvert"
                            :perPageOptions="false"
                            :ajax="url + 'market/allMarketPairs'"
                            ref="table"
                            :columns="columns"
                            @click="resolveRouteFromClick($event)"
                            :options="{
                        select: false,
                        serverSide: false,
                        responsive: true
                        }"
                    >
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th class="text-center">Price</th>
                            <th class="text-center">Change</th>
                            <th class="text-center">24h Volume</th>
                            <th class="text-center"></th>
                            <th class="text-center"></th>
                        </tr>
                        </thead>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
    <app-footer/>
</template>

<script>
import {getPairList} from "@/restApi/trade_api";
import AppFooter from "@/components/parcel/AppFooter.vue";
import DataTable from 'datatables.net-vue3';
import Select from 'datatables.net-select';
import {getMarketTopState} from "@/restApi/market.api";
import BigNumber from "bignumber.js";
import {log, MsgType} from "@/util/constant";
import {mapState} from "vuex";
import Store from "@/store/store";
import {computed, ref, watch} from "vue";

DataTable.use(Select);
export default {
    name: "MarketOverview",
    components: {
        AppFooter,
        DataTable
    },
    data() {
        return {
            url: process.env.VUE_APP_DOMAIN,
            columns: [
                //   {"data": "name"},
                {
                    "data": function (data) {
                        return `<div data-item-id="${data.pairName}" class="d-flex align-items-center"><img src=" ${data.icon} " width="30px" height="30px"  alt=""/> <strong class="mx-3">${data.name}</strong> <small class="textGray" style="margin-top: 2px;">${data.description}</small></div>`;
                    }
                },
                {
                    "data": (data) => this.changeCurrency(data)
                },
                {
                    "data": function (data) {
                        if(new BigNumber(data.changePercent.toString()).isGreaterThanOrEqualTo(new BigNumber('0'))) {
                            return "<span class='w-100 text-center d-block textGreen'>" + new BigNumber(data.changePercent.toString()).toFixed(2) + "%</span>";
                        } else {
                            return "<span class='w-100 text-center d-block textRed'>" + new BigNumber(data.changePercent.toString()).toFixed(2) + "%</span>";
                        }
                    }
                },
                {
                    "data": function (data) {
                        return "<span class='w-100 text-center d-block'>" + new BigNumber(data.volumeOneDay.toString()).multipliedBy(new BigNumber(data.price.toString())).toFixed(parseInt(data.view.toString())) + "</span>";
                    }
                }, {
                    "data": function () {
                        return "<span class='w-100 text-right d-block'><i class='fa-solid fa-chart-line'></i></span>";
                    }, sortable: false,
                }, {
                    "data": function () {
                        return `<span class="w-100 text-right d-block"><i class="fa-solid fa-chart-simple"></i></span>`;
                    }, sortable: false,
                },
            ],
            pairList: [],
            // highlightCoinList: [],
            // newCoinList: [],
            // gainerCoinList: [],
            // topVolumeCoinList: [],
            ws: null,
            curSymbol: '',
        }
    },
    // mounted() {
    //     window.vueRoute = this.route1;
    //
    //     document.body.addEventListener('clickedEdit', this.route2);
    // },
    created() {
        this.initWs();
        this.getTopMarketCoins();
        this.curSymbol = this.userCurrency['fiatSymbol'];
    },
    computed: {
        ...mapState({
            userCurrency: state => state.userCurrency,
        }),
    },
    methods: {

        changeCurrency(data){
            const aa = this.getBigNumber(this.userCurrency['fiatPriceOneUSD']).multipliedBy(this.getBigNumber(data.price))
            return "<span class='w-100 text-center d-block' id='marketTablePrice-"+data.pairID+"'>"+this.userCurrency['fiatSymbol']+" "+aa.toFixed(parseInt(data.view.toString()))+"</span>";
            // return ;
        },

        resolveRouteFromClick(e){
            const itemId = e.target.dataset.itemId;
            if (!itemId) {
                return
            }
            e.preventDefault();
            this.$router.push('/trade/' + itemId );
        },

        async getPairData() {
            const {pairData, error} = await getPairList(0);
            if (error.value === null) {
                if (JSON.parse(pairData.value)['status'].toString().toLowerCase() === 'true') {
                    if (JSON.parse(pairData.value)['data'].length > 0) {
                        for (let i = 0; i < JSON.parse(pairData.value)['data'].length; i++) {
                            this.pairList.push(JSON.parse(pairData.value)['data'][i]);
                        }
                    }
                }
            }
        },

        pickEleById(cId, colorType) {
            const mTPID = document.getElementById('marketTablePrice-'+cId);
            if(mTPID) {
                if(colorType === 'buy') {
                    mTPID.classList.add('textGreen');
                } else {
                    mTPID.classList.add('textRed');
                }
            }
        },

        // pickTextVal(pId) {

        // },

        async getTopMarketCoins() {
            const {res, err} = await getMarketTopState();
            if (err === null) {
                try {
                    const msg = JSON.parse(res)['message'].toString();
                    if (JSON.parse(res)['status'].toString().toLowerCase() === 'true') {
                        const data = JSON.parse(res)['data'];
                        for (let i = 0; i < data['highlights'].length; i++) {
                            data['highlights'][i]['oneUsdPrice'] = this.userCurrency['fiatPriceOneUSD'].toString();
                            data['highlights'][i]['usdPrice'] = data['highlights'][i]['price'].toString();
                            data['highlights'][i]['price'] = this.getBigNumber(this.userCurrency['fiatPriceOneUSD'].toString()).multipliedBy(this.getBigNumber(data['highlights'][i]['price'].toString()));
                            this.highlightCoinList.push(data['highlights'][i]);
                        }
                        for (let i = 0; i < data['newList'].length; i++) {
                            data['newList'][i]['oneUsdPrice'] = this.userCurrency['fiatPriceOneUSD'].toString();
                            data['newList'][i]['usdPrice'] = data['newList'][i]['price'].toString();
                            data['newList'][i]['price'] = this.getBigNumber(this.userCurrency['fiatPriceOneUSD'].toString()).multipliedBy(this.getBigNumber(data['newList'][i]['price'].toString()));
                            this.newCoinList.push(data['newList'][i]);
                        }
                        for (let i = 0; i < data['gainers'].length; i++) {
                            data['gainers'][i]['oneUsdPrice'] = this.userCurrency['fiatPriceOneUSD'].toString();
                            data['gainers'][i]['usdPrice'] = data['gainers'][i]['price'].toString();
                            data['gainers'][i]['price'] = this.getBigNumber(this.userCurrency['fiatPriceOneUSD'].toString()).multipliedBy(this.getBigNumber(data['gainers'][i]['price'].toString()));
                            this.gainerCoinList.push(data['gainers'][i]);
                        }
                        for (let i = 0; i < data['topVolumes'].length; i++) {
                            data['topVolumes'][i]['oneUsdPrice'] = this.userCurrency['fiatPriceOneUSD'].toString();
                            data['topVolumes'][i]['usdPrice'] = data['topVolumes'][i]['price'].toString();
                            data['topVolumes'][i]['price'] = this.getBigNumber(this.userCurrency['fiatPriceOneUSD'].toString()).multipliedBy(this.getBigNumber(data['topVolumes'][i]['price'].toString()));
                            this.topVolumeCoinList.push(data['topVolumes'][i]);
                        }
                    } else {
                        console.log(msg)
                    }
                } catch (e) {
                    console.log(e.toString());
                }
            } else {
                console.log(err)
            }
        },

        isGreaterThanZero(val) {
            return this.getBigNumber(val).isGreaterThanOrEqualTo(this.getBigNumber('0'));
        },

        getBigNumber(val) {
            return new BigNumber(val.toString());
        },

        getFixedVal(val, fixVal) {
            val = new BigNumber(val);
            return !isNaN(fixVal) ? val.toFixed(fixVal) : val.toFixed(4);
        },

        formattedCurrency(val, round) {
            if(val && val.toString().includes('.')) {
                if(parseFloat(val.toString().split('.')[0]) <= 0) {
                    return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: parseInt(round.toString())});
                } else {
                    return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: 2});
                }
            } else if(val) {
                return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: 2});
            } else {
                return '0.00';
            }
        },

        onMessageWS(event) {
            const eventData = JSON.parse(event.data);
            try {
                if (eventData.type === 'fiatPriceOneUSD') {
                    if (this.userCurrency['fiatID'].toString() === eventData['data']['fID'].toString()) {
                        for (let i = 0; i < this.highlightCoinList.length; i++) {
                            this.highlightCoinList[i]['oneUsdPrice'] = eventData['data']['price'];
                            this.highlightCoinList[i]['price'] = (this.getBigNumber(eventData['data']['price'].toString()).multipliedBy(this.getBigNumber(this.highlightCoinList[i]['usdPrice']))).toFixed();
                        }
                        for (let i = 0; i < this.newCoinList.length; i++) {
                            this.newCoinList[i]['oneUsdPrice'] = eventData['data']['price'];
                            this.newCoinList[i]['price'] = (this.getBigNumber(eventData['data']['price'].toString()).multipliedBy(this.getBigNumber(this.newCoinList[i]['usdPrice']))).toFixed();
                        }
                        for (let i = 0; i < this.gainerCoinList.length; i++) {
                            this.gainerCoinList[i]['oneUsdPrice'] = eventData['data']['price'];
                            this.gainerCoinList[i]['price'] = (this.getBigNumber(eventData['data']['price'].toString()).multipliedBy(this.getBigNumber(this.gainerCoinList[i]['usdPrice']))).toFixed();
                        }
                        for (let i = 0; i < this.topVolumeCoinList.length; i++) {
                            this.topVolumeCoinList[i]['oneUsdPrice'] = eventData['data']['price'];
                            this.topVolumeCoinList[i]['price'] = (this.getBigNumber(eventData['data']['price'].toString()).multipliedBy(this.getBigNumber(this.topVolumeCoinList[i]['usdPrice']))).toFixed();
                        }
                    }
                }

                if (eventData.type === 'pairPriceUSD') {
                    for (let i = 0; i < this.highlightCoinList.length; i++) {
                        if (eventData['data']['cID'].toString() === this.highlightCoinList[i]['id'].toString()) {
                            this.highlightCoinList[i]['usdPrice'] = eventData['data']['price'].toString();
                            this.highlightCoinList[i]['price'] = (this.getBigNumber(eventData['data']['price'].toString()).multipliedBy(this.getBigNumber(this.highlightCoinList[i]['oneUsdPrice']))).toFixed();
                        }
                    }
                    for (let i = 0; i < this.newCoinList.length; i++) {
                        if (eventData['data']['cID'].toString() === this.newCoinList[i]['id'].toString()) {
                            this.newCoinList[i]['usdPrice'] = eventData['data']['price'].toString();
                            this.newCoinList[i]['price'] = (this.getBigNumber(eventData['data']['price'].toString()).multipliedBy(this.getBigNumber(this.newCoinList[i]['oneUsdPrice']))).toFixed();
                        }
                    }
                    for (let i = 0; i < this.gainerCoinList.length; i++) {
                        if (eventData['data']['cID'].toString() === this.gainerCoinList[i]['id'].toString()) {
                            this.gainerCoinList[i]['usdPrice'] = eventData['data']['price'].toString();
                            this.gainerCoinList[i]['price'] = (this.getBigNumber(eventData['data']['price'].toString()).multipliedBy(this.getBigNumber(this.gainerCoinList[i]['oneUsdPrice']))).toFixed();
                        }
                    }
                    for (let i = 0; i < this.topVolumeCoinList.length; i++) {
                        if (eventData['data']['cID'].toString() === this.topVolumeCoinList[i]['id'].toString()) {
                            this.topVolumeCoinList[i]['usdPrice'] = eventData['data']['price'].toString();
                            this.topVolumeCoinList[i]['price'] = (this.getBigNumber(eventData['data']['price'].toString()).multipliedBy(this.getBigNumber(this.topVolumeCoinList[i]['oneUsdPrice']))).toFixed();
                        }
                    }
                }

                if (eventData.type === "priceUpdate") {
                    this.pickEleById(eventData['data']['pairID'].toString(), eventData['data']['pairPriceColor'])
                }
            } catch (e) {
                log('WS message Error', MsgType.error);
                log(e, MsgType.error);
            }
        },

        initWs() {
            const connect = () => {
                this.ws = new WebSocket(process.env.VUE_APP_WS_URL);

                this.ws.onopen = () => {
                    log('WebSocket connected', MsgType.log);
                };

                this.ws.onmessage = this.onMessageWS;

                this.ws.onclose = () => {
                    log('WebSocket closed', MsgType.log);
                    setTimeout(() => {
                        log('WebSocket reconnecting...', MsgType.log);
                        if (this.ws) {
                            connect();
                        }
                    }, 1000);
                };

                this.ws.onerror = (error) => {
                    log(error, MsgType.log);
                };
            };
            connect();
        },

        disconnectWebSocket() {
            log('socket disconnect on page close', MsgType.log);
            if (this.refreshDataTimeout) {
                clearTimeout(this.refreshDataTimeout);
            }
            if (this.ws) {
                this.ws.close();
                this.ws = null;
            }
        },

    },
    mounted() {
        document.title = 'Cryptocurrency Market | Bitrish';
    },
    setup() {
        const store = Store;
        const cur = computed(() => store.state.userCurrency);
        const highlightCoinList = ref([]);
        const newCoinList = ref([]);
        const gainerCoinList = ref([]);
        const topVolumeCoinList = ref([]);

        const checkWatch = (newVal) => {
            console.log("newVal: ", newVal);
            store.state.showLoading = true;
            window.location.reload();
            for (let i = 0; i < highlightCoinList.value.length; i++) {
                highlightCoinList.value[i]['oneUsdPrice'] = newVal['fiatPriceOneUSD'];
                const fPrice = new BigNumber(newVal['fiatPriceOneUSD'].toString());
                const price = new BigNumber(highlightCoinList.value[i]['usdPrice'].toString());
                highlightCoinList.value[i]['price'] = (fPrice.multipliedBy(price)).toFixed();
            }
            for (let i = 0; i < newCoinList.value.length; i++) {
                newCoinList.value[i]['oneUsdPrice'] = newVal['fiatPriceOneUSD'];
                const fPrice = new BigNumber(newVal['fiatPriceOneUSD'].toString());
                const price = new BigNumber(newCoinList.value[i]['usdPrice'].toString());
                newCoinList.value[i]['price'] = (fPrice.multipliedBy(price)).toFixed();
            }
            for (let i = 0; i < gainerCoinList.value.length; i++) {
                gainerCoinList.value[i]['oneUsdPrice'] = newVal['fiatPriceOneUSD'];
                const fPrice = new BigNumber(newVal['fiatPriceOneUSD'].toString());
                const price = new BigNumber(gainerCoinList.value[i]['usdPrice'].toString());
                gainerCoinList.value[i]['price'] = (fPrice.multipliedBy(price)).toFixed();
            }
            for (let i = 0; i < topVolumeCoinList.value.length; i++) {
                topVolumeCoinList.value[i]['oneUsdPrice'] = newVal['fiatPriceOneUSD'];
                const fPrice = new BigNumber(newVal['fiatPriceOneUSD'].toString());
                const price = new BigNumber(topVolumeCoinList.value[i]['usdPrice'].toString());
                topVolumeCoinList.value[i]['price'] = (fPrice.multipliedBy(price)).toFixed();
            }
        };

        watch(cur, (newVal) => {
            checkWatch(newVal);
        });

        return {
            highlightCoinList,
            newCoinList,
            gainerCoinList,
            topVolumeCoinList
        }
    }
}
</script>
<style>
@import 'datatables.net-dt';
</style>
