<template>
    <div class="customPopup">
        <div class="customPopupDialog">
            <div class="customPopupData d-flex justify-content-center align-items-center flex-column">
                <h1 class="icon ion-md-information"></h1>
                <p>You want to delete this trade?</p>
                <div class="py-2 px-3">
                    <a href="javascript:void(0)" @click="cancelOrder()" class="btn btn-primary-outline px-4 mr-2">Yes</a>
                    <a href="javascript:void(0)" @click="closeCustomPopup()" class="btn btn-primary-outline px-4">No</a>
                </div>
            </div>
        </div>
    </div>

  <!--  <div class="maxOrderDialog">-->
  <!--    <div class="maxOrderPopupDialog">-->
  <!--      <div class="d-flex justify-content-center align-items-center flex-column">-->
  <!--        <h1 class="icon ion-md-information"></h1>-->
  <!--        <p>Maximum of two open sell order per account are allowed, we kindly request you to cancel any additional sell-->
  <!--          order.</p>-->
  <!--        <div class="py-2 px-3">-->
  <!--          <a href="javascript:void(0)" @click="onMaxTradeClose" class="btn btn-primary-outline px-4 mr-2">Ok</a>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

    <div class="container">
        <div class="col-md-12 p-0">
            <div class="row">
                <div class="col-md-9">
                    <div class="row">
                        <div class="col-md-12 bl">
                            <div class="pairArea">
                                <div class="pair">
                                    <div class="br pr-4 d-inline-block pairFlex0">
                                        <h3><i class="icon-circle">
                                            <img :src="selectedCoinOneIcon" alt="Bitrish logo" v-if="selectedCoinOneIcon !== ''"/>
                                            <img src="/assets/img/small_bitrish.png" alt="Bitrish logo" v-else/>
                                        </i>{{ selectedCoinOne }}/{{ selectedCoinTwo }}</h3>
                                        <br>
                                    </div>
                                    <div class="tradingPrice pl-4 d-inline-block pairFlex0">
                                        <div class="tradingPriceText textGreen" v-if="lastOrderType === 'buy'">{{ customRoundValue(lastPrice, minDecimalView) }}</div>
                                        <div class="tradingPriceText textRed" v-else-if="lastOrderType === 'sell'">{{ customRoundValue(lastPrice, minDecimalView) }}</div>
                                        <div class="tradingPriceText" v-else>{{ customRoundValue(lastPrice, minDecimalView) }}</div>
                                        <div class="tradingPriceUSD">{{ userCurrency['fiatSymbol'] }}{{ parseFloat(lastPrice.toString()) > 0 ? formattedCurrency(this.calculatedPrice, minDecimalView) : '0.00' }}</div>
                                    </div>
                                    <div class="customTicker pairFlex1">
                                        <div class="tickerControl">
                                            <a @click="scroll_left()" class="tickerArrow arrowLeft"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg></a>
                                            <a @click="scroll_right()" class="tickerArrow arrowRight"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg></a>
                                        </div>
                                        <div class="customTickerData">
                                            <div class="tradingPrice d-inline-block">
                                                <div class="tradingPriceText textGray fs12">24h Change</div>
                                                <div class="tradingPriceUSD textRed" v-if="getFixedVal(statsPriceChange, minDecimalView) < 0">{{ customRoundValue(statsPriceChange, minDecimalView) }} {{ getFixedVal(statsPerChange, 2) }}%</div>
                                                <div class="tradingPriceUSD" v-else-if="getFixedVal(statsPriceChange, minDecimalView) === 0">{{ customRoundValue(statsPriceChange, minDecimalView) }} {{ getFixedVal(statsPerChange, 2) }}%</div>
                                                <div class="tradingPriceUSD textGreen" v-else>{{ customRoundValue(statsPriceChange, minDecimalView) }} {{ getFixedVal(statsPerChange, 2) }}%</div>
                                            </div>
                                            <div class="tradingPrice pl-4 d-inline-block">
                                                <div class="tradingPriceText textGray fs12">24h High</div>
                                                <div class="tradingPriceUSD">{{ customRoundValue(statsHigh, minDecimalView) }}</div>
                                            </div>
                                            <div class="tradingPrice pl-4 d-inline-block">
                                                <div class="tradingPriceText textGray fs12">24h Low</div>
                                                <div class="tradingPriceUSD">{{ customRoundValue(statsLow, minDecimalView) }}</div>
                                            </div>
                                            <div class="tradingPrice pl-4 d-inline-block">
                                                <div class="tradingPriceText textGray fs12">24h Volume({{ selectedCoinOne }})</div>
                                                <div class="tradingPriceUSD">{{ customRoundValue((getBigIntNumber(statsVolume).multipliedBy(getBigIntNumber(lastPrice))).toFixed(), minDecimalView) }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="pair">
                                    <a href="#" class="textGray">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                             class="css-1l3piql">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M3 12a9 9 0 1118 0 9 9 0 01-18 0zm8.934 3.5H10v-7h1.934L16 12l-4.066 3.5z"
                                                  fill="currentColor"></path>
                                        </svg>
                                        Spot Tutorial</a>
                                    <a href="#" class="textGray ml-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                             class="css-1l3piql">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M4.896 3h14v18h-14V3zm11 13v2h-8v-2h8zm-8-3h8V6h-8v7zm6-5h-4v3h4V8z"
                                                  fill="currentColor"></path>
                                        </svg>
                                        Spot Guidance</a>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-md-4 p-0">
                            <div class="order-book">
                                <h2 class="heading">Order Book</h2>
                                <div class="data-headings">
                                    <span>Price({{ selectedCoinTwo }})</span>
                                    <span>Amount({{ selectedCoinOne }})</span>
                                    <span>Total({{ selectedCoinTwo }})</span>
                                </div>
                                <div class="data" v-if="sellBook.length > 0">
                                    <div class="orderbook-progress" v-for="(sell, index) in sellBook" :key="index"
                                         v-on:click="selectOrderBook(sell)">
                                        <div class="progress-container">
                                            <div class="row-content">
                                                <div class="ask-light" style="font-size: 12px; flex: 1 1 0; text-align: left;color:rgb(246, 70, 93);font-weight:normal;">
                                                    {{ sell.price < 0 ? "--" : customRoundValue(sell['price'], minDecimalView) }}
                                                </div>
                                                <div class="text">{{sell.total < 0 ? "--" : customRoundValue(sell['total'], minDecimal) }}</div>
                                                <div class="text">{{ sell.price < 0 && sell.total < 0 ? "--" : customRoundValue((getBigIntNumber(sell.price).multipliedBy(getBigIntNumber(sell.total))).toFixed(), minDecimalView) }}</div>
                                            </div>
                                            <div class="custom-progress-bar red-progress" v-if="sell.total > 0" :style="{ width: `${(sell.total - sell.remaining)*(100/sell.total)}%` }"></div>
                                            <div class="custom-progress-bar red-progress" v-else :style="{ width: `0%` }"></div>
                                        </div>
                                    </div>
                                    <div class="popover">
                                        <div class="d-flex justify-content-between items-align-center flex-column zTop p-2">
                                            <div>First</div>
                                            <div>Second</div>
                                            <div>Third</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="data" v-else>
                                    <div class="orderbook-progress" v-for="(pair, index) in dummyPairList" :key="index"
                                         v-on:click="onSelectPair(index)">
                                        <div class="progress-container" style="display: flex; justify-content: space-between">
                                            <div class="shimmer-effect ml-2"></div>
                                            <div class="shimmer-effect"></div>
                                            <div class="shimmer-effect mr-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="order-book">
                                <div class="orderbook-ticker heading">
                                    <div class="contractPrice status-primary textGreen" v-if="lastOrderType === 'buy'">{{ customRoundValue(lastPrice, this.minDecimalView) }}</div>
                                    <div class="contractPrice status-primary textRed" v-else-if="lastOrderType === 'sell'">{{ customRoundValue(lastPrice, this.minDecimalView) }}</div>
                                    <div class="contractPrice status-primary textGray" v-else>{{ customRoundValue(lastPrice, this.minDecimalView) }}</div>
                                    <div style="width:16px">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                             class="arrow-icon status-buy" style="transform: rotate(180deg);" v-if="lastOrderType === 'buy'">
                                            <path d="M5 13.47l1.41-1.41 5.1 5.1V3h1.99v14.15l5.09-5.09L20 13.47l-7.5 7.5-7.5-7.5z"
                                                  fill="currentColor"></path>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                             class="arrow-icon status-sell" v-if="lastOrderType === 'sell'">
                                            <path d="M5 13.47l1.41-1.41 5.1 5.1V3h1.99v14.15l5.09-5.09L20 13.47l-7.5 7.5-7.5-7.5z"
                                                  fill="currentColor"></path>
                                        </svg>
                                    </div>
                                    <div class="markPrice">{{userCurrency['fiatSymbol'] }}{{ parseFloat(lastPrice.toString()) > 0 ? formattedCurrency(this.calculatedPrice, this.minDecimalView) : '0.00' }}</div>
                                </div>
                                <div class="data-headings">
                                    <span>Price({{ selectedCoinTwo }})</span>
                                    <span>Amount({{ selectedCoinOne }})</span>
                                    <span>Total({{ selectedCoinTwo }})</span>
                                </div>
                                <div class="data" v-if="buyBook.length > 0">
                                    <div class="orderbook-progress" v-for="(sell, index) in buyBook" :key="index"
                                         v-on:click="selectOrderBook(sell)">
                                        <div class="progress-container">
                                            <div class="row-content">
                                                <div class="ask-light textGreen"
                                                     style="font-size: 12px; flex: 1 1 0; text-align: left;font-weight:normal;">
                                                    {{ sell.price < 0 ? "--" : customRoundValue(sell['price'], minDecimalView) }}
                                                </div>
                                                <div class="text">{{sell.total < 0 ? "--" : customRoundValue(sell['total'], minDecimal) }}</div>
                                                <div class="text">{{ sell.price < 0 && sell.total < 0 ? "--" : customRoundValue((getBigIntNumber(sell['price']).multipliedBy(getBigIntNumber(sell['total']))).toFixed(), minDecimalView) }}</div>
                                            </div>
                                            <div class="custom-progress-bar green-progress" v-if="sell.total > 0" :style="{ width: `${(sell.total - sell.remaining)*(100/sell.total)}%` }"></div>
                                            <div class="custom-progress-bar green-progress" v-else :style="{ width: `0%` }"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="data" v-else>
                                    <div class="orderbook-progress" v-for="(pair, index) in dummyPairList" :key="index"
                                         v-on:click="onSelectPair(index)">
                                        <div class="progress-container" style="display: flex; justify-content: space-between">
                                            <div class="shimmer-effect ml-2"></div>
                                            <div class="shimmer-effect"></div>
                                            <div class="shimmer-effect mr-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 p-0">
                            <div class="main-chart">
<!--                                <div class="tradingview-widget-container" v-if="!isPairLaunch">
                                    <div class="col-md-12 p-0">
                                        <div class="row m-0">
                                            <div class="col-md-6 timing">
                                                <div class="d-flex justify-content-start align-items-center py-2">
                                                    <span>Time</span>
                                                    <a href="javascript:void(0)" class="d-inline-block mx-2" id="3MINT" @click="chartTimeSelect('3', 'MINT')">3m</a>
                                                    <a href="javascript:void(0)" class="d-inline-block mx-2" id="15MINT" @click="chartTimeSelect('15', 'MINT')">15m</a>
                                                    <a href="javascript:void(0)" class="d-inline-block mx-2" id="1HOUR" @click="chartTimeSelect('1', 'HOUR')">1H</a>
                                                    <a href="javascript:void(0)" class="d-inline-block mx-2" id="4HOUR" @click="chartTimeSelect('4', 'HOUR')">4H</a>
                                                    <a href="javascript:void(0)" class="d-inline-block mx-2" id="1DAY" @click="chartTimeSelect('1', 'DAY')">1D</a>
                                                    <a href="javascript:void(0)" class="d-inline-block mx-2" id="1WEEK" @click="chartTimeSelect('1', 'WEEK')">1W</a>
                                                    <a href="javascript:void(0)" class="d-inline-block mx-2" id="1MONTH" @click="chartTimeSelect('1', 'MONTH')">1M</a>
                                                    <li href="javascript:void(0)" class="mx-2 caret d-none"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path fill="currentColor" d="m98 190.06l139.78 163.12a24 24 0 0 0 36.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62Z"/></svg>
                                                        <div class="caretSubmenu">
                                                            <div class="submenuHeading d-flex justify-content-between align-items-center">
                                                                <span class="textInvert">Select Intervals</span>
                                                                <a href="javascript:void(0)" class="customLink">Edit</a>
                                                            </div>
                                                            <div class="submenuData">
                                                                <a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('1', 'SEC')">1s</a>
                                                                <a class="chart-time-text active" href="javascript:void(0)" @click="chartTimeSelect('1', 'MINT')">1m</a>
                                                                <a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('3', 'MINT')">3m</a>
                                                                <a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('5', 'MINT')">5m</a>
                                                                <a class="chart-time-text active" href="javascript:void(0)" @click="chartTimeSelect('15', 'MINT')">15m</a>
                                                                <a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('30', 'MINT')">30m</a>
                                                                <a class="chart-time-text active" href="javascript:void(0)" @click="chartTimeSelect('1', 'HOUR')">1H</a>
                                                                <a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('2', 'HOUR')">2H</a>
                                                                <a class="chart-time-text active" href="javascript:void(0)" @click="chartTimeSelect('4', 'HOUR')">4H</a>
                                                                <a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('6', 'HOUR')">6H</a>
                                                                <a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('8', 'HOUR')">8H</a>
                                                                <a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('12', 'HOUR')">12H</a>
                                                                <a class="chart-time-text active" href="javascript:void(0)" @click="chartTimeSelect('1', 'DAY')">1D</a>
                                                                <a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('3', 'DAY')">3D</a>
                                                                <a class="chart-time-text active" href="javascript:void(0)" @click="chartTimeSelect('1', 'WEEK')">1W</a>
                                                                <a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('1', 'MONTH')">1M</a>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </div>
                                                &lt;!&ndash; <ul class="chart-time-list">
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('1', 'SEC')">1s</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('1', 'MINT')">1m</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('3', 'MINT')">3m</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('5', 'MINT')">5m</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('15', 'MINT')">15m</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('30', 'MINT')">30m</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('1', 'HOUR')">1H</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('2', 'HOUR')">2H</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('4', 'HOUR')">4H</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('6', 'HOUR')">6H</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('8', 'HOUR')">8H</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('12', 'HOUR')">12H</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('1', 'DAY')">1D</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('3', 'DAY')">3D</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('1', 'WEEK')">1W</a></li>
                                                    <li><a class="chart-time-text" href="javascript:void(0)" @click="chartTimeSelect('1', 'MONTH')">1M</a></li>
                                                </ul> &ndash;&gt;
                                            </div>
                                            <div class="col-md-6 timing" style="color: red"></div>
                                        </div>
                                    </div>
                                    <div class="chart-container">
                                        <div id="chartRef"></div>
                                    </div>
                                </div>-->
                                <TvChart :selectedSymbol="selectedPair" :changeTheme="theme" v-if="!isPairLaunch" @callback="onChartReady"/>
                                <div class="tradingViewCountdown" v-if="isPairLaunch">
                                    <h1 class="my-4 textInvert">{{ selectedCoinOne }}/{{ selectedCoinTwo }}</h1>
                                    <p class="textGray m-0">Trading Will Start At</p>
                                    <p class="textInvert m-0">
                                        <strong>{{ launchDateTime }}</strong>
                                    </p>
                                    <p class="textGray mt-3">Just Left</p>
                                    <div class="time d-inline-flex justify-content-between align-items-center">
                                        <div class="d-inline-flex justify-content-center align-items-center flex-column mr-5">
                                            <span class="days timeText">{{ cdDay }}</span><span class="textGray">Days</span>
                                        </div>
                                        <div class="d-inline-flex justify-content-center align-items-center flex-column mr-4">
                                            <span class="hours timeText">{{ cdHour }}</span><span class="textGray">Hours</span>
                                        </div>
                                        <span class="timeSeparater">:</span>
                                        <div class="d-inline-flex justify-content-center align-items-center flex-column mx-4">
                                            <span class="minutes timeText">{{ cdMin }}</span><span class="textGray">Minutes</span>
                                        </div>
                                        <span class="timeSeparater">:</span>
                                        <div class="d-inline-flex justify-content-center align-items-center flex-column ml-4">
                                            <span class="seconds timeText">{{ cdSec }}</span><span class="textGray">s</span>
                                        </div>
                                    </div>
                                    <h3 class="textPrimary mt-3 coming-soon">COMING SOON</h3>
                                </div>
                            </div>
                            <div class="market-trade">

                                <!--                                <div class="customNoti show">-->
                                <!--                                    <div class="d-inline-block">-->
                                <!--                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"-->
                                <!--                                             class="css-1kazfoq">-->
                                <!--                                            <path fill-rule="evenodd" clip-rule="evenodd"-->
                                <!--                                                  d="M12 21a9 9 0 100-18 9 9 0 000 18zm-1.25-5.5V18h2.5v-2.5h-2.5zm0-9.5v7h2.5V6h-2.5z"-->
                                <!--                                                  fill="currentColor"></path>-->
                                <!--                                        </svg>-->
                                <!--                                        <div data-bn-type="text" class="d-inline-block">Invite friends &amp; get $100-->
                                <!--                                            off on trading fees-->
                                <!--                                            <div class="d-inline-block">-->
                                <!--                                                <a target="_blank"-->
                                <!--                                                   href="/en/activity/referral/offers?utm_source=spot_trading"-->
                                <!--                                                   rel="noreferrer"> - Refer a friend &gt;</a>-->
                                <!--                                            </div>-->
                                <!--                                        </div>-->
                                <!--                                    </div>-->
                                <!--                                    <div class="closeNoti d-inline-block" @click="greet" name="Testing">-->
                                <!--                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"-->
                                <!--                                             class="css-tdpz7s">-->
                                <!--                                            <path d="M6.697 4.575L4.575 6.697 9.88 12l-5.304 5.303 2.122 2.122L12 14.12l5.303 5.304 2.122-2.122L14.12 12l5.304-5.303-2.122-2.122L12 9.88 6.697 4.575z"-->
                                <!--                                                  fill="currentColor"></path>-->
                                <!--                                        </svg>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                                <ul class="nav nav-pills" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-toggle="pill" href="#pills-trade-limit"
                                           role="tab"
                                           aria-selected="true">Limit</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="pill" href="#pills-market" role="tab"
                                           aria-selected="false">Market</a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="pills-trade-limit" role="tabpanel">
                                        <div class="d-flex justify-content-between">
                                            <div class="market-trade-buy">
                                                <span class="fs12 textGray">Avbl - <span
                                                    class="fs12 textInvert">{{ coinTwoBalance === '' ? '' : getFixedVal(coinTwoBalance, minDecimalView) }} {{
                                                        selectedCoinTwo
                                                    }}</span></span>
                                                <div class="input-group">
                                                    <input type="number" class="form-control" ref="lBuyPriceVal"
                                                           v-model="lbpVal" placeholder="Price"
                                                           @input="onBuyPriceChange($event.target.value)">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">{{ selectedCoinTwo }}</span>
                                                    </div>
                                                </div>
                                                <div class="input-group">
                                                    <input type="number" class="form-control" ref="lBuyAmtVal"
                                                           v-model="lBuyAmt"
                                                           placeholder="Amount"
                                                           @input="onBuyAmtChange($event.target.value)">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">{{ selectedCoinOne }}</span>
                                                    </div>
                                                </div>
                                                <div class="range">
                                                    <span class="rangeBG"></span>
                                                    <div class="customRange">
                                                        <a class="thumb lbuy1" href="javascript:void(0)"
                                                           @click="lBuy(0)" data-tooltip="0%"></a>
                                                        <a class="thumb lbuy2" href="javascript:void(0)" @click="lBuy(25)"
                                                           data-tooltip="25%"></a>
                                                        <a class="thumb lbuy3" href="javascript:void(0)" @click="lBuy(50)"
                                                           data-tooltip="50%"></a>
                                                        <a class="thumb lbuy4" href="javascript:void(0)" @click="lBuy(75)"
                                                           data-tooltip="75%"></a>
                                                        <a class="thumb lbuy5" href="javascript:void(0)" @click="lBuy(100)"
                                                           data-tooltip="100%"></a>
                                                    </div>
                                                </div>
                                                <div class="input-group">
                                                    <input type="number" class="form-control" v-model="totalBuyVal"
                                                           placeholder="Total"
                                                           @input="onBuyTotalChange($event.target.value)">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">{{ selectedCoinTwo }}</span>
                                                    </div>
                                                </div>
                                                <button v-if="isLogin" class="btn buy"
                                                        @click="createLimitBuyOrder" :disabled="isPairLaunch">Buy {{ selectedCoinOne }}
                                                </button>
                                                <div v-else class="bgPrimary text-center p-2 radius-4">
                                                    <router-link to="/signIn" class="link">Sign In</router-link>
                                                    <span class="mx-2">or</span>
                                                    <router-link to="/signUp" class="link">Sign Up</router-link>
                                                </div>
                                            </div>
                                            <div class="market-trade-sell">
                                                <span class="fs12 textGray">Avbl - <span
                                                    class="fs12 textInvert">{{ coinOneBalance === '' ? '' : getFixedVal(coinOneBalance, minDecimalView) }} {{
                                                        selectedCoinOne
                                                    }}</span></span>
                                                <div class="input-group">
                                                    <input type="number" class="form-control" ref="lSellPriceVal"
                                                           v-model="lspVal"
                                                           placeholder="Price"
                                                           @input="onSellPriceChange($event.target.value)">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">{{ selectedCoinTwo }}</span>
                                                    </div>
                                                </div>
                                                <div class="input-group">
                                                    <input type="number" class="form-control" ref="lSellAmtVal"
                                                           v-model="lSellAmt"
                                                           placeholder="Amount"
                                                           @input="onSellAmtChange($event.target.value)">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">{{ selectedCoinOne }}</span>
                                                    </div>
                                                </div>
                                                <div class="lsell_range">
                                                    <span class="lsell_rangeBG"></span>
                                                    <div class="lsell_customRange">
                                                        <a class="lsell_thumb lsell1" href="javascript:void(0)"
                                                           @click="lSell(0)" data-tooltip="0%"></a>
                                                        <a class="lsell_thumb lsell2" href="javascript:void(0)" @click="lSell(25)"
                                                           data-tooltip="25%"></a>
                                                        <a class="lsell_thumb lsell3" href="javascript:void(0)" @click="lSell(50)"
                                                           data-tooltip="50%"></a>
                                                        <a class="lsell_thumb lsell4" href="javascript:void(0)" @click="lSell(75)"
                                                           data-tooltip="75%"></a>
                                                        <a class="lsell_thumb lsell5" href="javascript:void(0)" @click="lSell(100)"
                                                           data-tooltip="100%"></a>
                                                    </div>
                                                </div>
                                                <!-- <ul class="market-trade-list">
                                                    <li><a @click="lSell(25)">25%</a></li>
                                                    <li><a @click="lSell(50)">50%</a></li>
                                                    <li><a @click="lSell(75)">75%</a></li>
                                                    <li><a @click="lSell(100)">100%</a></li>
                                                </ul> -->
                                                <div class="input-group">
                                                    <input type="number" class="form-control" v-model="totalSellVal"
                                                           placeholder="Total"
                                                           @input="onSellTotalChange($event.target.value)">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">{{ selectedCoinTwo }}</span>
                                                    </div>
                                                </div>
                                                <button v-if="isLogin" class="btn sell"
                                                        v-on:click="createLimitSellOrder" :disabled="isPairLaunch">Sell {{ selectedCoinOne }}
                                                </button>
                                                <div v-else class="bgPrimary text-center p-2 radius-4">
                                                    <router-link to="/signIn" class="link">Sign In</router-link>
                                                    <span class="mx-2">or</span>
                                                    <router-link to="/signUp" class="link">Sign Up</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-market" role="tabpanel">
                                        <div class="d-flex justify-content-between">
                                            <div class="market-trade-buy">
                                                <span class="fs12 textGray">Avbl - <span
                                                    class="fs12 textInvert">{{ coinTwoBalance === '' ? '' : getFixedVal(coinTwoBalance, minDecimalView) }} {{
                                                        selectedCoinTwo
                                                    }}</span></span>
                                                <div class="input-group">
                                                    <input type="number" class="form-control" ref="mBuyPriceVal"
                                                           v-model="mbpVal"
                                                           placeholder="Market" disabled>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">{{ selectedCoinTwo }}</span>
                                                    </div>
                                                </div>
                                                <div class="input-group">
                                                    <input type="number" class="form-control" ref="mBuyAmtVal"
                                                           v-model="mBuyAmt"
                                                           placeholder="Amount" @input="mBuyAmtChange($event.target.value)">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">{{ selectedCoinOne }}</span>
                                                    </div>
                                                </div>
                                                <div class="mBuy_range">
                                                    <span class="mBuy_rangeBG"></span>
                                                    <div class="mBuy_customRange">
                                                        <a class="mBuy_thumb mBuy1" href="javascript:void(0)"
                                                           @click="mBuy(0)" data-tooltip="0%"></a>
                                                        <a class="mBuy_thumb mBuy2" href="javascript:void(0)" @click="mBuy(25)"
                                                           data-tooltip="25%"></a>
                                                        <a class="mBuy_thumb mBuy3" href="javascript:void(0)" @click="mBuy(50)"
                                                           data-tooltip="50%"></a>
                                                        <a class="mBuy_thumb mBuy4" href="javascript:void(0)" @click="mBuy(75)"
                                                           data-tooltip="75%"></a>
                                                        <a class="mBuy_thumb mBuy5" href="javascript:void(0)" @click="mBuy(100)"
                                                           data-tooltip="100%"></a>
                                                    </div>
                                                </div>
                                                <!-- <ul class="market-trade-list">
                                                    <li><a @click="mBuy(25)">25%</a></li>
                                                    <li><a @click="mBuy(50)">50%</a></li>
                                                    <li><a @click="mBuy(75)">75%</a></li>
                                                    <li><a @click="mBuy(100)">100%</a></li>
                                                </ul> -->
                                                <button v-if="isLogin" class="btn buy"
                                                        @click="createMarketBuyOrder" :disabled="isPairLaunch">Buy {{ selectedCoinOne }}
                                                </button>
                                                <div v-else class="bgPrimary text-center p-2 radius-4">
                                                    <router-link to="/signIn" class="link">Sign In</router-link>
                                                    <span class="mx-2">or</span>
                                                    <router-link to="/signUp" class="link">Sign Up</router-link>
                                                </div>
                                            </div>
                                            <div class="market-trade-sell">
                                                <span class="fs12 textGray">Avbl - <span
                                                    class="fs12 textInvert">{{ coinOneBalance === '' ? '' : getFixedVal(coinOneBalance, minDecimalView) }} {{
                                                        selectedCoinOne
                                                    }}</span></span>
                                                <div class="input-group">
                                                    <input type="number" class="form-control" ref="mSellPriceVal"
                                                           v-model="mspVal"
                                                           placeholder="Market" disabled>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">{{ selectedCoinTwo }}</span>
                                                    </div>
                                                </div>
                                                <div class="input-group">
                                                    <input type="number" class="form-control" ref="mSellAmtVal"
                                                           v-model="mSellAmt"
                                                           placeholder="Amount" @input="mSellAmtChange($event.target.value)">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">{{ selectedCoinOne }}</span>
                                                    </div>
                                                </div>
                                                <div class="mSell_range">
                                                    <span class="mSell_rangeBG"></span>
                                                    <div class="mSell_customRange">
                                                        <a class="mSell_thumb mSell1" href="javascript:void(0)"
                                                           @click="mSell(0)" data-tooltip="0%"></a>
                                                        <a class="mSell_thumb mSell2" href="javascript:void(0)" @click="mSell(25)"
                                                           data-tooltip="25%"></a>
                                                        <a class="mSell_thumb mSell3" href="javascript:void(0)" @click="mSell(50)"
                                                           data-tooltip="50%"></a>
                                                        <a class="mSell_thumb mSell4" href="javascript:void(0)" @click="mSell(75)"
                                                           data-tooltip="75%"></a>
                                                        <a class="mSell_thumb mSell5" href="javascript:void(0)" @click="mSell(100)"
                                                           data-tooltip="100%"></a>
                                                    </div>
                                                </div>
                                                <!-- <ul class="market-trade-list">
                                                    <li><a @click="mSell(25)">25%</a></li>
                                                    <li><a @click="mSell(50)">50%</a></li>
                                                    <li><a @click="mSell(75)">75%</a></li>
                                                    <li><a @click="mSell(100)">100%</a></li>
                                                </ul> -->
                                                <button v-if="isLogin" class="btn sell"
                                                        v-on:click="createMarketSellOrder" :disabled="isPairLaunch">Sell {{ selectedCoinOne }}
                                                </button>
                                                <div v-else class="bgPrimary text-center p-2 radius-4">
                                                    <router-link to="/signIn" class="link">Sign In</router-link>
                                                    <span class="mx-2">or</span>
                                                    <router-link to="/signUp" class="link">Sign Up</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 p-0">

                    <div class="market-pairs">
                        <ul class="nav nav-pills" role="tablist" style="margin-top: 0;">
                            <li class="nav-item" @click="onSelectCoin(0)">
                                <a v-if="selectedCoinId === 0" class="nav-link active" data-toggle="pill" role="tab"
                                   href="" aria-selected="true">All</a>
                                <a v-else class="nav-link" data-toggle="pill" role="tab" aria-selected="true">All</a>
                            </li>
                            <li class="nav-item" v-for="(pairT, index) in pairTList" :key="index"
                                @click="onSelectCoin(pairT['coin']['coinId'])">
                                <a v-if="pairT['coin']['coinId'] === selectedCoinId" class="nav-link active"
                                   data-toggle="pill" role="tab" href="">{{ pairT['coin']['coinName'] }}</a>
                                <a v-else class="nav-link" data-toggle="pill" role="tab"
                                   href="">{{ pairT['coin']['coinName'] }}</a>
                            </li>
                            <a href="javascript:void(0)" class="openSearchPairCoin nav-link"><i class="icon ion-md-search"></i></a>
                        </ul>
                        <div class="searchPairList">
                            <div class="font-400">
                                <div class="custom-input-group textInvert">
                                    <input type="text" @input="searchPair()" class="form-control" id="myInput" placeholder="Search a pair">
                                    <div class="custom-input-group-append">
                                        <div class="custom-input-group-text closeSearchPairCoin"><i class="fas fa-times"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane fade show active" role="tabpanel">
                                <div class="data-headings">
                                    <span>Pairs</span>
                                    <span>Last Price</span>
                                    <span>Change</span>
                                </div>
                                <div class="data" v-if="pairList.length > 0">
                                    <div class="orderbook-progress" v-for="(pair, index) in pairList" :key="index"
                                         v-on:click="onSelectPair(index)">
                                        <div class="progress-container" :data-pair="pair['pairName']">
                                            <div class="row-content" id="pair-list">
                                                <div class="ask-light text">{{ pair['pairName'] }}</div>
                                                <div v-if="pair['orderType'] === 'buy'" class="text textGreen">{{ pair['pairPrice'] }}</div>
                                                <div v-else-if="pair['orderType'] === 'sell'" class="text textRed">{{ pair['pairPrice'] }}</div>
                                                <div class="text" v-else>{{ pair['pairPrice'] }}</div>
                                                <div v-if="pair['percentChange'] > 0" class="text textGreen">{{ pair['percentChange'] }}%</div>
                                                <div v-else-if="pair['percentChange'] < 0" class="text textRed">{{ pair['percentChange'] }}%</div>
                                                <div class="text textGreen" v-else>{{ pair['percentChange'] }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="data" v-else>
                                    <div class="orderbook-progress" v-for="(pair, index) in dummyPairList" :key="index"
                                         v-on:click="onSelectPair(index)">
                                        <div class="progress-container" style="display: flex; justify-content: space-between">
                                            <div class="shimmer-effect ml-2"></div>
                                            <div class="shimmer-effect"></div>
                                            <div class="shimmer-effect mr-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="market-history">
                        <ul class="nav nav-pills" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="pill" role="tab" @click="refreshRecentTrade">Recent Trades</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade show active" role="tabpanel">
                                <div class="data-headings">
                                    <span>Price({{ selectedCoinTwo }})</span>
                                    <span>Amount({{ selectedCoinOne }})</span>
                                    <span>Time(UTC)</span>
                                </div>
                                <div class="data" v-if="lastOrder.length > 0">
                                    <div class="orderbook-progress" v-for="(order, index) in lastOrder" :key="index">
                                        <div class="progress-container">
                                            <div class="row-content">
                                                <div v-if="order.type.toString() === '0'" class="text textGreen">{{ customRoundValue(order['price'], minDecimalView) }}</div>
                                                <div v-else class="text textRed">{{ customRoundValue(order['price'], minDecimalView) }}</div>
                                                <div class="text">{{ customRoundValue(order['amount'], minDecimal) }}</div>
                                                <div class="text">{{ order.newTime }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="data" v-else>
                                    <div class="orderbook-progress" v-for="(pair, index) in dummyPairList" :key="index"
                                         v-on:click="onSelectPair(index)">
                                        <div class="progress-container" style="display: flex; justify-content: space-between">
                                            <div class="shimmer-effect ml-2"></div>
                                            <div class="shimmer-effect"></div>
                                            <div class="shimmer-effect mr-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 p-0">
                    <div class="market-history market-order">
                        <ul class="nav nav-pills" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link px-4 py-2 active" data-toggle="pill" href="#openOrderTrading" role="tab" @click="onSelectMyOrder(3)">Open Orders</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link px-4 py-2" data-toggle="pill" href="#orderHistoryTrading" role="tab" @click="onSelectMyOrder(2)">Order history</a>
                            </li>
<!--                            <li class="nav-item">-->
<!--                                <a class="nav-link" data-toggle="pill" href="#tradeHistoryTrading" role="tab" @click="onSelectMyOrder(1)">Trade history</a>-->
<!--                            </li>-->
                        </ul>
                        <div class="tab-content" v-if="isLogin">
                            <div class="tab-pane fade show active" role="tabpanel" id="openOrderTrading">
                                <div class="d-flex justify-content-between market-order-item">
                                    <table class="table" v-if="orderList.length > 0">
                                        <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Pair</th>
                                            <th>Type</th>
                                            <th>Side</th>
                                            <th>Price</th>
                                            <th>Amount</th>
                                            <th>Filled</th>
                                            <th>Total</th>
                                            <th class="text-right"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(order, index) in orderList" :key="index">
                                                <td>{{ order['newTime'] }}</td>
                                                <td>{{ selectedCoinOne }}/{{ selectedCoinTwo }}</td>
                                                <td>{{ order['tradeMarketType'].toString() === '1' ? 'Limit' : 'Market' }}</td>
                                                <td class="textGreen" v-if="order['tradeOrderType'].toString() === '0'">{{ 'Buy' }}</td>
                                                <td class="textRed" v-else>{{ 'Sell' }}</td>
                                                <td>{{ getFixedVal(order['tradePrice'], minDecimalView) }}</td>
                                                <td>{{ getFixedVal(order['tradeAmount'], minDecimal) }}</td>
                                                <td>{{ ((getBigIntNumber(order['tradeAmountProcess']).dividedBy(getBigIntNumber(order['tradeAmount']))).multipliedBy(getBigIntNumber('100'))).toFixed(2) }}%</td>
                                                <td v-if="order['tradeOrderType'].toString() === '0'">{{ (getBigIntNumber(order['tradeAmount'])).toFixed(minDecimalView) }} {{ selectedCoinOne }}</td>
                                                <td v-else>{{ (getBigIntNumber(order['tradePrice']).multipliedBy(getBigIntNumber(order['tradeAmount']))).toFixed(minDecimalView) }} {{ selectedCoinTwo }}</td>
                                                <td class="text-right"><a href="javascript:void(0)" @click="deleteTrade(order['tradeID'])" class="deleteTrade"><i class="icon ion-md-trash textRed"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <span class="no-data" v-else>
                                        <i class="icon ion-md-document"></i>
                                        No data
                                    </span>
                                </div>
                            </div>
                            <div class="tab-pane" role="tabpanel" id="orderHistoryTrading">
                                <div class="d-flex justify-content-between market-order-item">
                                    <table class="table" v-if="orderList.length > 0">
                                        <thead>
                                        <tr>
<!--                                            <th class="tdIcon"></th>-->
                                            <th>Order Time</th>
                                            <th>Pair</th>
                                            <th>Type</th>
                                            <th>Side</th>
<!--                                            <th>Average</th>-->
                                            <th>Price</th>
<!--                                            <th>Executed</th>-->
                                            <th>Amount</th>
                                            <th>Filled</th>
                                            <th>Total</th>
<!--                                            <th class="customFlex">Trigger Condition</th>-->
<!--                                            <th class="text-right"></th>-->
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(order, index) in orderList" :key="index">
<!--                                            <td class="tdIcon"><i class="icon ion-md-add"></i></td>-->

                                            <td>{{ order['newTime'] }}</td>
                                            <td>{{ selectedCoinOne }}/{{ selectedCoinTwo }}</td>
                                            <td>{{ order['tradeMarketType'].toString() === '1' ? 'Limit' : 'Market' }}</td>
                                            <td class="textGreen" v-if="order['tradeOrderType'].toString() === '0'">{{ 'Buy' }}</td>
                                            <td class="textRed" v-else>{{ 'Sell' }}</td>
<!--                                            <td>0.00000147</td>-->
                                            <td>{{ getFixedVal(order['tradePrice'], minDecimalView) }}</td>
<!--                                            <td>7,329.570</td>-->
                                            <td>{{ getFixedVal(order['tradeAmount'], minDecimal) }}</td>
                                            <td v-if="order['tradeCompleted'].toString() === '1'">100%</td>
                                            <td v-else>Cancelled</td>
                                            <td v-if="order['tradeOrderType'].toString() === '0'">{{ (getBigIntNumber(order['tradeTotal'])).toFixed(minDecimalView) }} {{ selectedCoinOne }}</td>
                                            <td v-else>{{ getBigIntNumber(order['tradeTotal']).toFixed(minDecimalView) }} {{ selectedCoinTwo }}</td>
                                            <!--                                            <td class="customFlex">&#45;&#45;</td>-->
<!--                                            <td class="text-right"><a href="javascript:void(0)" @click="deleteTrade($event.target)" class="deleteTrade"><i class="icon ion-md-trash"></i></a></td>-->
                                        </tr>
                                        </tbody>
                                    </table>

                                    <span class="no-data" v-else>
                                        <i class="icon ion-md-document"></i>
                                        No data
                                    </span>
                                </div>
                            </div>
<!--                            <div class="tab-pane" role="tabpanel" id="tradeHistoryTrading">-->
<!--                                <div class="d-flex justify-content-between market-order-item">-->
<!--                                    <table class="table">-->
<!--                                        <thead>-->
<!--                                        <tr>-->
<!--                                            <th>Date</th>-->
<!--                                            <th>Pair</th>-->
<!--                                            <th>Side</th>-->
<!--                                            <th>Price</th>-->
<!--                                            <th>Executed</th>-->
<!--                                            <th>Fee</th>-->
<!--                                            <th>Role</th>-->
<!--                                            <th>Total</th>-->
<!--                                            <th class="text-right">Total BUSD <i data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top" class="icon ion-md-information-circle-outline"></i></th>-->
<!--                                        </tr>-->
<!--                                        </thead>-->
<!--                                        <tbody>-->
<!--                                        &lt;!&ndash; <tr v-for="(order, index) in orderList" :key="index"-->
<!--                                            class="d-flex justify-content-between market-order-item">-->
<!--                                            <td>{{ order.newTime }}</td>-->
<!--                                            <td v-if="order.tradeMarketType === 1">Limit</td>-->
<!--                                            <td v-else>Market</td>-->
<!--                                            <td v-if="order.tradeOrderType === 0" class="green">Buy</td>-->
<!--                                            <td v-else class="red">Sell</td>-->
<!--                                            <td>{{ getFixedVal(order.tradePrice, minDecimalView) }}</td>-->
<!--                                            <td>{{ getFixedVal(order.tradeAmount, minDecimalView) }}</td>-->
<!--                                            <td v-if="order.tradeCompleted.toString() === '1'"><i class="icon ion-md-checkmark-circle-outline green"></i></td>-->
<!--                                            <td v-else-if="order.tradeCompleted.toString() === '0'"><i class="icon ion-md-alarm yellow" @click="cancelOrder(order['tradeID'])"></i></td>-->
<!--                                            <td v-else><i class="icon ion-md-close-circle-outline red"></i></td>-->
<!--                                        </tr> &ndash;&gt;-->
<!--                                        <tr>-->
<!--                                            <td>05-26 13:18:43</td>-->
<!--                                            <td><span class="text-warning">PEPU/USDT</span></td>-->
<!--                                            <td><span class="textGreen">Buy</span></td>-->
<!--                                            <td>0.00000147</td>-->
<!--                                            <td>7,329.570</td>-->
<!--                                            <td>7,329.57000000 PEPE</td>-->
<!--                                            <td>Teker</td>-->
<!--                                            <td>10.77446790 USDT</td>-->
<!--                                            <td class="text-right">10.85 BUSD</td>-->
<!--                                        </tr>-->
<!--                                        </tbody>-->
<!--                                    </table>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                        <div class="d-flex justify-content-center align-items-center w-100 h-100" v-else>
                            <div>
                                <router-link to="/signIn" class="link">Sign In </router-link>
                                <div class="d-inline-block mid-text px-2">or</div>
                                <router-link to="/signUp" class="link"> Sign Up</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {toast, Type} from "@/util/toast";
import {cancelOrderById, createSpotOrder, getAllOrderList, getPairList, getPairTitleList} from "@/restApi/trade_api";
import {getBalance, log, MsgType} from "@/util/constant";
import {mapActions, mapMutations, mapState} from "vuex";
import {CHECK_USER_INFO_ACTION, LOADING_STATE} from "@/util/store.constent";
import {computed, reactive, toRefs, watch} from "vue";
import Store from "@/store/store";
import LimitOrderValidation from "@/services/limitorder.validation";
import MarketOrderValidation from "@/services/marketorder.validation";
import BigNumber from "bignumber.js";
// import {createChart, CrosshairMode} from "lightweight-charts";
import {onBeforeRouteUpdate} from "vue-router";

import TvChart from "@/components/parcel/TvChart.vue";

let selectedPairId = '';
let selectedCoinOneId = '';
let selectedCoinTwoId = '';
let ws;

// let chart;
// let candlestickSeries;
// let volumeSeries;
export default {
    name: "TradingPage",
    components: {TvChart},
    mounted() {
        document.title = 'BitRish Exchange Trading';
        window.removeEventListener('beforeunload', this.disconnectWebSocket);
    },
    data() {
        return {
            defaultWidth: '100%',
            selectedCoinId: 0,
            selectedPair: 'BTC/USDT',
            lbpVal: '',
            lBuyAmt: '',
            lspVal: '',
            lSellAmt: '',
            mbpVal: '',
            mBuyAmt: '',
            mspVal: '',
            mSellAmt: '',
            pairList: [],
            pairTList: [],
            orderList: [],
            lastOrder: [],
            buyBook: [],
            sellBook: [],
            lastPrice: '0',
            minDecimal: 4,
            minDecimalView: 2,
            minBuyDecimalVal: '0.001',
            minSellDecimalVal: '0.001',
            lastOrderType: '',
            selectedCoinOne: '',
            selectedCoinOneIcon: '',
            selectedCoinTwo: '',
            coinOneBalance: '',
            coinTwoBalance: '',
            chartData: [],
            orderType: 3,
            totalBuyVal: '',
            totalSellVal: '',
            randomWidth: '',
            price: 0,
            chartBgColor : '#131722',
            chartTextColor : '#d1d4dc',
            selectedChartTime: '15MINT',
            statsPriceChange: '0',
            statsPerChange: '0',
            statsVolume: '0',
            statsHigh: '0',
            statsLow: '0',
            tradeIdForDelete: '',
            cdMilliTime: 0,
            launchDateTime: '',
            cdInterval: null,
            cdSec: '00',
            cdMin: '00',
            cdHour: '00',
            cdDay: '00',
            isPairLaunch: false,
            lastIntervelID: null,
            refreshDataTimeout: null,
            oneUsdPrice: '0',
            fPrice:'0',
            calculatedPrice: 0,
            dummyPairList: [],
            showDummyPairList: false,
        }
    },
    async created() {
        this.dummyPairList = [];
        for (let i = 0; i < 22; i++) {
            const m = {};
            m['t'] = 1;
            this.dummyPairList.push(m);
        }
        this.refreshBalAndOrderList();
        await this.checkUser;
        this.resetAllData();
        this.initSocket();
        await this.getPairTitles();
        await this.getPlist(this.selectedCoinId, true);

        if (this.isLogin) {
            if (selectedPairId !== null && selectedPairId !== '') {
                await this.onSelectMyOrder(3);
                this.refreshBalAndOrderList();
            }

        }
    },
    beforeRouteLeave() {
        log('socket disconnect on page close', MsgType.log);
        this.disconnectWebSocket();
    },
    methods: {
      onChartReady(data) {
        const rQuery = this.$route.params.pair.replace('_', '/');
        console.log('On chart Ready', data);
        console.log('pair from url == ', rQuery);
        this.selectedPair = rQuery;
      },
        ...mapMutations({
            showLoading: LOADING_STATE,
        }),
        deleteTrade(id){
            this.tradeIdForDelete = id.toString();
            document.querySelector(".customPopup").classList.add("show");
        },
        closeCustomPopup(){
            document.querySelector(".customPopup").classList.remove("show");
        },
        disconnectWebSocket() {
            log('socket disconnect on page close', MsgType.log);
            if(this.refreshDataTimeout) {
                clearTimeout(this.refreshDataTimeout);
            }
            if (ws) {
                ws.close(); // close the WebSocket connection
                ws = null;
            }
        },
        refreshRecentTrade() {
            // console.log('Recent Trade req === ', selectedPairId);
            if(selectedPairId.toString() !== '') {
                this.waitForSocketConnection(ws, () => {
                    const req = {
                        "type": "newOrder",
                        "pairId": selectedPairId
                    }
                    try {
                        if(ws) {
                            ws.send(JSON.stringify(req));
                        }
                    } catch (e) {
                        log(e, MsgType.error);
                    }
                });
            }
        },
        async onSelectPair(index) {
            selectedPairId = this.pairList[index].pairId;
            this.resetAllData();
            this.selectedCoinOne = this.pairList[index].coin1.coinName;
            this.selectedCoinTwo = this.pairList[index].coin2.coinName;
            this.selectedPair = this.pairList[index]['pairName'];
            console.log('Selected Pair == ', this.selectedPair);
            this.selectedCoinOneIcon = this.pairList[index]['coin1']['coinIcon'];
            this.minDecimal = this.pairList[index]['pairDecimalSupport'];
            this.minDecimalView = this.pairList[index]['pairDecimalView'];
            this.minBuyDecimalVal = this.pairList[index]['pairMinQut'];
            this.minSellDecimalVal = this.pairList[index]['pairMinQut'];

            this.statsPriceChange = '0';
            this.statsPerChange = '0';
            this.statsVolume = '0';
            this.statsHigh = '0';
            this.statsLow = '0';

            this.oneUsdPrice = this.pairList[index]['pairFiatPriceUSD'];
            this.fPrice = this.userCurrency['fiatPriceOneUSD'];
            this.calculatedPrice = parseFloat(this.fPrice.toString()) * parseFloat(this.oneUsdPrice.toString());
            document.title = '0.00   |   ' + this.selectedCoinOne + '/' + this.selectedCoinTwo + '   |   BitRish Exchange Trading';
            if (this.isLogin) {
                this.coinOneBalance = (await getBalance(this.pairList[index].coin1.coinId)).bal;
                this.coinTwoBalance = (await getBalance(this.pairList[index].coin2.coinId)).bal;
                this.onSelectMyOrder(this.orderType);
            }
            selectedCoinTwoId = this.pairList[index]['coin2']['coinId'];
            selectedCoinOneId = this.pairList[index]['coin1']['coinId'];
            this.getPriceVal(this.pairList[index]['coin2']['coinId'], this.userCurrency['fiatID']);
            this.waitForSocketConnection(ws, () => {
                log('Pair Change', MsgType.log);
                log(this.pairList[index].pairId, MsgType.log);
                this.sendMessage(this.pairList[index].pairId);
            });
            this.lbpVal = this.lastPrice;
            this.lspVal = this.lastPrice;
            this.updatePair(this.selectedCoinOne+'_'+this.selectedCoinTwo);
            if(this.lastIntervelID) clearInterval(this.lastIntervelID);
            if(this.pairList[index]['pairLaunched'].toString().toLowerCase() === 'false') {
                this.stopCountdown();
                this.cdMilliTime = this.pairList[index]['pairLaunchedTime'] === null ? 0 : parseInt(this.pairList[index]['pairLaunchedTime'].toString());
                this.isPairLaunch = true;
                const timestamp = this.pairList[index]['pairLaunchedTime'] === null ? 0 : parseInt(this.pairList[index]['pairLaunchedTime'].toString());
                const date = new Date(timestamp);
                const year = date.getUTCFullYear();
                const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                const day = String(date.getUTCDate()).padStart(2, '0');
                const hours = String(date.getUTCHours()).padStart(2, '0');
                const minutes = String(date.getUTCMinutes()).padStart(2, '0');
                const seconds = String(date.getUTCSeconds()).padStart(2, '0');


                this.launchDateTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
                // if(chart) {
                //     candlestickSeries.setData([]);
                //     volumeSeries.setData([]);
                //     chart = null;
                // }
                this.startCountdown();
            } else {
                this.isPairLaunch = false;
                await new Promise(r => setTimeout(r, 150));
                // if(!chart) {
                //     this.loadTradeChart();
                // }
                // this.chartTimeSelect('15', 'MINT');
                this.stopCountdown();
            }
        },
        async onSelectCoin(coinId) {
            this.selectedCoinId = coinId;
            await this.getPlist(this.selectedCoinId, false);
        },
        onSelectMyOrder(type) {
            this.orderList = [];
            this.orderType = type;
            this.getAllOrder(type, selectedPairId);
        },
        async createLimitBuyOrder() {
            const validation = new LimitOrderValidation(selectedPairId, this.$refs.lBuyPriceVal.value, this.$refs.lBuyAmtVal.value, this.minBuyDecimalVal, this.selectedCoinOne, 0);
            const validationError = validation.checkValidation();
            if(validationError === '') {
                this.showLoading(true);
                const {pairData, error} = await createSpotOrder(selectedPairId, this.$refs.lBuyPriceVal.value, this.$refs.lBuyAmtVal.value, "buy", "limit");
                try {
                    if (error.value === null) {
                        if (JSON.parse(pairData.value).status) {
                            this.coinOneBalance = (await getBalance(selectedCoinOneId)).bal;
                            this.coinTwoBalance = (await getBalance(selectedCoinTwoId)).bal;
                            this.lbpVal = this.lastPrice;
                            this.lBuyAmt = '';
                            this.totalBuyVal = '';
                            toast(JSON.parse(pairData.value).message, Type.success);
                            this.showLoading(false);
                        } else {
                            toast(JSON.parse(pairData.value).message, Type.danger);
                            this.showLoading(false);
                        }
                    } else {
                        toast('Something Went Wrong', Type.danger);
                        this.showLoading(false);
                    }
                } catch (e) {
                    log(e, MsgType.error);
                    this.showLoading(false);
                }
            } else {
                toast(validationError, Type.danger);
            }
        },
        async createLimitSellOrder() {
            const validation = new LimitOrderValidation(selectedPairId, this.$refs.lSellPriceVal.value, this.$refs.lSellAmtVal.value, this.minSellDecimalVal, this.selectedCoinOne,1);
            const validationError = validation.checkValidation();
            if(validationError === '') {
                this.showLoading(true);
                const {pairData, error} = await createSpotOrder(selectedPairId, this.$refs.lSellPriceVal.value, this.$refs.lSellAmtVal.value, "sell", "limit");
                try {
                    if (error.value === null) {
                        if (JSON.parse(pairData.value).status) {
                            this.coinOneBalance = (await getBalance(selectedCoinOneId)).bal;
                            this.coinTwoBalance = (await getBalance(selectedCoinTwoId)).bal;
                            this.lspVal = this.lastPrice;
                            this.lSellAmt = '';
                            this.totalSellVal = '';
                            toast(JSON.parse(pairData.value).message, Type.success);
                            this.showLoading(false);
                        } else {
                            toast(JSON.parse(pairData.value).message, Type.danger);
                            this.showLoading(false);
                        }
                    } else {
                        toast('Something Went Wrong', Type.danger);
                        this.showLoading(false);
                    }
                } catch (e) {
                    log(e, MsgType.error);
                    this.showLoading(false);
                }
            } else {
                toast(validationError, Type.danger);
            }
        },
        async createMarketBuyOrder() {
            const validation = new MarketOrderValidation(selectedPairId, this.$refs.mBuyAmtVal.value, this.minBuyDecimalVal, this.selectedCoinOne, 0);
            const validationError = validation.checkValidation();
            if(validationError === '') {
                this.showLoading(true);
                const {pairData, error} = await createSpotOrder(selectedPairId, 1, this.$refs.mBuyAmtVal.value, "buy", "market");
                try {
                    if (error.value === null) {
                        if (JSON.parse(pairData.value).status) {
                            this.coinOneBalance = (await getBalance(selectedCoinOneId)).bal;
                            this.coinTwoBalance = (await getBalance(selectedCoinTwoId)).bal;
                            this.mbpVal = '';
                            this.mBuyAmt = '';
                            toast(JSON.parse(pairData.value).message, Type.success);
                            this.showLoading(false);
                        } else {
                            toast(JSON.parse(pairData.value).message, Type.danger);
                            this.showLoading(false);
                        }
                    } else {
                        toast('Something Went Wrong', Type.danger);
                        this.showLoading(false);
                    }
                } catch (e) {
                    log(e, MsgType.error);
                    this.showLoading(false);
                }
            } else {
                toast(validationError, Type.danger);
            }
        },
        async createMarketSellOrder() {
            const validation = new MarketOrderValidation(selectedPairId, this.$refs.mSellAmtVal.value, this.minSellDecimalVal, this.selectedCoinOne, 1);
            const validationError = validation.checkValidation();
            if(validationError === '') {
                this.showLoading(true);
                const {pairData, error} = await createSpotOrder(selectedPairId, 1, this.$refs.mSellAmtVal.value, "sell", "market");
                try {
                    if (error.value === null) {
                        if (JSON.parse(pairData.value).status) {
                            this.coinOneBalance = (await getBalance(selectedCoinOneId)).bal;
                            this.coinTwoBalance = (await getBalance(selectedCoinTwoId)).bal;
                            this.mspVal = '';
                            this.mSellAmt = '';
                            toast(JSON.parse(pairData.value).message, Type.success);
                            this.showLoading(false);
                        } else {
                            toast(JSON.parse(pairData.value).message, Type.danger);
                            this.showLoading(false);
                        }
                    } else {
                        toast('Something Went Wrong', Type.danger);
                        this.showLoading(false);
                    }
                } catch (e) {
                    log(e, MsgType.error);
                    this.showLoading(false);
                }
            }else {
                toast(validationError, Type.danger);
            }
        },
        lBuy(per) {
            if(this.isLogin) {
                this.lBuyAmt = parseFloat(this.coinTwoBalance) * (parseFloat(per) / 100);
                this.lBuyAmt = parseFloat(this.coinTwoBalance) * (parseFloat(per) / 100);
                const thumbs = document.querySelectorAll(".thumb");
                for (const thumb of thumbs) {
                    thumb.classList.remove('active');
                }
                if(per < 100){
                    if(per === 75){
                        document.querySelector(".lbuy1").classList.add("active");
                        document.querySelector(".lbuy2").classList.add("active");
                        document.querySelector(".lbuy3").classList.add("active");
                        document.querySelector(".lbuy4").classList.add("active");
                    }else if(per === 50){
                        document.querySelector(".lbuy1").classList.add("active");
                        document.querySelector(".lbuy2").classList.add("active");
                        document.querySelector(".lbuy3").classList.add("active");
                    }else if(per === 25){
                        document.querySelector(".lbuy1").classList.add("active");
                        document.querySelector(".lbuy2").classList.add("active");
                    }else{
                        document.querySelector(".lbuy1").classList.add("active");
                    }
                }else if(per >= 100){
                    for (const thumb of thumbs) {
                        thumb.classList.add('active');
                    }
                }
                let rangeBG = document.querySelector(".rangeBG");
                rangeBG.style.width = per + '%';
            }
        },
        lSell(per) {
            if(this.isLogin){
                this.lSellAmt = parseFloat(this.coinOneBalance) * (parseFloat(per) / 100);
                const thumbs = document.querySelectorAll(".lsell_thumb");
                for (const thumb of thumbs) {
                    thumb.classList.remove('active');
                }
                if(per < 100){
                    if(per === 75){
                        document.querySelector(".lsell1").classList.add("active");
                        document.querySelector(".lsell2").classList.add("active");
                        document.querySelector(".lsell3").classList.add("active");
                        document.querySelector(".lsell4").classList.add("active");
                    }else if(per === 50){
                        document.querySelector(".lsell1").classList.add("active");
                        document.querySelector(".lsell2").classList.add("active");
                        document.querySelector(".lsell3").classList.add("active");
                    }else if(per === 25){
                        document.querySelector(".lsell1").classList.add("active");
                        document.querySelector(".lsell2").classList.add("active");
                    }else{
                        document.querySelector(".lsell1").classList.add("active");
                    }
                }else if(per >= 100){
                    for (const thumb of thumbs) {
                        thumb.classList.add('active');
                    }
                }
                let rangeBG = document.querySelector(".lsell_rangeBG");
                rangeBG.style.width = per + '%';
            }
        },
        mBuy(per) {
            if(this.isLogin) {
                this.mBuyAmt = parseFloat(this.coinOneBalance) * (parseFloat(per) / 100);
                const thumbs = document.querySelectorAll(".mBuy_thumb");
                for (const thumb of thumbs) {
                    thumb.classList.remove('active');
                }
                if(per < 100){
                    if(per === 75){
                        document.querySelector(".mBuy1").classList.add("active");
                        document.querySelector(".mBuy2").classList.add("active");
                        document.querySelector(".mBuy3").classList.add("active");
                        document.querySelector(".mBuy4").classList.add("active");
                    }else if(per === 50){
                        document.querySelector(".mBuy1").classList.add("active");
                        document.querySelector(".mBuy2").classList.add("active");
                        document.querySelector(".mBuy3").classList.add("active");
                    }else if(per === 25){
                        document.querySelector(".mBuy1").classList.add("active");
                        document.querySelector(".mBuy2").classList.add("active");
                    }else{
                        document.querySelector(".mBuy1").classList.add("active");
                    }
                }else if(per >= 100){
                    for (const thumb of thumbs) {
                        thumb.classList.add('active');
                    }
                }
                let rangeBG = document.querySelector(".mBuy_rangeBG");
                rangeBG.style.width = per + '%';
            }
        },
        mSell(per) {
            if(this.isLogin) {
                this.mSellAmt = parseFloat(this.coinOneBalance) * (parseFloat(per) / 100);
                const thumbs = document.querySelectorAll(".mSell_thumb");
                for (const thumb of thumbs) {
                    thumb.classList.remove('active');
                }
                if(per < 100){
                    if(per === 75){
                        document.querySelector(".mSell1").classList.add("active");
                        document.querySelector(".mSell2").classList.add("active");
                        document.querySelector(".mSell3").classList.add("active");
                        document.querySelector(".mSell4").classList.add("active");
                    }else if(per === 50){
                        document.querySelector(".mSell1").classList.add("active");
                        document.querySelector(".mSell2").classList.add("active");
                        document.querySelector(".mSell3").classList.add("active");
                    }else if(per === 25){
                        document.querySelector(".mSell1").classList.add("active");
                        document.querySelector(".mSell2").classList.add("active");
                    }else{
                        document.querySelector(".mSell1").classList.add("active");
                    }
                }else if(per >= 100){
                    for (const thumb of thumbs) {
                        thumb.classList.add('active');
                    }
                }
                let rangeBG = document.querySelector(".mSell_rangeBG");
                rangeBG.style.width = per + '%';
            }
        },
        onMessageWS(event) {
            const eventData = JSON.parse(event.data);
            try {
                if (eventData.type === "newOrder") {
                    try {
                        if (eventData['pairID'].toString() === selectedPairId.toString()) {
                            this.lastOrder = eventData.data;
                        }
                    } catch (e) {
                        log(e, MsgType.error);
                    }
                }

                if (eventData.type === "newOrderLive") {
                    if (this.lastOrder.length > 25) {
                        this.lastOrder.pop();
                    }
                    for (let i = 0; i < (eventData.data.length > 25 ? 25 : eventData.data.length); i++) {
                        try {
                            if (eventData.data[i]['pairID'].toString() === selectedPairId.toString()) {
                                this.lastOrder.unshift(eventData.data[i]);
                            }
                        } catch (e) {
                            log(e, MsgType.error);
                        }
                    }
                }

                if (eventData.type === "orderBookBuy") {
                    if (eventData['data']['pairID'].toString() === selectedPairId.toString()) {
                        let jsonData = eventData.data.buyBook;
                        if (jsonData.length > 17) {
                            jsonData = jsonData.slice(0, 17)
                        } else {
                            const m = 17 - jsonData.length;
                            for (let i = 0; i < m; i++) {
                                const m = {
                                    price: -1,
                                    total: -1,
                                    remaining: 0,
                                    remainingPercent: 0,
                                    remainingPercent2: 0,
                                };
                                jsonData.push(m);
                            }
                        }
                        if (jsonData.length > 0) {
                            this.buyBook = jsonData;
                        }
                    }
                }

                if (eventData.type === "orderBookSell") {
                    if (eventData['data']['pairID'].toString() === selectedPairId.toString()) {
                        let jsonData = eventData.data.sellBook;
                        if (jsonData.length > 17) {
                            jsonData = jsonData.slice(-17)
                        } else {
                            const m = 17 - jsonData.length;
                            for (let i = 0; i < m; i++) {
                                const m = {
                                    price: -1,
                                    total: -1,
                                    remaining: 0,
                                    remainingPercent: 0,
                                    remainingPercent2: 0,
                                };
                                jsonData.push(m);
                            }
                        }
                        if (jsonData.length > 0) {
                            this.sellBook = jsonData;
                        } else {
                            this.sellBook = [];
                        }
                    }
                }

                if (eventData.type === "priceUpdate") {
                    if (eventData['data']['pairID'].toString() === selectedPairId.toString()) {
                        this.lastPrice = eventData['data']['pairPrice'];
                        document.title = this.lastPrice + '   |   ' + this.selectedCoinOne + '/' + this.selectedCoinTwo + '   |   BitRish Exchange Trading';
                        this.lastOrderType = eventData['data']['pairPriceColor'];
                        if (this.lbpVal === '0') {
                            this.lbpVal = this.lastPrice;
                        }
                        if (this.lspVal === '0') {
                            this.lspVal = this.lastPrice;
                        }
                    }
                    for (let i = 0; i < this.pairList.length; i++) {
                        if (eventData['data']['pairID'].toString() === this.pairList[i]['pairId'].toString()) {
                            this.pairList[i]['pairPrice'] = this.customRoundValue(eventData['data']['pairPrice'], this.pairList[i]['pairDecimalView']);
                            this.pairList[i]['orderType'] = eventData['data']['pairPriceColor'];
                        }
                    }
                }

                // if (eventData.type === "chartData") {
                //     if (eventData.pairID.toString() === selectedPairId.toString()) {
                //         for (let i = 0; i < eventData.data.t.length; i++) {
                //             const row = {
                //                 time: eventData.data.t[i],
                //                 open: eventData.data.o[i],
                //                 high: eventData.data.h[i],
                //                 low: eventData.data.l[i],
                //                 close: eventData.data.c[i]
                //             };
                //             const vRow = {
                //                 time: eventData.data.t[i],
                //                 value: eventData.data.v[i],
                //             };
                //             candlestickSeries.update(row);
                //             volumeSeries.update(vRow);
                //         }
                //     }
                // }
                //
                // if (eventData.type === "chartDataLive") {
                //     if (eventData.pairID.toString() === selectedPairId.toString()) {
                //         if(eventData.period.toString() + eventData.periodType.toString() === this.selectedChartTime) {
                //             const row = {
                //                 time: eventData.data.interval,
                //                 open: eventData.data.open,
                //                 high: eventData.data.high,
                //                 low: eventData.data.low,
                //                 close: eventData.data.close
                //             };
                //             const vRow = {
                //                 time: eventData.data.interval,
                //                 value: eventData.data.volume,
                //             };
                //             candlestickSeries.update(row);
                //             volumeSeries.update(vRow);
                //         }
                //
                //     }
                // }

                if (eventData.type === 'fiatPrice') {
                    if (selectedCoinTwoId.toString() === eventData['data']['cID'].toString()) {
                        if (this.userCurrency['fiatID'].toString() === eventData['data']['fID'].toString()) {
                            this.price = eventData['data']['price'];
                        }
                    }
                }

                if (eventData.type === 'priceStats') {
                    if (selectedPairId.toString() === eventData['data']['pairID'].toString()) {
                        this.statsPriceChange = eventData['data']['priceChange'].toString();
                        this.statsPerChange = eventData['data']['percentChange'].toString();
                        this.statsLow = eventData['data']['low'].toString();
                        this.statsHigh = eventData['data']['high'].toString();
                        this.statsVolume = eventData['data']['volume'].toString();
                    }

                    for (let i = 0; i < this.pairList.length; i++) {
                        if (eventData['data']['pairID'].toString() === this.pairList[i]['pairId'].toString()) {
                            this.pairList[i]['percentChange'] = this.getFixedVal(eventData['data']['percentChange'].toString(), 2);
                        }
                    }
                }

                if (eventData.type === 'fiatPriceOneUSD') {
                    if (this.userCurrency['fiatID'].toString() === eventData['data']['fID'].toString()) {
                        this.fPrice = eventData['data']['price'];
                        this.calculatedPrice = parseFloat(this.fPrice.toString()) * parseFloat(this.oneUsdPrice.toString());
                    }
                }

                if (eventData.type === 'pairPriceUSD') {
                    if (selectedCoinOneId.toString() === eventData['data']['cID'].toString()) {
                        this.oneUsdPrice = eventData['data']['price'];
                        this.calculatedPrice = parseFloat(this.fPrice.toString()) * parseFloat(this.oneUsdPrice.toString());
                    }
                }

            } catch (e) {
                log('WS message Error', MsgType.error);
                log(e, MsgType.error);
            }
        },
        resetAllData() {
            this.lastPrice = '0';
            this.price = 0;
            this.lastOrderType = '';
            this.buyBook = [];
            this.sellBook = [];
            // for (let i = 0; i < 17; i++) {
            //     const m = {
            //         price: -1,
            //         total: -1
            //     };
            //     this.sellBook.push(m);
            // }
            // for (let i = 0; i < 17; i++) {
            //     const m = {
            //         price: -1,
            //         total: -1
            //     };
            //     this.buyBook.push(m);
            // }
            this.lastOrder = [];
        },
        initSocket() {
            const connect = () => {
                ws = new WebSocket(process.env.VUE_APP_WS_URL);

                ws.onopen = () => {
                    log('WebSocket connected', MsgType.log);
                    if (selectedPairId !== null && selectedPairId !== '') {
                        log('Web Socket Connect', MsgType.log);
                        log(selectedPairId, MsgType.log);
                        this.sendMessage(selectedPairId);
                    }
                };

                ws.onmessage = this.onMessageWS;

                ws.onclose = () => {
                    log('WebSocket closed', MsgType.log);
                    setTimeout(() => {
                        log('WebSocket reconnecting...', MsgType.log);
                        if (ws) {
                            connect();
                        }
                    }, 1000);
                };

                ws.onerror = (error) => {
                    log(error, MsgType.log);
                };
            };
            connect();
        },
        sendMessage(pairId) {
            const req = {
                "type": "newOrder",
                "pairId": pairId
            }
            const reqBuy = {
                "type": "orderBook",
                "pairId": pairId
            }
            const reqPU = {
                "type": "priceUpdate",
                "pairId": pairId
            }
            const reqPS = {
                "type": "priceStats",
                "pairId": pairId
            }
            try {
                if(ws) {
                    ws.send(JSON.stringify(req));
                    ws.send(JSON.stringify(reqBuy));
                    ws.send(JSON.stringify(reqPU));
                    ws.send(JSON.stringify(reqPS));
                }
            } catch (e) {
                log(e, MsgType.error);
            }
        },
        getPriceVal(coinId, fiatId) {
            const req = {
                "type": "fiatPrice",
                "coinId": coinId,
                "fiatId": fiatId,
            }
            try {
                if(ws) {
                    ws.send(JSON.stringify(req));
                }
            } catch (e) {
                log(e, MsgType.error);
            }
        },
        waitForSocketConnection(socket, callback) {
            setTimeout(
                function () {
                    try {
                        if(socket){
                            if (socket.readyState === 1) {
                                console.log("Connection is made")
                                if (callback != null) {
                                    callback();
                                }
                            } else {
                                console.log("wait for connection...")
                                this.waitForSocketConnection(socket, callback);
                            }
                        }
                    } catch (e) {
                        log(e, MsgType.error);
                    }
                }, 5);
        },
        async getPairTitles() {
            const {pairTData, error} = await getPairTitleList();
            if (error.value === null) {
                if (JSON.parse(pairTData.value).status) {
                    let i;
                    if (JSON.parse(pairTData.value).data.length > 0) {
                        for (i = 0; i < JSON.parse(pairTData.value).data.length; i++) {
                            this.pairTList.push(JSON.parse(pairTData.value).data[i]);
                        }
                    }
                } else {
                    log(JSON.parse(pairTData.value).message, MsgType.error)
                }
            }
        },
        async getPlist(cId, isFirstTime) {
            this.dummyPairList = [];
            for (let i = 0; i < 22; i++) {
                const m = {};
                m['t'] = 1;
                this.dummyPairList.push(m);
            }
            const {pairData, error} = await getPairList(cId);
            if (error.value === null) {
                this.dummyPairList = [];
                try {
                    if (JSON.parse(pairData.value).status.toString().toLowerCase() === 'true') {
                        this.pairList = [];
                        if (JSON.parse(pairData.value).data.length > 0) {
                            if (isFirstTime) {
                                const rQuery = this.$route.params.pair.replace('_', '/');
                                console.log('pair from url == ', rQuery);

                                let m = -1;

                                for (let i = 0; i < JSON.parse(pairData.value).data.length; i++) {
                                    if (JSON.parse(pairData.value).data[i]['pairName'] === rQuery) {
                                        m = i;
                                        break;
                                    }
                                }

                                if (m === -1) m = 0;

                                selectedPairId = JSON.parse(pairData.value).data[m].pairId;
                                this.waitForSocketConnection(ws, () => {
                                    this.sendMessage(JSON.parse(pairData.value).data[m].pairId);
                                });
                                // this.selectedPair = JSON.parse(pairData.value).data[m]['pairName'];
                                this.selectedCoinOne = JSON.parse(pairData.value).data[m]['coin1']['coinName'];
                                this.selectedCoinTwo = JSON.parse(pairData.value).data[m]['coin2']['coinName'];
                                this.selectedCoinOneIcon = JSON.parse(pairData.value).data[m]['coin1']['coinIcon'];
                                this.minDecimal = JSON.parse(pairData.value).data[m]['pairDecimalSupport'];
                                this.minDecimalView = JSON.parse(pairData.value).data[m]['pairDecimalView'];
                                this.minBuyDecimalVal = JSON.parse(pairData.value).data[m]['pairMinQut'];
                                this.minSellDecimalVal = JSON.parse(pairData.value).data[m]['pairMinQut'];
                                this.oneUsdPrice = JSON.parse(pairData.value).data[m]['pairFiatPriceUSD'];
                                this.fPrice = this.userCurrency['fiatPriceOneUSD'];
                                this.calculatedPrice = parseFloat(this.fPrice.toString()) * parseFloat(this.oneUsdPrice.toString());

                                if (this.isLogin) {
                                    this.coinOneBalance = (await getBalance(JSON.parse(pairData.value).data[m]['coin1']['coinId'])).bal;
                                    this.coinTwoBalance = (await getBalance(JSON.parse(pairData.value).data[m]['coin2']['coinId'])).bal;
                                }

                                selectedPairId = JSON.parse(pairData.value).data[m].pairId;
                                selectedCoinTwoId = JSON.parse(pairData.value).data[m]['coin2']['coinId']
                                selectedCoinOneId = JSON.parse(pairData.value).data[m]['coin1']['coinId']
                                this.getPriceVal(JSON.parse(pairData.value).data[m]['coin2']['coinId'], this.userCurrency['fiatID']);
                                this.lbpVal = this.lastPrice;
                                this.lspVal = this.lastPrice;

                                if(JSON.parse(pairData.value).data[m]['pairLaunched'].toString().toLowerCase() === 'false') {
                                    this.cdMilliTime = JSON.parse(pairData.value).data[m]['pairLaunchedTime'] === null ? 0 : parseInt(JSON.parse(pairData.value).data[m]['pairLaunchedTime'].toString());
                                    this.isPairLaunch = true;
                                    const timestamp = JSON.parse(pairData.value).data[m]['pairLaunchedTime'] === null ? 0 : parseInt(JSON.parse(pairData.value).data[m]['pairLaunchedTime'].toString());
                                    const date = new Date(timestamp);
                                    const year = date.getUTCFullYear();
                                    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                                    const day = String(date.getUTCDate()).padStart(2, '0');
                                    const hours = String(date.getUTCHours()).padStart(2, '0');
                                    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
                                    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

                                    this.launchDateTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
                                    // if(chart) {
                                    //     candlestickSeries.setData([]);
                                    //     volumeSeries.setData([]);
                                    //     chart = null;
                                    // }
                                    this.startCountdown();
                                } else {
                                    this.isPairLaunch = false;
                                    await new Promise(r => setTimeout(r, 150));
                                    console.log('Load Chart in pair List');
                                    // if(!chart) {
                                    //     this.loadTradeChart();
                                    // } else  {
                                    //     chart = null;
                                    //     if(!chart) {
                                    //         this.loadTradeChart();
                                    //     }
                                    // }
                                    // this.chartTimeSelect('15', 'MINT');
                                }

                                this.updatePair(this.selectedCoinOne+'_'+this.selectedCoinTwo);
                            }

                            for (let i = 0; i < JSON.parse(pairData.value).data.length; i++) {
                                const m = JSON.parse(pairData.value).data[i];
                                m['pairPrice'] = this.customRoundValue(JSON.parse(pairData.value).data[i]['pairPrice'], JSON.parse(pairData.value).data[i]['pairDecimalView']);
                                m['orderType'] = "";
                                m['percentChange'] = this.getFixedVal(JSON.parse(pairData.value).data[i]['pairPriceChange'].toString(), 2);
                                this.pairList.push(m);
                            }
                            document.title = this.lastPrice + '   |   ' + this.selectedCoinOne + '/' + this.selectedCoinTwo + '   |   Bitrish Exchange Trading';
                        }
                    } else {
                        toast(JSON.parse(pairData.value).message, Type.danger);
                    }
                } catch (e) {
                    console.log('Pair chart list == ', e);
                }
            } else {
                toast("Something Went Wrong.", Type.danger);
            }
        },
        updatePair(pair) {
            console.log('update pair === ', pair);
            try {
                const currentPath = this.$route.path; // Get the current path
                const newPath = currentPath.replace(/\/trade\/[^/]+/, `/trade/${pair}`);
                this.$router.push(newPath);
            } catch (e) {
                console.log('update pair === ', e);
            }
        },
        async getAllOrder(type, pId) {
            if (this.isLogin) {
                if(pId !== '') {
                    const {orderData, err} = await getAllOrderList(type, pId);
                    if (err.value === null) {
                        const oRes = JSON.parse(orderData.value);
                        if (oRes['status'].toString().toLowerCase() === 'true') {
                            if (oRes['data'].length > 0) {
                                const mD = oRes['data']
                                for (let i = 0; i < mD.length; i++) {
                                    const mmm = mD[i];
                                    const d = new Date(0);
                                    d.setUTCSeconds(mmm['tradeTime']);
                                    const da = d.getDate().toString().length > 1 ? d.getDate() : '0' + d.getDate();
                                    const mo = (d.getMonth() + 1).toString().length > 1 ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1);
                                    const h = d.getHours().toString().length > 1 ? d.getHours() : '0' + d.getHours();
                                    const m = d.getMinutes().toString().length > 1 ? d.getMinutes() : '0' + d.getMinutes();
                                    const s = d.getSeconds().toString().length > 1 ? d.getSeconds() : '0' + d.getSeconds();
                                    mmm['newTime'] = (mo + '-' + da + ' ' +h + ':' + m + ':' + s);
                                    this.orderList.push(mmm);
                                }
                            }
                        }
                    }
                }
            }
        },
        // chartTimeSelect(time, type) {
        //     if(selectedPairId !== '') {
        //         this.selectedChartTime = time+type;
        //         const reqChart = {
        //             "type": "chartData",
        //             "pairId": selectedPairId,
        //             'period': time,
        //             'periodType': type,
        //         }
        //         try {
        //             const timingLink = document.querySelectorAll(".timing a");
        //             for(const link of timingLink){
        //                 link.classList.remove("active");
        //             }
        //             document.getElementById(this.selectedChartTime).classList.add("active");
        //         } catch (e) {
        //             log(e, MsgType.error);
        //         }
        //         ws.send(JSON.stringify(reqChart));
        //         if(candlestickSeries) {
        //             candlestickSeries.setData([]);
        //         }
        //         if(volumeSeries) {
        //             volumeSeries.setData([]);
        //         }
        //         if(type === 'SEC') {
        //             chart.applyOptions({
        //                 timeScale: {
        //                     timeVisible: true,
        //                     secondsVisible: true,
        //                     ticksVisible: true,
        //                     borderColor: "#485158"
        //                 },
        //             })
        //         } else if(type === 'MINT') {
        //             chart.applyOptions({
        //                 timeScale: {
        //                     timeVisible: true,
        //                     secondsVisible: false,
        //                     ticksVisible: true,
        //                     borderColor: "#485158"
        //                 },
        //             })
        //         } else if(type === 'HOUR') {
        //             chart.applyOptions({
        //                 timeScale: {
        //                     timeVisible: true,
        //                     secondsVisible: false,
        //                     ticksVisible: true,
        //                     borderColor: "#485158"
        //                 },
        //             })
        //         } else if(type === 'DAY') {
        //             chart.applyOptions({
        //                 timeScale: {
        //                     timeVisible: false,
        //                     secondsVisible: false,
        //                     ticksVisible: false,
        //                     borderColor: "#485158"
        //                 },
        //             })
        //         }
        //     }
        // },
        // loadTradeChart() {
        //     // loadChart();
        //     chart = createChart(document.getElementById('chartRef'), {
        //         width: document.getElementById('chartRef').offsetWidth,
        //         height:  document.getElementById('chartRef').offsetHeight,
        //         layout: {
        //             background: {
        //                 type: 'solid',
        //                 color: this.theme === 'dark' ? '#131722' : '#FFF',
        //             },
        //             textColor: this.theme === 'dark' ? '#FFF' : '#131722'
        //         },
        //         grid: {
        //             vertLines: {
        //                 color: this.theme === 'dark' ? '#202738' : '#e0e1e8'
        //             },
        //             horzLines: {
        //                 color: this.theme === 'dark' ? '#202738' : '#e0e1e8'
        //             }
        //         },
        //         crosshair: {
        //             mode: CrosshairMode.Normal
        //         },
        //         priceScale: {
        //             borderColor: "#485c7b",
        //
        //         },
        //
        //         timeScale: {
        //             timeVisible: true,
        //             secondsVisible: true,
        //             ticksVisible: true,
        //             borderColor: "#485158"
        //         },
        //         watermark: {
        //             text: "BITRISH",
        //             fontSize: 70,
        //             color: "rgba(256, 256, 256, 0.1)",
        //             visible: true
        //         },
        //
        //     });
        //
        //     volumeSeries = chart.addHistogramSeries({
        //         color: 'rgba(159,113,113,0.43)',
        //         priceFormat: {
        //             type: 'volume',
        //         },
        //         priceScaleId: '',
        //     });
        //
        //     volumeSeries.priceScale().applyOptions({
        //         scaleMargins: {
        //             top: 0.8,
        //             bottom: 0,
        //         },
        //     });
        //
        //     candlestickSeries = chart.addCandlestickSeries();
        //
        //     candlestickSeries.applyOptions({
        //         lastValueVisible: true,
        //         priceFormat: {
        //             minMove: 0.00001,
        //             precision: 1,
        //         },
        //     });
        //     candlestickSeries.priceScale().applyOptions({
        //         scaleMargins: {
        //             top: 0.1,
        //             bottom: 0.4,
        //         },
        //     })
        //
        //     candlestickSeries.setData(this.chartData);
        //
        //     chart.timeScale().fitContent();
        //     window.onresize = function() {
        //         try{
        //             if(document.getElementById('chartRef')) {
        //                 chart.applyOptions({
        //                     width: document.getElementById('chartRef').offsetWidth,
        //                     height: document.getElementById('chartRef').offsetHeight
        //                 });
        //             }
        //         } catch (e) {
        //             log(e, MsgType.error);
        //         }
        //     }
        // },
        onBuyPriceChange(val) {
            this.lbpVal = val;
            const m = this.getFixedVal(this.$refs.lBuyAmtVal.value, this.minDecimal);
            if(val !== '') {
                this.lBuyAmt = m;
            }
            if (this.$refs.lBuyAmtVal.value !== '') {
                this.totalBuyVal = (this.getBigIntNumber(this.$refs.lBuyAmtVal.value).multipliedBy(this.getBigIntNumber(this.lbpVal))).toFixed(this.minDecimalView).toString()/*roundOff(parseFloat(this.$refs.lBuyAmtVal.value) * parseFloat(this.lbpVal)).toString()*/;
            }
        },
        onBuyAmtChange(val) {
            let rangeBG = document.querySelector(".rangeBG");
            const per = parseFloat(val.toString()) * (100 / parseFloat(this.coinTwoBalance.toString()));
            if(per > 100) {
                rangeBG.style.width = 100 + '%';
            } else {
                rangeBG.style.width = per + '%';
            }
            this.totalBuyVal = (this.getBigIntNumber(val).multipliedBy(this.getBigIntNumber(this.lbpVal))).toFixed(this.minDecimalView).toString()/*roundOff(parseFloat(val) * parseFloat(this.lbpVal)).toString()*/;
            var thumbs = document.querySelectorAll(".thumb");
            for (const thumb of thumbs) {
                thumb.classList.remove('active');
            }
            if(per < 100){
                if(per >= 75){
                    document.querySelector(".lbuy1").classList.add("active");
                    document.querySelector(".lbuy2").classList.add("active");
                    document.querySelector(".lbuy3").classList.add("active");
                    document.querySelector(".lbuy4").classList.add("active");
                    //thumbs[i].classList.add('active');
                }else if(per >= 50){
                    document.querySelector(".lbuy1").classList.add("active");
                    document.querySelector(".lbuy2").classList.add("active");
                    document.querySelector(".lbuy3").classList.add("active");
                }else if(per >= 25){
                    document.querySelector(".lbuy1").classList.add("active");
                    document.querySelector(".lbuy2").classList.add("active");
                }else{
                    document.querySelector(".lbuy1").classList.add("active");
                }
            }else if(per >= 100){
                for (const thumb of thumbs) {
                    thumb.classList.add('active');
                }
            }
        },
        onBuyTotalChange(val) {
            let rangeBG = document.querySelector(".rangeBG");
            const per = (parseFloat(val) / parseFloat(this.lbpVal)) * (100 / parseFloat(this.coinTwoBalance.toString()));
            if(per > 100) {
                rangeBG.style.width = 100 + '%';
            } else {
                rangeBG.style.width = per + '%';
            }
            if(val !== '') {
                this.lBuyAmt = (this.getBigIntNumber(val).dividedBy(this.getBigIntNumber(this.lbpVal))).toFixed(this.minDecimal).toString()/*roundOff(parseFloat(val) / parseFloat(this.lbpVal)).toString()*/;
            }
            var thumbs = document.querySelectorAll(".thumb");
            for (const thumb of thumbs) {
                thumb.classList.remove('active');
            }
            if(per < 100){
                if(per >= 75){
                    document.querySelector(".lbuy1").classList.add("active");
                    document.querySelector(".lbuy2").classList.add("active");
                    document.querySelector(".lbuy3").classList.add("active");
                    document.querySelector(".lbuy4").classList.add("active");
                    //thumbs[i].classList.add('active');
                }else if(per >= 50){
                    document.querySelector(".lbuy1").classList.add("active");
                    document.querySelector(".lbuy2").classList.add("active");
                    document.querySelector(".lbuy3").classList.add("active");
                }else if(per >= 25){
                    document.querySelector(".lbuy1").classList.add("active");
                    document.querySelector(".lbuy2").classList.add("active");
                }else{
                    document.querySelector(".lbuy1").classList.add("active");
                }
            }else if(per >= 100){
                for (const thumb of thumbs) {
                    thumb.classList.add('active');
                }
            }
        },
        onSellPriceChange(val) {
            this.lspVal = val;
            const m = this.getBigIntNumber(this.$refs.lSellAmtVal.value).toFixed(this.minDecimal).toString();
            if(val !== '') {
                this.lSellAmt = m;
            }
            if (this.$refs.lSellAmtVal.value !== '') {
                this.totalSellVal = (this.getBigIntNumber(this.$refs.lSellAmtVal.value).multipliedBy(this.getBigIntNumber(this.lspVal))).toFixed(this.minDecimal).toString()/*roundOff(parseFloat(this.$refs.lSellAmtVal.value) * parseFloat(this.lspVal)).toString()*/;
            }
        },
        onSellAmtChange(val) {
            this.totalSellVal = (this.getBigIntNumber(val).multipliedBy(this.getBigIntNumber(this.lspVal))).toFixed(this.minDecimal).toString()/*roundOff(parseFloat(val) * parseFloat(this.lspVal)).toString()*/;
            const per = parseFloat(val) * (100 / this.coinOneBalance);
            let rangeBG = document.querySelector(".lsell_rangeBG");
            if(per > 100) {
                rangeBG.style.width = 100 + '%';
            } else {
                rangeBG.style.width = per + '%';
            }
            // this.totalBuyVal = roundOff(parseFloat(val) * parseFloat(this.lbpVal)).toString();
            var thumbs = document.querySelectorAll(".lsell_thumb");
            for (const thumb of thumbs) {
                thumb.classList.remove('active');
            }
            if(per < 100){
                if(per >= 75){
                    document.querySelector(".lsell1").classList.add("active");
                    document.querySelector(".lsell2").classList.add("active");
                    document.querySelector(".lsell3").classList.add("active");
                    document.querySelector(".lsell4").classList.add("active");
                    //thumbs[i].classList.add('active');
                }else if(per >= 50){
                    document.querySelector(".lsell1").classList.add("active");
                    document.querySelector(".lsell2").classList.add("active");
                    document.querySelector(".lsell3").classList.add("active");
                }else if(per >= 25){
                    document.querySelector(".lsell1").classList.add("active");
                    document.querySelector(".lsell2").classList.add("active");
                }else{
                    document.querySelector(".lsell1").classList.add("active");
                }
            }else if(per >= 100){
                for (const thumb of thumbs) {
                    thumb.classList.add('active');
                }
            }
        },
        onSellTotalChange(val) {
            if(val !== '') {
                this.lSellAmt = (this.getBigIntNumber(val).dividedBy(this.getBigIntNumber(this.lspVal))).toFixed(this.minDecimalView).toString()/*roundOff(parseFloat(val) / parseFloat(this.lbpVal)).toString()*/;
            }
            // this.lSellAmt = roundOff(parseFloat(val) / parseFloat(this.lspVal)).toString();
            //const per = parseFloat(val * this.lspVal) * (100 / this.coinOneBalance);
            const per = (parseFloat(val) / parseFloat(this.lspVal)) * (100 / parseFloat(this.coinOneBalance));
            let rangeBG = document.querySelector(".lsell_rangeBG");
            if(per > 100) {
                rangeBG.style.width = 100 + '%';
            } else {
                rangeBG.style.width = per + '%';
            }
            this.totalBuyVal = (this.getBigIntNumber(val).multipliedBy(this.getBigIntNumber(this.lspVal))).toFixed(this.minDecimalView).toString();
            var thumbs = document.querySelectorAll(".lsell_thumb");
            for (const thumb of thumbs) {
                thumb.classList.remove('active');
            }
            if(per < 100){
                if(per >= 75){
                    document.querySelector(".lsell1").classList.add("active");
                    document.querySelector(".lsell2").classList.add("active");
                    document.querySelector(".lsell3").classList.add("active");
                    document.querySelector(".lsell4").classList.add("active");
                    //thumbs[i].classList.add('active');
                }else if(per >= 50){
                    document.querySelector(".lsell1").classList.add("active");
                    document.querySelector(".lsell2").classList.add("active");
                    document.querySelector(".lsell3").classList.add("active");
                }else if(per >= 25){
                    document.querySelector(".lsell1").classList.add("active");
                    document.querySelector(".lsell2").classList.add("active");
                }else{
                    document.querySelector(".lsell1").classList.add("active");
                }
            }else if(per >= 100){
                for (const thumb of thumbs) {
                    thumb.classList.add('active');
                }
            }
        },
        selectOrderBook(val) {
            if(this.getBigIntNumber(val['price']).isGreaterThan(this.getBigIntNumber('0'))) {
                this.lspVal = this.getBigIntNumber(val['price'].toString()).toFixed();
                this.lbpVal = this.getBigIntNumber(val['price'].toString()).toFixed();
            }
        },
        mBuyAmtChange(val) {
            const per = parseFloat(val) * (100 / this.coinOneBalance);
            let rangeBG = document.querySelector(".mBuy_rangeBG");
            if(per > 100) {
                rangeBG.style.width = 100 + '%';
            } else {
                rangeBG.style.width = per + '%';
            }
            var thumbs = document.querySelectorAll(".mBuy_thumb");
            for (const thumb of thumbs) {
                thumb.classList.remove('active');
            }
            if(per < 100){
                if(per >= 75){
                    document.querySelector(".mBuy1").classList.add("active");
                    document.querySelector(".mBuy2").classList.add("active");
                    document.querySelector(".mBuy3").classList.add("active");
                    document.querySelector(".mBuy4").classList.add("active");
                    //thumbs[i].classList.add('active');
                }else if(per >= 50){
                    document.querySelector(".mBuy1").classList.add("active");
                    document.querySelector(".mBuy2").classList.add("active");
                    document.querySelector(".mBuy3").classList.add("active");
                }else if(per >= 25){
                    document.querySelector(".mBuy1").classList.add("active");
                    document.querySelector(".mBuy2").classList.add("active");
                }else{
                    document.querySelector(".mBuy1").classList.add("active");
                }
            }else if(per >= 100){
                for (const thumb of thumbs) {
                    thumb.classList.add('active');
                }
            }
        },
        mSellAmtChange(val) {
            const per = parseFloat(val) * (100 / this.coinOneBalance);
            let rangeBG = document.querySelector(".mSell_rangeBG");
            if(per > 100) {
                rangeBG.style.width = 100 + '%';
            } else {
                rangeBG.style.width = per + '%';
            }
            var thumbs = document.querySelectorAll(".mSell_thumb");
            for (const thumb of thumbs) {
                thumb.classList.remove('active');
            }
            if(per < 100){
                if(per >= 75){
                    document.querySelector(".mSell1").classList.add("active");
                    document.querySelector(".mSell2").classList.add("active");
                    document.querySelector(".mSell3").classList.add("active");
                    document.querySelector(".mSell4").classList.add("active");
                    //thumbs[i].classList.add('active');
                }else if(per >= 50){
                    document.querySelector(".mSell1").classList.add("active");
                    document.querySelector(".mSell2").classList.add("active");
                    document.querySelector(".mSell3").classList.add("active");
                }else if(per >= 25){
                    document.querySelector(".mSell1").classList.add("active");
                    document.querySelector(".mSell2").classList.add("active");
                }else{
                    document.querySelector(".mSell1").classList.add("active");
                }
            }else if(per >= 100){
                for (const thumb of thumbs) {
                    thumb.classList.add('active');
                }
            }
        },
        formattedCurrency(val, round) {
            if(val && val.toString().includes('.')) {
                if(parseFloat(val.toString().split('.')[0]) <= 0) {
                    return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: parseInt(round.toString())});
                } else {
                    return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: 2});
                }
            } else if(val) {
                return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: 2});
            } else {
                return '0.00';
            }
        },
        scroll_left() {
            let content = document.querySelector(".customTickerData");
            // alert(content.scrollLeft);
            content.scrollLeft -= 50;
        },
        scroll_right() {
            let content = document.querySelector(".customTickerData");
            // alert(content.scrollLeft);
            content.scrollLeft += 40;
        },
        async cancelOrder() {
            document.querySelector(".customPopup").classList.remove("show");
            this.showLoading(true);
            const {orderData, err} = await cancelOrderById(this.tradeIdForDelete);
            if(err.value === null) {
                const v = JSON.parse(orderData.value);
                if(v['status'].toString() === 'true') {
                    this.showLoading(false);
                    toast(v['message'], Type.success);
                } else {
                    this.showLoading(false);
                    toast(v['message'], Type.danger);
                }
            } else {
                this.showLoading(false);
                toast('Something Went Wrong', Type.danger);
            }
        },
        getBigIntNumber(val) {
            return new BigNumber(val.toString());
        },
        getFixedVal(val, fixVal){
            val = new BigNumber(val);
            return !isNaN(fixVal) ? val.toFixed(fixVal) : val.toFixed(4);
        },
        startCountdown() {
                // this.cdMilliTime = 1685946600000;
            this.cdInterval = setInterval(async () => {
                const currentTime = new Date().getTime();
                const remainingTime = this.cdMilliTime - currentTime;
                if (remainingTime <= 0) {
                    clearInterval(this.cdInterval);
                    window.location.reload();
                    return;
                }

                const seconds = Math.floor((remainingTime / 1000) % 60);
                this.cdSec = seconds.toString().length > 1 ? seconds.toString() : '0' + seconds.toString();
                const minutes = Math.floor((remainingTime / 1000 / 60) % 60);
                this.cdMin = minutes.toString().length > 1 ? minutes.toString() : '0' + minutes.toString();
                const hours = Math.floor((remainingTime / 1000 / 3600) % 24);
                this.cdHour = hours.toString().length > 1 ? hours.toString() : '0' + hours.toString();
                const days = Math.floor(remainingTime / 1000 / 3600 / 24);
                this.cdDay = days.toString().length > 1 ? days.toString() : '0' + days.toString();
            }, 1000);
        },
        stopCountdown() {
            if(this.cdInterval) {
                clearInterval(this.cdInterval);
            }
        },
        refreshBalAndOrderList() {
            this.refreshDataTimeout = setTimeout(async () => {
                if (this.isLogin) {
                    if(selectedPairId === '' ) {
                        this.coinOneBalance = (await getBalance(selectedCoinOneId)).bal;
                        this.coinTwoBalance = (await getBalance(selectedCoinTwoId)).bal;
                        this.onSelectMyOrder(this.orderType);
                    }
                }
                this.refreshBalAndOrderList();
            }, 5000);
        },

        searchPair() {
            let input = document.getElementById("myInput");
            let PL = document.querySelectorAll(".market-pairs .progress-container");
            let filter = input.value.toUpperCase();
            for(var i=0; i<PL.length; i++){
                if(PL[i].getAttribute("data-pair").toUpperCase().indexOf(filter) > -1){
                    PL[i].parentElement.style.display = "";
                }else{
                    PL[i].parentElement.style.display = "none";
                }
            }
        },

        customRoundValue(value, roundAfter) {
            let finalVal = '0.00';
            if(value.toString().includes('.')) {
                const splitVal = value.toString().split('.');
                if (parseInt(roundAfter.toString()) > 0) {
                    if(splitVal[1].length < parseInt(roundAfter.toString())) {
                        let afterDotVal = splitVal[1];
                        for(let i = 0; i < (parseInt(roundAfter.toString()) - splitVal[1].length); i++) {
                            afterDotVal = afterDotVal + '0';
                        }
                        finalVal = splitVal[0] +'.'+ afterDotVal;
                    } else {
                        finalVal = splitVal[0] +'.'+ splitVal[1].substring(0, parseInt(roundAfter.toString()));
                    }
                } else {
                    finalVal = splitVal[0];
                }
            } else {
                if (parseInt(roundAfter.toString()) > 0) {
                    let afterDotVal = '';
                    for(let i = 0; i < parseInt(roundAfter.toString()); i++) {
                        afterDotVal = afterDotVal + '0';
                    }
                    finalVal = value.toString() +'.'+ afterDotVal;
                } else {
                    finalVal = value.toString();
                }
            }
            return finalVal;
        }
    },
    setup() {
        const store = Store;
        const cur = computed(() => store.state.userCurrency);
        // const the = computed(() => store.state.theme);
        const data = reactive({
            fPrice: '0',
            calculatedPrice: '0',
            oneUsdPrice: '0'
        });

        const checkWatch = (newVal) => {
            data.fPrice = newVal['fiatPriceOneUSD'];
            data.calculatedPrice = parseFloat(data.fPrice.toString()) * parseFloat(data.oneUsdPrice.toString());
        };

        watch(cur, (newVal) => {
            checkWatch(newVal);
        });

        // watch(
        //     the, (newVal) => {
        //         chart.applyOptions({
        //             layout: {
        //                 background: {
        //                     type: 'solid',
        //                     color: newVal === 'dark' ? '#131722' : '#FFF',
        //                 },
        //                 textColor: newVal === 'dark' ? '#FFF' : '#131722'
        //             },
        //
        //             grid: {
        //                 vertLines: {
        //                     color: newVal === 'dark' ? '#202738' : '#e0e1e8'
        //                 },
        //                 horzLines: {
        //                     color: newVal === 'dark' ? '#202738' : '#e0e1e8'
        //                 }
        //             },
        //         })
        //     }
        // );

        onBeforeRouteUpdate((to, from, next) => {
            console.log('Back or forward button clicked');

            next();
        });

        return {
          cur, /*the,*/ ...toRefs(data)
        }
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.isLogin,
            userCurrency: state => state.userCurrency,
            theme: state => state.theme,
        }),
        ...mapActions({
            checkUser: CHECK_USER_INFO_ACTION,
        }),
        style() {
            return {transform: 'translateX(-' + this.randomWidth + '%)'}
        },
    }
}
</script>

<style scoped></style>
