import {createWebHistory, createRouter} from 'vue-router'
import LandingPage from "@/components/view/LandingPage.vue";
import SignUp from "@/components/view/SignUp.vue";
import SignIn from "@/components/view/SignIn.vue";
import TradingPage from "@/components/view/exchangeView/TradingPage.vue";
import ForgetPassword from "@/components/view/Forget-Password.vue";
import MyProfile from "@/components/view/userView/MyProfile.vue";
import TradingFee from "@/components/view/userView/TradingFee.vue";
import DepositFee from "@/components/view/userView/DepositFee.vue";
import TermsCondition from "@/components/view/userView/TermsCondition.vue";
import AboutUs from "@/components/view/userView/AboutUs.vue";
import NotFound from "@/components/view/NotFound.vue";
import ListingPage from "@/components/view/ListingPage.vue";
import ComingSoon from "@/components/view/ComingSoon.vue";
import BitrishConvert from "@/components/view/BitrishConvert.vue";
import P2pTrade from "@/components/view/P2pTrade.vue";
import LegalPage from "@/components/view/LegalPage.vue";
import BuildingTrust from "@/components/view/BuildingTrust.vue";
import MarketOverview from "@/components/view/MarketOverview.vue";
import WalletPage from "@/components/view/userView/WalletPage.vue";
import DepositPage from "@/components/view/userView/DepositPage.vue";
import MyWithdraw from "@/components/view/userView/MyWithdraw.vue";
import TransactionHistory from "@/components/view/userView/TransactionHistory.vue";

const routes = [
    {
        name: 'LandingPage',
        path: '/',
        component: LandingPage,
        meta:{
            reload: true
        }
    },
    {
        name: 'SignUp',
        path: '/signUp',
        component: SignUp,
        meta:{
            reload: true
        }
    },
    {
        name: 'SignIn',
        path: '/signIn',
        component: SignIn,
        meta:{
            reload: true
        }
    },
    {
        name: 'TradingPage',
        path: '/trade',
        component: TradingPage,
        meta:{
            reload: true
        }
    },
    {
        name: 'TradingPage',
        path: '/trade/:pair',
        component: TradingPage,
        meta:{
            reload: true
        }
    },
    {
        name: 'ForgetPassword',
        path: '/forgetPassword',
        component: ForgetPassword,
        meta:{
            reload: true
        }
    },
    {
        name: 'MyProfile',
        path: '/MyProfile',
        component: MyProfile,
        meta:{
            reload: true
        }
    },
    {
        name: 'TradingFee',
        path: '/TradingFee',
        component: TradingFee,
        meta:{
            reload: true
        }
    },
    {
        name: 'DepositFee',
        path: '/DepositFee',
        component: DepositFee,
        meta:{
            reload: true
        }
    },
    {
        name: 'TermsCondition',
        path: '/TermsCondition',
        component: TermsCondition,
        meta:{
            reload: true
        }
    },
    {
        name: 'AboutUs',
        path: '/AboutUs',
        component: AboutUs,
        meta:{
            reload: true
        }
    },
    {
        name: 'NotFound',
        path: '/:catchAll(.*)',
        component: NotFound,
        meta:{
            reload: true
        }
    },
    {
        name: 'ListingPage',
        path: '/listingPage',
        component: ListingPage,
        meta:{
            reload: true
        }
    },
    {
        name: 'ComingSoon',
        path: '/comingSoon',
        component: ComingSoon,
        meta:{
            reload: true
        }
    },
    {
        name: 'BitrishConvert',
        path: '/BitrishConvert',
        component: BitrishConvert,
        meta:{
            reload: true
        }
    },
    {
        name: 'P2pTrade',
        path: '/P2pTrade',
        component: P2pTrade,
        meta:{
            reload: true
        }
    },
    {
        name: 'LegalPage',
        path: '/legalPage',
        component: LegalPage,
        meta:{
            reload: true
        }
    },
    {
        name: 'BuildingTrust',
        path: '/buildingTrust',
        component: BuildingTrust,
        meta:{
            reload: true
        }
    },
    {
        name: 'MarketOverview',
        path: '/market_overview',
        component: MarketOverview,
        meta:{
            reload: true
        }
    },
    {
        name: 'WalletPage',
        path: '/MyWallet',
        component: WalletPage,
        meta:{
            reload: true
        }
    },
    {
        name: 'DepositPage',
        path: '/MyDeposit',
        component: DepositPage,
        meta:{
            reload: true
        }
    },
    {
        name: 'MyWithdraw',
        path: '/MyWithdraw',
        component: MyWithdraw,
        meta:{
            reload: true
        }
    },
    {
        name: 'TransactionHistory',
        path: '/TransactionHistory',
        component: TransactionHistory,
        meta:{
            reload: true
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if(savedPosition) {
            return savedPosition;
        } else {
            return {top: 0};
        }
    }
});

export default router;
