<template>
    <div class="container">
        <div class="row m-0">
            <div class="col-md-12 my-5 d-flex justify-content-between align-items-center">
                <h1 class="boldHeading">Deposit & Withdrawal Fees</h1><router-link class="pageLink d-flex justify-content-between align-items-center" to="/TradingFee">Trading Fees <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-94sbqg"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 11.999l-7.071-7.071-1.768 1.768 4.055 4.054H2.999v2.5h13.216l-4.054 4.053 1.768 1.768L21 12v-.001z" fill="currentColor"></path></svg></router-link>
            </div>
        </div>
    </div>
    <div class="row m-0 p-0 pageContent">
        <div class="container">
<!--            <div class="col-md-12 my-5">-->
<!--                <p>Log in to check your trading fee rate. <a href="javascript:void(0)" class="customLink ml-3">Login</a></p>-->
<!--            </div>-->
            <div class="col-md-12 my-5">
                <ul class="nav nav-pills" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="pill" href="#spot" role="tab" aria-selected="true">Spot</a>
                    </li>
                </ul>
                <div class="my-3 font-400">
                    <p>
                        BitRish does not charge deposit fees.
                    </p>
                    <p>
                        At BitRish, we believe in providing transparent information about Deposit & Withdrawal Fees. We understand the importance of clarity when it comes to the costs associated with managing your digital assets. Our deposit and withdrawal fees are designed to be competitive and reflect the costs incurred in processing these transactions securely and efficiently. The list of Deposit & Withdrawal Fees can be found on the respective page, empowering you with the knowledge to plan your transactions effectively. We strive to provide a seamless experience while ensuring the security and integrity of your funds. Our fee structure is regularly reviewed to align with industry standards and provide you with the best value for your deposits and withdrawals.
                    </p>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="my-3 font-400">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icon ion-md-search"></i></span>
                                </div>
                                <input type="text" @input="searchCoin()" class="form-control" id="myInput" placeholder="Search a coin">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="spot" role="tabpanel">
                        <div class="card-body p-0 my-3">
                            <table class="table latestTransaction" id="myTable">
                                <thead>
                                    <tr>
                                        <th>Coin/Token</th>
                                        <th class="text-center">Full Name</th>
                                        <th class="text-center">Network</th>
                                        <th class="text-center">Minimum Withdrawal</th>
                                        <th class="text-center">Deposit Fee</th>
                                        <th class="text-right">Withdrawal Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(c, i) in coinList" :key="i">
                                        <td>
                                            <div class="d-flex justify-content-start align-items-center">
                                                <img class="coinLogo" :src="c['coinIcon']" alt="Bitrish Logo"><span class="coinName">{{ c['coinName'] }}</span></div>
                                        </td>
                                        <td class="text-center">{{ c['coinDescription'] }}</td>
                                        <td class="text-center">{{ c['coinNetwork'] }}</td>
                                        <td class="text-center">{{ c['coinWithdrawalMin'] }}</td>
                                        <td class="text-center">{{ c['coinDepositFees'] <= 0 ? 'Free' : c['coinDepositFees']}}</td>
                                        <td class="text-right">{{ c['coinWithdrawalFees'] }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</template>

<script>
import {getAllCoinList} from "@/restApi/deposit_api";
import AppFooter from "@/components/parcel/AppFooter.vue";

export default {
    name: "DepositFee",
    components: {AppFooter},
    data() {
        return {
            coinList: [],
        }
    },
    created() {
        this.getCoinData();
    },
    methods: {
        searchCoin() {
            // Declare variables
            var input, filter, table, tr, td, i, txtValue;
            input = document.getElementById("myInput");
            filter = input.value.toUpperCase();
            table = document.getElementById("myTable");
            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                td = tr[i].getElementsByTagName("td")[0];
                if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
                }
            }
            },
        async getCoinData() {
            const {coinData, error} = await getAllCoinList(0);
            if(error.value === null) {
                if(JSON.parse(coinData.value)['status'].toString().toLowerCase() === 'true') {
                    if(JSON.parse(coinData.value)['data'].length > 0) {
                        for (let i = 0; i < JSON.parse(coinData.value)['data'].length; i++ ) {
                            this.coinList.push(JSON.parse(coinData.value)['data'][i]);
                        }
                    }
                }
            }
        }
    },
    mounted() {
        document.title = 'Deposit Fee - BitRish';
    }
}
</script>
