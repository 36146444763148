<template>
    <div class="container">
        <div class="row m-0">
            <div class="col-md-12 my-5 d-flex justify-content-between align-items-center">
                <h1 class="boldHeading">Trading Fees & Trade Limits</h1><router-link class="pageLink d-flex justify-content-between align-items-center" to="/DepositFee">Deposit & Withdrawal Fees <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-94sbqg"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 11.999l-7.071-7.071-1.768 1.768 4.055 4.054H2.999v2.5h13.216l-4.054 4.053 1.768 1.768L21 12v-.001z" fill="currentColor"></path></svg></router-link>
            </div>
        </div>
    </div>
    <div class="row m-0 p-0 pageContent">
        <div class="container">
            <div class="col-md-12 my-5">
                <p>In our commitment to transparency, BitRish provides clear guidelines on Trading Fees & Trade Limits. We believe in offering competitive and fair fees for trading cryptocurrencies on our platform. The trading fee structure is designed to cater to both high-volume traders and those starting their crypto journey. Our Trade Limits are established to ensure a secure trading environment while allowing flexibility for our users. You can find the comprehensive list of trading fees and trade limits on the respective page, empowering you with the information needed to make informed trading decisions. We continuously review and optimize our fee structure to provide the best value for our users while maintaining the highest standards of security and service.</p>
            </div>
            <div class="col-md-12">
                <ul class="nav nav-pills" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="pill" href="#spot" role="tab" aria-selected="true">Spot</a>
                    </li>
                </ul>

                <div class="tab-content">
                    <div class="tab-pane fade show active" id="spot" role="tabpanel">
                        <div class="card-body p-0 my-3">
                            <table class="table latestTransaction">
                                <thead>
                                    <tr>
                                        <th>Pair</th>
                                        <th class="text-center">Minimum Trade Amount</th>
                                        <th class="text-center">Maximum Limit Order Amount</th>
                                        <th class="text-center">Max Number of Open Orders</th>
                                        <th class="text-center">Price Protection Threshold</th>
                                        <th class="text-right">Maker / Taker</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(p, i) in pairList" :key="i">
                                        <td>{{ p['pairName'] }}</td>
                                        <td class="text-center">{{ p['pairMinQut'] }} {{p['coin1']['coinName']}}</td>
                                        <td class="text-center">{{ p['pairMaxQut'] }} {{p['coin1']['coinName']}}</td>
                                        <td class="text-center">10</td>
                                        <td class="text-center">20%</td>
                                        <td class="text-right">{{ p['makerFees'] }} / {{ p['traderFees'] }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <li class="mb-3">
                            A “Taker” is a trader who places an order at the market price, whereas a “Maker” is a trader who places an order at a limit price. <a href="javascript:void(0)" class="customLink ml-2">Learn more</a>
                        </li>
                        <li class="mb-3">
                            VIP trade volume levels are measured on the basis of the spot trading volume, or whether the futures trading volume meets the standard (Futures trading volume includes USDS-M futures and COIN-M futures).
                        </li>
                        <li class="mb-3">
                            Refer friends to earn trading fees 20% kickback. <a href="javascript:void(0)" class="customLink ml-2">Learn more</a>
                        </li>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer/>
</template>

<script>
import {getPairList} from "@/restApi/trade_api";
import AppFooter from "@/components/parcel/AppFooter.vue";

export default {
    name: "TradingFee",
    components: {AppFooter},
    data() {
        return {
            pairList: [],
        }
    },
    created() {
        this.getPairData();
    },
    methods: {
        async getPairData() {
            const {pairData, error} = await getPairList(0);
            if(error.value === null) {
                if(JSON.parse(pairData.value)['status'].toString().toLowerCase() === 'true') {
                    if(JSON.parse(pairData.value)['data'].length > 0) {
                        for (let i = 0; i < JSON.parse(pairData.value)['data'].length; i++ ) {
                            this.pairList.push(JSON.parse(pairData.value)['data'][i]);
                        }
                    }
                }
            }
        }
    },
    mounted() {
        document.title = 'Trading Fee - BitRish';
    }
}
</script>
