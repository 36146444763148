import Validations from "@/services/Validations";

export default class KycValidation {

    constructor(dob, num, add, proofId, proofIdBack, addId, addIdBack, faceId, tName, country, state, city) {
        this.dob = dob;
        this.num = num;
        this.add = add;
        this.proofId = proofId;
        this.proofIdBack = proofIdBack;
        this.addId = addId;
        this.addIdBack = addIdBack;
        this.faceId = faceId;
        this.tName = tName;
        this.country = country;
        this.state = state;
        this.city = city;
    }

    checkValidation () {
        let error = '';

        if(this.dob === '') {
            error = 'Please select your date of birth';
        } else if(!Validations.checkPhone(this.num)){
            error = 'Phone number not valid';
        } else if(this.add === '') {
            error = 'Please enter address';
        } else if(this.country === '') {
            error = 'Please select country';
        } else if(this.state === '') {
            error = 'Please select state';
        } else if(this.city === '') {
            error = 'Please enter city';
        } else if(this.proofId === undefined || this.proofId === '') {
            error = 'Please upload your ID proof front image';
        } else if(this.proofIdBack === undefined || this.proofIdBack === '') {
            error = 'Please upload your ID proof Back image';
        } else if(this.addId === undefined || this.addId === '') {
            error = 'Please upload your address proof front image';
        } else if(this.addIdBack === undefined || this.addIdBack === '') {
            error = 'Please upload your address proof Back image';
        } else if(this.faceId === undefined || this.faceId === '') {
            error = 'Please upload your selfie';
        } else if(!Validations.checkTwitterUserName(this.tName)) {
            error = 'Please enter valid Twitter Username';
        }

        return error;
    }

}
