<template>
    <div>
        <div class="landing-hero">
            <div class="container">
                <div class="row homeBanner">
                    <div class="col-md-8 offset-md-2 text-center">
                        <img v-if="theme === ''" src="/assets/img/logo-dark.png" alt="logo"  class="my-sm-4 my-xs-4" />
                        <img v-else src="/assets/img/logo-light.png" alt="logo"  class="my-sm-4 my-xs-4" />
                        <h1>Shaping the future of digital finance - BitRish</h1>
                        <p><em>Welcome to BitRish, your trusted cryptocurrency exchange, providing a secure and seamless platform for trading digital assets. Explore our wide range of cryptocurrencies, advanced trading features, and comprehensive educational resources to empower your cryptocurrency journey</em></p>
                        <router-link to="/signUp" class="btn btn-primary btn-lg px-5" v-if="!isLogin"><i class="far fa-paper-plane"></i> Get Started</router-link>
                        <router-link :to="{ path: '/trade/BTC_USDT'}" class="btn btn-primary btn-lg px-5" v-if="isLogin"><i class="far fa-paper-plane"></i> Get Started</router-link>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="row text-right">
                            <img src="" alt="" class="my-sm-4 my-xs-4" />
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="landing-number bg-dark textInvert">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <h2>$218B</h2>
                        <p class="textInvert">Quarterly volume traded</p>
                    </div>
                    <div class="col-md-4">
                        <h2>99+</h2>
                        <p>Countries supported
                        </p>
                    </div>
                    <div class="col-md-4">
                        <h2>11+M</h2>
                        <p>Verified users
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mtb100">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex justify-content-center align-items-center flex-column mb-5 textInvert w-100 bg-333 p-4 br12 landingServices">
                            <img src="@/assets/authentication.png" class="smallImg" alt="" />
                            <div class="d-inline-flex flex-column mt-md-5 mt-sm-3">
                                <h2>Verify your identity</h2>
                                <p>
                                    Ensure the security of your account and transactions by completing the identity verification process.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-center align-items-center flex-column mb-5 textInvert w-100 bg-333 p-4 br12 landingServices">
                            <img src="@/assets/crypto.png" class="smallImg" alt="" />
                            <div class="d-inline-flex flex-column mt-md-5 mt-sm-3">
                                <h2>Fund your account</h2>
                                <p>
                                    Start trading cryptocurrencies by depositing funds into your account using a wide range of payment options.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-center align-items-center flex-column mb-5 textInvert w-100 bg-333 p-4 br12 landingServices">
                            <img src="@/assets/trading.png" class="smallImg" alt="" />
                            <div class="d-inline-flex flex-column mt-md-5 mt-sm-3">
                                <h2>Start trading</h2>
                                <p>
                                    Give some alternative of this line You're good to go! Buy/sell crypto, set up recurring buys for your investments, and discover what BitRish has to offer.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <router-link to="/signUp" class="btn btn-primary btn-lg px-4" v-if="!isLogin"> Get Started</router-link>
                        <router-link :to="{ path: '/trade/BTC_USDT'}" class="btn btn-primary btn-lg px-4" v-if="isLogin"> Get Started</router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="landing-info-one mtb100">
            <div class="container">
                <div class="row">
                    <div class="col-md-5">
                        <div class="tradingview-widget-container tradingview-gain-and-loser">
                            <div class="tradingview-widget-container__widget"></div>
                        </div>
                    </div>
                    <div class="offset-md-1 col-md-6">
                        <h2>Live Market Gainers and Losers tracking</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam iusto, nisi illo nulla alias
                            fugiat
                            repudiandae unde ab maxime placeat facere, dicta deleniti ipsam animi expedita quam minus
                            perferendis
                            veniam?</p>
                        <ul>
                            <li><i class="icon ion-ios-checkmark-circle"></i> Live Gainers Tracking</li>
                            <li><i class="icon ion-ios-checkmark-circle"></i> Live Losers Tracking</li>
                            <li><i class="icon ion-ios-checkmark-circle"></i> Live Market Data</li>
                            <li><i class="icon ion-ios-checkmark-circle"></i> Live Cryptocurrency Price</li>
                            <li><i class="icon ion-ios-checkmark-circle"></i> Past Coin Price check</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="landing-feature">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2>Find out what makes <br>BitRish the top choice</h2>
                    </div>
                    <div class="col-md-4">
                        <div class="landing-feature-item">
                            <img src="../../../public/assets/img/landing/stroge.svg" alt="">
                            <h3>Security </h3>
                            <p>Your security is our utmost priority. BitRish employs advanced measures to ensure the safety of your funds and personal information, providing you with a secure trading environment.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="landing-feature-item">
                            <img src="../../../public/assets/img/landing/backup.svg" alt="">
                            <h3>Grow with BitRish</h3>
                            <p>Embark on a transformative journey of growth with BitRish as your trusted partner in the world of cryptocurrency. Explore our diverse range of trading options & cutting-edge features.</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="landing-feature-item">
                            <img src="../../../public/assets/img/landing/managment.svg" alt="">
                            <h3>Advanced Data Encryption</h3>
                            <p>Your data security is paramount to us. At BitRish, we utilize advanced encryption methods to safeguard your personal and financial information, ensuring the highest level of protection for your data.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="landing-feature landing-start">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2>Get started in a few steps</h2>
                    </div>
                    <div class="col-md-4">
                        <div class="landing-feature-item testing">
                            <img src="@/assets/user.png" alt="">
                            <span>1</span>
                            <h3>Create an account </h3>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="landing-feature-item testing">
                            <img src="@/assets/scan.png" alt="">
                            <span>2</span>
                            <h3>Update your KYC</h3>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="landing-feature-item testing">
                            <img src="@/assets/buying.png" alt="">
                            <span>3</span>
                            <h3>Start buying & selling</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="landing-sub">
            <div class="container">
                <div class="row">
                    <div class="offset-md-1 col-md-10">
                        <div class="landing-sub-content">
                            <h2>Become part of a global community of people who have found their path to the crypto
                                world with Crypo
                            </h2>
                            <a href="">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <app-footer></app-footer>

    </div>
</template>

<script>
import AppFooter from "@/components/parcel/AppFooter.vue";
import {mapActions, mapState} from "vuex";
import {CHECK_USER_INFO_ACTION} from "@/util/store.constent";


export default {
    name: "LandingPage",
    components: {
        AppFooter,
    },
    computed: {
        ...mapActions({
            checkUser: CHECK_USER_INFO_ACTION,
        }),
        ...mapState({
            theme: (state) => state.theme,
            isLogin: state => state.isLogin,
        }),
    },
    async created() {
        await this.checkUser;
    },
    mounted() {
        document.title = 'Bitrish - Cryptocurrency Exchange';
    }
}
</script>

<style scoped>

</style>
