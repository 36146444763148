import {createStore} from "vuex";
import {
    CHANGE_THEME_ACTION,
    CHECK_USER_INFO_ACTION, GET_All_FIAT_CURRENCY_ACTION, GET_All_NOTIFICATION_ACTION,
    LOADING_STATE,
    LOGOUT_ACTION, REMOVE_SELECTED_NOTIFICATION_MUTATION,
    SET_All_FIAT_CURRENCY_MUTATION, SET_ALL_NOTIFICATION_MUTATION,
    SET_FIAT_CURRENCY_MUTATION,
    SET_IS_LOGIN_DATA_MUTATION, SET_NOTIFICATION_STATE_MUTATION,
    SET_ON_LOGOUT_DATA_MUTATION,
    SET_THEME_DATA_MUTATION, SET_WITHDRAW_DATA_MUTATION
} from "@/util/store.constent";
import {getCurrencyList, getUserDataApi} from "@/restApi/api";
import {log, MsgType} from "@/util/constant";
import createPersistedState from 'vuex-persistedstate';
import {getNotifications} from "@/restApi/common.api";

let expireTime = 5000; //900000

let timer = '';
const store = createStore({
    plugins: [createPersistedState()],
    state() {
        return {
            showLoading: false,
            isLogin: false,
            userName: '',
            userImage: '',
            userEmail: '',
            userContact: '',
            userAddress: '',
            isKycApproved: false,
            userCurrency: '',
            allCurrency: [],
            stateWithdrawID: '',
            stateWithdrawADD: '',
            stateWithdrawAMT: '',
            notificationList: [],
            showNotification: false,
            hidePermanentNotification: false,
            expireTime: expireTime,
            theme: 'dark',
        };
    },
    mutations: {
        [LOADING_STATE](state, payload)  {
            state.showLoading = payload;
        },

        [SET_ON_LOGOUT_DATA_MUTATION] (state, payload) {
            state.isLogin = payload.isLogin;
            state.userName = payload.userName;
            state.userImage = payload.userImage;
            state.userEmail = payload.userEmail;
            state.userContact = payload.userContact;
            state.userAddress = payload.userAddress;
            state.isKycApproved = payload.isKycApproved;
            state.expireTime = payload.expireTime;
            state.stateWithdrawID = '';
            state.stateWithdrawADD = '';
            state.stateWithdrawAMT = '';
            state.hidePermanentNotification = true;
            state.showNotification = false;
            state.notificationList = [];
        },

        [SET_IS_LOGIN_DATA_MUTATION] (state, payload) {
            state.isLogin = payload.isLogin;
            state.userName = payload.userName;
            state.userImage = payload.userImage;
            state.userEmail = payload.userEmail;
            state.userContact = payload.userContact;
            state.userAddress = payload.userAddress;
            state.isKycApproved = payload.isKycApproved;
            state.expireTime = expireTime;
            state.hidePermanentNotification = false;
        },

        [SET_THEME_DATA_MUTATION] (state, payload) {
            state.theme = payload
        },

        [SET_FIAT_CURRENCY_MUTATION] (state, payload) {
            state.userCurrency = payload
        },

        [SET_All_FIAT_CURRENCY_MUTATION] (state, payload) {
            if(payload === '') {
                state.allCurrency = [];
            } else {
                state.allCurrency.push(payload)
            }

        },

        [SET_NOTIFICATION_STATE_MUTATION] (state, payload) {
            state.showNotification = payload;
            state.hidePermanentNotification = !payload;
        },

        [SET_ALL_NOTIFICATION_MUTATION] (state, payload) {
            if(payload === '') {
                state.notificationList = [];
                state.showNotification = false;
            } else {
                state.notificationList.push(payload);
                state.showNotification = true;
            }
        },

        [REMOVE_SELECTED_NOTIFICATION_MUTATION] (state, payload) {
            state.notificationList = state.notificationList.filter((item) => item['nId'] !== payload);
            if(state.notificationList.length === 0) {
                state.showNotification = false;
            }
        },

        [SET_WITHDRAW_DATA_MUTATION] (state, payload) {
            state.stateWithdrawID = payload['id'];
            state.stateWithdrawADD = payload['add'];
            state.stateWithdrawAMT = payload['amt'];
        }
    },
    actions: {
        [LOGOUT_ACTION](context) {
            context.commit(SET_ON_LOGOUT_DATA_MUTATION, {
                isLogin: false,
                userName: '',
                userImage: '',
                userEmail: '',
                userContact: '',
                isKycApproved: false,
                userCurrency: '',
            });
            localStorage.clear();
            if(timer) {
                clearTimeout(timer);
            }
            window.location.reload();
        },

        [CHECK_USER_INFO_ACTION](context) {
            log('Call check Info fun', MsgType.log);
            log(context.state.expireTime, MsgType.log);
            log(context.state.isLogin, MsgType.log);
            if(context.state.isLogin && process.env.NODE_ENV !== 'development') {
                timer = setTimeout(async () => {
                    const {res, err} = await getUserDataApi();
                    if (err.value === null) {
                        if (JSON.parse(res.value)['status'].toString() !== 'true') {
                            await context.dispatch(LOGOUT_ACTION);
                        }else {
                            let add = '';
                            let city = '';
                            let state = '';
                            let country = '';
                            if(JSON.parse(res.value)['data']['userAddress'] !== null) {
                                const addJson = JSON.parse(JSON.parse(res.value)['data']['userAddress']);
                                add = addJson['add'];
                                city = addJson['city'];
                                state = addJson['state'];
                                country = addJson['country'];
                            }
                            const loginData = {
                                isLogin: true,
                                userName: JSON.parse(res.value)['data']['userName'],
                                userImage: JSON.parse(res.value)['data']['userDP'],
                                userEmail: JSON.parse(res.value)['data']['userEmail'],
                                userContact: JSON.parse(res.value)['data']['userPhone'],
                                userAddress: `${add} ${city} ${state} ${country}`,
                                isKycApproved: (JSON.parse(res.value)['data']['userStatusKYC'].toString() === '1'),
                            }
                            context.commit(SET_IS_LOGIN_DATA_MUTATION, loginData);
                            await context.dispatch(CHECK_USER_INFO_ACTION);
                        }
                    } else {
                        await context.dispatch(LOGOUT_ACTION);
                    }
                }, context.state.expireTime);
            }
        },

        [CHANGE_THEME_ACTION] (context, payload) {
            if(payload === "dark-theme"){
                context.commit(SET_THEME_DATA_MUTATION, "dark");
                log(payload, MsgType.log);
                log(context.state.theme, MsgType.log);
                document.body.id = context.state.theme;
            }else{
                context.commit(SET_THEME_DATA_MUTATION, "");
                log(payload, MsgType.log);
                log(context.state.theme, MsgType.log);
                document.body.id = context.state.theme;
            }
        },

        async [GET_All_FIAT_CURRENCY_ACTION](context) {
            const {curData, error} = await getCurrencyList();
            if (error.value === null) {
                try {
                    const data = JSON.parse(curData.value);
                    const msg = data['message'].toString();
                    if (data['status'].toString() === 'true') {
                        context.commit(SET_All_FIAT_CURRENCY_MUTATION, '');
                        for (let i = 0; i < data['data'].length; i++) {
                            if(context.state.userCurrency === '') {
                                if(i === 0) {
                                    context.commit(SET_FIAT_CURRENCY_MUTATION, data['data'][i]);
                                }
                            }
                            context.commit(SET_All_FIAT_CURRENCY_MUTATION, data['data'][i]);
                        }
                    } else {
                        context.commit(SET_FIAT_CURRENCY_MUTATION, {
                            "fiatID": 1,
                            "fiatName": "USD",
                            "fiatSymbol": "$",
                            "fiatPriceOneUSD": "1",
                        });
                        log(msg, MsgType.error);
                    }
                } catch (e) {
                    context.commit(SET_FIAT_CURRENCY_MUTATION, {
                        "fiatID": 1,
                        "fiatName": "USD",
                        "fiatSymbol": "$",
                        "fiatPriceOneUSD": "1",
                    });
                    log(e, MsgType.error);
                }
            } else {
                if(context.state.userCurrency === ''){
                    context.commit(SET_FIAT_CURRENCY_MUTATION, {
                        "fiatID": 1,
                        "fiatName": "USD",
                        "fiatSymbol": "$",
                        "fiatPriceOneUSD": "1",
                    });
                }
                log('Currency API Error', MsgType.error);
            }
        },

        async [GET_All_NOTIFICATION_ACTION](context) {
            const nList = [];
            if(!context.state.hidePermanentNotification) {
                const {notiRes, error} = await getNotifications();
                if(error.value === null) {
                    if(JSON.parse(notiRes.value)['status'].toString().toLowerCase() === 'true' && JSON.parse(notiRes.value)['data'].length > 0){
                        for (let i = 0; i < JSON.parse(notiRes.value)['data'].length; i++) {
                            const d = JSON.parse(notiRes.value)['data'][i];
                            const rrr = {
                                nId: (i+1).toString(),
                                nTitle: d['mainText'],
                                nAction: d['buttonText'] ?? '',
                                nRoute: d['buttonRoute'] ?? ''
                            }
                            nList.push(rrr);
                        }
                    }
                }
            }

            context.state.notificationList = [];
            context.state.showNotification = false;
            if (nList.length > 0) {
                for (let i = 0; i < nList.length; i++) {
                    context.commit(SET_ALL_NOTIFICATION_MUTATION, nList[i]);
                }
            } else {
                context.commit(SET_ALL_NOTIFICATION_MUTATION, '');
            }
        }
    }
});

export default store;
