<template>
    <footer>
        <div class="container">
            <div class="row mx-0 mt-5 p-0 aboutPage">
                <div class="col-md-2">
                    <h4 class="textInvert d-flex justify-content-between align-items-center footerToggle">About Us <span class="customAccordionIcon"></span></h4>
                    <ul>
                        <li><router-link to="/AboutUs">About</router-link></li>
<!--                        <li><a href="javascript:void(0)">Press</a></li>-->
<!--                        <li><a href="javascript:void(0)">Career</a></li>-->
<!--                        <li><a href="javascript:void(0)">Business Contacts</a></li>-->
<!--                        <li><a href="javascript:void(0)">Community</a></li>-->
<!--                        <li><a href="javascript:void(0)">BitRish Blog</a></li>-->
<!--                        <li><a href="javascript:void(0)">Building Trust</a></li>-->
                        <li><router-link to="/legalPage">Legal</router-link></li>
                        <li><router-link to="/TermsCondition">Terms</router-link></li>
                        <li><a href="javascript:void(0)">Privacy</a></li>
                        <li><a href="javascript:void(0)">Risk Warning</a></li>
                        <li><a href="javascript:void(0)">Announcements</a></li>
                        <li><a href="https://docs.bitrish.com/" target="_blank">Document</a></li>
                        <li><a href="javascript:void(0)">Notices</a></li>
                        <li><a href="javascript:void(0)">Sitemap</a></li>
                        <li><a href="javascript:void(0)">Cookie Preferences</a></li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h4 class="textInvert d-flex justify-content-between align-items-center footerToggle">Products <span class="customAccordionIcon"></span></h4>
                    <ul>
                        <li><a href="javascript:void(0)">Exchange</a></li>
                        <li><a href="javascript:void(0)">Academy</a></li>
                        <li><a href="javascript:void(0)">Charity</a></li>
                        <li><a href="javascript:void(0)">NFT (Coming Soon)</a></li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h4 class="textInvert d-flex justify-content-between align-items-center footerToggle">Service <span class="customAccordionIcon"></span></h4>
                    <ul>
                        <li><a href="javascript:void(0)">Buy Crypto</a></li>
                        <li><a href="javascript:void(0)">OTC Trading</a></li>
                        <li><router-link to="/listingPage">Listing Application</router-link></li>
                        <li><a href="javascript:void(0)">P2P Merchant Application</a></li>
                        <li><a href="javascript:void(0)">P2Pro Merchant Application</a></li>
                        <li><a href="javascript:void(0)">Historical Market Data</a></li>
                        <li><a href="javascript:void(0)">Proof of Reserves</a></li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h4 class="textInvert d-flex justify-content-between align-items-center footerToggle">Support <span class="customAccordionIcon"></span></h4>
                    <ul>
                        <li><a href="javascript:void(0)">Request a Feature</a></li>
                        <li><a href="javascript:void(0)">Support Center</a></li>
                        <li><a href="javascript:void(0)" @click="openGmailCompose">24/7 Chat Support</a></li>
                        <li><router-link to="/DepositFee">Fees</router-link></li>
                        <li><router-link to="/TradingFee">Trading Rules</router-link></li>
                        <li><a href="javascript:void(0)">BitRish Verify</a></li>
                        <li><a href="javascript:void(0)">Law Enforcement Requests</a></li>
                        <li><a href="javascript:void(0)">BitRish Legal (Court Orders)</a></li>
                        <li><a href="javascript:void(0)">BitRish Airdrop Portal</a></li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h4 class="textInvert d-flex justify-content-between align-items-center footerToggle">Learn <span class="customAccordionIcon"></span></h4>
                    <ul>
<!--                        <li><a href="javascript:void(0)">Learn & Earn</a></li>-->
                        <li><router-link to="/market_overview">Browse Crypto Prices</router-link></li>
                        <li><router-link to="/market_overview">Bitcoin Price</router-link></li>
                        <li><router-link to="/market_overview">Ethereum Price</router-link></li>
                        <li><router-link :to="getTradeRoute('BNB_USDT')">Buy BNB</router-link></li>
                        <li><router-link :to="getTradeRoute('BTC_BUSD')">Buy BUSD</router-link></li>
                        <li><router-link :to="getTradeRoute('BTC_BUSD')">Buy Bitcoin</router-link></li>
                        <li><router-link :to="getTradeRoute('ETH_BTC')">Buy Ethereum</router-link></li>
                        <li><router-link :to="getTradeRoute('DOGE_USDT')">Buy Degecoin</router-link></li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <h4 class="textInvert">Community</h4>
                    <div class="mt-3">
<!--                        <a href="javascript:void(0)" class="communityLink"><i class="fa-brands fa-facebook"></i></a>-->
                        <a href="https://twitter.com/bitrishexchange" target="_blank" class="communityLink"><i class="fa-brands fa-twitter"></i></a>
                        <a href="https://bitrish.medium.com/" target="_blank" class="communityLink"><i class="fa-brands fa-medium"></i></a>
<!--                        <a href="javascript:void(0)" class="communityLink"><i class="fa-brands fa-telegram"></i></a>
                        <a href="javascript:void(0)" class="communityLink"><i class="fa-brands fa-pinterest"></i></a>
                        <a href="javascript:void(0)" class="communityLink"><i class="fa-brands fa-discord"></i></a>
                        <a href="javascript:void(0)" class="communityLink"><i class="fa-brands fa-instagram"></i></a>
                        <a href="javascript:void(0)" class="communityLink"><i class="fa-brands fa-youtube"></i></a>
                        <a href="javascript:void(0)" class="communityLink"><i class="fa-brands fa-tiktok"></i></a>
                        <a href="javascript:void(0)" class="communityLink"><i class="fa-brands fa-reddit"></i></a>
                        <a href="javascript:void(0)" class="communityLink"><i class="fa-brands fa-vk"></i></a>-->
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-block p-4 text-center textInvert borderTop">
                        BitRish &copy; 2023
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>

import {mapState} from "vuex";

export default {
    name: "AppFooter",
    computed: {
        ...mapState({
            isLogin: (state) => state.isLogin,
            userName: (state) => state.userName,
            userEmail: (state) => state.userEmail,
        })
    },
    methods: {
        openGmailCompose() {
            const to = 'hello@bitrish.com';
            const subject = 'Hello!';
            const message = '';
            const url = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=${encodeURIComponent(to)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
            window.open(url);
        },
        getTradeRoute(pair) {
            return { path: '/trade/' + pair };
        },
        dynamicUrl (pair) {
            return window.location.protocol + '//' + window.location.host + '/#/trade?pair=' + pair
        }
    },
}
</script>

<style>

</style>
