import router from "@/route/routes";
import {getWalletByCoinId} from "@/restApi/deposit_api";
import BigNumber from "bignumber.js";

export const isDebug = false;

export let theme = window.localStorage.getItem("theme");

export const MsgType = {
    log: "log",
    error: "error",
}

export async function logout() {
    localStorage.clear();
    if (router) {
        await router.push("/signIn");
    } else {
        window.location.href = "/"
    }
}

export async function checkToken(status) {
    log("Status =="+status, MsgType.log);
    if (status === 401 || status === 408 || status === 401) {
        await logout();
    }
}

export function log(msg, msgType) {
    if (isDebug) {
        if (msgType === MsgType.log) {
            console.log(msg);
        } else if (msgType === MsgType.error) (
            console.error(msg)
        )
    }
}
export async function getBalance(coinId) {
    let bal = '0';
    const {resData, error} = await getWalletByCoinId(coinId);
    if (error.value === null) {
        if (JSON.parse(resData.value)['status'].toString().toLowerCase() === 'true') {
            bal = JSON.parse(resData.value)['data']['walletBalance'];
        }
    }
    return { bal }
}

export function roundOff(value) {
    if(!value) return 0.0000;
    return parseFloat(value.toString()).toFixed(4)
}

export function themeSwitch(themeId){
    if(themeId === "dark-theme"){
        theme = 'dark';
        document.body.id = theme;
        window.localStorage.setItem("theme","dark");
    }else{
        theme = '';
        document.body.id = '';
        window.localStorage.setItem("theme","");
    }
}

export function convertValueAccordingDecimal(wei, decimals) {
    const weiBigNumber = new BigNumber(wei);
    const decimal = weiBigNumber.dividedBy(new BigNumber(10).exponentiatedBy(decimals));
    return decimal.toFixed();
}

