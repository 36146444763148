<template>
    <div class="container">
        <div class="row m-0">
            <div class="col-md-12 my-5 textInvert">
                <h1 class="boldHeading">Building Trust</h1>
            </div>
        </div>
    </div>
    <div class="row m-0 p-0 pageContent">
        <div class="container">
            <div class="col-md-12 mt-5">
                <p>
                    The blockchain industry is at the cutting edge of global financial innovation. Bitrish recognizes transparency of user funds, robust security, and regulatory compliance as key components of building and maintaining the user trust needed for the technology to deliver on its massive promise. Our approach to building trust as a centralized digital asset exchange (CEX) is comprehensively summarized in this policy paper.
                </p>
                <p>
                    This page is a hub for information related to Bitrish’s initiatives to enhance the transparency of funds, security, and regulatory compliance, as well as promote sensible policies around digital assets and education in these critical domains. Here, you will find detailed information and timely updates related to these efforts. Anyone interested is encouraged to use this resource to learn more about Bitrish’s approach to transparency and safekeeping of user funds, maintaining robust security, and meeting regulatory requirements.
                </p>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</template>

<script>
import AppFooter from "@/components/parcel/AppFooter.vue";

export default {
    name: "BuildingTrust",
    components: {AppFooter}
}
</script>

<style scoped>

</style>
