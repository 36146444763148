<template>
    <div class="customSelfi">
        <div class="customSelfiInner">
            <a href="javascript:void(0)" @click="closeSelfiSample()" class="closeSelfi">&times;</a>
            <img src="https://gpslabindia.nyc3.cdn.digitaloceanspaces.com/exchangeBitRish/sample/kycsample.jpg"  alt=""/>
        </div>
    </div>
    <div class="customAppPassword customModalForm">
        <div class="customAppPasswordInner">
            <a href="javascript:void(0)" @click="closeAppPasswordModal()" class="closeAppPassword">&times;</a>
            <h3 class="textInvert d-inline-flex justify-content-between align-items-center w-100">Add New App Password</h3>
            <hr />
            <div class="d-flex justify-content-start align-items-start flex-column">
                <div class="form-group w-100">
                    <label for="appUsername">Nick Name</label>
                    <input type="text" class="form-control" ref="nickName" placeholder="Enter your nick name" name="appUsername" id="appUsername" autocomplete="off" />
                </div>

                <div class="textInvert w-100 mb-3">
                    <label>OTP <span class="desc-text" style="font-size: 12px; color: lightslategray"> (Note: OTP will be sent on the mail you provided)</span></label>
                    <div class="input-group">
                        <input type="text" id="otp" ref="appOtp" placeholder="Enter OTP" class="form-control w-100" maxlength="6" :disabled="isSendPassOTP"/>
                        <div class="inout-group-button">
                            <button class="btn btn-primary" :disabled="!isSendPassOTP" @click="getPassOTP">Send OTP</button>
                        </div>
                    </div>
                </div>

                <div class="form-group w-100 text-right align-items-center" v-if="!getServerPassword">
                    <span class="appPasswordStatus textGreen mr-3"></span>
<!--                    <button class="btn btn-primary mr-3"  v-if="getServerPassword" @click="closeAppPasswordModal()"><i class="fa-solid fa-times"></i> Close</button>-->
                    <button class="btn btn-primary" @click="generateAppPassword()"><i class="fa-solid fa-rotate-right"></i> Generate Password</button>
                </div>

                <div v-if="getServerPassword" class="textInvert w-100">
                    <label>Generated Password</label>
                    <div class="input-group">
                        <input type="text" id="genPassword" v-model="genPass" readonly class="form-control w-100" />
                        <div class="inout-group-button">
                            <button class="btn btn-primary" @click="copyAppPassword()"><i class="fa-solid fa-copy"></i> Copy</button>
                        </div>
                    </div>
                    <small class="textRed mb-5 d-block">Copy the password before you closing this popup or it won't be reveal again.</small>
                </div>
            </div>
            <div class="customAppPasswordLoading">
                <svg class="spinner" viewBox="0 0 50 50">
                    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
            </div>
        </div>
    </div>
    <div class="row m-0 p-0">
        <div class="col-xl-2 col-md-2 col-sm-3 my-3">
            <div class="sidebar">
                <ul>
                    <li>
                        <a href="javascript:void(0)" @click="changeTab('contentOne', $event.target)" class="customLink active">Profile</a>
                        <ul class="customDropdown">
                            <li><a href="javascript:void(0)" @click="changeTab('contentOne', $event.target)" class="customLink active">Personal Information</a></li>
                            <li><a href="javascript:void(0)" @click="changeTab('contentTwo', $event.target)" class="customLink">KYC</a></li>
                            <li><a href="javascript:void(0)" @click="changeTab('contentThree', $event.target)" class="customLink">Change Password</a></li>
                            <li><a href="javascript:void(0)" @click="changeTab('contentFive', $event.target)" class="customLink">App Password</a></li>
                        </ul>
                    </li>
                    <li>
                        <router-link to="/MyWallet" class="customLink">Wallet</router-link>
                    </li>
<!--                    <li>-->
<!--                        <a href="javascript:void(0)" @click="changeTab('contentSix', $event.target)" class="customLink">All Transactions</a>-->
<!--                    </li>-->
                </ul>
            </div>
        </div>
        <div class="col-xl-10 col-md-10 col-sm-9 my-3">
            <div class="mainContent">
                <section class="customSection active" id="contentOne">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title border-bottom mb-5">Personal Information</h5>
                            <div class="settings-profile">
                                <form>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="h-100 w-100 border border-round text-center p-4 d-flex justify-content-center align-items-center flex-column">
                                                <img src="../../../../public/assets/img/avatar.svg" alt="avatar">
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label for="legalNameP">Legal name</label>
                                                    <input id="legalNameP" type="text" class="form-control" v-model="userName" disabled>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="num">Phone Number</label>
                                                    <input id="num" type="text" class="form-control" v-model="userNum" disabled>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="emailAddress">Email</label>
                                                    <input id="emailAddress" type="text" class="form-control" v-model="userEmail" disabled>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="addressP">Address</label>
                                                    <input id="addressP" type="text" class="form-control" v-model="userAddressP" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="customSection" id="contentTwo">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title border-bottom mb-5 d-flex justify-content-between align-items-center">Account Information <span class="d-inline-flex" v-if="isHasKycData">KYC Status: <span class="textRed ml-2" v-if="kycStatus.toString() === '2'">Cancelled</span><span class="text-warning ml-2" v-else-if="kycStatus.toString() === '0'">Pending</span><span class="textGreen ml-2" v-else-if="kycStatus.toString() === '1'">Approved</span></span></h5>
                            <div class="settings-profile">
                                <form>
                                    <div class="form-row mt-4">
                                        <div class="col-md-6">
                                            <label class="heading-text" for="legalName">Legal Name</label>
                                            <div class="input-group" v-if="getStatusForIcon('kyc_legal_name')">
                                                <input id="legalName" type="text" ref="legalName" v-model="lName" class="form-control" placeholder="Your name will appear after verification" readonly />
                                                <div class="input-group-append customTooltip customGroup" data-toggle="tooltip" data-placement="top" :title="getErrorText('kyc_legal_name')" v-if="getStatusForIcon('kyc_legal_name') === 3">
                                                    <span v-if="getStatusForIcon('kyc_legal_name') === 2"><i class="fa-solid fa-check textGreen"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_legal_name') === 1"><i class="fa-solid fa-hourglass text-warning"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_legal_name') === 3"><i class="fa-solid fa-circle-exclamation textRed"></i></span>
                                                </div>
                                                <div class="input-group-append customGroup" v-else>
                                                    <span v-if="getStatusForIcon('kyc_legal_name') === 2"><i class="fa-solid fa-check textGreen"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_legal_name') === 1"><i class="fa-solid fa-hourglass text-warning"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_legal_name') === 3" ><i class="fa-solid fa-circle-exclamation textRed"></i></span>
                                                </div>
                                            </div>
                                            <div class="form-group" v-else>
                                                <input id="legalName" type="text" ref="legalName" v-model="lName" class="form-control" placeholder="Your name will appear after verification" readonly />
                                            </div>
                                        </div>
                                        <div class="col-md-6 dobArea">
                                            <label class="heading-text" for="dob">Date of Birth</label>
                                            <div class="input-group" v-if="getStatusForIcon('kyc_dob')">
                                                <input id="dob" type="date" ref="dob" v-model="birthDate" :min="minDate" :max="maxDate" class="form-control" placeholder="Date of Birth" :disabled="getStatus('kyc_dob')">
                                                <div class="input-group-append customTooltip customGroup" data-toggle="tooltip" data-placement="top" :title="getErrorText('kyc_dob')" v-if="getStatusForIcon('kyc_dob') === 3">
                                                    <span v-if="getStatusForIcon('kyc_dob') === 2"><i class="fa-solid fa-check textGreen"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_dob') === 1"><i class="fa-solid fa-hourglass text-warning"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_dob') === 3" ><i class="fa-solid fa-circle-exclamation textRed"></i></span>
                                                </div>
                                                <div class="input-group-append customGroup" v-else>
                                                    <span v-if="getStatusForIcon('kyc_dob') === 2"><i class="fa-solid fa-check textGreen"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_dob') === 1"><i class="fa-solid fa-hourglass text-warning"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_dob') === 3" ><i class="fa-solid fa-circle-exclamation textRed"></i></span>
                                                </div>
                                            </div>
                                            <div class="form-group" v-else>
                                                <input id="dob" type="date" ref="dob" v-model="selectedDate" :min="minDate" :max="maxDate" class="form-control" placeholder="dd-mm-yyyy" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="heading-text" for="contactNumber">Contact Number</label>
                                            <input id="contactNumber" type="tel" ref="phoneNum" v-model="phoneNumber" class="form-control" placeholder="Contact Number" disabled>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="heading-text" for="address">Address</label>
                                            <div class="input-group" v-if="getStatusForIcon('kyc_address')">
                                                <input id="address" type="text" ref="add" v-model="address" class="form-control" placeholder="Address" :disabled="getStatus('kyc_address')">
                                                <div class="input-group-append customTooltip customGroup" data-toggle="tooltip" data-placement="top" :title="getErrorText('kyc_address')" v-if="getStatusForIcon('kyc_address') === 3">
                                                    <span v-if="getStatusForIcon('kyc_address') === 2"><i class="fa-solid fa-check textGreen"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_address') === 1"><i class="fa-solid fa-hourglass text-warning"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_address') === 3"><i class="fa-solid fa-circle-exclamation textRed"></i></span>
                                                </div>
                                                <div class="input-group-append customGroup" v-else>
                                                    <span v-if="getStatusForIcon('kyc_address') === 2"><i class="fa-solid fa-check textGreen"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_address') === 1"><i class="fa-solid fa-hourglass text-warning"></i></span>
                                                    <span v-else-if="getStatusForIcon('kyc_address') === 3"><i class="fa-solid fa-circle-exclamation textRed"></i></span>
                                                </div>
                                            </div>
                                            <div class="form-group" v-else>
                                                <input id="address" type="text" ref="add" class="form-control" placeholder="Address">
                                            </div>
                                        </div>

                                        <div class="col-md-4 mb-4">
                                            <label for="Country">Select Country</label>
                                            <CountrySelect class="form-control" v-model="country" :country="country" topCountry="US" countryName="true" :disabled="getStatus('kyc_address')"/>
                                        </div>

                                        <div class="col-md-4 mb-4">
                                          <label for="Region">Select State</label>
                                          <RegionSelect @input="setState($event.target.value)" class="form-control" v-model="region" :country="country" :region="region" countryName="true" regionName="true" :disabled="getStatus('kyc_address')"/>
                                        </div>

                                        <div class="col-md-4 mb-4" v-if="getStatus('kyc_address')">
                                          <label for="City">City</label>
                                          <input type="text" name="City" id="City" v-model="kCity" ref="KCity" class="form-control" :disabled="getStatus('kyc_address')">
                                        </div>
                                        <div class="col-md-4 mb-4" v-else>
                                            <label for="City">City</label>
                                            <input type="text" name="City" id="City" v-model="kCity" ref="KCity" :disabled="getState" class="form-control">
                                        </div>

                                      <!-- One -->
                                        <div class="col-md-4 order-md-1" v-if="!getStatus('kyc_government_id_img_id')">
                                            <label class="heading-text" for="address">ID Proof <span class="desc-text" style="font-size: 12px; color: lightslategray"> (Front)</span></label>
                                            <br>
                                            <file-pond
                                                name="imageFile"
                                                ref="pond"
                                                class-name="my-pond"
                                                label-idle="Drop files here..."
                                                allow-multiple="false"
                                                max-files="1"
                                                accepted-file-types="image/jpeg, image/png"
                                                credits=""
                                                v-bind:server="myProofServer"
                                                v-bind:files="myProofFiles"
                                                v-on:init="handleProofFileInit"
                                                v-on:removefile="handleProofFileRemove"
                                            />

                                            <div class="confirm-div" v-if="getStatusForIcon('kyc_government_id_img_id') === 2"><a class="textGreen">Approved</a></div>
                                            <div class="pending-div" v-else-if="getStatusForIcon('kyc_government_id_img_id') === 1"><a class="text-warning">Pending</a></div>
                                            <div class="error-div" v-else-if="getStatusForIcon('kyc_government_id_img_id') === 3"><a class="textRed">{{ getErrorText('kyc_government_id_img_id') }}</a></div>
                                        </div>

                                        <div class="col-md-4 order-md-1 mb-4" v-else>
                                            <label class="heading-text" for="proof-file">ID Proof <span class="desc-text" style="font-size: 12px; color: lightslategray"> (Front)</span></label>
                                            <br>
                                            <div class="imgCover" :style="{backgroundImage:`url(${myProofImg}) `}">
                                                <div class="filePondExtra">
                                                    <div class="confirm-div" v-if="getStatusForIcon('kyc_government_id_img_id') === 2"><a class="textGreen">Approved</a></div>
                                                    <div class="pending-div" v-else-if="getStatusForIcon('kyc_government_id_img_id') === 1"><a class="text-warning">Pending</a></div>
                                                    <div class="error-div" v-else-if="getStatusForIcon('kyc_government_id_img_id') === 3"><a class="textRed">{{ getErrorText('kyc_government_id_img_id') }}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- One -->

                                        <!-- Two -->
                                        <div class="col-md-4 order-md-4" v-if="!getStatus('kyc_government_id_img_back_id')">
                                            <label class="heading-text" for="address">ID Proof <span class="desc-text" style="font-size: 12px; color: lightslategray"> (Back)</span></label>
                                            <br>
                                            <file-pond
                                                name="imageFile"
                                                ref="pond"
                                                class-name="my-pond"
                                                label-idle="Drop files here..."
                                                allow-multiple="false"
                                                max-files="1"
                                                accepted-file-types="image/jpeg, image/png"
                                                credits=""
                                                v-bind:server="myProofBackServer"
                                                v-bind:files="myProofFilesBack"
                                                v-on:init="handleProofFileInit"
                                                v-on:removefile="handleProofBackFileRemove"
                                            />

                                            <div class="confirm-div" v-if="getStatusForIcon('kyc_government_id_img_back_id') === 2"><a class="textGreen">Approved</a></div>
                                            <div class="pending-div" v-else-if="getStatusForIcon('kyc_government_id_img_back_id') === 1"><a class="text-warning">Pending</a></div>
                                            <div class="error-div" v-else-if="getStatusForIcon('kyc_government_id_img_back_id') === 3"><a class="textRed">{{ getErrorText('kyc_government_id_img_back_id') }}</a></div>
                                        </div>

                                        <div class="col-md-4 order-md-4 mb-4" v-else>
                                            <label class="heading-text" for="proof-file">ID Proof <span class="desc-text" style="font-size: 12px; color: lightslategray"> (Back)</span></label>
                                            <br>
                                            <div class="imgCover" :style="{backgroundImage:`url(${myProofImgBack}) `}">
                                                <div class="filePondExtra">
                                                    <div class="confirm-div" v-if="getStatusForIcon('kyc_government_id_img_back_id') === 2"><a class="textGreen">Approved</a></div>
                                                    <div class="pending-div" v-else-if="getStatusForIcon('kyc_government_id_img_back_id') === 1"><a class="text-warning">Pending</a></div>
                                                    <div class="error-div" v-else-if="getStatusForIcon('kyc_government_id_img_back_id') === 3"><a class="textRed">{{ getErrorText('kyc_government_id_img_back_id') }}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Two -->

                                        <!-- Three -->
                                        <div class="col-md-4 order-md-2" v-if="!getStatus('kyc_government_address_proof_img_id')">
                                            <label class="heading-text" for="address">Address Proof <span class="desc-text" style="font-size: 12px; color: lightslategray"> (Front)</span></label>
                                            <file-pond
                                            name="imageFile"
                                            ref="pond"
                                            class-name="my-pond"
                                            label-idle="Drop files here..."
                                            allow-multiple="false"
                                            max-files="1"
                                            accepted-file-types="image/jpeg, image/png"
                                            credits=""
                                            v-bind:server="myAddServer"
                                            v-bind:files="myAddFiles"
                                            v-on:init="handleAddFileInit"
                                            v-on:removefile="handleAddFileRemove"
                                            />

                                            <div class="confirm-div" v-if="getStatusForIcon('kyc_government_address_proof_img_id') === 2"><a class="textGreen">Approved</a></div>
                                            <div class="pending-div" v-else-if="getStatusForIcon('kyc_government_address_proof_img_id') === 1"><a class="text-warning">Pending</a></div>
                                            <div class="error-div" v-else-if="getStatusForIcon('kyc_government_address_proof_img_id') === 3"><a class="textRed">{{ getErrorText('kyc_government_address_proof_img_id') }}</a></div>
                                        </div>

                                        <div class="col-md-4 order-md-2 mb-4" v-else>
                                            <label class="heading-text" for="address">Address Proof <span class="desc-text" style="font-size: 12px; color: lightslategray"> (Front)</span></label>
                                            <br>
                                            <div class="imgCover" :style="{backgroundImage:`url(${myAddImg}) `}">
                                                <div class="filePondExtra">
                                                    <div class="confirm-div" v-if="getStatusForIcon('kyc_government_address_proof_img_id') === 2"><a class="textGreen">Approved</a></div>
                                                    <div class="pending-div" v-else-if="getStatusForIcon('kyc_government_address_proof_img_id') === 1"><a class="text-warning">Pending</a></div>
                                                    <div class="error-div" v-else-if="getStatusForIcon('kyc_government_address_proof_img_id') === 3"><a class="textRed">{{ getErrorText('kyc_government_address_proof_img_id') }}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Three -->

                                        <!-- Five -->
                                        <div class="col-md-4 order-md-5" v-if="!getStatus('kyc_government_address_proof_img_back_id')">
                                            <label class="heading-text" for="address">Address Proof <span class="desc-text" style="font-size: 12px; color: lightslategray"> (Back)</span></label>
                                            <br>
                                            <file-pond
                                                name="imageFile"
                                                ref="pond"
                                                class-name="my-pond"
                                                label-idle="Drop files here..."
                                                allow-multiple="false"
                                                max-files="1"
                                                accepted-file-types="image/jpeg, image/png"
                                                credits=""
                                                v-bind:server="myAddBackServer"
                                                v-bind:files="myAddFilesBack"
                                                v-on:init="handleAddFileInit"
                                                v-on:removefile="handleAddBackFileRemove"
                                            />

                                            <div class="confirm-div" v-if="getStatusForIcon('kyc_government_address_proof_img_back_id') === 2"><a class="textGreen">Approved</a></div>
                                            <div class="pending-div" v-else-if="getStatusForIcon('kyc_government_address_proof_img_back_id') === 1"><a class="text-warning">Pending</a></div>
                                            <div class="error-div" v-else-if="getStatusForIcon('kyc_government_address_proof_img_back_id') === 3"><a class="textRed">{{ getErrorText('kyc_government_address_proof_img_back_id') }}</a></div>
                                        </div>

                                        <div class="col-md-4 order-md-5 mb-4" v-else>
                                            <label class="heading-text" for="proof-file">Address Proof <span class="desc-text" style="font-size: 12px; color: lightslategray"> (Back)</span></label>
                                            <br>
                                            <div class="imgCover" :style="{backgroundImage:`url(${myAddImgBack}) `}">
                                                <div class="filePondExtra">
                                                    <div class="confirm-div" v-if="getStatusForIcon('kyc_government_address_proof_img_back_id') === 2"><a class="textGreen">Approved</a></div>
                                                    <div class="pending-div" v-else-if="getStatusForIcon('kyc_government_address_proof_img_back_id') === 1"><a class="text-warning">Pending</a></div>
                                                    <div class="error-div" v-else-if="getStatusForIcon('kyc_government_address_proof_img_back_id') === 3"><a class="textRed">{{ getErrorText('kyc_government_address_proof_img_back_id') }}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Five -->

                                        <!-- Four -->
                                        <div class="col-md-4 order-md-3" v-if="!getStatus('kyc_user_face_img_id')">
                                            <label class="heading-text" for="face-file">Selfie <span class="desc-text" style="font-size: 12px; color: lightslategray"> (With holding Government ID)</span><a href="javascript:void(0)" @click="openSelfiSample()" class="ml-4 customLink">Sample</a></label>
                                            <file-pond
                                                name="imageFile"
                                                ref="pond"
                                                class-name="my-pond"
                                                label-idle="Drop files here..."
                                                allow-multiple="false"
                                                max-files="1"
                                                accepted-file-types="image/jpeg, image/png"
                                                credits=""
                                                v-bind:server="myFaceServer"
                                                v-bind:files="myFaceFiles"
                                                v-on:init="handleFaceFileInit"
                                                v-on:removefile="handleFaceFileRemove"
                                            />

                                            <div class="confirm-div" v-if="getStatusForIcon('kyc_user_face_img_id') === 2"><a class="textGreen">Approved</a></div>
                                            <div class="pending-div" v-else-if="getStatusForIcon('kyc_user_face_img_id') === 1"><a class="text-warning">Pending</a></div>
                                            <div class="error-div" v-else-if="getStatusForIcon('kyc_user_face_img_id') === 3"><a class="textRed">{{ getErrorText('kyc_user_face_img_id') }}</a></div>
                                        </div>

                                        <div class="col-md-4 order-md-3 mb-4" v-else>
                                            <label class="heading-text" for="face-file">Selfie <span class="desc-text" style="font-size: 12px; color: lightslategray"> (With holding Government ID)</span><a href="javascript:void(0)" @click="openSelfiSample()" class="ml-4 customLink">Sample</a></label>
                                            <br>
                                            <div class="imgCover" :style="{backgroundImage:`url(${myFaceImg}) `}">
                                                <div class="filePondExtra">
                                                    <div class="confirm-div" v-if="getStatusForIcon('kyc_user_face_img_id') === 2"><a class="textGreen">Approved</a></div>
                                                    <div class="pending-div" v-else-if="getStatusForIcon('kyc_user_face_img_id') === 1"><a class="text-warning">Pending</a></div>
                                                    <div class="error-div" v-else-if="getStatusForIcon('kyc_user_face_img_id') === 3"><a class="textRed">{{ getErrorText('kyc_user_face_img_id') }}</a></div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Four -->

                                        <div class="col-md-8 order-6">
                                            <div class="w-100 d-flex justify-content-start align-items-center">
                                                <label class="heading-text m-0" for="tName">Please follow us on Twitter <a href="https://twitter.com/bitrishexchange" target="_blank">@bitrishexchange</a></label>
                                                <div class="twitterHandle">
                                                    <div class="input-group" v-if="getStatusForIcon('kyc_twitter_username')">
                                                        <input id="tName" type="text" ref="tName" v-model="tUserName" class="form-control" placeholder="Enter you twitter username with '@'" :disabled="getStatus('kyc_twitter_username')">
                                                        <div class="input-group-append customTooltip customGroup" data-toggle="tooltip" data-placement="top" :title="getErrorText('kyc_twitter_username')" v-if="getStatusForIcon('kyc_twitter_username') === 3">
                                                            <span v-if="getStatusForIcon('kyc_twitter_username') === 2"><i class="fa-solid fa-check textGreen"></i></span>
                                                            <span v-else-if="getStatusForIcon('kyc_twitter_username') === 1"><i class="fa-solid fa-hourglass text-warning"></i></span>
                                                            <span v-else-if="getStatusForIcon('kyc_twitter_username') === 3"><i class="fa-solid fa-circle-exclamation textRed"></i></span>
                                                        </div>
                                                        <div class="input-group-append customGroup" v-else>
                                                            <span v-if="getStatusForIcon('kyc_twitter_username') === 2"><i class="fa-solid fa-check textGreen"></i></span>
                                                            <span v-else-if="getStatusForIcon('kyc_twitter_username') === 1"><i class="fa-solid fa-hourglass text-warning"></i></span>
                                                            <span v-else-if="getStatusForIcon('kyc_twitter_username') === 3"><i class="fa-solid fa-circle-exclamation textRed"></i></span>
                                                        </div>
                                                    </div>

                                                    <div class="form-group" v-else>
                                                        <input id="tName" type="text" ref="tName" class="form-control" placeholder="Enter you twitter username with '@'">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4 order-7 text-right" v-if="kycStatus.toString() !== '1'">
                                            <input v-if="!isHasKycData" type="button" class="btn btn-primary" value="Apply KYC" @click="kycApply">
                                            <input v-else type="button" class="btn btn-primary" value="Update KYC" @click="kycUpdate" :disabled="!updateBtnState">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="customSection" id="contentThree">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title border-bottom mb-5">Security Information</h5>
                            <div class="settings-profile">
                                <form>
                                    <div class="form-row">
                                        <div class="col-md-12 mb-3">
                                            <label for="currentPass">Current password</label>
                                            <div class="input-group">
                                                <input type="password" class="form-control" id="currentPassword" placeholder="Enter your old password" ref="oldPass" v-model="oP" :disabled="isOtpSend">
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary" @click="togglePassword('currentPassword')" type="button"><i class="icon ion-md-eye"></i></button>
                                                    <div class="border-right m-0 p-0"></div>
                                                    <button class="btn btn-primary" type="button" :disabled="isOtpSend" @click="getOtp">Send OTP</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="newPass">OTP</label>
                                            <input type="number" class="form-control" maxlength="6" placeholder="Enter OTP" ref="otp" v-model="oTP" :disabled="!readyChangePass">
                                        </div>
                                        <div class="col-md-4">
                                            <label for="newPass">New password</label>
                                            <div class="input-group">
                                                <input type="password" id="newPassword" class="form-control" @input="runTimeValidation($event.target.value,'securityPasswordArea')" placeholder="Enter new password" ref="newPass" v-model="np" :disabled="!readyChangePass">
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary" @click="togglePassword('newPassword')" :disabled="!readyChangePass" type="button"><i class="icon ion-md-eye"></i></button>
                                                </div>
                                            </div>
                                            <div class="securityPasswordArea">
                                                <div id="popover-password">
                                                    <p>Password Strength: <span class="result"> </span></p>
                                                    <div class="progress">
                                                        <div id="password-strength" class="password-strength progress-bar progress-bar-danger" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width:0%"></div>
                                                    </div>
                                                </div>
                                                <span class="upperLower textRed mr-2 d-block">1 Lowercase & 1 Uppercase <i class="upperLowerIcon icon ion-md-close"></i></span>
                                                <span class="numericDigit textRed mr-2 d-block">1 Number (0-9) <i class="numericDigitIcon icon ion-md-close"></i></span>
                                                <span class="specialCharacter textRed mr-2 d-block">1 Special Character (!@#$%^&*) <i class="specialCharacterIcon icon ion-md-close"></i></span>
                                                <span class="passwordLength textRed mr-2 d-block">Atleast 8 Character <i class="passwordLengthIcon icon ion-md-close"></i></span>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="newPass">Re-enter password</label>
                                            <div class="input-group">
                                                <input type="password" id="rePassword" class="form-control" placeholder="Enter new password" ref="rePass" v-model="rp" :disabled="!readyChangePass">
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary" @click="togglePassword('rePassword')" :disabled="!readyChangePass" type="button"><i class="icon ion-md-eye"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-right mt-3">
                                            <button class="btn btn-primary" :disabled="!readyChangePass" @click="changePassword">Update</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="customSection" id="contentFive">
                    <div class="card">
                        <div class="card-body position-relative">
                            <h5 class="card-title border-bottom mb-5 d-inline-flex justify-content-between align-items-center w-100">App Password <a href="javascript:void(0)" class="btn btn-primary btn-sm mb-2" @click="openAppPasswordModal()">+ New</a></h5>
                            <div class="settings-profile" v-if="appPassList.length > 0">
                                <table class="table customPadding" id="appPasswordTable">
                                    <thead>
                                        <tr>
                                            <th>SR.</th>
                                            <th>Nick Name</th>
                                            <th>Created At</th>
                                            <th>Last Use</th>
                                            <th class="text-right">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(passList, index) in appPassList" :key="index" class="textInvert">
                                            <td>{{ index + 1}}</td>
                                            <td>{{ passList['appPassName'] }}</td>
                                            <td>{{ passList['createAtIso'] }}</td>
                                            <td>{{ passList['usedAtIso'] }}</td>
                                            <td class="text-right"><a href='javascript:void(0)' class='textInvert' @click="deleteAppPassword(passList['appPassID'])"><i class='fas fa-trash'></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="settings-profile" v-else>
                                <div class="justify-content-between">
                                    <span class="no-data-found"><i class="icon ion-md-document"></i>No App Password</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="customSection" id="contentSix">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title border-bottom mb-5 d-inline-flex justify-content-between align-items-center w-100">All Transactions</h5>
                            <div class="settings-profile">
                                <table class="table customPadding">
                                    <thead>
                                        <tr>
                                            <th>SR.</th>
                                            <th>Heading 1</th>
                                            <th>Heading 2</th>
                                            <th>Heading 3</th>
                                        </tr>
                                    </thead>
                                    <tbody class="textInvert">
                                        <tr>
                                            <td>1</td>
                                            <td>V1</td>
                                            <td>V2</td>
                                            <td>V3</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="customSection" id="contentFour">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center border-bottom mb-4 customMenu">
                                <h5 class="card-title">Wallet Information</h5>
                                <div class="mb-2 d-inline-flex walletPageLinks">
                                    <button target="#walletMainTab" class="btn btn-primary">Wallet</button>
                                    <button target="#depositWallet" class="btn btn-secondary">Deposit</button>
                                    <button target="#withdrawWallet" class="btn btn-secondary">Withdraw</button>
                                    <button target="#historyWallet" class="btn btn-secondary">Transaction History</button>
                                    <!-- <button target="#historyWallet" class="btn btn-secondary" @click="walletPageChange('historyWallet')">Transaction History</button> -->
                                </div>
                            </div>
                            <div class="settings-profile">
                                <div class="row d-none walletTabs0" id="depositWallet">
                                    <div class="col-md-6">
                                        <h2>Deposit Crypto</h2>
                                        <div class="steps d-flex justify-content-start w-100 mt-5">
                                            <div class="stepsContent active">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem">1</span>
                                                </div>
                                                <div class="stepsContentRight pt-2">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                        <div class="searchCoinDropdown">
                                                            <div class="historyCoinList p-3">
                                                                <small class="textGray">Search History</small>
                                                                <div class="hintCoin d-flex gap-1">
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>USDT</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>BTC</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="trendingCoinList p-3">
                                                                <small class="textGray">Trending</small>
                                                                <div class="hintCoin d-flex gap-1">
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>USDT</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>BTC</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>USDT</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>BTC</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>USDT</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>BTC</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="CoinList p-3">
                                                                <ul class="CoinListUL">
                                                                    <li class="coinListItem">Coin 1</li>
                                                                    <li class="coinListItem">Coin 2</li>
                                                                    <li class="coinListItem">Coin 3</li>
                                                                    <li class="coinListItem">Coin 4</li>
                                                                    <li class="coinListItem">Coin 5</li>
                                                                    <li class="coinListItem">Coin 6</li>
                                                                    <li class="coinListItem">Coin 7</li>
                                                                    <li class="coinListItem">Coin 8</li>
                                                                    <li class="coinListItem">Coin 9</li>
                                                                    <li class="coinListItem">Coin 10</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="hintCoin d-flex justify-content-between align-items-center mb-4">
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">USDT</span>
                                                        </a>
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">BTC</span>
                                                        </a>
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">TRX</span>
                                                        </a>
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">ALPAKA</span>
                                                        </a>
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">ALPINE</span>
                                                        </a>
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">MATIK</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem">2</span>
                                                </div>
                                                <div class="stepsContentRight pt-2">
                                                    <label>Select Network</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem">3</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-none walletTabs0" id="withdrawWallet">
                                    <div class="col-md-8">
                                        <h2>Withdraw</h2>
                                        <div class="steps d-flex justify-content-start w-100">
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">1</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">2</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">3</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-none walletTabs0" id="historyWallet">
                                    <div class="col-md-8">
                                        <h2>Transaction History</h2>
                                        <div class="steps d-flex justify-content-start w-100">
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">1</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">2</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">3</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row active walletTabs0" id="walletMainTab">
                                    <div class="col-md-12 m-4 py-5 text-center" v-if="!coinList">
                                        <h3 class="py-5 text-center">No Coin Found</h3>
                                    </div>
                                    <div class="col-md-3" v-if="coinList">
                                        <div class="my-3 font-400">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="icon ion-md-search"></i></span>
                                                </div>
                                                <input type="text" @input="searchCoin()" class="form-control p-0" id="myInput" placeholder="Search a coin">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3" v-if="coinList">
                                        <div class="my-3 font-400 d-flex align-items-center textGray pl-3">
                                            <label class="contentBox hideSmallBalances"> Hide Small Balances
                                                <input type="checkbox" checked="checked">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-if="coinList">
                                        <table class="table latestTransaction" id="myTable">
                                            <thead>
                                                <tr>
                                                    <th>Coin</th>
                                                    <th>Total</th>
                                                    <th>Available</th>
                                                    <th>In Order</th>
                                                    <th>BTC Value</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(coin, index) in coinList" :key="index" v-on:click="onCoinSelect(index)">
                                                    <td>
                                                        <div class="d-flex align-items-center walletCoinDetails">
                                                            <img class="walletCoinImage" :src="coin['coinIcon']" />
                                                            <div class="mx-3 d-flex flex-column">
                                                                <strong>{{ coin['coinName'] }}</strong>
                                                                <p class="m-0">Network: {{ coin['coinNetwork'] }}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><div class="d-flex justify-content-center flex-column walletCoinDetails">7,423,411.19000000</div></td>
                                                    <td><div class="d-flex justify-content-center flex-column walletCoinDetails">0.19000000</div></td>
                                                    <td><div class="d-flex justify-content-center flex-column walletCoinDetails">7,423,411.19000000</div></td>
                                                    <td><div class="d-flex justify-content-center flex-column walletCoinDetails">7,423,411.19000000<p class="m-0 textGray">≈ $10.79</p></div></td>
                                                    <td>
                                                        <div class="d-flex align-items-center walletCoinDetails justify-content-between">
                                                            <div>
                                                                <a href="javascript:void(0)" class="text-primary mr-3">Buy</a>
                                                                <a href="javascript:void(0)" class="text-primary mr-3">Deposit</a>
                                                                <a href="javascript:void(0)" class="text-primary mr-3">Withdraw</a>
                                                                <a href="javascript:void(0)" class="text-primary mr-3">Trade</a>
                                                                <!-- <a href="javascript:void(0)" class="text-primary mr-3">Earn</a>
                                                                <a href="javascript:void(0)" class="text-primary mr-3">Convert</a> -->
                                                            </div>
                                                            <div>
                                                                <a href="javascript:void(0)" class="gotoLink"><i class="fas fa-chevron-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- <div class="col-md-4 col-lg-3 pr-0 walletTabs">
                                        <div class="nav nav-pills walletTabLink walletTabsArea" role="tablist"
                                             aria-orientation="vertical">
                                            <a v-for="(coin, index) in coinList" :key="index"
                                               class="nav-link d-flex justify-content-between align-items-center"
                                               data-toggle="pill" role="tab" aria-selected="true"
                                               v-on:click="onCoinSelect(index)">
                                                <div class="d-inline-flex justify-content-start align-items-center">
                                                    <img :src="coin['coinIcon']">
                                                    <div class="mx-3">
                                                        <h2>{{ coin['coinName'] }}</h2>
                                                        <p>Network: {{ coin['coinNetwork'] }}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-8 col-lg-9 pl-0 walletContent">
                                        <div class="tab-content walletTabContent">
                                            <div class="tab-pane fade show active" id="coinBTC" role="tabpanel">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Balances</h5>
                                                        <div class="row">
                                                            <div class="col-md-6 mb-4">
                                                                <div class="d-inline-flex w-100 justify-content-between align-items-center customBadges">
                                                                    <div class="d-inline-flex align-items-center">
                                                                        <i class="icon ion-md-cash"></i>
                                                                        <h2>Balance</h2>
                                                                    </div>
                                                                    <h3>{{ cryptoBal }} {{ selectedCoin }}</h3>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-4">
                                                                <div class="d-inline-flex w-100 justify-content-between align-items-center customBadges">
                                                                    <div class="d-inline-flex align-items-center">
                                                                        <i class="icon ion-md-lock"></i>
                                                                        <h2>Lock Amount</h2>
                                                                    </div>
                                                                    <h3>{{ cryptoLockBal }} {{ selectedCoin }}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div>
                                                        <ul class="nav nav-pills" role="tablist">
                                                            <li class="nav-item">
                                                                <a class="nav-link active" data-toggle="pill" href="#deposit" role="tab" aria-selected="true">Deposit</a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a class="nav-link" data-toggle="pill" href="#withdraw" role="tab" aria-selected="false">Withdraw</a>
                                                            </li>
                                                        </ul>
                                                        <div class="tab-content">
                                                            <div class="tab-pane fade show active" id="deposit" role="tabpanel">
                                                                <div class="card-body">
                                                                    <h5 class="card-title">Wallet Deposit Address</h5>
                                                                    <div class="row wallet-address">
                                                                        <div class="col-md-8">
                                                                            <div>
                                                                                <p>Please note that if you deposit an amount that exceeds the deposit limit, you will not be able to withdraw the entire sum in a single transaction. Withdrawals will be subject to the withdrawal limit corresponding to your account type and compliance with our security protocols.</p>
                                                                                <div class="input-group">
                                                                                    <input type="text" class="form-control" readonly id="depositWA"
                                                                                           v-model="walletAdd">
                                                                                    <div class="input-group-append">
                                                                                        <button class="btn btn-primary" @click="copyAdd">COPY</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="input-group mt-1" v-if="walletExtra !== ''">
                                                                                    <input type="text" class="form-control" readonly id="depositWE"
                                                                                           v-model="walletExtra">
                                                                                    <div class="input-group-append">
                                                                                        <button class="btn btn-primary" @click="copyExtra">COPY</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <small class="textRed mt-2">Minimum Deposit Value:
                                                                                {{ selectedCoinDepositVal }} {{ selectedCoin }}</small>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <vue-qrcode v-bind:value="walletAdd"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-body">
                                                                        <h5 class="card-title">Latest Transactions</h5>
                                                                        <div class="wallet-history walletHistoryTableArea">
                                                                            <table class="table latestTransaction">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>No.</th>
                                                                                        <th>Date/Time</th>
                                                                                        <th class="text-center">Confirmation</th>
                                                                                        <th class="text-center">Status</th>
                                                                                        <th class="text-right">Amount</th>
                                                                                        <th class="text-right">Transaction Hash</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="(transaction, index) in depositList" :key="index">
                                                                                        <td>{{ index + 1}}</td>
                                                                                        <td>{{ transaction['new_date_time'] }}</td>
                                                                                        <td class="text-center">
                                                                                            <span class="text-warning" v-if="parseInt(transaction['confirmations']['current'].toString()) < parseInt(transaction['confirmations']['total'].toString())">
                                                                                                {{ transaction['confirmations']['current'] }}/{{ transaction['confirmations']['total'] }}
                                                                                                <a href="javascript:void(0)" class="text-warning" @click="getDepositTransactionList(walletAdd, sCoinID)">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M19.933 13.041a8 8 0 1 1-9.925-8.788c3.899-1 7.935 1.007 9.425 4.747"/><path d="M20 4v5h-5"/></g></svg>
                                                                                                </a>
                                                                                            </span>
                                                                                            <span class="textGreen" v-else-if="parseInt(transaction['confirmations']['current'].toString()) >= parseInt(transaction['confirmations']['total'].toString())">{{ transaction['confirmations']['current'] }}/{{ transaction['confirmations']['total'] }}</span>
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <span class="text-warning" v-if="transaction['status'].toString() === '0'">Pending</span>
                                                                                            <span class="textGreen" v-else-if="transaction['status'].toString() === '1'">Complete</span>
                                                                                        </td>
                                                                                        <td class="text-right">{{ transaction['amount'] }}</td>
                                                                                        <td class="text-right"><a :href="transaction['blockExplorerURL']" target="_blank">{{ makeShortHash(transaction['trxHash']) }}</a></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="lightbox-deposit" v-if="!isCanDeposit">
                                                                    <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                                                        <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                                                            <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                                                            <h1 class="text-warning m-0">Warning:</h1>
                                                                        </div>
                                                                        <span class="textInvert m-0 ml-2">Deposits suspended up to prior notice</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="tab-pane fade" id="withdraw" role="tabpanel">
                                                                <div class="card-body">
                                                                    <div class="row wallet-address">
                                                                        <div class="col-md-12">
                                                                            <div v-if="!showVerification">
                                                                                <h5 class="card-title">Withdraw</h5>
                                                                                <p>Please be aware that you will not be able to withdraw an amount exceeding the specified withdrawal limit for your account. Withdrawals are subject to the set limit as per your account type and our security measures.</p>

                                                                                <div class="input-group">
                                                                                    <input type="text" class="form-control" ref="withdrawAddress" placeholder="Enter Address" id="walletAddressPaste" v-model="withdrawAddVal" />
                                                                                    <div class="input-group-append">
                                                                                        <button class="btn btn-primary" @click="pasteText">PASTE</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="form-group mt-3">
                                                                                    <input type="text" class="form-control" ref="withdrawAmount" placeholder="Enter Amount" id="walletAmountPaste" v-model="withdrawAmtVal" />
                                                                                </div>
                                                                                <div class="form-group mt-2">
                                                                                    <button type="button" class="btn btn-primary" @click="sendWithdrawRequest">Withdraw</button>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="showVerification">
                                                                                <h3>Verification</h3>
                                                                                <hr />
                                                                                <div class="input-group">
                                                                                    <input type="text" class="form-control" ref="mobileOtp" placeholder="Enter Mobile OTP" v-model="withdrawPOTPVal"/>
                                                                                    <div class="input-group-append">
                                                                                        <button class="btn btn-primary" @click="resendOtp('PHONE')">Resend OTP</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="input-group mt-3">
                                                                                    <input type="text" class="form-control" ref="emailOtp" placeholder="Enter Email OTP" v-model="withdrawEOTPVal"/>
                                                                                    <div class="input-group-append">
                                                                                        <button class="btn btn-primary" @click="resendOtp('EMAIL')">Resend OTP</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="input-group mt-3">
                                                                                    <button class="btn btn-primary" @click="verifyOtp">Verify & Withdraw</button>
                                                                                    <button class="btn btn-primary ml-3" @click="cancelWithdraw">Cancel</button>
                                                                                </div>
                                                                            </div>
                                                                            <small class="textRed">Withdraw Fee: {{ selectedCoinWithdrawFee }} {{ selectedCoin }}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-body">
                                                                        <h5 class="card-title">Latest Transactions</h5>
                                                                        <div class="wallet-history">
                                                                            <table class="table latestTransaction">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>No.</th>
                                                                                        <th>Date/Time</th>
                                                                                        <th class="text-center">Status</th>
                                                                                        <th>Fee</th>
                                                                                        <th class="text-right">Amount</th>
                                                                                        <th class="text-right">Transaction Hash</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="(transaction, index) in withdrawList" :key="index">
                                                                                        <td>{{ index + 1}}</td>
                                                                                        <td>{{ transaction['new_date_time'] }}</td>
                                                                                        <td class="text-center">
                                                                                            <span class="text-warning" v-if="transaction['status'].toString() === '0'">Pending</span>
                                                                                            <span class="textGreen" v-else-if="transaction['status'].toString() === '1'">Complete</span>
                                                                                        </td>
                                                                                        <td>{{ transaction['fees'] }}</td>
                                                                                        <td class="text-right">{{ transaction['amount'] }}</td>
                                                                                        <td class="text-right"><a :href="transaction['blockExplorerURL']" target="_blank">{{ makeShortHash(transaction['trxHash']) }}</a></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="lightbox-deposit" v-if="!isCanWithdraw">
                                                                    <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                                                        <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                                                            <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                                                            <h1 class="text-warning m-0">Warning:</h1>
                                                                        </div>
                                                                        <span class="textInvert m-0 ml-2">Withdrawals suspended up to prior notice</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="lightbox" v-if="!isKycA">
                                            <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                                <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                                    <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                                    <h1 class="text-warning m-0">Warning:</h1>
                                                </div>
                                                <span class="textInvert m-0 ml-2">Your KYC is still pending</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <!-- Dialog Simple datepicker -->
    <div tabindex="-1" class="modal fade" id="datepicker-dialog" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <!-- Inline popup datepicker start -->
                <div id="datepicker-popup-inline"></div>
                <!-- Inline popup datepicker end -->
                <div class="modal-footer">
                    <button type="button" class="btn pmd-ripple-effect btn-light" aria-hidden="true" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn pmd-ripple-effect btn-primary">Select</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Dialog Simple datepicker -->
    <!-- Wallet Drawer -->
    <div class="drawer">
        <div class="drawerInner">
            <div class="drawerOverlay" @click="closeDrawer()"></div>
            <div class="drawerContent">
                <a href="javascript:void(0)" @click="closeDrawer()" class="closeDrawer"><i class="fas fa-times"></i></a>
                <div class="row h-100 m-0 drawerContentRow">
                    <div class="col-md-12 col-lg-12 p-0 walletContent h-100">
                        <div class="tab-content walletTabContent h-100">
                            <div class="tab-pane fade show active h-100" id="coinBTC" role="tabpanel">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Balances</h5>
                                        <div class="row">
                                            <div class="col-md-6 mb-4">
                                                <div class="d-inline-flex w-100 justify-content-between align-items-center customBadges">
                                                    <div class="d-inline-flex align-items-center">
                                                        <i class="icon ion-md-cash"></i>
                                                        <h2>Balance</h2>
                                                    </div>
                                                    <h3>{{ cryptoBal }} {{ selectedCoin }}</h3>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-4">
                                                <div class="d-inline-flex w-100 justify-content-between align-items-center customBadges">
                                                    <div class="d-inline-flex align-items-center">
                                                        <i class="icon ion-md-lock"></i>
                                                        <h2>Lock Amount</h2>
                                                    </div>
                                                    <h3>{{ cryptoLockBal }} {{ selectedCoin }}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div>
                                        <ul class="nav nav-pills" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" data-toggle="pill" href="#deposit" role="tab" aria-selected="true">Deposit</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" data-toggle="pill" href="#withdraw" role="tab" aria-selected="false">Withdraw</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane fade show active" id="deposit" role="tabpanel">
                                                <div class="card-body">
                                                    <h5 class="card-title">Wallet Deposit Address</h5>
                                                    <div class="row wallet-address">
                                                        <div class="col-md-8">
                                                            <div>
                                                                <p>Please note that if you deposit an amount that exceeds the deposit limit, you will not be able to withdraw the entire sum in a single transaction. Withdrawals will be subject to the withdrawal limit corresponding to your account type and compliance with our security protocols.</p>
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" readonly id="depositWA"
                                                                            v-model="walletAdd">
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-primary" @click="copyAdd">COPY</button>
                                                                    </div>
                                                                </div>
                                                                <div class="input-group mt-1" v-if="walletExtra !== ''">
                                                                    <input type="text" class="form-control" readonly id="depositWE"
                                                                            v-model="walletExtra">
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-primary" @click="copyExtra">COPY</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <small class="textRed mt-2">Minimum Deposit Value:
                                                                {{ selectedCoinDepositVal }} {{ selectedCoin }}</small>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <vue-qrcode v-bind:value="walletAdd"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Latest Transactions</h5>
                                                        <div class="wallet-history walletHistoryTableArea">
                                                            <table class="table latestTransaction">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No.</th>
                                                                        <th>Date/Time</th>
                                                                        <th class="text-center">Confirmation</th>
                                                                        <th class="text-center">Status</th>
                                                                        <th class="text-right">Amount</th>
                                                                        <th class="text-right">Transaction Hash</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(transaction, index) in depositList" :key="index">
                                                                        <td>{{ index + 1}}</td>
                                                                        <td>{{ transaction['new_date_time'] }}</td>
                                                                        <td class="text-center">
                                                                            <span class="text-warning" v-if="parseInt(transaction['confirmations']['current'].toString()) < parseInt(transaction['confirmations']['total'].toString())">
                                                                                {{ transaction['confirmations']['current'] }}/{{ transaction['confirmations']['total'] }}
                                                                                <a href="javascript:void(0)" class="text-warning" @click="getDepositTransactionList(walletAdd, sCoinID)">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M19.933 13.041a8 8 0 1 1-9.925-8.788c3.899-1 7.935 1.007 9.425 4.747"/><path d="M20 4v5h-5"/></g></svg>
                                                                                </a>
                                                                            </span>
                                                                            <span class="textGreen" v-else-if="parseInt(transaction['confirmations']['current'].toString()) >= parseInt(transaction['confirmations']['total'].toString())">{{ transaction['confirmations']['current'] }}/{{ transaction['confirmations']['total'] }}</span>
                                                                        </td>
                                                                        <td class="text-center">
                                                                            <span class="text-warning" v-if="transaction['status'].toString() === '0'">Pending</span>
                                                                            <span class="textGreen" v-else-if="transaction['status'].toString() === '1'">Complete</span>
                                                                        </td>
                                                                        <td class="text-right">{{ transaction['amount'] }}</td>
                                                                        <td class="text-right"><a :href="transaction['blockExplorerURL']" target="_blank">{{ makeShortHash(transaction['trxHash']) }}</a></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="lightbox-deposit" v-if="!isCanDeposit">
                                                    <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                                        <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                                            <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                                            <h1 class="text-warning m-0">Warning:</h1>
                                                        </div>
                                                        <span class="textInvert m-0 ml-2">Deposits suspended up to prior notice</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab-pane fade" id="withdraw" role="tabpanel">
                                                <div class="card-body">
                                                    <div class="row wallet-address">
                                                        <div class="col-md-12">
                                                            <div v-if="!showVerification">
                                                                <h5 class="card-title">Withdraw</h5>
                                                                <p>Please be aware that you will not be able to withdraw an amount exceeding the specified withdrawal limit for your account. Withdrawals are subject to the set limit as per your account type and our security measures.</p>

                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" ref="withdrawAddress" placeholder="Enter Address" id="walletAddressPaste" v-model="withdrawAddVal" />
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-primary" @click="pasteText">PASTE</button>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group mt-3">
                                                                    <input type="text" class="form-control" ref="withdrawAmount" placeholder="Enter Amount" id="walletAmountPaste" v-model="withdrawAmtVal" />
                                                                </div>
                                                                <div class="form-group mt-2">
                                                                    <button type="button" class="btn btn-primary" @click="sendWithdrawRequest">Withdraw</button>
                                                                </div>
                                                            </div>
                                                            <div v-if="showVerification">
                                                                <h3>Verification</h3>
                                                                <hr />
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" ref="mobileOtp" placeholder="Enter Mobile OTP" v-model="withdrawPOTPVal"/>
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-primary" @click="resendOtp('PHONE')">Resend OTP</button>
                                                                    </div>
                                                                </div>
                                                                <div class="input-group mt-3">
                                                                    <input type="text" class="form-control" ref="emailOtp" placeholder="Enter Email OTP" v-model="withdrawEOTPVal"/>
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-primary" @click="resendOtp('EMAIL')">Resend OTP</button>
                                                                    </div>
                                                                </div>
                                                                <div class="input-group mt-3">
                                                                    <button class="btn btn-primary" @click="verifyOtp">Verify & Withdraw</button>
                                                                    <button class="btn btn-primary ml-3" @click="cancelWithdraw">Cancel</button>
                                                                </div>
                                                            </div>
                                                            <small class="textRed">Withdraw Fee: {{ selectedCoinWithdrawFee }} {{ selectedCoin }}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Latest Transactions</h5>
                                                        <div class="wallet-history">
                                                            <table class="table latestTransaction">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No.</th>
                                                                        <th>Date/Time</th>
                                                                        <th class="text-center">Status</th>
                                                                        <th>Fee</th>
                                                                        <th class="text-right">Amount</th>
                                                                        <th class="text-right">Transaction Hash</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(transaction, index) in withdrawList" :key="index">
                                                                        <td>{{ index + 1}}</td>
                                                                        <td>{{ transaction['new_date_time'] }}</td>
                                                                        <td class="text-center">
                                                                            <span class="text-warning" v-if="transaction['status'].toString() === '0'">Pending</span>
                                                                            <span class="textGreen" v-else-if="transaction['status'].toString() === '1'">Complete</span>
                                                                        </td>
                                                                        <td>{{ transaction['fees'] }}</td>
                                                                        <td class="text-right">{{ transaction['amount'] }}</td>
                                                                        <td class="text-right"><a :href="transaction['blockExplorerURL']" target="_blank">{{ makeShortHash(transaction['trxHash']) }}</a></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="lightbox-deposit" v-if="!isCanWithdraw">
                                                    <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                                        <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                                            <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                                            <h1 class="text-warning m-0">Warning:</h1>
                                                        </div>
                                                        <span class="textInvert m-0 ml-2">Withdrawals suspended up to prior notice</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lightbox" v-if="!isKycA">
                            <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                    <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                    <h1 class="text-warning m-0">Warning:</h1>
                                </div>
                                <span class="textInvert m-0 ml-2">Your KYC is still pending</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Wallet Drawer -->
    <!-- App Password delete Popup --><div class="customPopup">
    <div class="customPopupDialog">
        <div class="customPopupData d-flex justify-content-center align-items-center flex-column">
            <h1 class="icon ion-md-information"></h1>
            <p>You want to delete this App Password?</p>
                <div class="py-2 px-3">
                <a href="javascript:void(0)" @click="deleteAppPasswordById" class="btn btn-primary-outline px-4 mr-2">Yes</a>
                <a href="javascript:void(0)" @click="closeCustomAppPassPopup" class="btn btn-primary-outline px-4">No</a>
                </div>
            </div>
        </div>
    </div>
    <!-- App Password delete Popup -->
</template>

<script>
import {createAppPassword, deleteAppPassword, getAllAppPasswords, getAppPasswordOTP} from "@/restApi/app_password.api";

// function randomString(length) {
//     var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
//     var result = '';
//     for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
//     return result;
// }
import vueFilepondEsm from "vue-filepond";

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';

import {
    getAllCoinList,
    getTransactionListByCoinId,
    getWalletByCoinId,
    startDepositListenByCoinId,
} from "@/restApi/deposit_api";
import VueQrcode from 'vue-qrcode';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import {FileType} from "@/restApi/upload_api";
import {toast, Type} from "@/util/toast";
import {applyKyc, getKycData} from "@/restApi/kyc_api";
import {getBalance, log, MsgType} from "@/util/constant";
import {mapActions, mapMutations, mapState} from "vuex";
import {CHECK_USER_INFO_ACTION, LOADING_STATE, SET_WITHDRAW_DATA_MUTATION} from "@/util/store.constent";
import {changeOldPassword, getOtpForChangePassword} from "@/restApi/api";
import {
    cancelWithdraw,
    getWithdrawList,
    resendOtpReq,
    verifyWithdrawOtp,
    withdrawRequest
} from "@/restApi/withdraw.api";
import WithdrawreqValidation from "@/services/withdrawreq.validation";
import Validations from "@/services/Validations";
import {useRoute} from "vue-router";
import {reactive, watch , ref, computed} from "vue";
import KycValidation from "@/services/kyc.validation";
import { CountrySelect, RegionSelect } from "vue3-ts-country-region-select";

const FilePond = vueFilepondEsm(FilePondPluginFileValidateType, FilePondPluginImagePreview);
let proofId;
let proofIdBack;
let addId;
let addIdBack;
let faceId;
let selectCoinId;
// let selectWalletAddress;
export default {
        name:"MyProfile",
        props: ['message'],
        components: {
            FilePond,
            VueQrcode,
          CountrySelect,
          RegionSelect,
        },
    computed: {
        ...mapState({
            isLogin: (state) => state.isLogin,
            userName: (state) => state.userName,
            userEmail: (state) => state.userEmail,
            userNum: (state) => state.userContact,
            isKycA: (state) => state.isKycApproved,
            userAddressP: (state) => state.userAddress,
            withId: (state) => state.stateWithdrawID,
        }),
        ...mapActions({
            checkUser: CHECK_USER_INFO_ACTION,
        }),
    },
        mounted() {
            document.title = 'Profile - Bitrish';
        },
        data: function () {
        return {
            // birthDate: new Date().setFullYear(new Date().getFullYear() + 1),
            kCity: '',
            getState: true,
            country: '',
            region: '',
            kycStatus: '',
            coinList: [],
            depositList: [],
            withdrawList: [],
            cryptoBal: '0.00',
            cryptoLockBal: '0.00',
            walletAdd: '',
            walletExtra: '',
            selectedCoin: 'MATIC',
            sCoinID: '',
            selectedCoinDepositFees: '0',
            selectedCoinDepositVal: '0',
            selectedCoinWithdrawFee: '0',
            isOtpSend: false,
            readyChangePass: false,
            op: '',
            oTP: '',
            np: '',
            rp: '',
            lName: null,
            birthDate: null,
            address: null,
            phoneNumber: null,
            tUserName: null,
            myProofFiles: null,
            myProofFilesBack: null,
            myAddFiles: null,
            myAddFilesBack: null,
            myFaceFiles: null,
            myProofImg: null,
            myProofImgBack: null,
            myAddImg: null,
            myAddImgBack: null,
            myFaceImg: null,
            isHasKycData: false,
            getServerPassword: false,
            myProofServer: {
                process: (fieldName, file, metadata, load, error, progress, abort) => {
                    const formData = new FormData();
                    formData.append(fieldName, file, file.name);
                    formData.append('fileType', FileType.govId);

                    const request = new XMLHttpRequest();
                    request.open('POST', process.env.VUE_APP_DOMAIN + 'kyc/uploadImageKYC');
                    request.setRequestHeader("Authorization", "Bearer "+localStorage.getItem("app_token"));

                    request.upload.onprogress = (e) => {
                        progress(e.lengthComputable, e.loaded, e.total);
                    };

                    request.onload = function () {
                        if (request.status >= 200 && request.status < 300) {
                            const jsonRes = JSON.parse(request.responseText);
                            if(jsonRes.status){
                                proofId = jsonRes.data.uploadedFileID;
                                load(jsonRes.message);
                            } else {
                                error(jsonRes.message)
                            }
                        } else {
                            error('Something Went Wrong');
                        }
                    };
                    request.send(formData);
                    return {
                        abort: () => {
                            request.abort();
                            abort();
                        },
                    };
                },
            },
            myProofBackServer: {
                process: (fieldName, file, metadata, load, error, progress, abort) => {
                    const formData = new FormData();
                    formData.append(fieldName, file, file.name);
                    formData.append('fileType', FileType.govIdBack);

                    const request = new XMLHttpRequest();
                    request.open('POST', process.env.VUE_APP_DOMAIN + 'kyc/uploadImageKYC');
                    request.setRequestHeader("Authorization", "Bearer "+localStorage.getItem("app_token"));

                    request.upload.onprogress = (e) => {
                        progress(e.lengthComputable, e.loaded, e.total);
                    };

                    request.onload = function () {
                        if (request.status >= 200 && request.status < 300) {
                            const jsonRes = JSON.parse(request.responseText);
                            if(jsonRes.status){
                                proofIdBack = jsonRes.data.uploadedFileID;
                                load(jsonRes.message);
                            } else {
                                error(jsonRes.message)
                            }
                        } else {
                            error('Something Went Wrong');
                        }
                    };
                    request.send(formData);
                    return {
                        abort: () => {
                            request.abort();
                            abort();
                        },
                    };
                },
            },
            myAddServer: {
                process: (fieldName, file, metadata, load, error, progress, abort) => {
                    const formData = new FormData();
                    formData.append(fieldName, file, file.name);
                    formData.append('fileType', FileType.addId);

                    const request = new XMLHttpRequest();
                    request.open('POST', process.env.VUE_APP_DOMAIN + 'kyc/uploadImageKYC');
                    request.setRequestHeader("Authorization", "Bearer "+localStorage.getItem("app_token"));

                    request.upload.onprogress = (e) => {
                        progress(e.lengthComputable, e.loaded, e.total);
                    };

                    request.onload = function () {
                        if (request.status >= 200 && request.status < 300) {
                            const jsonRes = JSON.parse(request.responseText);
                            if(jsonRes.status){
                                addId = jsonRes.data.uploadedFileID;
                                load(jsonRes.message);
                            } else {
                                error(jsonRes.message)
                            }
                        } else {
                            error('oh no');
                        }
                    };
                    request.send(formData);
                    return {
                        abort: () => {
                            request.abort();
                            abort();
                        },
                    };
                },
            },
            myAddBackServer: {
                process: (fieldName, file, metadata, load, error, progress, abort) => {
                    const formData = new FormData();
                    formData.append(fieldName, file, file.name);
                    formData.append('fileType', FileType.addIdBack);

                    const request = new XMLHttpRequest();
                    request.open('POST', process.env.VUE_APP_DOMAIN + 'kyc/uploadImageKYC');
                    request.setRequestHeader("Authorization", "Bearer "+localStorage.getItem("app_token"));

                    request.upload.onprogress = (e) => {
                        progress(e.lengthComputable, e.loaded, e.total);
                    };

                    request.onload = function () {
                        if (request.status >= 200 && request.status < 300) {
                            const jsonRes = JSON.parse(request.responseText);
                            if(jsonRes.status){
                                addIdBack = jsonRes.data.uploadedFileID;
                                load(jsonRes.message);
                            } else {
                                error(jsonRes.message)
                            }
                        } else {
                            error('oh no');
                        }
                    };
                    request.send(formData);
                    return {
                        abort: () => {
                            request.abort();
                            abort();
                        },
                    };
                },
            },
            myFaceServer: {
                process: (fieldName, file, metadata, load, error, progress, abort) => {
                    const formData = new FormData();
                    formData.append(fieldName, file, file.name);
                    formData.append('fileType', FileType.faceId);

                    const request = new XMLHttpRequest();
                    request.open('POST', process.env.VUE_APP_DOMAIN + 'kyc/uploadImageKYC');
                    request.setRequestHeader("Authorization", "Bearer "+localStorage.getItem("app_token"));

                    request.upload.onprogress = (e) => {
                        progress(e.lengthComputable, e.loaded, e.total);
                    };

                    request.onload = function () {
                        if (request.status >= 200 && request.status < 300) {
                            const jsonRes = JSON.parse(request.responseText);
                            if(jsonRes.status){
                                faceId = jsonRes.data.uploadedFileID;
                                load(jsonRes.message);
                            } else {
                                error(jsonRes.message)
                            }
                        } else {
                            error('oh no');
                        }
                    };
                    request.send(formData);
                    return {
                        abort: () => {
                            request.abort();
                            abort();
                        },
                    };
                },
            },
            nameStatusText: '',
            dobStatusText: '',
            addStatusText: '',
            proofStatusText: '',
            addProofStatusText: '',
            selfieStatusText: '',
            kycStatusData: [],
            updateBtnState: false,
            showVerification: false,
            withdrawAddVal: '',
            withdrawAmtVal: '',
            withdrawPOTPVal: '',
            withdrawEOTPVal: '',
            isCanDeposit: true,
            isCanWithdraw: true,
            isSendPassOTP: true,
            genPass: '',
            appPassList: [],
            selAppPassIdForDelete: '',
        };
    },
    async created() {
        await this.checkUser;
        if (this.isLogin) {
            await this.getKycData();
            await this.getAllPasswordList()
        } else {
          this.$router.replace('/signIn');
        }
        await this.getAllCoin();
        if(this.withId !== '') {
            this.showVerification = true;
        }
        this.refreshBalAndOrderList();
    },
        methods: {
            ...mapMutations({
                showLoading: LOADING_STATE,
                withdrawId: SET_WITHDRAW_DATA_MUTATION,
            }),
            setState(event){
              if(event){
                this.getState = false;
              }else{
                this.getState = true;
                document.getElementById("City").value = "";
              }
            },
            closeSelfiSample(){
                document.querySelector(".customSelfi").classList.remove("show");
            },
            openSelfiSample(){
                document.querySelector(".customSelfi").classList.add("show");
            },
            openAppPasswordModal(){
                if(this.isKycA) {
                    document.querySelector(".customAppPassword").classList.add('active');
                } else {
                    toast('Your KYC not Approved, Please update your KYC.', Type.danger);
                }
            },
            walletPageChange(target, $this){
                var walletTabs = document.querySelectorAll(".walletTabs0");
                for(const wt of walletTabs){
                    wt.classList.remove("active");
                    wt.classList.add("d-none");
                }
                document.getElementById(target).classList.add("active");
                document.getElementById(target).classList.remove("d-none");
                $this.classList.add("active");
                $this.classList.remove("d-none");
                //alert(target);
            },
            closeDrawer(){
                document.querySelector(".drawerContent").style.right = "-50%";
                setTimeout(function(){
                    document.querySelector(".drawer").classList.remove("active");
                    document.querySelector(".drawerContent").style.right = '';
                },500);
            },
            MaxDOB(){
                var d = new Date();
                var today = d.setFullYear((d.getFullYear() - 18));
                return today;
                // document.getElementById("dob").setAttribute("max", today);
            },
            searchCoin() {
            // Declare variables
                var input, filter, table, tr, td, i, txtValue;
                input = document.getElementById("myInput");
                filter = input.value.toUpperCase();
                table = document.getElementById("myTable");
                tr = table.getElementsByTagName("tr");

                // Loop through all table rows, and hide those who don't match the search query
                for (i = 0; i < tr.length; i++) {
                    td = tr[i].getElementsByTagName("td")[0];
                    if (td) {
                    txtValue = td.textContent || td.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                    }
                }
            },
            async getOtp() {
            if(this.$refs.oldPass.value !== '') {
                this.showLoading(true);
                const {resData, error} = await getOtpForChangePassword(this.$refs.oldPass.value);
                if(error.value === null) {
                    try {
                        const msg = JSON.parse(resData.value)['message'];
                        if(JSON.parse(resData.value)['status'].toString() === 'true') {
                            this.isOtpSend = true;
                            this.readyChangePass = true;
                            this.showLoading(false);
                            toast(msg, Type.success);
                        } else {
                            toast(msg, Type.danger);
                            this.showLoading(false);
                        }
                    } catch (e) {
                        log(e, MsgType.error);
                        this.showLoading(false);
                    }
                } else {
                    this.showLoading(false);
                    toast('Something Went Wrong', Type.danger);
                }
            } else {
                toast('Please enter old password', Type.danger);
            }
        },
        async onCoinSelect(index) {
            if(this.isLogin && this.isKycA) {
                if(selectCoinId !== this.coinList[index].coinId) {
                    this.selectedCoin = this.coinList[index].coinName;
                    // selectWalletAddress = this.walletAdd;
                    selectCoinId = this.coinList[index].coinId;
                    this.sCoinID = this.coinList[index].coinId;
                    this.selectedCoinWithdrawFee = this.coinList[index]['coinWithdrawalFees'].toString();
                    this.selectedCoinDepositVal = this.coinList[index]['coinDepositMin'].toString();
                    this.isCanDeposit = this.coinList[index]['coinDepositAllowed'];
                    this.isCanWithdraw = this.coinList[index]['coinWithdrawalAllowed'];
                    this.showLoading(true);
                    const {resData, error} = await getWalletByCoinId(this.coinList[index].coinId);
                    this.showLoading(false);
                    if (error.value === null) {
                        if (JSON.parse(resData.value)['status'].toString() === 'true') {
                            this.cryptoBal = JSON.parse(resData.value)['data']['walletBalance'];
                            this.walletAdd = JSON.parse(resData.value)['data']['walletAddress'];
                            this.walletExtra = JSON.parse(resData.value)['data']['extraData'];
                            this.cryptoLockBal = JSON.parse(resData.value)['data']['walletBalanceLock'];
                            await startDepositListenByCoinId(this.walletAdd, this.coinList[index].coinId, this.walletExtra);
                        }
                    }
                    await this.getDepositTransactionList(this.walletAdd, selectCoinId);
                    await this.getWithdrawTransactionList(selectCoinId);
                    document.querySelector(".drawer").classList.add("active");
                }
            }
        },
        runTimeValidation(event, target){
            var upperLower = document.querySelector('.'+target+' .upperLower');
            var upperLowerIcon = document.querySelector('.'+target+' .upperLowerIcon');
            var numericDigit = document.querySelector('.'+target+' .numericDigit');
            var numericDigitIcon = document.querySelector('.'+target+' .numericDigitIcon');
            var specialCharacter = document.querySelector('.'+target+' .specialCharacter');
            var specialCharacterIcon = document.querySelector('.'+target+' .specialCharacterIcon');
            var passwordLength = document.querySelector('.'+target+' .passwordLength');
            var passwordLengthIcon = document.querySelector('.'+target+' .passwordLengthIcon');
            var passwordStrength = document.querySelector('.'+target+' .password-strength');
            var passwordResult = document.querySelector('.'+target+' .result');
            let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.*[!@#$%^&*])(?=.{8,})');
            let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
            console.log("Result: ", passwordResult);
            function StrengthChecker(PasswordParameter) {
                if(strongPassword.test(PasswordParameter)) {
                    // passwordResult.style.backgroundColor = "green";
                    passwordResult.innerHTML = 'Strong';
                    passwordStrength.style.width = '100%';
                    passwordStrength.classList.add('progress-bar-success');
                    passwordStrength.classList.remove('progress-bar-warning');
                    passwordStrength.classList.remove('progress-bar-danger');
                } else if(mediumPassword.test(PasswordParameter)) {
                    // passwordResult.style.backgroundColor = 'blue';
                    passwordResult.innerHTML = 'Medium';
                    passwordStrength.style.width = '60%';
                    passwordStrength.classList.remove('progress-bar-success');
                    passwordStrength.classList.add('progress-bar-warning');
                    passwordStrength.classList.remove('progress-bar-danger');
                } else {
                    // passwordResult.style.backgroundColor = 'red';
                    passwordResult.innerHTML = 'Weak';
                    passwordStrength.style.width = '10%';
                    passwordStrength.classList.remove('progress-bar-success');
                    passwordStrength.classList.remove('progress-bar-warning');
                    passwordStrength.classList.add('progress-bar-danger');
                }
            }
            if(event){
                StrengthChecker(event);
            }else{
                passwordResult.innerHTML= '';
                passwordStrength.style.width = '0%';
                passwordStrength.classList.remove('progress-bar-success');
                passwordStrength.classList.remove('progress-bar-warning');
                passwordStrength.classList.add('progress-bar-danger');
            }

            if(/(?=.*[a-z])(?=.*[A-Z])/.test(event)){
                upperLower.classList.remove('textGray');
                upperLower.classList.remove('textRed');
                upperLower.classList.add('textGreen');
                upperLowerIcon.classList.remove('ion-md-close');
                upperLowerIcon.classList.add('ion-md-checkmark');
            }else{
                upperLower.classList.remove('textGray');
                upperLower.classList.add('textRed');
                upperLower.classList.remove('textGreen');
                upperLowerIcon.classList.add('ion-md-close');
                upperLowerIcon.classList.remove('ion-md-checkmark');
            }
            if(/(?=.*[0-9])/.test(event)){
                numericDigit.classList.remove('textGray');
                numericDigit.classList.remove('textRed');
                numericDigit.classList.add('textGreen');
                numericDigitIcon.classList.remove('ion-md-close');
                numericDigitIcon.classList.add('ion-md-checkmark');
            }else{
                numericDigit.classList.remove('textGray');
                numericDigit.classList.add('textRed');
                numericDigit.classList.remove('textGreen');
                numericDigitIcon.classList.add('ion-md-close');
                numericDigitIcon.classList.remove('ion-md-checkmark');
            }
            if(/(?=.*[!@#$%^&*])/.test(event)){
                specialCharacter.classList.remove('textGray');
                specialCharacter.classList.remove('textRed');
                specialCharacter.classList.add('textGreen');
                specialCharacterIcon.classList.remove('ion-md-close');
                specialCharacterIcon.classList.add('ion-md-checkmark');
            }else{
                specialCharacter.classList.remove('textGray');
                specialCharacter.classList.add('textRed');
                specialCharacter.classList.remove('textGreen');
                specialCharacterIcon.classList.add('ion-md-close');
                specialCharacterIcon.classList.remove('ion-md-checkmark');
            }
            if(event.length >= 8){
                passwordLength.classList.remove('textGray');
                passwordLength.classList.remove('textRed');
                passwordLength.classList.add('textGreen');
                passwordLengthIcon.classList.remove('ion-md-close');
                passwordLengthIcon.classList.add('ion-md-checkmark');
            }else{
                passwordLength.classList.remove('textGray');
                passwordLength.classList.add('textRed');
                passwordLength.classList.remove('textGreen');
                passwordLengthIcon.classList.add('ion-md-close');
                passwordLengthIcon.classList.remove('ion-md-checkmark');
            }
        },
        async changePassword() {
            if(this.$refs.otp.value.length === 6) {
                if(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(this.$refs.newPass.value)) {
                    if(this.$refs.newPass.value === this.$refs.rePass.value) {
                        const {resData, error} = await changeOldPassword(this.userEmail, this.$refs.oldPass.value, this.$refs.otp.value, this.$refs.newPass.value, this.$refs.rePass.value);
                        if(error.value === null) {
                            try {
                                const msg = JSON.parse(resData.value)['message'];
                                if(JSON.parse(resData.value)['status'].toString() === 'true') {
                                    this.op = '';
                                    this.oTP = '';
                                    this.np = '';
                                    this.rp = '';
                                    this.isOtpSend = false;
                                    this.readyChangePass = false;
                                    this.showLoading(false);
                                    toast(msg, Type.success);
                                } else {
                                    toast(msg, Type.danger);
                                    this.showLoading(false);
                                }
                            } catch (e) {
                                log(e, MsgType.error);
                                this.showLoading(false);
                            }
                        } else {
                            this.showLoading(false);
                            toast('Something Went Wrong', Type.danger);
                        }
                    } else {
                        toast('Confirm Password not match with Old Password', Type.danger);
                    }
                } else {
                    toast('Please enter valid password', Type.danger);
                }
            } else {
                toast('Please enter valid OTP', Type.danger);
            }
        },
            togglePassword(id) {
                var x = document.getElementById(id);
                if (x.type === "password") {
                    x.type = "text";
                } else {
                    x.type = "password";
                }
            },
            changeTab(id, $this){
                var allLink = document.querySelectorAll(".customLink");
                for(const cl of allLink){
                    cl.classList.remove("active");
                }
                var allList = document.querySelectorAll(".sidebar li");
                for(const li of allList){
                    li.classList.remove("active");
                }
                $this.parentElement.parentElement.parentElement.classList.add("active");
                $this.classList.add("active");
                var allid = document.querySelectorAll(".customSection");
                for(const mi of allid){
                    mi.classList.remove("active");
                }
                document.getElementById(id).classList.add("active");
                if(id === 'contentOne') {
                    document.title = 'Personal Information - BitRish';
                } else if (id === 'contentTwo') {
                    document.title = 'KYC - BitRish';
                } else if(id === 'contentThree') {
                    document.title = 'Security - BitRish';
                } else if(id === 'contentFour') {
                    document.title = 'Wallet - BitRish';
                }
            },
            getErrorText(key) {
                for (let i = 0; i < this.kycStatusData.length; i++) {
                    if(this.kycStatusData[i].key === key) {
                        return this.kycStatusData[i].message;
                    }
                }
            },
            getStatus(key) {
                let vvv = true;
                if(this.isHasKycData) {
                    for (let i = 0; i < this.kycStatusData.length; i++) {
                        if(this.kycStatusData[i].key === key) {
                            vvv = (this.kycStatusData[i].status !== 2);
                        }
                    }
                } else {
                    vvv = false;
                }
                return vvv;
            },

            // 0 = nothing, 1 = pending, 2 = confirm, 3 = problem
            getStatusForIcon(key) {
                let vvv = 0;
                if(this.isHasKycData) {
                    for (let i = 0; i < this.kycStatusData.length; i++) {
                        if(this.kycStatusData[i].key === key) {
                            if(this.kycStatusData[i].status === 0) {
                                vvv = 1;
                            } else if(this.kycStatusData[i].status === 1) {
                                vvv = 2;
                            } else if(this.kycStatusData[i].status === 2) {
                                vvv = 3;
                            }
                        }
                    }
                }
                return vvv;
            },

            handleProofFileInit: function () {
                this.$refs.pond.getFiles();
            },

            handleProofFileRemove() {
                proofId = '';
            },

            handleProofBackFileRemove() {
                proofIdBack = '';
            },

            handleAddFileRemove() {
                addId = '';
            },

            handleAddBackFileRemove() {
                addIdBack = '';
            },

            handleFaceFileRemove() {
                faceId = '';
            },

            handleAddFileInit: function () {
                this.$refs.pond.getFiles();
            },
            handleFaceFileInit: function () {
                this.$refs.pond.getFiles();
            },

            copyAdd() {
                const input = document.getElementById('depositWA');
                input.select();
                document.execCommand('copy');
            },

            copyExtra() {
                const input = document.getElementById('depositWE');
                input.select();
                document.execCommand('copy');
            },

            async pasteText() {
                try {
                    const text = await navigator.clipboard.readText()
                    document.getElementById('walletAddressPaste').value += text;
                } catch (error) {
                    try {
                        document.getElementById('walletAddressPaste').focus();
                        document.execCommand('paste')
                    } catch (e) {
                        toast('Unable to get clipboard content', Type.danger);
                    }
                }
            },

            async sendWithdrawRequest() {
                const validation = new WithdrawreqValidation(this.$refs.withdrawAddress.value, this.$refs.withdrawAmount.value, this.cryptoBal, this.isKycA);
                const err = validation.checkValidation();
                if (err === '') {
                    this.showLoading(true);
                    const {
                        res,
                        error
                    } = await withdrawRequest(this.$refs.withdrawAddress.value, selectCoinId, this.$refs.withdrawAmount.value);
                    try {
                        if (error.value === null) {
                            const msg = JSON.parse(res.value)['message'];
                            if (JSON.parse(res.value)['status'].toString().toLowerCase() === 'true') {
                                this.withdrawId({
                                    'id': JSON.parse(res.value)['data'].toString(),
                                    'add': this.$refs.withdrawAddress.value,
                                    'amt': this.$refs.withdrawAmount.value,
                                });
                                this.withdrawAddVal = '';
                                this.withdrawAmtVal = '';
                                this.showVerification = true;
                                this.showLoading(false);
                                toast(msg, Type.success);
                            } else {
                                this.showLoading(false);
                                toast(msg, Type.danger);
                            }
                        } else {
                            this.showLoading(false);
                            toast('Something Went Wrong', Type.danger);
                        }
                    } catch (e) {
                        this.showLoading(false);
                        log(e, MsgType.error);
                        toast('Something Went Wrong', Type.danger);
                    }
                } else {
                    this.showLoading(false);
                    toast(err, Type.danger);
                }
            },

            async verifyOtp() {
                const pOTPValid = Validations.checkOTP(this.$refs.mobileOtp.value);
                const eOTPValid = Validations.checkOTP(this.$refs.emailOtp.value);
                if (pOTPValid && eOTPValid) {
                    this.showLoading(true);
                    const {
                        verifyRes,
                        error
                    } = await verifyWithdrawOtp(this.withId, this.$refs.mobileOtp.value, this.$refs.emailOtp.value);
                    try {
                        if (error.value === null) {
                            const msg = JSON.parse(verifyRes.value)['message'];
                            if (JSON.parse(verifyRes.value)['status'].toString() === 'true') {
                                this.cryptoBal = (await getBalance(selectCoinId)).bal;
                                this.withdrawId({
                                    'id': '',
                                    'add': '',
                                    'amt': '',
                                });
                                this.withdrawAddVal = '';
                                this.withdrawAmtVal = '';
                                this.withdrawPOTPVal = '';
                                this.withdrawEOTPVal = '';
                                this.showLoading(false);
                                this.showVerification = false;
                                toast(msg, Type.success);
                            } else {
                                this.showLoading(false);
                                toast(msg, Type.danger);
                            }
                        } else {
                            this.showLoading(false);
                            toast('Something Went Wrong', Type.danger);
                        }
                    } catch (e) {
                        this.showLoading(false);
                        toast('Something Went Wrong', Type.danger);
                    }
                }
            },

            async cancelWithdraw() {
                this.showLoading(true);
                const {cancelRes, error} = await cancelWithdraw(this.withId);
                try {
                    if (error.value === null) {
                        const msg = JSON.parse(cancelRes.value)['message'];
                        if (JSON.parse(cancelRes.value)['status'].toString() === 'true') {
                            this.withdrawId({
                                'id': '',
                                'add': '',
                                'amt': '',
                            });
                            this.withdrawAddVal = '';
                            this.withdrawAmtVal = '';
                            this.withdrawPOTPVal = '';
                            this.withdrawEOTPVal = '';
                            this.showLoading(false);
                            this.showVerification = false;
                            toast(msg, Type.success);
                        } else {
                            this.showLoading(false);
                            toast(msg, Type.danger);
                        }
                    } else {
                        this.showLoading(false);
                        toast('Something Went Wrong', Type.danger);
                    }
                } catch (e) {
                    this.showLoading(false);
                    toast('Something Went Wrong', Type.danger);
                }
            },

            async resendOtp(type) {
                this.showLoading(true);
                const {resendOTPRes, error} = await resendOtpReq(this.withId, type);
                try {
                    if (error.value === null) {
                        const msg = JSON.parse(resendOTPRes.value)['message'];
                        if (JSON.parse(resendOTPRes.value)['status'].toString() === 'true') {
                            this.showLoading(false);
                            toast(msg, Type.success);
                        } else {
                            this.showLoading(false);
                            toast(msg, Type.danger);
                        }
                    } else {
                        this.showLoading(false);
                        toast('Something Went Wrong', Type.danger);
                    }
                } catch (e) {
                    this.showLoading(false);
                    toast('Something Went Wrong', Type.danger);
                }
            },

            async getKycData() {
                this.showLoading(true);
                const {resData, error} = await getKycData();
                if (error.value === null) {
                    try {
                        if (JSON.parse(resData.value)['status'].toString().toLowerCase() === 'true') {
                            const data = JSON.parse(resData.value)['data'];
                            this.isHasKycData = true;
                            this.lName = data['kyc_legal_name'];
                            this.birthDate = data['kyc_dob'].split('/',).slice().reverse().join('-');
                            const addressJson = JSON.parse(data['kyc_address']);
                            this.address = addressJson['add'];
                            this.country = addressJson['country'];
                            this.region = addressJson['state'];
                            this.kCity = addressJson['city'];
                            if(data['kyc_contact_number'] === '') {
                                this.phoneNumber = data['kyc_contact_number'];
                            } else {
                                this.phoneNumber = this.userNum;
                            }
                            this.tUserName = data['kyc_twitter_username'];
                            this.myProofImg = process.env.VUE_APP_DOMAIN + 'getImage?fID=' + data['kyc_government_id_img_id'];
                            this.myProofImgBack = process.env.VUE_APP_DOMAIN + 'getImage?fID=' + data['kyc_government_id_img_back_id'];
                            this.myAddImg = process.env.VUE_APP_DOMAIN + 'getImage?fID=' + data['kyc_government_address_proof_img_id'];
                            this.myAddImgBack = process.env.VUE_APP_DOMAIN + 'getImage?fID=' + data['kyc_government_address_proof_img_back_id'];
                            this.myFaceImg = process.env.VUE_APP_DOMAIN + 'getImage?fID=' + data['kyc_user_face_img_id'];
                            const kycRes = JSON.parse(data['userErrorMsgKYC']);
                            this.kycStatus = data['userStatusKYC'].toString();
                            for (let i = 0; i < kycRes.length; i++) {
                                if (kycRes[i].status === 2) {
                                    this.updateBtnState = true;
                                }
                                if (kycRes[i].key === 'kyc_legal_name') {
                                    kycRes[i]['value'] = data['kyc_legal_name'];
                                    this.kycStatusData.push(kycRes[i]);
                                }
                                if (kycRes[i].key === 'kyc_dob') {
                                    kycRes[i]['value'] = data['kyc_dob'];
                                    this.kycStatusData.push(kycRes[i]);
                                }
                                if (kycRes[i].key === 'kyc_contact_number') {
                                    kycRes[i]['value'] = data['kyc_contact_number'];
                                    this.kycStatusData.push(kycRes[i]);
                                }
                                if (kycRes[i].key === 'kyc_address') {
                                    kycRes[i]['value'] = data['kyc_address'];
                                    this.kycStatusData.push(kycRes[i]);
                                }
                                if (kycRes[i].key === 'kyc_government_id_img_id') {
                                    kycRes[i]['value'] = data['kyc_government_id_img_id'];
                                    this.kycStatusData.push(kycRes[i]);
                                }
                                if (kycRes[i].key === 'kyc_government_id_img_back_id') {
                                    kycRes[i]['value'] = data['kyc_government_id_img_back_id'];
                                    this.kycStatusData.push(kycRes[i]);
                                }
                                if (kycRes[i].key === 'kyc_government_address_proof_img_id') {
                                    kycRes[i]['value'] = data['kyc_government_address_proof_img_id'];
                                    this.kycStatusData.push(kycRes[i]);
                                }
                                if (kycRes[i].key === 'kyc_government_address_proof_img_back_id') {
                                    kycRes[i]['value'] = data['kyc_government_address_proof_img_back_id'];
                                    this.kycStatusData.push(kycRes[i]);
                                }
                                if (kycRes[i].key === 'kyc_user_face_img_id') {
                                    kycRes[i]['value'] = data['kyc_user_face_img_id'];
                                    this.kycStatusData.push(kycRes[i]);
                                }
                                if (kycRes[i].key === 'kyc_twitter_username') {
                                    kycRes[i]['value'] = data['kyc_twitter_username'];
                                    this.kycStatusData.push(kycRes[i]);
                                }
                            }
                            this.showLoading(false);
                        } else {
                            this.showLoading(false);
                            this.phoneNumber = this.userNum;
                        }
                    } catch (e) {
                        console.error('KYC Data Error === ', e);
                        this.showLoading(false);
                        this.phoneNumber = this.userNum;
                    }
                } else {
                    this.showLoading(false);
                    this.phoneNumber = this.userNum;
                }
            },

            async kycApply() {
                const validation = new KycValidation(this.$refs.dob.value, this.$refs.phoneNum.value, this.$refs.add.value, proofId, proofIdBack, addId, addIdBack, faceId, this.$refs.tName.value, this.country, this.region, this.$refs.KCity.value);
                const error = validation.checkValidation();
                if (error === '') {
                    this.showLoading(true);
                    const address = {
                        "add": this.$refs.add.value,
                        "country": this.country,
                        "state": this.region,
                        "city": this.$refs.KCity.value,
                    }
                    const addressString = JSON.stringify(address);
                    const date = this.$refs.dob.value.split('-',).slice().reverse().join('/');
                    console.log(date);
                    const {resData, error} = await applyKyc(date, this.$refs.phoneNum.value, addressString, proofId, proofIdBack, addId, addIdBack, faceId, this.$refs.tName.value);
                    if(error.value === null) {
                        try {
                            const msg = JSON.parse(resData.value)['message'];
                            if (JSON.parse(resData.value)['status'].toString().toLowerCase() === 'true') {
                                this.showLoading(false);
                                toast(msg, Type.success);
                                await this.getKycData();
                            } else {
                                this.showLoading(false);
                                toast(msg, Type.danger);
                            }
                        } catch (e) {
                            this.showLoading(false);
                            toast('Something Went Wrong', Type.danger);
                        }
                    } else {
                        this.showLoading(false);
                        toast('Something Went Wrong', Type.danger);
                    }
                } else {
                    toast(error, Type.danger);
                }
            },

            async kycUpdate() {
                let dob = '';
                // let name = '';
                let num = '';
                let add = '';
                let proofIdU = '';
                let proofIdUBack = '';
                let addIdU = '';
                let addIdUBack = '';
                let faceIdU = '';
                let tUserName = '';
                for (let i = 0; i < this.kycStatusData.length; i++) {
                    if (this.kycStatusData[i].key === 'kyc_dob') {
                        if (this.kycStatusData[i].status === 2) {
                            dob = this.$refs.dob.value;
                        } else {
                            dob = this.kycStatusData[i].value;
                        }
                    }
                    if (this.kycStatusData[i].key === 'kyc_contact_number') {
                        if (this.kycStatusData[i].status === 2) {
                            num = this.$refs.phoneNum.value;
                        } else {
                            num = this.kycStatusData[i].value;
                        }
                    }
                    if (this.kycStatusData[i].key === 'kyc_address') {
                        if (this.kycStatusData[i].status === 2) {
                            add = this.$refs.add.value;
                        } else {
                            add = this.kycStatusData[i].value;
                        }
                    }
                    if (this.kycStatusData[i].key === 'kyc_government_id_img_id') {
                        if (this.kycStatusData[i].status === 2) {
                            proofIdU = proofId === undefined ? '' : proofId;
                        } else {
                            proofIdU = this.kycStatusData[i].value;
                        }
                    }
                    if (this.kycStatusData[i].key === 'kyc_government_id_img_back_id') {
                        if (this.kycStatusData[i].status === 2) {
                            proofIdUBack = proofIdBack === undefined ? '' : proofIdBack;
                        } else {
                            proofIdUBack = this.kycStatusData[i].value;
                        }
                    }
                    if (this.kycStatusData[i].key === 'kyc_government_address_proof_img_id') {
                        if (this.kycStatusData[i].status === 2) {
                            addIdU = addId === undefined ? '' : addId;
                        } else {
                            addIdU = this.kycStatusData[i].value;
                        }
                    }
                    if (this.kycStatusData[i].key === 'kyc_government_address_proof_img_back_id') {
                        if (this.kycStatusData[i].status === 2) {
                            addIdUBack = addIdBack === undefined ? '' : addIdBack;
                        } else {
                            addIdUBack = this.kycStatusData[i].value;
                        }
                    }
                    if (this.kycStatusData[i].key === 'kyc_user_face_img_id') {
                        if (this.kycStatusData[i].status === 2) {
                            faceIdU = faceId === undefined ? '' : faceId;
                        } else {
                            faceIdU = this.kycStatusData[i].value;
                        }
                    }
                    if (this.kycStatusData[i].key === 'kyc_twitter_username') {
                        if (this.kycStatusData[i].status === 2) {
                            tUserName = this.$refs.tName.value;
                        } else {
                            tUserName = this.kycStatusData[i].value;
                        }
                    }
                }
                const validation = new KycValidation(dob, num, add, proofIdU, proofIdUBack, addIdU, addIdUBack, faceIdU, tUserName);
                const error = validation.checkValidation();
                if (error === '') {
                    this.showLoading(true);
                    const date = this.$refs.dob.value.split('-',).slice().reverse().join('/');
                    const {resData, error} = await applyKyc(date, num, add, proofIdU, proofIdUBack, addIdU, addIdUBack, faceIdU, tUserName);
                    if(error.value === null) {
                        try {
                            const msg = JSON.parse(resData.value)['message'];
                            if (JSON.parse(resData.value)['status'].toString().toLowerCase() === 'true') {
                                this.showLoading(false);
                                toast(msg, Type.success);
                                await this.getKycData();
                            } else {
                                this.showLoading(false);
                                toast(msg, Type.danger);
                            }
                        } catch (e) {
                            this.showLoading(false);
                            toast('Something Went Wrong', Type.danger);
                        }
                    } else {
                        this.showLoading(false);
                        toast('Something Went Wrong', Type.danger);
                    }
                } else {
                    toast(error, Type.danger);
                }
            },

            async getAllCoin() {
                const {coinData, error} = await getAllCoinList();
                if (error.value === null) {
                    if (JSON.parse(coinData.value)['status'].toString().toLowerCase() === 'true') {
                        const cData = JSON.parse(coinData.value)['data'];
                        if (cData.length > 0) {
                            for (let i = 0; i < cData.length; i++) {
                                this.coinList.push(cData[i]);
                            }
                            selectCoinId = cData[0]['coinId'];
                            this.selectedCoinWithdrawFee = cData[0]['coinWithdrawalFees'].toString();
                            this.selectedCoin = cData[0]['coinName'];
                            this.selectedCoinDepositFees = cData[0]['coinDepositFees'];
                            this.selectedCoinDepositVal = cData[0]['coinDepositMin'];
                            this.isCanDeposit = cData[0]['coinDepositAllowed'];
                            this.isCanWithdraw = cData[0]['coinWithdrawalAllowed'];
                            if(this.isLogin && this.isKycA) {
                                const {resData, error} = await getWalletByCoinId(selectCoinId);
                                if (error.value === null) {
                                    if (JSON.parse(resData.value)['status'].toString().toLowerCase() === 'true') {
                                        const wData = JSON.parse(resData.value)['data'];
                                        this.cryptoBal = wData['walletBalance'];
                                        this.walletAdd = wData['walletAddress'];
                                        // selectWalletAddress = this.walletAdd;
                                        this.cryptoLockBal = wData['walletBalanceLock'];
                                        this.walletExtra = wData['extraData']
                                        await startDepositListenByCoinId(this.walletAdd, selectCoinId, this.walletExtra);
                                        await this.getDepositTransactionList(this.walletAdd, selectCoinId);
                                        await this.getWithdrawTransactionList(selectCoinId);
                                    }
                                }
                            }
                        }
                    } else {
                        toast(JSON.parse(coinData.value).message, Type.danger);
                    }
                } else {
                    toast("Something Went Wrong.", Type.danger);
                }
            },

            async getDepositTransactionList(walletAdd, coinId) {
                const {transactionData, error} = await getTransactionListByCoinId(walletAdd, coinId);
                this.depositList = [];
                if(error.value === null) {
                    try {
                        if(selectCoinId.toString() === coinId.toString()) {
                            if (JSON.parse(transactionData.value)['status'].toString().toLowerCase() === 'true') {
                                const tData = JSON.parse(transactionData.value)['data']
                                if (tData.length > 0) {
                                    for (let i = 0; i < tData.length; i++) {
                                        const mxx = tData[i];
                                        const dateString = parseInt(mxx['dateTime'].toString()) * 1000;
                                        const d = new Date(dateString);
                                        const da = d.getDate().toString().length > 1 ? d.getDate() : '0' + d.getDate();
                                        const mo = (d.getMonth() + 1).toString().length > 1 ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1);
                                        const y = d.getFullYear().toString().length > 1 ? d.getFullYear() : '0' + d.getFullYear();
                                        const h = d.getHours().toString().length > 1 ? d.getHours() : '0' + d.getHours();
                                        const m = d.getMinutes().toString().length > 1 ? d.getMinutes() : '0' + d.getMinutes();
                                        const s = d.getSeconds().toString().length > 1 ? d.getSeconds() : '0' + d.getSeconds();
                                        mxx['new_date_time'] = (da + '/' + mo + '/' + y + ' ' + h + ':' + m + ':' + s);
                                        this.depositList.push(mxx);
                                        console.log('geting Deposite Api ', this.depositList);
                                    }
                                }
                            }
                        }
                    } catch (e) {
                        log(e, MsgType.error);
                    }
                }
            },

            async getWithdrawTransactionList(coinId) {
                const {withdrawRes, error} = await getWithdrawList(coinId);
                this.withdrawList = [];
                if(error.value === null) {
                    try {
                        if(selectCoinId.toString() === coinId.toString()) {
                            if (JSON.parse(withdrawRes.value)['status'].toString().toLowerCase() === 'true') {
                                const tData = JSON.parse(withdrawRes.value)['data']
                                if (tData.length > 0) {
                                    for (let i = 0; i < tData.length; i++) {
                                        const mxx = tData[i];
                                        const dateString = parseInt(mxx['dateTime'].toString()) * 1000;
                                        const d = new Date(dateString);
                                        const da = d.getDate().toString().length > 1 ? d.getDate() : '0' + d.getDate();
                                        const mo = (d.getMonth() + 1).toString().length > 1 ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1);
                                        const y = d.getFullYear().toString().length > 1 ? d.getFullYear() : '0' + d.getFullYear();
                                        const h = d.getHours().toString().length > 1 ? d.getHours() : '0' + d.getHours();
                                        const m = d.getMinutes().toString().length > 1 ? d.getMinutes() : '0' + d.getMinutes();
                                        const s = d.getSeconds().toString().length > 1 ? d.getSeconds() : '0' + d.getSeconds();
                                        mxx['new_date_time'] = (da + '/' + mo + '/' + y + ' ' + h + ':' + m + ':' + s);
                                        this.withdrawList.push(mxx);
                                    }
                                }
                            }
                        }
                    } catch (e) {
                        log(e, MsgType.error);
                    }
                }
            },

            makeShortHash(hash) {
                const start = 0;
                const end = 6;
                if(!hash || hash.toString() === 'null' || hash.toString() === '')  {
                    return '';
                } else  {
                    return hash.substring(start, end) + "...." + hash.substring(hash.length - 4)
                }
            },

            async getPassOTP() {
                document.querySelector('.customAppPasswordLoading').classList.add('active');
                this.isSendPassOTP = false;
                const {res, error} = await getAppPasswordOTP();
                if(error === null) {
                    try {
                        const msg = JSON.parse(res)['message'];
                        if(JSON.parse(res)['status'].toString().toLowerCase() === 'true') {
                            document.querySelector('.customAppPasswordLoading').classList.remove('active');
                            toast(msg, Type.success)
                        } else {
                            this.isSendPassOTP = true;
                            document.querySelector('.customAppPasswordLoading').classList.remove('active');
                            toast(msg, Type.danger)
                        }
                    } catch (e) {
                        this.isSendPassOTP = true;
                        document.querySelector('.customAppPasswordLoading').classList.remove('active');
                        log(e, MsgType.error);
                        toast('Something Went Wrong', Type.danger)
                    }
                } else  {
                    this.isSendPassOTP = true;
                    document.querySelector('.customAppPasswordLoading').classList.remove('active');
                    toast(error, Type.danger)
                }
            },

            async generateAppPassword() {
                if(this.$refs.nickName.value !== '') {
                    if(this.$refs.appOtp.value.length === 6) {
                        document.querySelector('.customAppPasswordLoading').classList.add('active');
                        const {res, error} = await createAppPassword(this.$refs.nickName.value, this.$refs.appOtp.value);
                        console.log('Gen App Pass res == ', res);
                        if (error === null) {
                            try {
                                const msg = JSON.parse(res)['message'];
                                if (JSON.parse(res)['status'].toString().toLowerCase() === 'true') {
                                    this.getServerPassword = true;
                                    if (JSON.parse(res)['data'].toString()) {
                                        this.genPass = JSON.parse(res)['data'].toString();
                                    }
                                    document.querySelector('.customAppPasswordLoading').classList.remove('active');
                                    toast(msg, Type.success)
                                } else {
                                    document.querySelector('.customAppPasswordLoading').classList.remove('active');
                                    toast(msg, Type.danger)
                                }
                            } catch (e) {
                                document.querySelector('.customAppPasswordLoading').classList.remove('active');
                                log(e, MsgType.error);
                                toast('Something Went Wrong', Type.danger)
                            }
                        } else {
                            document.querySelector('.customAppPasswordLoading').classList.remove('active');
                            toast(error, Type.danger)
                        }
                    } else {
                        toast('Please Enter Valid OTP', Type.danger);
                    }
                } else  {
                    toast('Please Enter Valid Nick Name', Type.danger);
                }
            },

            async copyAppPassword() {
                const input = document.getElementById('genPassword');
                input.select();
                document.execCommand('copy');
            },

            async closeAppPasswordModal() {
                await this.getAllPasswordList();
                document.getElementById("appUsername").value = '';
                document.getElementById("otp").value = '';
                this.genPass = '';
                this.isSendPassOTP = true;
                this.getServerPassword = false;
                document.querySelector(".customAppPassword").classList.remove('active');
            },

            async getAllPasswordList() {
                const {res, error} = await getAllAppPasswords();
                if (error === null) {
                    try {
                        if (JSON.parse(res)['status'].toString().toLowerCase() === 'true') {
                            this.appPassList = [];
                            const d = JSON.parse(res)['data'];
                            if(d.length > 0) {
                                for (let i = 0; i < d.length; i++) {
                                    const timestamp = d[i]['appPassCreatedAt'].toString();
                                    d[i]['createAtIso'] = this.getISOTimeFormat(timestamp);
                                    if(d[i]['appPassLastUse'] === null) {
                                        d[i]['usedAtIso'] = 'Not Used';
                                    } else {
                                        const timest = parseInt(d[i]['appPassLastUse'].toString());
                                        d[i]['usedAtIso'] = this.getISOTimeFormat(timest);
                                    }
                                    this.appPassList.push(d[i]);
                                }
                            } else {
                                this.appPassList = [];
                            }
                        }
                    } catch (e) {
                        log(e, MsgType.error);
                    }
                } else {
                    toast(error, Type.danger)
                }
            },

            deleteAppPassword(id){
                this.selAppPassIdForDelete = id.toString();
                document.querySelector(".customPopup").classList.add("show");
            },
            closeCustomAppPassPopup(){
                document.querySelector(".customPopup").classList.remove("show");
            },

            async deleteAppPasswordById() {
                document.querySelector(".customPopup").classList.remove("show");
                document.querySelector('.customAppPasswordLoading').classList.add('active');
                console.log('Delete App pass Id == ', this.selAppPassIdForDelete);
                const {res, error} = await deleteAppPassword(this.selAppPassIdForDelete);
                if (error === null) {
                    try {
                        const msg = JSON.parse(res)['message'].toString()
                        if (JSON.parse(res)['status'].toString().toLowerCase() === 'true') {
                            await this.getAllPasswordList();
                            toast(msg, Type.success);
                            document.querySelector('.customAppPasswordLoading').classList.remove('active');
                        } else  {
                            toast(msg, Type.danger);
                            document.querySelector('.customAppPasswordLoading').classList.remove('active');
                        }
                    } catch (e) {
                        log(e, MsgType.error);
                        toast('Something Went Wrong', Type.danger);
                        document.querySelector('.customAppPasswordLoading').classList.remove('active');
                    }
                } else {
                    toast(error, Type.danger);
                    document.querySelector('.customAppPasswordLoading').classList.remove('active');
                }
            },

            getISOTimeFormat(timestamp) {
                const date = new Date(timestamp);
                const isoDate = date.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                });
                const isoTime = date.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                });

                return `${isoDate} ${isoTime}`;
            },

            refreshBalAndOrderList() {
                this.refreshDataTimeout = setTimeout(async () => {
                    const {resData, error} = await getWalletByCoinId(selectCoinId);
                    if (error.value === null) {
                        if (JSON.parse(resData.value)['status'].toString() === 'true') {
                            this.cryptoBal = JSON.parse(resData.value)['data']['walletBalance'];
                            this.cryptoLockBal = JSON.parse(resData.value)['data']['walletBalanceLock'];
                        }
                    }
                    await this.getDepositTransactionList(this.walletAdd, selectCoinId);
                    await this.getWithdrawTransactionList(selectCoinId);
                    this.refreshBalAndOrderList();
                }, 10000);
            },

        },
        setup() {

            const route = useRoute()
            const queryParams = reactive(route.query)

            //  MAX
            const selectedDate = ref('');
            // Calculate the minimum and maximum date allowed for an 18-year-old
            const today = new Date();
            const minDate = computed(() => {
            const min = new Date(today);
            min.setFullYear(min.getFullYear() - 60);
            return min.toISOString().slice(0, 10);
            });
            const maxDate = computed(() => {
            const max = new Date(today);
            max.setFullYear(max.getFullYear() - 18);
            return max.toISOString().slice(0, 10);
            });
            //  MAX

            watch(
                () => route.query,
                () => {
                    Object.assign(queryParams, route.query)
                    let allLink;
                    let allList;
                    let allid;
                    setTimeout(function(){
                        if(route.query.type){
                            if(route.query.type.toString() === '1') {
                                allLink = document.querySelectorAll(".customLink");
                                for(const cl of allLink){
                                    cl.classList.remove("active");
                                }
                                allList = document.querySelectorAll(".sidebar li");
                                for(const li of allList){
                                    li.classList.remove("active");
                                }
                                document.querySelector(".sidebar li:nth-child(1) a:nth-child(1)").classList.add("active");
                                document.querySelector(".sidebar li:nth-child(1) li:nth-child(1) a:nth-child(1)").classList.add("active");
                                allid = document.querySelectorAll(".customSection");
                                for(const mi of allid){
                                    mi.classList.remove("active");
                                }
                                document.getElementById('contentOne').classList.add("active");
                                document.title = 'Personal Information - BitRish';
                            }
                            else if(route.query.type.toString() === '2') {
                                allLink = document.querySelectorAll(".customLink");
                                for(const cl of allLink){
                                    cl.classList.remove("active");
                                }
                                allList = document.querySelectorAll(".sidebar li");
                                for(const li of allList){
                                    li.classList.remove("active");
                                }
                                document.querySelector(".sidebar li:nth-child(1) li:nth-child(2) a:nth-child(1)").classList.add("active");
                                allid = document.querySelectorAll(".customSection");
                                for(const mi of allid){
                                    mi.classList.remove("active");
                                }
                                document.getElementById('contentTwo').classList.add("active");
                                document.title = 'KYC - BitRish';
                            }
                            else if(route.query.type.toString() === '3') {
                                allLink = document.querySelectorAll(".customLink");
                                for(const cl of allLink){
                                    cl.classList.remove("active");
                                }
                                allList = document.querySelectorAll(".sidebar li");
                                for(const li of allList){
                                    li.classList.remove("active");
                                }
                                document.querySelector(".sidebar li:nth-child(1) li:nth-child(3) a:nth-child(1)").classList.add("active");
                                allid = document.querySelectorAll(".customSection");
                                for(const mi of allid){
                                    mi.classList.remove("active");
                                }
                                document.getElementById('contentThree').classList.add("active");
                                document.title = 'Security - BitRish';
                            }
                            else if(route.query.type.toString() === '4') {
                                allLink = document.querySelectorAll(".customLink");
                                for(const cl of allLink){
                                    cl.classList.remove("active");
                                }
                                allList = document.querySelectorAll(".sidebar li");
                                for(const li of allList){
                                    li.classList.remove("active");
                                }
                                document.querySelector(".sidebar li:nth-child(1) li:nth-child(4) a:nth-child(1)").classList.add("active");
                                // document.querySelector(".sidebar>ul>li:nth-child(2) a").classList.add("active");
                                allid = document.querySelectorAll(".customSection");
                                for(const mi of allid){
                                    mi.classList.remove("active");
                                }
                                document.getElementById('contentFive').classList.add("active");
                                document.title = 'App Password - BitRish';
                            }
                        }
                    },500);
                },
                { immediate: true }
            )

            return {
                queryParams,
                selectedDate,
                minDate,
                maxDate
            }
        },
    }
</script>
