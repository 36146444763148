<template>
    <div ref="tvChartContainer"></div>
</template>

<script>

import { widget } from "../../../public/charting_library";
import { UDFCompatibleDatafeed } from "../../../public/datafeeds/udf";
let chartWidget;

export default {
    name: 'TVChartContainer',
    emits: ['callback'],
    props: {
        selectedSymbol: {
            default: 'BTC/USDT',
            type: String,
            required: true,
        },
        // symbol: {
        //     default: 'BTC/USDT',
        //     type: String,
        // },
        interval: {
            default: 'D',
            type: String,
        },
        datafeedUrl: {
            default: process.env.VUE_APP_CHART_URL,
            type: String,
        },
        libraryPath: {
            default: '/charting_library/',
            type: String,
        },
        userId: {
            default: 'public_user_id',
            type: String,
        },
        fullscreen: {
            default: false,
            type: Boolean,
        },
        autosize: {
            default: false,
            type: Boolean,
        },
        studiesOverrides: {
            type: Object,
        },
        changeTheme: {
            default: 'dark',
            type: String,
            required: true,
        },
    },
    mounted() {
        this.initChart();
    },
    watch: {
        selectedSymbol(newSymbol) {
            this.setSymbol(newSymbol);
        },
        changeTheme(newTheme) {
            this.setTheme(newTheme === '' ? 'light' : 'dark');
        },
    },
    methods: {
        initChart() {
            const widgetOptions = {
                symbol: this.selectedSymbol,
                datafeed: new UDFCompatibleDatafeed(this.datafeedUrl),
                interval: 'D',
                container_id: this.$refs.tvChartContainer,
                library_path: '/charting_library/',
                locale: 'en',
                theme: this.changeTheme,
                enabled_features: ['use_localstorage_for_settings'],
                disabled_features: ['header_symbol_search', 'study_templates'],
                // overrides: {
                //     'mainSeriesProperties.priceFormat': {
                //         precision: 8, // Adjust the precision according to your needs
                //     },
                //     'paneProperties.vertGridProperties.color': '#F0F3FA',
                //     'paneProperties.horzGridProperties.color': '#F0F3FA',
                //     'paneProperties.crossHairProperties.color': '#C2C7D1',
                //     'scalesProperties.priceScale.precision': 8, // Adjust the precision for the price scale
                //     'scalesProperties.priceScale.minMove': 0.00000001, // Adjust the minMove for the price scale
                // },
                studies_overrides: {
                },
            };

            chartWidget = new widget(widgetOptions);
            chartWidget.onChartReady(() => {
              console.log('Chart Ready to Render');
              this.$emit('callback', 'Chart Ready to Render');
                // chartWidget.mainSeriesPriceFormatter()._priceScale = 10;
                // const chart = chartWidget.chart();
                // const panes = chart.getPanes();
                // const firstPane = panes[0];
                // const mainPriceScale = firstPane.getMainSourcePriceScale();
                // mainPriceScale.setAutoScale(true);
                chartWidget.headerReady().then(() => {
                    // const priceScaleOptions = {
                    //     precision: 8,
                    //     minMove: 0.00000001,
                    // };
                    // chartWidget.setPriceF({
                    //     priceScale: priceScaleOptions,
                    // });
                    // const button = chartWidget.createButton();
                    // button.setAttribute('title', 'Click to show a notification popup');
                    // button.classList.add('apply-common-tooltip');
                    // button.addEventListener('click', () =>
                    //     chartWidget.showNoticeDialog({
                    //         title: 'Notification',
                    //         body: 'TradingView Charting Library API works correctly',
                    //         callback: () => {
                    //             console.log('Noticed!');
                    //         },
                    //     })
                    // );
                    // button.innerHTML = 'Check API';
                });
            });
        },
        setSymbol(newSymbol) {
            if (chartWidget) {
                chartWidget.setSymbol(newSymbol, 'D', () => {});
            }
        },
        setTheme(newTheme) {
            if (chartWidget) {
                chartWidget.changeTheme(newTheme);

            }
        },
        getChartWidget() {
            const widgets = window.TradingView && window.TradingView.widgets;
            return widgets && widgets[this.$refs.tvChartContainer.id];
        },
    },
};
</script>
