import BigNumber from "bignumber.js";

export default class LimitOrderValidation {
    constructor(pairId, priceVal, amtVal, decimalVal, coinName, type) {
        this.pairId = pairId;
        this.priceVal = priceVal;
        this.amtVal = amtVal;
        this.decimal = decimalVal;
        this.coinName = coinName
        this.type = type;
    }

    checkValidation() {
        let error = '';

        if(this.pairId === '' || this.pairId === null) {
            error = 'Something went wrong, Please reload page or Select any pair.';
        } else if(this.priceVal.length <= 0) {
            error = 'Please Enter Price Value';
        } else if (this.amtVal.length <= 0) {
            error = 'Please Enter Amount Value';
        } else if (this.priceVal === '0') {
            error = 'Price Value Can\'t be 0';
        } else if (this.amtVal === '0') {
            error = 'Amount Value Can\'t be 0';
        } else if(new BigNumber(this.decimal.toString()).isGreaterThan(new BigNumber(this.amtVal.toString()))){
            error = `Minimum ${this.decimal} ${this.coinName} Allow To ${this.type === 0 ? 'Buy': 'Sell'}`;
        }

        return error;
    }

}
