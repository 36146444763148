<template>
    <div class="vh-100 d-flex justify-content-center text-center">
        <div class="my-auto textInvert">
            <h1>Coming Soon</h1>
            <p>Some features are on the way.</p>
            <router-link to="/" class="btn btn-primary">Back to Home <i class="icon ion-md-home"></i></router-link>
        </div>
    </div>
    <app-footer></app-footer>
</template>

<script>
import AppFooter from "@/components/parcel/AppFooter.vue";

export default {
    name: "ComingSoon",
    components: {AppFooter},
    mounted() {
        document.title = 'Coming Soon - Bitrish';
    }
}
</script>

<style scoped>

</style>
