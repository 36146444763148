<template>
    <div class="row m-0 p-0">
        <div class="col-xl-2 col-md-2 col-sm-3 my-3">
            <div class="sidebar">
                <ul>
                    <li>
                        <router-link to="/MyProfile" class="customLink">Profile</router-link>
                        <ul class="customDropdown d-none">
                            <li><a href="javascript:void(0)" class="customLink">Personal Information</a></li>
                            <li><a href="javascript:void(0)" class="customLink">KYC</a></li>
                            <li><a href="javascript:void(0)" class="customLink">Change Password</a></li>
                            <li><a href="javascript:void(0)" class="customLink">App Password</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0)" class="customLink active">Wallet</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-xl-10 col-md-10 col-sm-9 my-3">
            <div class="mainContent">
                <section class="customSection active" id="contentFour">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center border-bottom mb-4 customMenu">
                                <h5 class="card-title">Withdraw Crypto</h5>
                                <div class="mb-2 d-inline-flex walletPageLinks">
                                    <router-link to="/MyWallet" class="btn btn-secondary">Wallet</router-link>
                                    <router-link to="/MyDeposit" class="btn btn-secondary">Deposit</router-link>
                                    <router-link to="/MyWithdraw" class="btn btn-primary">Withdraw</router-link>
                                    <router-link to="/TransactionHistory" class="btn btn-secondary">Transaction History</router-link>
                                    <!-- <button target="#historyWallet" class="btn btn-secondary">Transaction History</button> -->
                                </div>
                            </div>
                            <div class="settings-profile">
                                <div class="row active walletTabs0" id="depositWallet">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <p>Select Coin</p>
                                            </div>
                                            <div class="col-md-9">
                                                <div class="steps d-flex justify-content-start w-100">
                                                    <div class="stepsContent active">
                                                        <div class="stepsContentRight gap-0">
                                                            <p>Coin</p>
                                                            <div class="selectedCoin d-none">
                                                                <div class="selectedCoinItem">
                                                                    <img class="walletCoinImage" src="assets/img/logo.png" /><a href="javascript:void(0)" class="ml-2">EQL</a>
                                                                </div>
                                                                <div class="chevron"><i class="fas fa-chevron-down"></i></div>
                                                            </div>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                                <input type="text" name="selectCoin" id="selectCoin" @input="searchPair()" class="form-control" />
                                                                <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                                <div class="searchCoinDropdown">
<!--                                                                    <div class="historyCoinList">
                                                                        <div class="d-flex justify-content-between align-items-center">
                                                                            <small class="textGray">Search History</small>
                                                                            <small class="textGray"><a class="textGray" href="javascript:void(0)"><i class="fas fa-trash"></i></a></small>
                                                                        </div>
                                                                        <div class="hintCoin d-flex">
                                                                            <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                                <span>USDT</span>
                                                                            </a>
                                                                            <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                                <span>BTC</span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div class="trendingCoinList">
                                                                        <small class="textGray">Trending</small>
                                                                        <div class="hintCoin d-flex">
                                                                            <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                                <span>USDT</span>
                                                                            </a>
                                                                            <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                                <span>BTC</span>
                                                                            </a>
                                                                            <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                                <span>USDT</span>
                                                                            </a>
                                                                            <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                                <span>BTC</span>
                                                                            </a>
                                                                            <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                                <span>USDT</span>
                                                                            </a>
                                                                            <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                                <span>BTC</span>
                                                                            </a>
                                                                        </div>
                                                                    </div>-->
                                                                    <div class="CoinList">
                                                                        <small class="textGray">Coin List</small>
                                                                        <ul class="CoinListUL">
                                                                            <li v-for="(coin, index) in coinList" :key="index" class="coinListItem" @click="onGetNetWorkByCoinId(coin['coinId'].toString(), coin['coinName'], coin['coinIcon'], coin['coinWithdrawalFees'], coin['coinWithdrawalAllowed'])">
                                                                                <div class="d-flex align-items-center gap-1 CoinSelectValue" :data-pair="coin['coinName']">
                                                                                    <img class="walletCoinImage" :src="coin['coinIcon']" />
                                                                                    <a href="javascript:void(0)">{{ coin['coinName'] }}</a>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-md-3">
                                                <p>Send to</p>
                                            </div>
                                            <div class="col-md-9">
                                                <div class="steps">
                                                    <div class="stepsContent">
                                                        <div class="stepsContentRight gap-0">
                                                            <p>Select Network</p>
                                                            <div class="selectedNetwork d-none">
                                                                <div class="selectedNetworkItem">
                                                                    <a href="javascript:void(0)" class="ml-2">EQL</a>
                                                                </div>
                                                                <div class="chevron"><i class="fas fa-chevron-down"></i></div>
                                                            </div>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                                <input type="text" name="selectNetwork" id="selectNetwork" class="form-control" />
                                                                <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                                <div class="searchNetworkDropdown">
                                                                    <div class="CoinList pt-2">
                                                                        <small class="textGray bg-dark br12 p-3">Please make sure you have selected the correct blockchain network, if you have selected the wrong network your assets will be lost, BitRish will not be able to recover it.</small>
                                                                        <ul class="CoinListUL">
                                                                            <li v-for="(coin, index) in coinNetwork" :key="index" class="networkListItem" @click="onSelectNetwork(coin['coinNetworkId'].toString())">
                                                                                <div class="d-flex align-items-center gap-1 NetworkSelectValue" :data-pair="coin">
                                                                                    <a href="javascript:void(0)">{{ coin['coinNetwork'] }}</a>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- coinNetwork -->
                                                        </div>
                                                    </div>
                                                    <div class="stepsContent mb-0" v-if="showAddressSection && canWithdraw">
                                                        <div class="stepsContentRight gap-0">
                                                            <p>Withdraw Fee: {{ withdrawFee }} {{ selectedCoin }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="stepsContent mt-0" v-if="showAddressSection && canWithdraw">
                                                        <div class="stepsContentRight gap-0">
                                                          <p>Avbl: {{ walletBalance }} {{ selectedCoin }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="stepsContent" v-if="showAddressSection && canWithdraw">
                                                        <div class="stepsContentRight gap-0">
                                                            <p>Address</p>
                                                            <div class="input-group">
                                                                <input type="text" name="withdrawAdd" id="withdrawAdd" ref="withdrawAdd" v-model="withdrawAddVal" class="form-control pl-3"/>
                                                                <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="stepsContent" v-if="showAddressSection && canWithdraw">
                                                        <div class="stepsContentRight gap-0">
                                                            <p>Amount</p>
                                                            <div class="input-group">
                                                                <input type="number" name="withdrawAmt" id="withdrawAmt" ref="withdrawAmt" v-model="withdrawAmtVal" class="form-control pl-3"/>
                                                                <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="stepsContent" v-if="showAddressSection && canWithdraw">
                                                        <button type="button" class="btn btn-primary" @click="sendWithdrawRequest">Withdraw</button>
                                                    </div>
                                                    <div class="bg-dark textInvert br12 p-4 d-flex justify-content-center align-items-center flex-column"
                                                         v-if="showAddressSection && !canWithdraw">
                                                        <i class="fa-solid fa-circle-exclamation fa-4x"></i>
                                                        <h3 class="text-center">Withdraw temporarily unavailable</h3>
                                                        <p class="text-center">
                                                            Crypto Withdraw temporarily suspended. Please check back later for updates.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 offset-md-1">
                                        <strong class="d-block mb-4">FAQ</strong>
                                        <div class="faqItem">
                                            <a href="javascript:void(0)" data-target="#testingOneModal">
                                                <i class="fas fa-play-circle"></i> <span class="ml-2">How to deposit crypto?</span>
                                            </a>
                                            <div id="testingOneModal" class="testingModal">
                                                <div class="testingModalInner">
                                                    <div class="testingModalHeader">
                                                        <h3></h3>
                                                        <a href="javascript:void(0)" class="faqCloseModal"><i class="fas fa-times fa-3x"></i></a>
                                                    </div>
                                                    <div class="testingModalInnerContent">
                                                        <p>Depositing crypto into your BitRish.com account is easy and convenient. Follow these steps to securely transfer your assets and start trading:</p>
                                                        <p>Create an account on BitRish.com.<br />Log in to your account.<br />Go to the "Wallet" tab and select "Deposit."<br />Choose the cryptocurrency you want to deposit.<br />BitRish.com will generate a unique deposit address.<br />Use the provided address or QR code to initiate the transfer from your external wallet or exchange.<br />Wait for confirmation, which may vary based on network congestion and the specific cryptocurrency.<br />How to Deposit Crypto?</p>
                                                        <p>Track your deposit on BitRish.com.<br />Conclusion:<br />Depositing crypto in BitRish.com is a straightforward process. By following these steps, you can quickly and securely transfer your assets, opening up opportunities for trading and utilizing the platform's services. Remember to verify transaction details to ensure a smooth deposit experience.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="faqItem">
                                            <a href="javascript:void(0)" data-target="#testingTwoModal">
                                                <i class="fas fa-file"></i> <span class="ml-2">How to Deposit Crypto Step-by-step Guide</span>
                                            </a>
                                            <div id="testingTwoModal" class="testingModal">
                                                <div class="testingModalInner">
                                                    <div class="testingModalHeader">
                                                        <h3></h3>
                                                        <a href="javascript:void(0)" class="faqCloseModal"><i class="fas fa-times fa-3x"></i></a>
                                                    </div>
                                                    <div class="testingModalInnerContent">
                                                        <p>1. Log in to your BitRish Website and tap [Deposit].</p>
                                                        <p>2. Tap [Deposit Crypto].</p>
                                                        <p>3. Choose the cryptocurrency you want to deposit, for example, USDT.</p>
                                                        <p>4. You will see the available network for depositing the asset. Please choose the deposit network carefully and make sure that the selected network is the same as the network of the platform you are withdrawing funds from. If you select the wrong network, your funds might be lost and couldn&rsquo;t be recovered.</p>
                                                        <p>Some examples of network selection:<br />BNB refers to the BNB Beacon Chain (BEP2);<br />BSC refers to the BNB Smart Chain (BEP20);<br />ETH refers to the Ethereum network (ERC20);<br />TRX refers to the TRON network (TRC20);<br />BTC refers to the Bitcoin network;<br />SegWitBTC refers to Native Segwit (bech32), and the address starts with &ldquo;bc1&rdquo;. You can withdraw or send your Bitcoin to SegWit (bech32) addresses. For more details, please refer to What Is Segregated Witness (SegWit).<br />For certain networks, such as BEP2 or EOS, you must fill in the Memo when making a transfer, or your address cannot be detected.</p>
                                                        <p>5. You will see a QR code and the deposit address. Please confirm the deposit network and contract information carefully before proceeding. Tap the information icon next to [Contract Information] to view details. The contract address of the asset you&rsquo;re depositing must be the same as the one shown here, or your assets might be lost.</p>
                                                        <p>6. You can deposit to either the Spot or Funding Wallet. Tap the information icon next to [Selected Wallet] - [Change Wallet] to select.</p>
                                                        <p>7. After confirming the network, contract information and wallet, tap the copy icon to copy and paste your BitRish Wallet&rsquo;s deposit address to the address field on the platform you intend to withdraw crypto from. You may also tap [Save as Image] and import the QR code on the withdrawing platform directly.<br />To share the deposit QR code and address, tap [Share Address].</p>
                                                        <p>8. After confirming the deposit request, the transfer will be processed. The funds will be credited to your BitRish account shortly after.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="faqItem">
                                            <a href="javascript:void(0)" data-target="#testingThreeModal">
                                                <i class="fas fa-file"></i> <span class="ml-2">Deposit hasn't arrived?</span>
                                            </a>
                                            <div id="testingThreeModal" class="testingModal">
                                                <div class="testingModalInner">
                                                    <div class="testingModalHeader">
                                                        <h3></h3>
                                                        <a href="javascript:void(0)" class="faqCloseModal"><i class="fas fa-times fa-3x"></i></a>
                                                    </div>
                                                    <div class="testingModalInnerContent">
                                                        <p>Sometimes, delays can occur when depositing crypto into your BitRish.com account. If your deposit hasn't arrived yet, follow these simple steps to resolve the issue:</p>
                                                        <p>Check the Blockchain Explorer:<br />Use a blockchain explorer to search for your transaction. Verify its status and confirmations on the blockchain network.</p>
                                                        <p>Wait for Confirmation:<br />Depending on network congestion and the specific cryptocurrency, confirmation times may vary. Patience is key, as the deposit might still be in progress.</p>
                                                        <p>Contact Support:<br />If the deposit hasn't arrived even after waiting for a reasonable amount of time, reach out to BitRish.com's customer support. They can investigate the issue and provide further assistance.</p>
                                                        <p>Conclusion:<br />Experiencing a delay in your deposit on BitRish.com can be concerning, but there are steps you can take to resolve the issue. By checking the blockchain explorer, waiting for confirmation, and contacting support if necessary, you can ensure that your deposit is addressed promptly and accurately.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="faqItem d-none">
                                            <a href="javascript:void(0)" data-target="#testingFourModal">
                                                <i class="fas fa-file"></i> <span class="ml-2">Deposit & Withdrawal Status query</span>
                                            </a>
                                            <div id="testingFourModal" class="testingModal">
                                                <div class="testingModalInner">
                                                    <div class="testingModalHeader">
                                                        <h3></h3>
                                                        <a href="javascript:void(0)" class="faqCloseModal"><i class="fas fa-times fa-3x"></i></a>
                                                    </div>
                                                    <div class="testingModalInnerContent">
                                                        <p>Sometimes, delays can occur when depositing crypto into your BitRish.com account. If your deposit hasn't arrived yet, follow these simple steps to resolve the issue:</p>
                                                        <p>Check the Blockchain Explorer:<br />Use a blockchain explorer to search for your transaction. Verify its status and confirmations on the blockchain network.</p>
                                                        <p>Wait for Confirmation:<br />Depending on network congestion and the specific cryptocurrency, confirmation times may vary. Patience is key, as the deposit might still be in progress.</p>
                                                        <p>Contact Support:<br />If the deposit hasn't arrived even after waiting for a reasonable amount of time, reach out to BitRish.com's customer support. They can investigate the issue and provide further assistance.</p>
                                                        <p>Conclusion:<br />Experiencing a delay in your deposit on BitRish.com can be concerning, but there are steps you can take to resolve the issue. By checking the blockchain explorer, waiting for confirmation, and contacting support if necessary, you can ensure that your deposit is addressed promptly and accurately.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="fundArea">
                                            <strong class="mt-5 d-block mb-4">Sponsored</strong>
                                            <a href="https://dantedoge.com/" target="_blank">
                                            <div class="fundItem">
                                                <img src="assets/images/dantedoge.png" />
                                                <div class="fundMessage">
                                                    <p class="m-0"><strong>DanteDoge</strong></p>
                                                    <p class="m-0">
                                                        DanteDoge is the no-bullshit meme coin you've been waiting for.
                                                    </p>
                                                </div>
                                                <i class="fas fa-chevron-right opacity-0"></i>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-5">
                                        <h2 class="d-flex align-items-baseline">Recent Withdraw <a href="javascript:void(0)" class="textGray ml-3 customRefresh" @click="getWithdrawTransactionList(selectedCoinId)"><i class="fas fa-refresh"></i></a></h2>
                                        <div class="recentDepositsArea mt-4" v-for="(list, index) in withdrawList" :key="index">
                                            <div class="recentDepositItem">
                                                <div class="recentDepositItemContent">
                                                    <div class="d-block">
                                                        <div class="coinName">
                                                            <img :src="list['coinIcon']" class="img-responsive" alt=""/>
                                                            <span>{{ list['coinName'] }}</span>
                                                        </div>
                                                        <div class="d-inline-flex text-center" v-if="list['status'].toString() === '0'">
                                                            <div class="customBadge customBadgeWarning">Pending</div>
                                                        </div>
                                                        <div class="d-inline-flex text-center" v-if="list['status'].toString() === '1'">
                                                            <div class="customBadge customBadgeSuccess">Complete</div>
                                                        </div>
                                                    </div>
                                                    <div class="d-block">
                                                        <div class="commonBlock"><strong>{{ list['new_date_time'] }}</strong></div>
                                                        <div class="commonBlock d-md-inline-block d-none">Network <strong>{{ list['coinNetwork'] }}</strong></div>
<!--                                                        <div class="commonBlock d-md-inline-block d-none">Address <strong>0x2727d89a86dbeff12b85025b1e42177ebb12bfc3 <a href="javascript:void(0)" class="textGray"><i class="textGray fas fa-link"></i></a> <a href="javascript:void(0)" class="textGray"><i class="textGray fas fa-copy"></i></a></strong></div>-->
                                                        <div class="commonBlock d-md-inline-block d-none">TxID
                                                            <a :href="list['blockExplorerURL']" target="_blank">
                                                                <strong>{{ list['trxHash'] }}
                                                                    <a :href="list['blockExplorerURL']" target="_blank" class="">
                                                                        <i class="fas fa-link"></i>
                                                                    </a>
                                                                </strong>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="recentDepositItemChevron">
                                                    <div class="chevronBg">
                                                        <i class="fas fa-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lightbox-deposit" v-if="!isKycA">
                                        <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                            <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                                <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                                <h1 class="text-warning m-0">Warning:</h1>
                                            </div>
                                            <span class="textInvert m-0 ml-2">Your KYC is still pending</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <!-- Dialog Simple datepicker -->
    <div tabindex="-1" class="modal fade" id="datepicker-dialog" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <!-- Inline popup datepicker start -->
                <div id="datepicker-popup-inline"></div>
                <!-- Inline popup datepicker end -->
                <div class="modal-footer">
                    <button type="button" class="btn pmd-ripple-effect btn-light" aria-hidden="true" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn pmd-ripple-effect btn-primary">Select</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Dialog Simple datepicker -->
    <!-- Popup For Get OTP -->
    <div class="customWithdrawVerify customModalForm">
        <div class="customAppPasswordInner">
            <h3 class="textInvert d-inline-flex justify-content-between align-items-center w-100">Verify Withdraw OTP</h3>
            <hr />
            <div class="d-flex justify-content-start align-items-start flex-column">
<!--                <div class="form-group w-100">
                    <label for="appUsername">Phone OTP</label>
                    <div class="input-group" >
                        <input type="number" class="form-control" ref="phoneOtp" name="pOtp" v-model="withdrawPOTP" @input="validatePOTP($event.target.value)"/>
                        <div class="inout-group-button">
                            <button class="btn btn-primary" @click="resendOtp('PHONE')" :disabled="!pOTPEnable">{{ pOTPText }}</button>
                        </div>
                    </div>

                </div>-->

                <div class="textInvert w-100 mb-3">
                    <label>Email OTP</label>
                    <div class="input-group">
                        <input type="number" class="form-control" ref="emailOtp" name="eOtp" v-model="withdrawEOTP" @input="validateEOTP($event.target.value)"/>
                        <div class="inout-group-button">
                            <button class="btn btn-primary" @click="resendOtp('EMAIL')" :disabled="!eOTPEnable">{{ eOTPText }}</button>
                        </div>
                    </div>
                </div>

                <div class="form-group w-100 text-right align-items-center">
                    <button class="btn btn-primary mr-2" @click="verifyOtp()">Verify & Withdraw</button>
                    <button class="btn btn-primary" @click="cancelWithdraw()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Popup For Get OTP -->
</template>

<script>
import {getAllCoinList, getBalByCoinId, getCoinNetworkList} from "@/restApi/deposit_api";
import {toast, Type} from "@/util/toast";
import {getBalance, log, MsgType} from "@/util/constant";
import {mapActions, mapMutations, mapState} from "vuex";
import {CHECK_USER_INFO_ACTION, LOADING_STATE, SET_WITHDRAW_DATA_MUTATION} from "@/util/store.constent";
import WithdrawreqValidation from "@/services/withdrawreq.validation";
import {
    cancelWithdraw,
    getWithdrawList,
    resendOtpReq,
    verifyWithdrawOtp, withdrawRequest,
    // withdrawRequest
} from "@/restApi/withdraw.api";
import Validations from "@/services/Validations";

let selectCoinId;
export default {
    name: "MyWithdraw",
    props: ['message'],
    components: {
        // VueQrcode,
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.isLogin,
            userName: (state) => state.userName,
            userEmail: (state) => state.userEmail,
            userNum: (state) => state.userContact,
            isKycA: (state) => state.isKycApproved,
            userAddressP: (state) => state.userAddress,
            withId: (state) => state.stateWithdrawID,
        }),
        ...mapActions({
            checkUser: CHECK_USER_INFO_ACTION,
        }),
    },
    mounted() {
        document.title = 'Profile - Bitrish';
    },
    data: function () {
        return {
            coinList: [],
            coinNetwork: [],
            withdrawList: [],
            walletAdd: '',
            walletExtra: '',
            showAddressSection: false,
            selectedCoinId: '',
            selectedCoin: '',
            selectedCoinIcon: '',
            walletBalance: '0',
            withdrawAddVal: '',
            withdrawAmtVal: '',
            withdrawEOTP: '',
            withdrawPOTP: '',
            withdrawFee: '',
            canWithdraw: false,

            pOTPText: 'Send OTP',
            pOTPEnable: true,
            eOTPText: 'Send OTP',
            eOTPEnable: true,
            pOTPTimer: null,
            eOTPTimer: null,
        };
    },
    async created() {
        await this.checkUser;
        if (!this.isLogin) {
            this.$router.replace('/signIn');
        }

        if(this.withId !== '') {
            this.openWithdrawVerifyModal();
        }
        await this.getAllCoin();
    },
    methods: {
        ...mapMutations({
            showLoading: LOADING_STATE,
            withdrawId: SET_WITHDRAW_DATA_MUTATION,
        }),

        async getAllCoin() {
            const {coinData, error} = await getAllCoinList();
            if (error.value === null) {
                if (JSON.parse(coinData.value)['status'].toString().toLowerCase() === 'true') {
                    const cData = JSON.parse(coinData.value)['data'];
                    if (cData.length > 0) {
                        for (let i = 0; i < cData.length; i++) {
                            this.coinList.push(cData[i]);
                        }
                    }
                }
            }
        },

        async onGetNetWorkByCoinId(coinId, coinName, coinIcon, fee, canWithdraw) {
            this.coinNetwork = [];
            this.withdrawList = [];
            this.walletAdd = '';
            this.walletExtra = '';
            this.showAddressSection = false;
            this.selectedCoinId = coinId;
            this.selectedCoin = coinName;
            this.selectedCoinIcon = coinIcon;
            this.withdrawFee = fee;
            this.canWithdraw = canWithdraw;
            const {res, err} = await getCoinNetworkList(coinId);
            if (err === null) {
                if (JSON.parse(res)['status'].toString().toLowerCase() === 'true') {
                    const data = JSON.parse(res)['data'];
                    for (let i = 0; i < data.length; i++) {
                        this.coinNetwork.push(data[i]);
                    }
                }
            }
        },

        async onSelectNetwork(coinId) {
            console.log('Coin id ==', coinId);
            this.walletBalance = (await this.getBalance(coinId))['bal'];
            await this.getWithdrawTransactionList(coinId);
            this.showAddressSection = true;
        },

        async getBalance(coinId) {
            const {res, err} = await getBalByCoinId(coinId);
            // console.log('Balance Data === ', res.value, err.value);
            if (err.value === null) {
                if (JSON.parse(res.value)['status'].toString().toLowerCase() === 'true') {
                    const data = JSON.parse(res.value)['data'];
                    // console.log('Balance Data === ', data);
                    return {
                        'bal': data['walletBalance'],
                    };
                } else {
                    return {
                        'bal': '0',
                    };
                }
            } else {
                return {
                    'bal': '0',
                };
            }
        },

        async sendWithdrawRequest() {
            const validation = new WithdrawreqValidation(this.$refs.withdrawAdd.value, this.$refs.withdrawAmt.value, this.walletBalance, this.isKycA);
            const err = validation.checkValidation();
            if (err === '') {
                this.showLoading(true);
                const {res, error} = await withdrawRequest(this.$refs.withdrawAdd.value, this.selectedCoinId, this.$refs.withdrawAmt.value);
                try {
                    if (error.value === null) {
                        const msg = JSON.parse(res.value)['message'];
                        if (JSON.parse(res.value)['status'].toString().toLowerCase() === 'true') {
                            this.withdrawId({
                                'id': JSON.parse(res.value)['data'].toString(),
                                'add': this.$refs.withdrawAdd.value,
                                'amt': this.$refs.withdrawAmt.value,
                            });
                            this.openWithdrawVerifyModal();
                            this.withdrawAddVal = '';
                            this.withdrawAmtVal = '';
                            this.coinNetwork = [];
                            this.withdrawList = [];
                            this.walletAdd = '';
                            this.walletExtra = '';
                            this.showAddressSection = false;
                            this.selectedCoinId = '';
                            this.selectedCoin = '';
                            this.selectedCoinIcon = '';
                            this.showLoading(false);
                            // toast(msg, Type.success);
                        } else {
                            this.showLoading(false);
                            toast(msg, Type.danger);
                        }
                    } else {
                        this.showLoading(false);
                        toast('Something Went Wrong', Type.danger);
                    }
                } catch (e) {
                    this.showLoading(false);
                    log(e, MsgType.error);
                    toast('Something Went Wrong', Type.danger);
                }
            } else {
                this.showLoading(false);
                toast(err, Type.danger);
            }
        },

        openWithdrawVerifyModal(){
            document.querySelector(".customWithdrawVerify").classList.add('active');
        },

        closeWithdrawVerifyModal(){
            document.querySelector(".customWithdrawVerify").classList.remove("active");
        },

        async verifyOtp() {
            // const pOTPValid = Validations.checkOTP(this.$refs.phoneOtp.value);
            const eOTPValid = Validations.checkOTP(this.$refs.emailOtp.value);
            if (/*pOTPValid && */eOTPValid) {
                this.showLoading(true);
                const {verifyRes, error} = await verifyWithdrawOtp(this.withId/*, this.$refs.phoneOtp.value*/, this.$refs.emailOtp.value);
                try {
                    if (error.value === null) {
                        const msg = JSON.parse(verifyRes.value)['message'];
                        if (JSON.parse(verifyRes.value)['status'].toString() === 'true') {
                            this.cryptoBal = (await getBalance(selectCoinId)).bal;
                            this.withdrawId({
                                'id': '',
                                'add': '',
                                'amt': '',
                            });
                            this.withdrawPOTP = '';
                            this.withdrawEOTP = '';
                            this.showLoading(false);
                            this.closeWithdrawVerifyModal();
                            toast(msg, Type.success);
                        } else {
                            this.showLoading(false);
                            toast(msg, Type.danger);
                        }
                    } else {
                        this.showLoading(false);
                        toast('Something Went Wrong', Type.danger);
                    }
                } catch (e) {
                    this.showLoading(false);
                    toast('Something Went Wrong', Type.danger);
                }
            }
        },

        async cancelWithdraw() {
            this.showLoading(true);
            const {cancelRes, error} = await cancelWithdraw(this.withId);
            try {
                if (error.value === null) {
                    const msg = JSON.parse(cancelRes.value)['message'];
                    if (JSON.parse(cancelRes.value)['status'].toString() === 'true') {
                        this.withdrawId({
                            'id': '',
                            'add': '',
                            'amt': '',
                        });
                        this.withdrawPOTP = '';
                        this.withdrawEOTP = '';
                        this.showLoading(false);
                        this.closeWithdrawVerifyModal();
                        toast(msg, Type.success);
                    } else {
                        this.withdrawId({
                            'id': '',
                            'add': '',
                            'amt': '',
                        });
                        this.withdrawPOTP = '';
                        this.withdrawEOTP = '';
                        this.showLoading(false);
                        this.closeWithdrawVerifyModal();
                        toast(msg, Type.danger);
                    }
                } else {
                    this.showLoading(false);
                    toast('Something Went Wrong', Type.danger);
                }
            } catch (e) {
                this.showLoading(false);
                toast('Something Went Wrong', Type.danger);
            }
        },

        async pasteText() {
            try {
                const text = await navigator.clipboard.readText()
                document.getElementById('walletAddressPaste').value += text;
            } catch (error) {
                try {
                    document.getElementById('walletAddressPaste').focus();
                    document.execCommand('paste')
                } catch (e) {
                    toast('Unable to get clipboard content', Type.danger);
                }
            }
        },

        async resendOtp(type) {
            this.showLoading(true);
            const {resendOTPRes, error} = await resendOtpReq(this.withId, type);
            try {
                if (error.value === null) {
                    const msg = JSON.parse(resendOTPRes.value)['message'];
                    if (JSON.parse(resendOTPRes.value)['status'].toString() === 'true') {
                        if (type === 'PHONE') {
                            this.pOTPText = 'Resend OTP';
                            this.pOTPEnable = false;
                            this.pOtpButtonED();
                        } else if (type === 'EMAIL') {
                            this.eOTPText = 'Resend OTP';
                            this.eOTPEnable = false;
                            this.eOtpButtonED();
                        }
                        this.showLoading(false);
                        toast(msg, Type.success);
                    } else {
                        this.showLoading(false);
                        toast(msg, Type.danger);
                    }
                } else {
                    this.showLoading(false);
                    toast('Something Went Wrong', Type.danger);
                }
            } catch (e) {
                this.showLoading(false);
                toast('Something Went Wrong', Type.danger);
            }
        },

        async getWithdrawTransactionList(coinId) {
            const {withdrawRes, error} = await getWithdrawList(coinId);
            if (error.value === null) {
                try {
                    if (JSON.parse(withdrawRes.value)['status'].toString().toLowerCase() === 'true') {
                        const tData = JSON.parse(withdrawRes.value)['data']
                        if (tData.length > 0) {
                            this.withdrawList = [];
                            for (let i = 0; i < tData.length; i++) {
                                const mxx = tData[i];
                                const dateString = parseInt(mxx['dateTime'].toString()) * 1000;
                                const d = new Date(dateString);
                                const da = d.getDate().toString().length > 1 ? d.getDate() : '0' + d.getDate();
                                const mo = (d.getMonth() + 1).toString().length > 1 ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1);
                                const y = d.getFullYear().toString().length > 1 ? d.getFullYear() : '0' + d.getFullYear();
                                const h = d.getHours().toString().length > 1 ? d.getHours() : '0' + d.getHours();
                                const m = d.getMinutes().toString().length > 1 ? d.getMinutes() : '0' + d.getMinutes();
                                const s = d.getSeconds().toString().length > 1 ? d.getSeconds() : '0' + d.getSeconds();
                                mxx['new_date_time'] = (da + '/' + mo + '/' + y + ' ' + h + ':' + m + ':' + s);
                                this.withdrawList.push(mxx);
                            }
                        }
                    }
                } catch (e) {
                    log(e, MsgType.error);
                }
            }
        },

        pOtpButtonED() {
            this.pOTPTimer = setTimeout(() => {
                this.pOTPEnable = true;
                if(this.pOTPTimer !== null) {
                    clearTimeout(this.pOTPTimer);
                }
            }, 60000);
        },

        eOtpButtonED() {
            setTimeout(() => {
                this.eOTPEnable = true;
                if(this.eOTPTimer !== null) {
                    clearTimeout(this.eOTPTimer);
                }
            }, 60000);
        },

        validatePOTP(input) {
            if (input.length > 6) {
                this.withdrawPOTP = input.slice(0, 6);
            }
        },

        validateEOTP(input) {
            if (input.length > 6) {
                this.withdrawEOTP = input.slice(0, 6);
            }
        },

    },
}
</script>
