import {ref} from "vue";
import axios from "axios";
import {log, MsgType} from "@/util/constant";

async function getNotifications() {
    const notiRes = ref(null)
    const error = ref(null)

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'getAnnouncementList',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('app_token')
        },
    };

    try {
        const response = await axios.request(config);
        log(response, MsgType.log);
        notiRes.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {notiRes, error};
}

export { getNotifications }
