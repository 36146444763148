import {log, MsgType} from "@/util/constant";
import axios from "axios";

async function getAppPasswordOTP() {
    let res = null;
    let error = null;

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'setting/appPass/getCreateAppPasswordOTP',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("app_token"),
        }
    };

    try {
        const response = await axios.request(config);
        res = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error = e.message;
    }

    return {res, error};

}

async function createAppPassword(nickName, otp) {
    let res = null;
    let error = null;

    let data = JSON.stringify({
        "nickName": nickName,
        "otp": otp
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'setting/appPass/createAppPassword',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("app_token"),
        },
        data : data,
    };

    try {
        const response = await axios.request(config);
        res = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error = e.message;
    }

    return {res, error};

}

async function getAllAppPasswords() {
    let res = null;
    let error = null;

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'setting/appPass/getAllAppPasswords',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("app_token"),
        },
    };

    try {
        const response = await axios.request(config);
        res = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error = e.message;
    }
    return {res, error};
}

async function deleteAppPassword(passId) {
    let res = null;
    let error = null;
    let data = JSON.stringify({
        "appPasswordID": passId
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'setting/appPass/deleteAppPassword',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("app_token"),
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        res = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error = e.message;
    }
    return {res, error};
}

export { getAppPasswordOTP, createAppPassword, getAllAppPasswords, deleteAppPassword }
