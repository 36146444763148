<template>
    <div class="row m-0 dark-bg-wallpaper">
        <div class="col-md-6 ch-100 d-inline-flex justify-content-center align-items-center">
            <img v-if="theme === ''" src="/assets/img/logo-dark.png" alt="Bitrish Logo" /><img v-else src="/assets/img/logo-light.png" alt="Bitrish Logo" />
        </div>
        <div class="col-md-6  d-inline-flex justify-content-start align-items-center">
            <div class="p-3 w-100 border-left d-flex justify-content-center align-items-center">
                <div class="form-access my-auto">
                <form>
                    <h2 class="text-center formHeading">Create Account</h2>
                    <div class="input-group code mb-3">
                        <select id="country-code" name="country-code" class="form-control" ref="countryCode" :disabled="!enableForPhone">
                            <option value="">Select Country Code</option>
                            <!-- <option value="1">+1 (United States)</option>
                            <option value="44">+44 (United Kingdom)</option> -->
                            <option :value="data.phoneCode" v-for="(data, index) in CountryData" :key="index">{{data.name}} : {{ data.phoneCode }}</option>
                        </select>
                    </div>

                    <div class="input-group mb-3">
                        <input type="tel" class="form-control" placeholder="Phone Number" ref="phoneNumber" :disabled="!enableForPhone">
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button" @click="getPhoneOtp">{{mOtp}}</button>
                        </div>
                    </div>

                    <div class="form-group mb-3" v-if="!enableForPhone">
                        <input type="number" class="form-control" maxlength="6" placeholder="OTP" ref="phoneOtp">
                    </div>

                    <div v-if="!enableForPhone">

                        <div class="input-group mb-3">
                            <input type="email" class="form-control" placeholder="Email Address" ref="email" :disabled="!enableForEmail">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" @click="getEmailOtp">{{eOtp}}</button>
                            </div>
                        </div>

                        <div class="form-group" v-if="!enableForEmail">
                            <input type="number" class="form-control" maxlength="6" placeholder="OTP" ref="emailOtp">
                        </div>
                        <div>
                            <div class="input-group mb-3">
                                <input type="password" id="pass" class="form-control" placeholder="Password" ref="password" @input="runTimeValidation($event.target.value)">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" @click="togglePassword('pass')" type="button"><i class="icon ion-md-eye"></i></button>
                                </div>
                            </div>
                            <div class="passwordThing">
                                <div id="popover-password">
                                    <p>Password Strength: <span id="result"> </span></p>
                                    <div class="progress">
                                        <div id="password-strength" class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width:0%"></div>
                                    </div>
                                </div>
                                <span class="upperLower textRed mr-2 d-block">1 Lowercase & 1 Uppercase <i class="upperLowerIcon icon ion-md-close"></i></span>
                                <span class="numericDigit textRed mr-2 d-block">1 Number (0-9) <i class="numericDigitIcon icon ion-md-close"></i></span>
                                <span class="specialCharacter textRed mr-2 d-block">1 Special Character (!@#$%^&*) <i class="specialCharacterIcon icon ion-md-close"></i></span>
                                <span class="passwordLength textRed mr-2 d-block">Atleast 8 Character <i class="passwordLengthIcon icon ion-md-close"></i></span>
                            </div>
                        </div>

                        <div class="input-group mb-3">
                            <input type="password" id="cPass" class="form-control" placeholder="Confirm Password" ref="rePassword">
                            <div class="input-group-append">
                                <button class="btn btn-primary" @click="togglePassword('cPass')" type="button"><i class="icon ion-md-eye"></i></button>
                            </div>
                        </div>
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="form-checkbox" ref="tcCheck">
                            <label class="custom-control-label" for="form-checkbox">I agree to the <a href="">Terms &
                                Conditions</a></label>
                        </div>
                        <button type="button" class="btn btn-primary btn-block mt08" @click="createAccount">Create Account</button>
                    </div>
                </form>
                <h2>Already have an account? <router-link to="/signIn">Sign in here</router-link></h2>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</template>

<script>
import SignUpValidation from "@/services/signup.validation";
import {mapMutations, mapState} from "vuex";
import {LOADING_STATE} from "@/util/store.constent";
import {getForVerifyOtp} from "@/restApi/api";
import {toast, Type} from "@/util/toast";
import {log, MsgType} from "@/util/constant";
import {registrationApi} from "@/restApi/api";
import country from '@/../public/assets/country.json';
import AppFooter from "@/components/parcel/AppFooter.vue";

export default {
    name: "SignUP",
    components: {AppFooter},
    computed:{
        ...mapState({
            theme: (state) => state.theme,
            isLogin: state => state.isLogin,
        }),
    },
    mounted() {
        document.title = 'Sign UP';
        if(this.isLogin) {
            this.$router.replace('/');
        }
    },
    data() {
      return {
          enableForPhone: true,
          enableForEmail: true,
          verifiedPhone: false,
          mOtp:"Send OTP",
          eOtp:"Send OTP",
          CountryData: [],
      }
    },
    created() {
        for(let i = 0; i < country.length; i++) {
            if(country[i].phoneCode.indexOf('+') < 0){
                country[i].phoneCode = '+' + country[i].phoneCode;
            }
            this.CountryData.push(country[i]);
        }
    },
    methods: {
        ...mapMutations({
            showLoading: LOADING_STATE,
        }),
        async createAccount() {
            if(!this.enableForEmail && !this.enableForEmail) {
                if(this.$refs.phoneOtp.value.length === 6 && this.$refs.emailOtp.value.length === 6) {
                    let validation = new SignUpValidation((this.$refs.countryCode.value + this.$refs.phoneNumber.value), this.$refs.email.value, this.$refs.password.value, this.$refs.rePassword.value);

                    const error = validation.checkValidations();
                    if(error === '') {
                        if(this.$refs.tcCheck.checked) {
                            this.showLoading(true);
                            const {resData, error} = await registrationApi(this.$refs.email.value, (this.$refs.countryCode.value + this.$refs.phoneNumber.value), this.$refs.password.value, this.$refs.rePassword.value, this.$refs.emailOtp.value, this.$refs.phoneOtp.value);
                            if(error.value === null) {
                                const msg = JSON.parse(resData.value)['message'].toString();
                                if(JSON.parse(resData.value)['status'].toString() === 'true'){
                                    this.showLoading(false);
                                    toast(msg, Type.success);
                                    this.$router.push('/signIn');
                                } else {
                                    this.showLoading(false);
                                    toast(msg, Type.danger);
                                }
                            } else {
                                this.showLoading(false);
                                toast("Something Went Wrong", Type.danger);
                            }
                        } else {
                            toast("Please confirm our Terms & condition", Type.danger);
                        }
                    } else {
                        toast(error, Type.danger);
                    }
                } else {
                    toast('Please Enter Valid OTP', Type.danger);
                }
            } else {
                toast('First Verify Your Email & Phone Number', Type.danger);
            }
        },
        async getPhoneOtp() {
            // console.log()
            if(this.$refs.countryCode.value !== '') {
                let validation = new SignUpValidation((this.$refs.countryCode.value + this.$refs.phoneNumber.value), '', '', '');
                const err = validation.checkPhone();
                if(err === '') {
                    this.showLoading(true);
                    const {resData, error} = await getForVerifyOtp(this.$refs.countryCode.value+this.$refs.phoneNumber.value, 'PHONE');
                    try {
                        if (error.value === null) {
                            const msg = JSON.parse(resData.value)['message'].toString();
                            if(JSON.parse(resData.value)['status'].toString() === 'true') {
                                this.mOtp = "Resend";
                                this.enableForPhone = false;
                                this.showLoading(false);
                                toast(msg, Type.success);
                            } else {
                                this.showLoading(false);
                                toast(msg, Type.danger);
                            }
                        } else {
                            this.showLoading(false);
                            toast('Something Went Wrong', Type.danger);
                        }
                    } catch (e) {
                        this.showLoading(false);
                        log(e, MsgType.error)
                    }
                } else {
                    toast(err, Type.danger);
                }
            } else {
                toast('Please Select Country Code', Type.danger);
            }
        },
        async getEmailOtp() {
            // console.log('getEmailOtp === ');
            let validation = new SignUpValidation('', this.$refs.email.value, '', '');
            const error = validation.checkEmail(this.$refs.email.value);
            if(error === '') {
                this.showLoading(true);
                const {resData, error} = await getForVerifyOtp(this.$refs.email.value, 'EMAIL');
                try {
                    if (error.value === null) {
                        const msg = JSON.parse(resData.value)['message'].toString();
                        if(JSON.parse(resData.value)['status'].toString() === 'true') {
                            this.enableForEmail = false;
                            this.eOtp = "Resend";
                            this.showLoading(false);
                            toast(msg, Type.success);
                        } else {
                            this.showLoading(false);
                            toast(msg, Type.danger);
                        }
                    } else {
                        this.showLoading(false);
                        toast('Something Went Wrong', Type.danger);
                    }
                } catch (e) {
                    this.showLoading(false);
                    log(e, MsgType.error)
                }
            } else {
                toast(error, Type.danger);
            }
        },
        togglePassword(id) {
            var x = document.getElementById(id);
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        },
        runTimeValidation(event){
            var upperLower = document.querySelector('.upperLower');
            var upperLowerIcon = document.querySelector('.upperLowerIcon');
            var numericDigit = document.querySelector('.numericDigit');
            var numericDigitIcon = document.querySelector('.numericDigitIcon');
            var specialCharacter = document.querySelector('.specialCharacter');
            var specialCharacterIcon = document.querySelector('.specialCharacterIcon');
            var passwordLength = document.querySelector('.passwordLength');
            var passwordLengthIcon = document.querySelector('.passwordLengthIcon');
            var passwordStrength = document.getElementById('password-strength');
            var passwordResult = document.getElementById("result");
            let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.*[!@#$%^&*])(?=.{8,})');
            let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
            function StrengthChecker(PasswordParameter) {
                if(strongPassword.test(PasswordParameter)) {
                    // passwordResult.style.backgroundColor = "green";
                    passwordResult.textContent = 'Strong';
                    passwordStrength.style.width = '100%';
                    passwordStrength.classList.add('progress-bar-success');
                    passwordStrength.classList.remove('progress-bar-warning');
                    passwordStrength.classList.remove('progress-bar-danger');
                } else if(mediumPassword.test(PasswordParameter)) {
                    // passwordResult.style.backgroundColor = 'blue';
                    passwordResult.textContent = 'Medium';
                    passwordStrength.style.width = '60%';
                    passwordStrength.classList.remove('progress-bar-success');
                    passwordStrength.classList.add('progress-bar-warning');
                    passwordStrength.classList.remove('progress-bar-danger');
                } else {
                    // passwordResult.style.backgroundColor = 'red';
                    passwordResult.textContent = 'Weak';
                    passwordStrength.style.width = '10%';
                    passwordStrength.classList.remove('progress-bar-success');
                    passwordStrength.classList.remove('progress-bar-warning');
                    passwordStrength.classList.add('progress-bar-danger');
                }
            }
            if(event){
                StrengthChecker(event);
            }else{
                passwordResult.textContent = '';
                passwordStrength.style.width = '0%';
                passwordStrength.classList.remove('progress-bar-success');
                passwordStrength.classList.remove('progress-bar-warning');
                passwordStrength.classList.add('progress-bar-danger');
            }

            if(/(?=.*[a-z])(?=.*[A-Z])/.test(event)){
                upperLower.classList.remove('textGray');
                upperLower.classList.remove('textRed');
                upperLower.classList.add('textGreen');
                upperLowerIcon.classList.remove('ion-md-close');
                upperLowerIcon.classList.add('ion-md-checkmark');
            }else{
                upperLower.classList.remove('textGray');
                upperLower.classList.add('textRed');
                upperLower.classList.remove('textGreen');
                upperLowerIcon.classList.add('ion-md-close');
                upperLowerIcon.classList.remove('ion-md-checkmark');
            }
            if(/(?=.*[0-9])/.test(event)){
                numericDigit.classList.remove('textGray');
                numericDigit.classList.remove('textRed');
                numericDigit.classList.add('textGreen');
                numericDigitIcon.classList.remove('ion-md-close');
                numericDigitIcon.classList.add('ion-md-checkmark');
            }else{
                numericDigit.classList.remove('textGray');
                numericDigit.classList.add('textRed');
                numericDigit.classList.remove('textGreen');
                numericDigitIcon.classList.add('ion-md-close');
                numericDigitIcon.classList.remove('ion-md-checkmark');
            }
            if(/(?=.*[!@#$%^&*])/.test(event)){
                specialCharacter.classList.remove('textGray');
                specialCharacter.classList.remove('textRed');
                specialCharacter.classList.add('textGreen');
                specialCharacterIcon.classList.remove('ion-md-close');
                specialCharacterIcon.classList.add('ion-md-checkmark');
            }else{
                specialCharacter.classList.remove('textGray');
                specialCharacter.classList.add('textRed');
                specialCharacter.classList.remove('textGreen');
                specialCharacterIcon.classList.add('ion-md-close');
                specialCharacterIcon.classList.remove('ion-md-checkmark');
            }
            if(event.length >= 8){
                passwordLength.classList.remove('textGray');
                passwordLength.classList.remove('textRed');
                passwordLength.classList.add('textGreen');
                passwordLengthIcon.classList.remove('ion-md-close');
                passwordLengthIcon.classList.add('ion-md-checkmark');
            }else{
                passwordLength.classList.remove('textGray');
                passwordLength.classList.add('textRed');
                passwordLength.classList.remove('textGreen');
                passwordLengthIcon.classList.add('ion-md-close');
                passwordLengthIcon.classList.remove('ion-md-checkmark');
            }
        },
    }
}
</script>

<style scoped>

.code {
    margin-bottom: 10px;
}

</style>
