<template>
    <div class="container">
        <div class="row m-0">
            <div class="col-md-12 my-5 d-flex justify-content-between align-items-center">
                <h1 class="boldHeading">Bitrish Convert</h1>
            </div>
        </div>
    </div>
    <div class="row m-0 p-0 textInvert pageContent">
        <div class="container">
            <div class="d-flex justify-content-center align-items-center flex-column">
                <div class="convertUI">
                    <ul class="nav nav-pills" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="pill" href="#market" role="tab" aria-selected="true">Market</a>
                            <a class="nav-link" data-toggle="pill" href="#limit" role="tab" aria-selected="false">Limit</a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="market" role="tabpanel">
                            <div class="card-body p-0 my-3">
                                <p class="text-right">
                                    <a href="javascript:void(0)" class="customLink"><i class="fas fa-wallet"></i> Spot Wallet</a>
                                </p>
                                <div class="row position-relative m-0">
                                    <div class="col-md-12 pb-5 pt-3 coinSection">
                                        <div class="row">
                                            <div class="col-sm-8">
                                                <p>From</p>
                                                <input type="text" name="coinAmount" id="CoinAmount" class="form-control coin" placeholder="0.00" />
                                            </div>
                                            <div class="col-sm-4 textGray">
                                                <p>Balance: -- <span class="selectedCoin">VUE</span></p>
                                                <div class="d-flex justify-content-center align-items-center selectedCoinChange">
                                                    <div class="coinImg">
                                                        <img src="@/assets/logo.png" alt="Coin Icon and Name" />
                                                    </div>
                                                    <strong class="mr-2">VUE</strong>
                                                    <i class="fas fa-chevron-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 pb-5 pt-3 coinSection">
                                        <div class="row">
                                            <div class="col-sm-8">
                                                <p>To</p>
                                                <input type="text" name="coinAmount" id="CoinAmount" class="form-control coin" placeholder="0.00" />
                                            </div>
                                            <div class="col-sm-4 textGray">
                                                <p>Balance: -- <span class="selectedCoin">VUE</span></p>
                                                <div class="d-flex justify-content-center align-items-center selectedCoinChange">
                                                    <div class="coinImg">
                                                        <img src="@/assets/logo.png" alt="Coin Icon and Name" />
                                                    </div>
                                                    <strong class="mr-2">VUE</strong>
                                                    <i class="fas fa-chevron-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="convertButton"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path d="M7.5 3h3v18.5l-7-7h4V3zM16.5 21h-3V2.5l7 7h-4V21z" fill="currentColor"></path></svg></button>
                                </div>
                                <div class="row my-3">
                                    <div class="col-md-12">
                                        <button class="btn btn-primary btn-block" disabled="disabled">Place Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="limit" role="tabpanel">
                            <div class="card-body p-0 my-3">
                                <p class="text-right">
                                    <a href="javascript:void(0)" class="customLink"><i class="fas fa-wallet"></i> Spot Wallet</a>
                                </p>
                                <div class="row position-relative m-0">
                                    <div class="col-md-12 pb-5 pt-3 coinSectionLimit">
                                        <div class="row">
                                            <div class="col-sm-8">
                                                <p>From</p>
                                                <input type="text" name="coinAmount" id="CoinAmount" class="form-control coin" placeholder="0.00" />
                                            </div>
                                            <div class="col-sm-4 textGray">
                                                <p>Balance: -- <span class="selectedCoin">VUE</span></p>
                                                <div class="d-flex justify-content-center align-items-center selectedCoinChange">
                                                    <div class="coinImg">
                                                        <img src="@/assets/logo.png" alt="Coin Icon and Name" />
                                                    </div>
                                                    <strong class="mr-2">VUE</strong>
                                                    <i class="fas fa-chevron-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row position-relative m-0">
                                    <div class="col-md-12 pb-5 pt-3 coinSection convertPriceSection">
                                        <div class="row">
                                            <div class="col-sm-5">
                                                <p>Convert Price</p>
                                                <small>1 BNB =</small>
                                                <input type="text" name="coinAmount" id="CoinAmount" class="form-control coin" placeholder="0.00" value="5.0000756" />
                                            </div>
                                            <div class="col-sm-7 textGray">
                                                <div class="d-flex justify-content-end align-items-end flex-column">
                                                    <small>Market Price: 307.01499258 USDT</small>
                                                    <strong class="mt-5">USDT</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 pb-5 pt-3 coinSection">
                                        <div class="row">
                                            <div class="col-sm-8">
                                                <p>To</p>
                                                <input type="text" name="coinAmount" id="CoinAmount" class="form-control coin" placeholder="0.00" />
                                            </div>
                                            <div class="col-sm-4 textGray">
                                                <p>Balance: -- <span class="selectedCoin">VUE</span></p>
                                                <div class="d-flex justify-content-center align-items-center selectedCoinChange">
                                                    <div class="coinImg">
                                                        <img src="@/assets/logo.png" alt="Coin Icon and Name" />
                                                    </div>
                                                    <strong class="mr-2">VUE</strong>
                                                    <i class="fas fa-chevron-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="convertButton"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path d="M7.5 3h3v18.5l-7-7h4V3zM16.5 21h-3V2.5l7 7h-4V21z" fill="currentColor"></path></svg></button>
                                </div>
                                <div class="row my-3">
                                    <div class="col-md-12">
                                        <button class="btn btn-primary btn-block" disabled="disabled">Place Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-md-12 mb-5">
                        <h2>FAQ</h2>
                    </div>
                    <div class="col-md-12">
                        <div class="customAccordion">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 1. Are there any trading fees?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                There are no fees for using Bitrish Convert.
                                            </p>
                                            <p>
                                                In Market mode, live prices are quoted based on current market conditions. The quoted amount of assets shown is the actual amount of assets you will receive in your account once the conversion is executed.
                                            </p>
                                            <p>
                                                In Limit mode, once your order is executed at the specified limit price, the assets will be converted exactly at the specified limit price.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 2. What are the benefits of using Bitrish Convert and how do I get started using the platform?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                Bitrish Convert is a simple tool for you to buy or sell cryptocurrencies with just a few clicks without having to go through placing orders on the trading interface. You can simply and instantly convert your crypto or fiat assets anytime with a live price based on current market conditions with zero fees.
                                            </p>
                                            <p>
                                                Find out more about the benefits of Bitrish Convert and how to get started here:
                                            </p>
                                            <p>
                                                <a href="javascript:void(0)" class="customLink">How to Use Bitrish Convert</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 3. What are the requirements to use the portal?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                Please log in to your account to access all the functions of Bitrish Convert (e.g. checking indicative prices / confirming trades). All users are required to complete Identity Verification and existing account deposit / withdrawal limits apply.
                                            </p>
                                            <p>
                                                For more information on Identity Verification, please click here.
                                            </p>
                                            <p>
                                                <a href="javascript:void(0)" class="customLink">How to Use Bitrish Convert</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 4. What are the minimum and maximum trade amounts?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                Minimum and maximum trade amounts will be dependent on the coin / pair and can be previewed before entering in a trade amount.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 5. How does settlement work?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                Trades are settled directly into your Spot Wallet or Funding wallet, depending on which wallet is selected. Settlement will usually occur immediately or, in some cases, up to a few minutes after you confirm a trade.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 6. How do I make deposits and withdrawals?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                Bitrish Convert is connected to your account's Spot Wallet and Funding Wallet. Simply make deposits and withdrawals to / from your existing Spot Wallet or Funding Wallet.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 7. Why did I receive an “insufficient funds” error?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                To confirm an OTC trade, you must have the required amount of coins that you are selling in your account. For example, if you want to buy 10 BTC with USDT and the price per BTC is 10,000 USDT, then you must have at least 100,000 USDT in your account to confirm the trade.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 8. What is the difference between "Market" mode and "Limit" mode?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                Market mode allows you to convert your assets at the current market price based on the quoted price in the conversion.
                                            </p>
                                            <p>
                                                Limit mode allows you to place an order to convert your assets at a specified limit price. For example, if you place an order to convert USDT to BTC at a limit price of 9,000 USDT per BTC and the current price is 10,000 USDT per BTC, the order will only execute when the price per BTC is 9,000 USDT or lower.
                                            </p>
                                            <p>
                                                Similarly, if you place an order to convert BTC to USDT at a limit price of 11,000 USDT per BTC and the current price is 10,000 USDT per BTC, the order will only execute when the price per BTC is 11,000 USDT or higher.
                                            </p>
                                            <p>
                                                Limit mode orders are executed subject to market conditions and may not be completed even if the market price reaches or crosses your limit price momentarily.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 9. Why is the price quoted different from the last traded price on the exchange?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                Last traded prices on the exchange do not include trading fees or factor in the size of your trade and are not live prices. Bitrish Convert dynamically quotes the best prices (with no hidden fees) based on market conditions and are held for a period of time for reviewing before executing your trade.
                                            </p>
                                            <p>
                                                The quoted amount of assets shown when previewing a conversion is the actual amount of assets you will receive in your account. There are no additional fees on top of the price quoted.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 10. Where can I check my trades history?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                You may find this in Convert history within your account (Orders >> Convert History) once you are logged in.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 11. Can I swap old tokens that have undergone token migration on Bitrish Convert?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                Bitrish Convert allows users to swap old versions of tokens to new versions that, for example, may have resulted from project teams implementing a token swap or token migration. The old tokens can only be swapped to the new versions of the token. If a specific old token is not listed, this indicates that we currently do not support the conversion.
                                            </p>
                                            <p>
                                                Please note that there is a daily limit on the amount of old tokens you can convert, which refreshes at 12:00am UTC+0. Daily limits vary by token.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 12. Are Convert trades included in the spot trading volume calculation for the VIP Program?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                Yes, Convert trades are also included in the spot trading volume calculation, except for old to new token conversions (for tokens that have undergone a token swap or token migration).
                                            </p>
                                            <p>
                                                Yes, Convert trades are also included in the spot trading volume calculation, except for old to new token conversions (for tokens that have undergone a token swap or token migration).
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 13. How many trading tokens and trading pairs does Bitrish Convert support?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                Bitrish Convert supports over 350 tokens, such as <a href="javascript:void(0)" class="customLink">Bitcoin (BTC)</a> and <a href="javascript:void(0)" class="customLink">Ethereum (ETH)</a> . Their price details can be viewed on this <a href="javascript:void(0)" class="customLink">token list</a>.
                                            </p>
                                            <p>
                                                Through its unique any-coin-to-any-coin feature, Bitrish Convert supports more than 45,000 of conversion possibilities among any two supported tokens. However, do note that a few exceptions exist, where conversion from old tokens to new tokens only allows conversion in one direction.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 14. What legal terms apply to trading on Bitrish Convert?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                When trading on Bitrish Convert, users will be subject to the <a href="javascript:void(0)" class="customLink">Bitrish Terms of Use</a> and the <a href="javascript:void(0)" class="customLink">Convert Terms of Use</a>. Users may be subject to localized versions of these terms when onboarded to local Bitrish entities. Should you have any questions, please contact us.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="customAccordionItem">
                                        <h3 class="customAccordionTitle"><span class="customAddIcon"></span> 15. What are the “NEW” and “HOT” token tags?</h3>
                                        <div class="customAccordionDescription">
                                            <p>
                                                What are the “NEW” and “HOT” token tags? The “NEW” tag refers to newly listed tokens on Bitrish Convert and will last 7 days after listing. The “HOT” tag refers to the top 3 tokens with the highest percentage gain in price in the last 24 hours. A detailed ranking of tokens can be viewed <a href="javascript:void(0)" class="customLink">here</a>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer/>
</template>

<script>
import AppFooter from "@/components/parcel/AppFooter.vue";

export default {
    name: "BitrishConvert",
    components: {AppFooter},
}
</script>
