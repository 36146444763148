export default class SignInValidation {
    constructor(email, password) {
        this.email = email;
        this.password = password;
    }

    checkValidation() {
        let error = '';

        if(this.email === '') {
            error = 'Please Enter Email';
        } else if (this.password === '') {
            error = 'Please Enter Password';
        }

        return error;
    }

}