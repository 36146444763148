import axios from "axios"
import {ref} from "vue";
import {log, MsgType} from "@/util/constant";
// const myVar = import.meta.env.VITE_API_ENDPOINT;

axios.defaults.baseURL = "http://192.168.1.101:8080/api/v1/";

async function registrationApi(email, pNum, pass, rePass, eOtp, pOtp) {
    const resData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "email": email,
        "phone": pNum,
        "password": pass,
        "repeat_password": rePass,
        "emailOTP": eOtp,
        "phoneOTP": pOtp

    });
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'user/register',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        resData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {resData, error}
}

async function loginApi(email, pass, otp) {
    const resData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "email": email,
        "password": pass,
        "loginOTP": otp,
    });
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'user/login',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        resData.value = JSON.stringify(response.data);
    } catch (e) {
        error.value = 'Error';
        log(e, MsgType.error);
    }

    return {resData, error}
}

async function getUserDataApi() {
    const res = ref(null)
    const err = ref(null)
    let data = "";
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'user/getInfo',
        headers: {
            'Authorization': 'Bearer '+localStorage.getItem("app_token")
        },
        data: data
    };

    try{
        const response = await axios.request(config);
        res.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        err.value = "Error";
    }

    return {res, err}
}

async function getForVerifyOtp(receiver, type) {
    const resData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "receiver": receiver,
        "type": type
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'user/getRegistrationOTP',
        headers: {
            'Content-Type': 'application/json'
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        log(JSON.stringify(response.data), MsgType.log);
        resData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = "Error";
    }

    return {resData, error}
}

async function getOtpForChangePassword(oldPass) {
    const resData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "oldPass": oldPass,
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'user/getChangeOTP',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("app_token")
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        log(JSON.stringify(response.data), MsgType.log);
        resData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = "Error";
    }

    return {resData, error}
}

async function changeOldPassword(email, oldPass, otp, newPass, reNewPass) {
    const resData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "email": email,
        "oldPass": oldPass,
        "otp": otp,
        "newPass": newPass,
        "newPassRepeat": reNewPass
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'user/setChangePassword',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem("app_token")
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        log(JSON.stringify(response.data), MsgType.log);
        resData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = "Error";
    }

    return {resData, error}
}

async function getOtpForForgetPassword(mail) {
    const resData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "email": mail,
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'user/getForgetOTP',
        headers: {
            'Content-Type': 'application/json',
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        log(JSON.stringify(response.data), MsgType.log);
        resData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = "Error";
    }

    return {resData, error}
}

async function resetForgetPassword(mail, otp, nPass, cPass) {
    const ressData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "email": mail,
        "otp": otp,
        "newPass": nPass,
        "newPassRepeat": cPass
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'user/setForgetPassword',
        headers: {
            'Content-Type': 'application/json',
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        log(JSON.stringify(response.data), MsgType.log);
        ressData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = "Error";
    }

    return {ressData, error}
}

async function getCurrencyList() {
    const curData = ref(null)
    const error = ref(null)
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'currency/list',
        headers: { }
    };

    try {
        const response = await axios.request(config);
        curData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = "Error";
    }

    return {curData, error}
}

export { registrationApi, loginApi, getUserDataApi, getForVerifyOtp, getOtpForChangePassword, changeOldPassword, getOtpForForgetPassword, resetForgetPassword, getCurrencyList }
