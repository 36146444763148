import axios from "axios";
import {log, MsgType} from "@/util/constant";

async function getAllTransactionList() {
    let res = null
    let err = null

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'transaction/allDepositWithdrawalList',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('app_token')
        },
    };

    try {
        const response = await axios.request(config);
        log(JSON.stringify(response.data), MsgType.log)
        res = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        err = e.message;
    }

    return {res, err}
}

export { getAllTransactionList }
