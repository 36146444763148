<template>
    <div class="row m-0 dark-bg-wallpaper">
        <div class="col-md-6 ch-100 d-inline-flex justify-content-center align-items-center">
            <img v-if="theme === ''" src="/assets/img/logo-dark.png" alt="Bitrish Logo" /><img v-else src="/assets/img/logo-light.png" alt="Bitrish Logo" />
        </div>
        <div class="col-md-6 ch-100 d-inline-flex justify-content-start align-items-center">
            <div class="p-3 w-100 border-left d-flex justify-content-center align-items-center">
                <div class="form-access my-auto">
                    <form>
                        <h2 class="text-center formHeading">{{ btnText === "Send OTP" ? 'Forget Password' : "Reset Password"}}</h2>
                        <div class="input-group mb-3" v-if="btnText !== 'Reset Password'">
                            <input type="email" class="form-control" placeholder="Email Address" ref="email" :disabled="btnText === 'Reset Password'">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" @click="sendOtp">{{ btnText }}</button>
                            </div>
                        </div>
                        <div class="form-group" v-if="btnText === 'Reset Password'">
                            <input type="tel" class="form-control" maxlength="6" placeholder="Enter OTP" ref="otp">
                        </div>
                        <div class="mb-3" v-if="btnText === 'Reset Password'">
                            <div class="input-group">
                                <input type="password" class="form-control" placeholder="Enter New Password" ref="pass" id="nPass"  @input="runTimeValidation($event.target.value)">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" @click="togglePassword('nPass')" type="button"><i class="icon ion-md-eye"></i></button>
                                </div>
                            </div>
                            <div class="passwordThing">
                                <div id="popover-password">
                                    <p>Password Strength: <span id="result"> </span></p>
                                    <div class="progress">
                                        <div id="password-strength" class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width:0%"></div>
                                    </div>
                                </div>
                                <span class="upperLower textRed mr-2 d-block">1 Lowercase & 1 Uppercase <i class="upperLowerIcon icon ion-md-close"></i></span>
                                <span class="numericDigit textRed mr-2 d-block">1 Number (0-9) <i class="numericDigitIcon icon ion-md-close"></i></span>
                                <span class="specialCharacter textRed mr-2 d-block">1 Special Character (!@#$%^&*) <i class="specialCharacterIcon icon ion-md-close"></i></span>
                                <span class="passwordLength textRed mr-2 d-block">Atleast 8 Character <i class="passwordLengthIcon icon ion-md-close"></i></span>
                            </div>
                        </div>
                        <div class="input-group mb-3" v-if="btnText === 'Reset Password'">
                            <input type="password" class="form-control" placeholder="Enter Confirm Password" ref="repass" id="cPass">
                            <div class="input-group-append">
                                <button class="btn btn-primary" @click="togglePassword('cPass')" type="button"><i class="icon ion-md-eye"></i></button>
                            </div>
                        </div>
                        <div class="form-group" v-if="btnText === 'Reset Password'">
                            <button class="btn btn-primary btn-block" type="button" @click="sendOtp">{{ btnText }}</button>
                        </div>
                    </form>
                    <h2>
                        <router-link to="/signUp">Sign up</router-link>
                        or
                        <router-link to="/signIn">Sign in</router-link>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {toast, Type} from "@/util/toast";
import {mapMutations, mapState} from "vuex";
import {LOADING_STATE} from "@/util/store.constent";
import Validations from "@/services/Validations";
import {resetForgetPassword} from "@/restApi/api";
import {getOtpForForgetPassword} from "@/restApi/api";
import {log, MsgType} from "@/util/constant";

export default {
    name: "Forget-Password",
    computed:{
        ...mapState({
            theme: (state) => state.theme,
            isLogin: state => state.isLogin,
        }),
    },
    data() {
        return {
            btnText: 'Send OTP',
            emailVal: '',
        }
    },
    methods: {
        ...mapMutations({
            showLoading: LOADING_STATE
        }),
        async sendOtp() {
            if (this.btnText === 'Send OTP') {
                const email = this.$refs.email.value;
                if (!Validations.checkEmail(email)) {
                    toast('Please enter E-mail', Type.danger)
                } else {
                    this.showLoading(true);
                    try {
                        const {resData, error} = await getOtpForForgetPassword(email);
                        if (error.value === null) {
                            // log(resData.value, MsgType.log);
                            const msg = JSON.parse(resData.value)['message'];
                            if(JSON.parse(resData.value)['status'].toString() === 'true') {
                                this.showLoading(false);
                                this.emailVal = this.$refs.email.value;
                                this.btnText = 'Reset Password';
                                toast(msg, Type.success);
                            } else {
                                toast(msg, Type.danger);
                                this.showLoading(false);
                            }
                        } else {
                            toast('Something Went Wrong', Type.danger);
                            this.showLoading(false);
                        }
                    } catch (e) {
                        this.showLoading(false);
                        log(e, MsgType.error);
                    }
                }
            } else if (this.btnText === 'Reset Password') {
                const otp = this.$refs.otp.value;
                const pass = this.$refs.pass.value;
                const repass = this.$refs.repass.value;
                // console.log(this.emailVal);
                if (otp === '' && otp.length < 6) {
                    toast('Please Enter Valid OTP', Type.danger)
                } else {
                    if (!Validations.checkPassword(pass)) {
                        toast('Please Enter Valid password', Type.danger)
                    } else {
                        if (repass !== pass) {
                            toast('Confirm Password Not Match With New Password', Type.danger)
                        } else {
                            this.showLoading(true);
                            const {ressData, error} = await resetForgetPassword(this.emailVal, otp, pass, repass);
                            try {
                                if (error.value === null) {
                                    // log(ressData.value, MsgType.log);
                                    const msg = JSON.parse(ressData.value)['message'];
                                    if(JSON.parse(ressData.value)['status'].toString() === 'true') {
                                        this.showLoading(false);
                                        toast(msg, Type.success);
                                        this.$router.replace('/signIn');
                                    } else {
                                        toast(msg, Type.danger);
                                        this.showLoading(false);
                                    }
                                } else {
                                    toast('Something Went Wrong', Type.danger);
                                    this.showLoading(false);
                                }
                            } catch (e) {
                                this.showLoading(false);
                                log(e, MsgType.error);
                            }
                        }
                    }
                }
            }
        },
        togglePassword(id) {
            var x = document.getElementById(id);
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        },
        runTimeValidation(event){
            var upperLower = document.querySelector('.upperLower');
            var upperLowerIcon = document.querySelector('.upperLowerIcon');
            var numericDigit = document.querySelector('.numericDigit');
            var numericDigitIcon = document.querySelector('.numericDigitIcon');
            var specialCharacter = document.querySelector('.specialCharacter');
            var specialCharacterIcon = document.querySelector('.specialCharacterIcon');
            var passwordLength = document.querySelector('.passwordLength');
            var passwordLengthIcon = document.querySelector('.passwordLengthIcon');
            var passwordStrength = document.getElementById('password-strength');
            var passwordResult = document.getElementById("result");
            let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.*[!@#$%^&*])(?=.{8,})');
            let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
            function StrengthChecker(PasswordParameter) {
                if(strongPassword.test(PasswordParameter)) {
                    // passwordResult.style.backgroundColor = "green";
                    passwordResult.textContent = 'Strong';
                    passwordStrength.style.width = '100%';
                    passwordStrength.classList.add('progress-bar-success');
                    passwordStrength.classList.remove('progress-bar-warning');
                    passwordStrength.classList.remove('progress-bar-danger');
                } else if(mediumPassword.test(PasswordParameter)) {
                    // passwordResult.style.backgroundColor = 'blue';
                    passwordResult.textContent = 'Medium';
                    passwordStrength.style.width = '60%';
                    passwordStrength.classList.remove('progress-bar-success');
                    passwordStrength.classList.add('progress-bar-warning');
                    passwordStrength.classList.remove('progress-bar-danger');
                } else {
                    // passwordResult.style.backgroundColor = 'red';
                    passwordResult.textContent = 'Weak';
                    passwordStrength.style.width = '10%';
                    passwordStrength.classList.remove('progress-bar-success');
                    passwordStrength.classList.remove('progress-bar-warning');
                    passwordStrength.classList.add('progress-bar-danger');
                }
            }
            if(event){
                StrengthChecker(event);
            }else{
                passwordResult.textContent = '';
                passwordStrength.style.width = '0%';
                passwordStrength.classList.remove('progress-bar-success');
                passwordStrength.classList.remove('progress-bar-warning');
                passwordStrength.classList.add('progress-bar-danger');
            }

            if(/(?=.*[a-z])(?=.*[A-Z])/.test(event)){
                upperLower.classList.remove('textGray');
                upperLower.classList.remove('textRed');
                upperLower.classList.add('textGreen');
                upperLowerIcon.classList.remove('ion-md-close');
                upperLowerIcon.classList.add('ion-md-checkmark');
            }else{
                upperLower.classList.remove('textGray');
                upperLower.classList.add('textRed');
                upperLower.classList.remove('textGreen');
                upperLowerIcon.classList.add('ion-md-close');
                upperLowerIcon.classList.remove('ion-md-checkmark');
            }
            if(/(?=.*[0-9])/.test(event)){
                numericDigit.classList.remove('textGray');
                numericDigit.classList.remove('textRed');
                numericDigit.classList.add('textGreen');
                numericDigitIcon.classList.remove('ion-md-close');
                numericDigitIcon.classList.add('ion-md-checkmark');
            }else{
                numericDigit.classList.remove('textGray');
                numericDigit.classList.add('textRed');
                numericDigit.classList.remove('textGreen');
                numericDigitIcon.classList.add('ion-md-close');
                numericDigitIcon.classList.remove('ion-md-checkmark');
            }
            if(/(?=.*[!@#$%^&*])/.test(event)){
                specialCharacter.classList.remove('textGray');
                specialCharacter.classList.remove('textRed');
                specialCharacter.classList.add('textGreen');
                specialCharacterIcon.classList.remove('ion-md-close');
                specialCharacterIcon.classList.add('ion-md-checkmark');
            }else{
                specialCharacter.classList.remove('textGray');
                specialCharacter.classList.add('textRed');
                specialCharacter.classList.remove('textGreen');
                specialCharacterIcon.classList.add('ion-md-close');
                specialCharacterIcon.classList.remove('ion-md-checkmark');
            }
            if(event.length >= 8){
                passwordLength.classList.remove('textGray');
                passwordLength.classList.remove('textRed');
                passwordLength.classList.add('textGreen');
                passwordLengthIcon.classList.remove('ion-md-close');
                passwordLengthIcon.classList.add('ion-md-checkmark');
            }else{
                passwordLength.classList.remove('textGray');
                passwordLength.classList.add('textRed');
                passwordLength.classList.remove('textGreen');
                passwordLengthIcon.classList.add('ion-md-close');
                passwordLengthIcon.classList.remove('ion-md-checkmark');
            }
        },
    },
    mounted() {
        document.title = 'Forget Password - BitRish';
        if(this.isLogin) {
            this.$router.replace('/');
        }
    }
}
</script>

<style scoped>

</style>
