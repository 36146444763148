<template>
    <div class="row m-0 p-0">
        <div class="col-xl-2 col-md-2 col-sm-3 my-3">
            <div class="sidebar">
                <ul>
                    <li>
                        <router-link to="/MyProfile" class="customLink">Profile</router-link>
                        <ul class="customDropdown d-none">
                            <li><a href="javascript:void(0)" class="customLink">Personal Information</a></li>
                            <li><a href="javascript:void(0)" class="customLink">KYC</a></li>
                            <li><a href="javascript:void(0)" class="customLink">Change Password</a></li>
                            <li><a href="javascript:void(0)" class="customLink">App Password</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0)" class="customLink active">Wallet</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-xl-10 col-md-10 col-sm-9 my-3">
            <div class="mainContent">
                <section class="customSection active" id="contentFour">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center border-bottom mb-4 customMenu">
                                <h5 class="card-title">Deposit Crypto</h5>
                                <div class="mb-2 d-inline-flex walletPageLinks">
                                    <router-link to="/MyWallet" class="btn btn-secondary">Wallet</router-link>
                                    <router-link to="/MyDeposit" class="btn btn-primary">Deposit</router-link>
                                    <router-link to="/MyWithdraw" class="btn btn-secondary">Withdraw</router-link>
                                    <router-link to="/TransactionHistory" class="btn btn-secondary">Transaction History</router-link>
                                    <!-- <button target="#walletMainTab" class="btn btn-primary">Wallet</button>
                                    <button target="#depositWallet" class="btn btn-secondary">Deposit</button> -->
                                    <!-- <button target="#withdrawWallet" class="btn btn-secondary">Withdraw</button> -->
                                    <!-- <button target="#historyWallet" class="btn btn-secondary">Transaction History</button> -->
                                    <!-- <button target="#historyWallet" class="btn btn-secondary" @click="walletPageChange('historyWallet')">Transaction History</button> -->
                                </div>
                            </div>
                            <div class="settings-profile">
                                <div class="row active walletTabs0" id="depositWallet">
                                    <div class="col-md-6">
                                        <div class="steps d-flex justify-content-start w-100 mt-5">
                                            <div class="stepsContent active">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem">1</span>
                                                </div>
                                                <div class="stepsContentRight pt-2">
                                                    <label>Select Coin</label>
                                                    <div class="selectedCoin d-none">
                                                        <div class="selectedCoinItem">
                                                            <img class="walletCoinImage" src="assets/img/logo.png"/><a
                                                                href="javascript:void(0)" class="ml-2">EQL</a>
                                                        </div>
                                                        <div class="chevron"><i class="fas fa-chevron-down"></i></div>
                                                    </div>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i>
                                                        </div>
                                                        <input type="text" name="selectCoin" id="selectCoin"
                                                               @input="searchPair()" class="form-control"/>
                                                        <div class="input-group-append"><i class="fas fa-times"></i>
                                                        </div>
                                                        <div class="searchCoinDropdown">
                                                            <div class="CoinList">
                                                                <small class="textGray">Coin List</small>
                                                                <ul class="CoinListUL">
                                                                    <li v-for="(coin, index) in coinList" :key="index"
                                                                        class="coinListItem"
                                                                        @click="onGetNetWorkByCoinId(coin['coinId'].toString(), coin['coinName'], coin['coinIcon'], coin['coinDepositAllowed'])">
                                                                        <div class="d-flex align-items-center gap-1 CoinSelectValue"
                                                                             :data-pair="coin['coinName']">
                                                                            <img class="walletCoinImage"
                                                                                 :src="coin['coinIcon']"/>
                                                                            <a href="javascript:void(0)">{{
                                                                                coin['coinName']
                                                                                }}</a>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem">2</span>
                                                </div>
                                                <div class="stepsContentRight pt-2">
                                                    <label>Select Network</label>
                                                    <div class="selectedNetwork d-none">
                                                        <div class="selectedNetworkItem">
                                                            <a href="javascript:void(0)" class="ml-2">EQL</a>
                                                        </div>
                                                        <div class="chevron"><i class="fas fa-chevron-down"></i></div>
                                                    </div>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i>
                                                        </div>
                                                        <input type="text" name="selectNetwork" id="selectNetwork"
                                                               class="form-control"/>
                                                        <div class="input-group-append"><i class="fas fa-times"></i>
                                                        </div>
                                                        <div class="searchNetworkDropdown">
                                                            <div class="CoinList pt-2">
                                                                <small class="textGray bg-dark br12 p-3">Please note
                                                                    that only supported networks on Bitrish platform are
                                                                    shown, if you deposit via another network your
                                                                    assets may be lost.</small>
                                                                <ul class="CoinListUL">
                                                                    <li v-for="(coin, index) in coinNetwork"
                                                                        :key="index" class="networkListItem"
                                                                        @click="onSelectNetwork(coin['coinNetworkId'].toString())">
                                                                        <div class="d-flex align-items-center gap-1 NetworkSelectValue"
                                                                             :data-pair="coin">
                                                                            <a href="javascript:void(0)">{{
                                                                                coin['coinNetwork']
                                                                                }}</a>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- coinNetwork -->
                                                </div>
                                            </div>
                                            <div class="stepsContent depositAddressArea">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem">3</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Deposit Address</label>
                                                    <input type="text" style="z-index:-1;margin-bottom:-50px;" id="commonText" />
                                                    <div class="bg-dark textInvert br12 d-flex justify-content-between align-items-center  depositAreaDepositPage"
                                                         v-if="showAddressSection && walletAdd !== '' && canDeposit">
                                                         <div class="addressesArea">
                                                            <div class="w-100 d-flex justify-content-between" v-if="walletExtra">
                                                                <div class="w-50 d-flex flex-column p-1 justify-content-between doubleQRCode">
                                                                    <div class="addressArea">
                                                                        <small class="textGray">Address</small>
                                                                        <a class="copyBtn" @click="copyAdd" href="javascript:void(0)"><i class="fas fa-copy"></i></a>
                                                                    </div>
                                                                    <strong id="walletAdd">{{walletAdd}}</strong>
                                                                    <div class="qrcodeArea">
                                                                        <vue-qrcode v-bind:value="walletAdd" width="148"/>
                                                                    </div>
                                                                </div>
                                                                <div class="w-50 d-flex flex-column p-1 justify-content-between doubleQRCode">
                                                                    <div class="addressArea">
                                                                        <small class="textGray">Memo</small>
                                                                        <a class="copyBtn" @click="copyExtra" href="javascript:void(0)"><i class="fas fa-copy"></i></a>
                                                                    </div>
                                                                    <strong id="walletExtra">{{walletExtra}}</strong>
                                                                    <div class="qrcodeArea">
                                                                        <vue-qrcode v-bind:value="walletExtra" width="148"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="w-100 d-flex justify-content-between singleQRCode" v-else>
                                                                <div class="w-100 d-flex">
                                                                    <div class="qrcodeArea">
                                                                        <vue-qrcode v-bind:value="walletAdd" width="148"/>
                                                                    </div>
                                                                    <div class="addressArea">
                                                                        <div class="d-flex justify-content-between align-items-center">
                                                                            <small class="textGray">Address</small>
                                                                            <a class="copyBtn" @click="copyAdd" href="javascript:void(0)"><i class="fas fa-copy"></i></a>
                                                                        </div>
                                                                        <strong id="walletAdd">{{walletAdd}}</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="input-group">
                                                                <input type="text" class="form-control" readonly
                                                                       id="depositWA" v-model="walletAdd">
                                                                <div class="input-group-append">
                                                                </div>
                                                            </div> -->
                                                            <!-- <div class="input-group mt-1" v-if="walletExtra !== ''">
                                                                <input type="text" class="form-control" readonly
                                                                       id="depositWE" v-model="walletExtra">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-primary" @click="copyExtra">
                                                                        <i class="fas fa-copy"></i></button>
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="bg-dark textInvert br12 p-4 d-flex justify-content-center align-items-center flex-column"
                                                         v-if="showAddressSection && !canDeposit">
                                                        <i class="fa-solid fa-circle-exclamation fa-4x"></i>
                                                        <h3 class="text-center">Deposit temporarily unavailable</h3>
                                                        <p class="text-center">
                                                            Crypto deposit temporarily suspended. Please check back later for updates.
                                                        </p>
                                                    </div>
                                                    <!-- <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 offset-md-1">
                                        <h2>&nbsp;</h2>
                                        <strong class="d-block mb-4">FAQ</strong>
                                        <div class="faqItem">
                                            <a href="javascript:void(0)" data-target="#testingOneModal">
                                                <i class="fas fa-play-circle"></i> <span class="ml-2">How to deposit crypto?</span>
                                            </a>
                                            <div id="testingOneModal" class="testingModal">
                                                <div class="testingModalInner">
                                                    <div class="testingModalHeader">
                                                        <h3></h3>
                                                        <a href="javascript:void(0)" class="faqCloseModal"><i class="fas fa-times fa-3x"></i></a>
                                                    </div>
                                                    <div class="testingModalInnerContent">
                                                        <p>Depositing crypto into your BitRish.com account is easy and convenient. Follow these steps to securely transfer your assets and start trading:</p>
                                                        <p>Create an account on BitRish.com.<br />Log in to your account.<br />Go to the "Wallet" tab and select "Deposit."<br />Choose the cryptocurrency you want to deposit.<br />BitRish.com will generate a unique deposit address.<br />Use the provided address or QR code to initiate the transfer from your external wallet or exchange.<br />Wait for confirmation, which may vary based on network congestion and the specific cryptocurrency.<br />How to Deposit Crypto?</p>
                                                        <p>Track your deposit on BitRish.com.<br />Conclusion:<br />Depositing crypto in BitRish.com is a straightforward process. By following these steps, you can quickly and securely transfer your assets, opening up opportunities for trading and utilizing the platform's services. Remember to verify transaction details to ensure a smooth deposit experience.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="faqItem">
                                            <a href="javascript:void(0)" data-target="#testingTwoModal">
                                                <i class="fas fa-file"></i> <span class="ml-2">How to Deposit Crypto Step-by-step Guide</span>
                                            </a>
                                            <div id="testingTwoModal" class="testingModal">
                                                <div class="testingModalInner">
                                                    <div class="testingModalHeader">
                                                        <h3></h3>
                                                        <a href="javascript:void(0)" class="faqCloseModal"><i class="fas fa-times fa-3x"></i></a>
                                                    </div>
                                                    <div class="testingModalInnerContent">
                                                        <p>1. Log in to your BitRish Website and tap [Deposit].</p>
                                                        <p>2. Tap [Deposit Crypto].</p>
                                                        <p>3. Choose the cryptocurrency you want to deposit, for example, USDT.</p>
                                                        <p>4. You will see the available network for depositing the asset. Please choose the deposit network carefully and make sure that the selected network is the same as the network of the platform you are withdrawing funds from. If you select the wrong network, your funds might be lost and couldn&rsquo;t be recovered.</p>
                                                        <p>Some examples of network selection:<br />BNB refers to the BNB Beacon Chain (BEP2);<br />BSC refers to the BNB Smart Chain (BEP20);<br />ETH refers to the Ethereum network (ERC20);<br />TRX refers to the TRON network (TRC20);<br />BTC refers to the Bitcoin network;<br />SegWitBTC refers to Native Segwit (bech32), and the address starts with &ldquo;bc1&rdquo;. You can withdraw or send your Bitcoin to SegWit (bech32) addresses. For more details, please refer to What Is Segregated Witness (SegWit).<br />For certain networks, such as BEP2 or EOS, you must fill in the Memo when making a transfer, or your address cannot be detected.</p>
                                                        <p>5. You will see a QR code and the deposit address. Please confirm the deposit network and contract information carefully before proceeding. Tap the information icon next to [Contract Information] to view details. The contract address of the asset you&rsquo;re depositing must be the same as the one shown here, or your assets might be lost.</p>
                                                        <p>6. You can deposit to either the Spot or Funding Wallet. Tap the information icon next to [Selected Wallet] - [Change Wallet] to select.</p>
                                                        <p>7. After confirming the network, contract information and wallet, tap the copy icon to copy and paste your BitRish Wallet&rsquo;s deposit address to the address field on the platform you intend to withdraw crypto from. You may also tap [Save as Image] and import the QR code on the withdrawing platform directly.<br />To share the deposit QR code and address, tap [Share Address].</p>
                                                        <p>8. After confirming the deposit request, the transfer will be processed. The funds will be credited to your BitRish account shortly after.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="faqItem">
                                            <a href="javascript:void(0)" data-target="#testingThreeModal">
                                                <i class="fas fa-file"></i> <span class="ml-2">Deposit hasn't arrived?</span>
                                            </a>
                                            <div id="testingThreeModal" class="testingModal">
                                                <div class="testingModalInner">
                                                    <div class="testingModalHeader">
                                                        <h3></h3>
                                                        <a href="javascript:void(0)" class="faqCloseModal"><i class="fas fa-times fa-3x"></i></a>
                                                    </div>
                                                    <div class="testingModalInnerContent">
                                                        <p>Sometimes, delays can occur when depositing crypto into your BitRish.com account. If your deposit hasn't arrived yet, follow these simple steps to resolve the issue:</p>
                                                        <p>Check the Blockchain Explorer:<br />Use a blockchain explorer to search for your transaction. Verify its status and confirmations on the blockchain network.</p>
                                                        <p>Wait for Confirmation:<br />Depending on network congestion and the specific cryptocurrency, confirmation times may vary. Patience is key, as the deposit might still be in progress.</p>
                                                        <p>Contact Support:<br />If the deposit hasn't arrived even after waiting for a reasonable amount of time, reach out to BitRish.com's customer support. They can investigate the issue and provide further assistance.</p>
                                                        <p>Conclusion:<br />Experiencing a delay in your deposit on BitRish.com can be concerning, but there are steps you can take to resolve the issue. By checking the blockchain explorer, waiting for confirmation, and contacting support if necessary, you can ensure that your deposit is addressed promptly and accurately.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="faqItem d-none">
                                            <a href="javascript:void(0)" data-target="#testingFourModal">
                                                <i class="fas fa-file"></i> <span class="ml-2">Deposit & Withdrawal Status query</span>
                                            </a>
                                            <div id="testingFourModal" class="testingModal">
                                                <div class="testingModalInner">
                                                    <div class="testingModalHeader">
                                                        <h3></h3>
                                                        <a href="javascript:void(0)" class="faqCloseModal"><i class="fas fa-times fa-3x"></i></a>
                                                    </div>
                                                    <div class="testingModalInnerContent">
                                                        <p>Sometimes, delays can occur when depositing crypto into your BitRish.com account. If your deposit hasn't arrived yet, follow these simple steps to resolve the issue:</p>
                                                        <p>Check the Blockchain Explorer:<br />Use a blockchain explorer to search for your transaction. Verify its status and confirmations on the blockchain network.</p>
                                                        <p>Wait for Confirmation:<br />Depending on network congestion and the specific cryptocurrency, confirmation times may vary. Patience is key, as the deposit might still be in progress.</p>
                                                        <p>Contact Support:<br />If the deposit hasn't arrived even after waiting for a reasonable amount of time, reach out to BitRish.com's customer support. They can investigate the issue and provide further assistance.</p>
                                                        <p>Conclusion:<br />Experiencing a delay in your deposit on BitRish.com can be concerning, but there are steps you can take to resolve the issue. By checking the blockchain explorer, waiting for confirmation, and contacting support if necessary, you can ensure that your deposit is addressed promptly and accurately.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="fundArea">
                                            <strong class="mt-5 d-block mb-4">Sponsored</strong>
                                            <a href="https://dantedoge.com/" target="_blank">
                                            <div class="fundItem">
                                                <img src="assets/images/dantedoge.png" />
                                                <div class="fundMessage">
                                                    <p class="m-0"><strong>DanteDoge</strong></p>
                                                    <p class="m-0">
                                                        DanteDoge is the no-bullshit meme coin you've been waiting for.
                                                    </p>
                                                </div>
                                                <i class="fas fa-chevron-right opacity-0"></i>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-5">
                                        <h2 class="d-flex align-items-baseline">Recent Deposits <a href="javascript:void(0)" class="textGray ml-3 customRefresh" @click="getDepositTransactionList(walletAdd, selectedCoinId)"><i class="fas fa-refresh"></i></a></h2>
                                        <div class="recentDepositsArea mt-4" v-for="(list, index) in depositList" :key="index">
<!--                                            <div class="recentDepositItem">
                                                <div class="recentDepositItemContent">
                                                    <div class="d-block">
                                                        <div class="coinName">
                                                            <img src="assets/img/logo.png" class="img-responsive"/>
                                                            <span>EQL</span>
                                                        </div>
                                                        <div class="d-inline-flex text-center">
                                                            <div class="customBadge customBadgeSuccess">Completed</div>
                                                        </div>
                                                    </div>
                                                    <div class="d-block">
                                                        <div class="commonBlock"><strong>2023-05-26 12:15</strong></div>
                                                        <div class="commonBlock d-md-inline-block d-none">Network
                                                            <strong>MATIC</strong></div>
                                                        <div class="commonBlock d-md-inline-block d-none">Address
                                                            <strong>0x2727d89a86dbeff12b85025b1e42177ebb12bfc3 <a
                                                                    href="javascript:void(0)"><i
                                                                    class="fas fa-link"></i></a> <a
                                                                    href="javascript:void(0)"><i
                                                                    class="fas fa-copy"></i></a></strong></div>
                                                        <div class="commonBlock d-md-inline-block d-none">TxID <strong>0x179a8...cb67a77
                                                            <a href="javascript:void(0)"><i class="fas fa-link"></i></a>
                                                            <a href="javascript:void(0)"><i
                                                                    class=" fas fa-copy"></i></a></strong></div>
                                                        <div class="commonBlock">Deposit wallet <strong>Spot
                                                            Wallet</strong></div>
                                                    </div>
                                                </div>
                                                <div class="recentDepositItemChevron">
                                                    <div class="chevronBg">
                                                        <i class="fas fa-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </div>-->
                                            <!-- Second Deposit -->
                                            <div class="recentDepositItem">
                                                <div class="recentDepositItemContent">
                                                    <div class="d-block">
                                                        <div class="coinName">
                                                            <img :src="list['coinIcon']" class="img-responsive" alt=""/>
                                                            <span>{{ list['coinName'] }}</span>
                                                        </div>
                                                        <div class="d-inline-flex text-center" v-if="list['status'].toString() === '0'">
                                                            <div class="customBadge customBadgeWarning">Pending</div>
                                                        </div>
                                                        <div class="d-inline-flex text-center" v-if="list['status'].toString() === '1'">
                                                            <div class="customBadge customBadgeSuccess">Complete</div>
                                                        </div>
                                                    </div>
                                                    <div class="d-block">
                                                        <div class="commonBlock"><strong>{{ list['new_date_time'] }}</strong></div>
                                                        <div class="commonBlock d-md-inline-block d-none">Network
                                                            <strong>{{ list['coinNetwork'] }}</strong></div>
<!--                                                        <div class="commonBlock d-md-inline-block d-none">Address
                                                            <strong>0x2727d89a86dbeff12b85025b1e42177ebb12bfc3
                                                                <a href="javascript:void(0)" class="">
                                                                    <i class=" fas fa-link"></i>
                                                                </a>
                                                                <a href="javascript:void(0)" class="">
                                                                    <i class=" fas fa-copy"></i>
                                                                </a>
                                                            </strong>
                                                        </div>-->
                                                        <div class="commonBlock d-md-inline-block d-none">TxID
                                                            <a :href="list['blockExplorerURL']" target="_blank">
                                                                <strong>{{ list['trxHash'] }}
                                                                    <a :href="list['blockExplorerURL']" target="_blank" class="">
                                                                        <i class="fas fa-link"></i>
                                                                    </a>
<!--                                                                    <a href="javascript:void(0)" class="">-->
<!--                                                                        <i class=" fas fa-copy"></i>-->
<!--                                                                    </a>-->
                                                                </strong>
                                                            </a>
                                                        </div>
                                                        <div class="commonBlock">Confirmation
                                                            <strong class="text-warning" v-if="parseInt(list['confirmations']['current'].toString()) < parseInt(list['confirmations']['total'].toString())">{{ list['confirmations']['current'] }}/{{ list['confirmations']['total'] }}
                                                                <a href="javascript:void(0)" class="text-warning" @click="getDepositTransactionList(walletAdd, selectedCoinId)">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M19.933 13.041a8 8 0 1 1-9.925-8.788c3.899-1 7.935 1.007 9.425 4.747"/><path d="M20 4v5h-5"/></g></svg>
                                                                </a>
                                                            </strong>
                                                            <strong class="textGreen" v-if="parseInt(list['confirmations']['current'].toString()) >= parseInt(list['confirmations']['total'].toString())">{{ list['confirmations']['current'] }}/{{ list['confirmations']['total'] }}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="recentDepositItemChevron">
                                                    <div class="chevronBg">
                                                        <i class="fas fa-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lightbox-deposit" v-if="!isKycA">
                                        <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                            <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                                <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                                <h1 class="text-warning m-0">Warning:</h1>
                                            </div>
                                            <span class="textInvert m-0 ml-2">Your KYC is still pending</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
  <!-- Dialog Simple datepicker -->
    <div tabindex="-1" class="modal fade" id="datepicker-dialog" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <!-- Inline popup datepicker start -->
                <div id="datepicker-popup-inline"></div>
                <!-- Inline popup datepicker end -->
                <div class="modal-footer">
                    <button type="button" class="btn pmd-ripple-effect btn-light" aria-hidden="true"
                            data-dismiss="modal">Cancel
                    </button>
                    <button type="button" class="btn pmd-ripple-effect btn-primary">Select</button>
                </div>
            </div>
        </div>
    </div>
  <!-- Dialog Simple datepicker -->
</template>

<script>
import {
    getAllCoinList, getCoinNetworkList, getTransactionListByCoinId,
    getWalletByCoinId, startDepositListenByCoinId,
} from "@/restApi/deposit_api";
import {mapActions, mapMutations, mapState} from "vuex";
import {CHECK_USER_INFO_ACTION, LOADING_STATE} from "@/util/store.constent";
import VueQrcode from 'vue-qrcode';

export default {
    name: "DepositPage",
    props: ['message'],
    components: {
        VueQrcode,
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.isLogin,
            isKycA: (state) => state.isKycApproved,
        }),
        ...mapActions({
            checkUser: CHECK_USER_INFO_ACTION,
        }),
    },
    mounted() {
        document.title = 'Profile - Bitrish';
    },
    data: function () {
        return {
            coinList: [],
            coinNetwork: [],
            depositList: [],
            walletAdd: '',
            walletExtra: '',
            selectedCoinId: '',
            selectedCoin: 'MATIC',
            selectedCoinIcon: '',
            refreshCount: 0,

            showAddressSection: false,
            canDeposit: false,
        };
    },
    async created() {
        if (!this.isLogin) {
            this.$router.replace('/signIn');
        }
        await this.checkUser;
        await this.getAllCoin();
    },
    methods: {
        ...mapMutations({
            showLoading: LOADING_STATE,
        }),

        async onGetNetWorkByCoinId(coinId, coinName, coinIcon, canDeposit) {
            this.coinNetwork = [];
            this.depositList = [];
            this.walletAdd = '';
            this.walletExtra = '';
            this.showAddressSection = false;
            this.selectedCoinId = coinId;
            this.selectedCoin = coinName;
            this.selectedCoinIcon = coinIcon;
            this.canDeposit = canDeposit;
            this.refreshCount = 0;
            const {res, err} = await getCoinNetworkList(coinId);
            if (err === null) {
                if (JSON.parse(res)['status'].toString().toLowerCase() === 'true') {
                    const data = JSON.parse(res)['data'];
                    for (let i = 0; i < data.length; i++) {
                        this.coinNetwork.push(data[i]);
                    }
                }
            }
        },

        async onSelectNetwork(coinId) {
            // console.log('Coin id ==', coinId);
            const {resData, error} = await getWalletByCoinId(coinId);
            if (error.value === null) {
                const result = JSON.parse(resData.value);
                if (result['status'].toString().toLowerCase() === 'true') {
                    this.showAddressSection = true;
                    this.walletAdd = result['data']['walletAddress'];
                    this.walletExtra = result['data']['extraData'];
                    await this.getDepositTransactionList(this.walletAdd, coinId);
                    await startDepositListenByCoinId(this.walletAdd, coinId, this.walletExtra);
                } else {
                    this.showAddressSection = false;
                }
            } else {
                this.showAddressSection = false;
            }
        },

        copyAdd() {
            const input = document.getElementById('walletAdd').textContent;
            const textBox = document.getElementById("commonText");
            textBox.value = input
            textBox.select();
            document.execCommand('copy');
        },

        copyExtra() {
            const input = document.getElementById('walletExtra').textContent;
            const textBox = document.getElementById("commonText");
            textBox.value = input
            textBox.select();
            document.execCommand('copy');
        },

        async getAllCoin() {
            const {coinData, error} = await getAllCoinList();
            if (error.value === null) {
                if (JSON.parse(coinData.value)['status'].toString().toLowerCase() === 'true') {
                    const cData = JSON.parse(coinData.value)['data'];
                    if (cData.length > 0) {
                        for (let i = 0; i < cData.length; i++) {
                            this.coinList.push(cData[i]);
                        }
                    }
                }
            }
        },

        async getDepositTransactionList(walletAdd, coinId) {
            // console.log('Refresh Count  === ', this.refreshCount);
            if(this.refreshCount >= 5) {
                this.refreshCount = 0;
                // console.log('Refresh Count reset === ', this.refreshCount);
                await startDepositListenByCoinId(walletAdd, coinId, this.walletExtra);
                // console.log('Refresh Count reset === startDepositListenByCoinId');
            } else {
                this.refreshCount = this.refreshCount + 1;
                // console.log('Refresh Count +1  === ', this.refreshCount);
            }
            const {transactionData, error} = await getTransactionListByCoinId(walletAdd, coinId);
            // console.log('Deposit List == ', transactionData.value, error.value);
            if (error.value === null) {
                try {
                    const result = JSON.parse(transactionData.value);
                    if (result['status'].toString().toLowerCase() === 'true') {
                        const tData = result['data'];
                        if (tData.length > 0) {
                            this.depositList =[];
                            for (let i = 0; i < tData.length; i++) {
                                const mxx = tData[i];
                                const dateString = parseInt(mxx['dateTime'].toString()) * 1000;
                                const d = new Date(dateString);
                                const da = d.getDate().toString().length > 1 ? d.getDate() : '0' + d.getDate();
                                const mo = (d.getMonth() + 1).toString().length > 1 ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1);
                                const y = d.getFullYear().toString().length > 1 ? d.getFullYear() : '0' + d.getFullYear();
                                const h = d.getHours().toString().length > 1 ? d.getHours() : '0' + d.getHours();
                                const m = d.getMinutes().toString().length > 1 ? d.getMinutes() : '0' + d.getMinutes();
                                const s = d.getSeconds().toString().length > 1 ? d.getSeconds() : '0' + d.getSeconds();
                                mxx['new_date_time'] = (da + '/' + mo + '/' + y + ' ' + h + ':' + m + ':' + s);
                                this.depositList.push(mxx);
                                // console.log('Deposit List == ', this.depositList);
                            }
                        }
                    }
                } catch (e) {
                    console.log('Deposit List Error == ',e);
                }
            }
        },
    },
}
</script>
