import Validations from "@/services/Validations";
import BigNumber from "bignumber.js";

export default class WithdrawreqValidation {
    constructor(address, amount, bal, isKycApprove) {
        this.address = address;
        this.amount = amount;
        this.bal = bal;
        this.isKycApprove = isKycApprove;
    }

    checkValidation () {
        let error = '';

        if(!this.isKycApprove) {
            error = 'You cannot withdraw until you have completed your KYC';
        } else if(!Validations.checkWalletAddress(this.address)){
            error = 'Please Enter Valid Address';
        } else if(this.amount === '' || new BigNumber(this.amount).isLessThanOrEqualTo(new BigNumber('0'))) {
            error = 'Please Enter Valid Amount';
        } else if(new BigNumber(this.bal.toString()).isLessThan(new BigNumber(this.amount.toString()))) {
            error = 'You Don\'t Have Sufficient Balance';
        }

        return error;
    }

}
