import Validations from './Validations';

export default class SignUpValidation {
    constructor(phone, email, password, rePassword) {
        this.phone = phone;
        this.email = email;
        this.password = password;
        this.rePassword = rePassword;
    }

    checkValidations() {
        let errors = '';

        if (!Validations.checkPhone(this.phone)) {
            errors = 'Please Enter Valid Phone Number';
        } else if(!Validations.checkEmail(this.email)) {
            errors = 'Please Enter Valid Email';
        } else if(!Validations.checkPassword(this.password)) {
            errors = 'Please Enter Valid Password';
        } else if(this.password !== this.rePassword) {
            errors = 'Confirm Password Not Match With Password';
        }

        return errors;
    }

    checkPhone() {
        let errors = '';

        if (!Validations.checkPhone(this.phone)) {
            errors = 'Please Enter Valid Phone Number';
        }

        return errors;
    }

    checkEmail() {
        let errors = '';

        if (!Validations.checkEmail(this.email)) {
            errors = 'Please Enter Valid Email';
        }

        return errors;
    }
}
