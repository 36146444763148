import axios from "axios";
import {ref} from "vue";
import {log, MsgType} from "@/util/constant";

async function getAllCoinList() {
    const coinData = ref(null)
    const error = ref(null)
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'deposit/getCoinList',
        headers: { }
    };

    try {
        const response = await axios.request(config);
        coinData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {coinData, error}
}

async function getWalletByCoinId(coinId) {
    const resData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "coinID": coinId
    });
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'deposit/getCoinWallet',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('app_token')
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        resData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {resData, error}
}
async function startDepositListenByCoinId(walletAdd, coinId, extraData) {
    const resData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "walletAddress": walletAdd,
        "coinID": coinId,
        "extraData": extraData,
    });
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'deposit/startDepositListen',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('app_token')
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        resData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {resData, error}
}
// async function stopDepositListenByCoinId(walletAdd, coinId) {
//     const resData = ref(null)
//     const error = ref(null)
//     let data = JSON.stringify({
//         "walletAddress": walletAdd,
//         "coinID": coinId
//     });
//     let config = {
//         method: 'post',
//         maxBodyLength: Infinity,
//         url: process.env.VUE_APP_DOMAIN + 'deposit/stopDepositListen',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': 'Bearer '+localStorage.getItem('app_token')
//         },
//         data : data
//     };
//
//     try {
//         const response = await axios.request(config);
//         resData.value = JSON.stringify(response.data);
//     } catch (e) {
//         log(e, MsgType.error);
//         error.value = "Error";
//     }
//
//     return {resData, error}
// }

async function getTransactionListByCoinId(walletAdd, coinId) {
    const transactionData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "walletAddress": walletAdd,
        "coinID": coinId
    });
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'deposit/getUserCoinTransactionList',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('app_token')
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        transactionData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = "Error";
    }

    return {transactionData, error}
}

async function getBalByCoinId(coinId) {
    const res = ref(null)
    const err = ref(null)
    let data = JSON.stringify({
        "coinID": coinId
    });
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'deposit/getCoinBalance',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('app_token')
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        res.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        err.value = "Error";
    }

    return {res, err}
}

async function getCoinNetworkList(coinId) {
    let res = null
    let err = null
    let data = JSON.stringify({
        "cID": coinId
    });
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'deposit/getCoinNetworkList',
        headers: {
            'Content-Type': 'application/json',
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        res = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        err = "Error";
    }

    return {res, err}
}

export { getAllCoinList, getWalletByCoinId, startDepositListenByCoinId, getTransactionListByCoinId, getBalByCoinId, getCoinNetworkList }
