import axios from "axios";
import {ref} from "vue";
import {checkToken, log, MsgType} from "@/util/constant";

async function getPairList(cID) {
    const urlPrams = cID === 0 ? '' : '?cID='+cID;
    const pairData = ref(null)
    const error = ref(null)
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'trade/getPairsList'+urlPrams,
        headers: { },
    };

    try {
        const response = await axios.request(config);
        pairData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error)
        error.value = "Error";
    }

    return {pairData, error}
}

async function getPairTitleList() {
    const pairTData = ref(null)
    const error = ref(null)
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'trade/getPairsTitleList',
        headers: { }
    };

    try {
       const response =  await axios.request(config);
       log(response, MsgType.log);
       pairTData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error)
        error.value = "Error";
    }

    return {pairTData, error}
}

async function createSpotOrder(pairId, price, amount, orderType, marketType) {
    const pairData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "pairID": pairId,
        "amount": amount,
        "price": price,
        "orderType": orderType,
        "marketType": marketType
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'trade/createOrder',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('app_token')
        },
        data : data
    };

    await axios.request(config)
        .then((response) => {
            log("Create order Res == "+JSON.stringify(response.data), MsgType.log)
            pairData.value = JSON.stringify(response.data);
        })
        .catch((error) => {
            log("Create order err == "+error, MsgType.error)
            checkToken(error.response.status);
            error.value = "Error";
        });

    return {pairData, error}
}

async function getAllOrderList(type, pId) {
    const orderData = ref(null)
    const err = ref(null)
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'trade/listOrders?type='+type+'&pID='+pId,
        headers: {
            'Authorization': 'Bearer '+localStorage.getItem('app_token'),
        },
    };

    try {
        const response = await axios.request(config);
        orderData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        err.value = "Error";
    }

    return {orderData, err}
}


async function cancelOrderById(orderId) {
    const orderData = ref(null)
    const err = ref(null)
    let data = JSON.stringify({
        "orderID": orderId
    });
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'trade/cancelOrder',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('app_token'),
        },
        data : data
    };


    try {
        const response = await axios.request(config);
        log(JSON.stringify(response.data), MsgType.log)
        orderData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        err.value = "Error";
    }

    return {orderData, err}
}

export { getPairList, createSpotOrder, getAllOrderList, getPairTitleList, cancelOrderById }
