<template>
    <div class="customNoti">
        <div class="customNotiArea" v-for="(noti, index) in nList" :key="index">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1kazfoq"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 21a9 9 0 100-18 9 9 0 000 18zm-1.25-5.5V18h2.5v-2.5h-2.5zm0-9.5v7h2.5V6h-2.5z" fill="currentColor"></path></svg>
            <div data-bn-type="text" class="d-inline-block notiText">{{ noti['nTitle'] }}
                <div class="d-inline-block">
                    <router-link :to="noti['nRoute']" >{{ noti['nAction'] === '' ? '' : ' - &gt; ' +noti['nAction']  }} </router-link>
                </div>
            </div>
        </div>
        <div class="closeNoti d-inline-block" name="Testing" @click="removeNotification(nList[(nList.length)-1]['nId'])">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-tdpz7s"><path d="M6.697 4.575L4.575 6.697 9.88 12l-5.304 5.303 2.122 2.122L12 14.12l5.303 5.304 2.122-2.122L14.12 12l5.304-5.303-2.122-2.122L12 9.88 6.697 4.575z" fill="currentColor"></path></svg>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {REMOVE_SELECTED_NOTIFICATION_MUTATION, SET_NOTIFICATION_STATE_MUTATION} from "@/util/store.constent";

export default {
    data() {
        return {
            currentNews: 1,
            showMessage: "",
            setInt: '',
            setHeight:'',
        }
    },
    name: "AppNotification",
    computed: {
        ...mapState({
            nList: (state) => state.notificationList,
            showNotification: (state) => state.showNotification,
            hidePer: (state) => state.hidePermanentNotification,
        }),
    },created() {
        this.flipNotification()
    },
    methods: {
        ...mapMutations({
            removeN: REMOVE_SELECTED_NOTIFICATION_MUTATION,
            hideNoti: SET_NOTIFICATION_STATE_MUTATION
        }),
        removeNotification() {
            this.hideNoti(false);
            clearInterval(this.setInt);
        },flipNotification(){
            //this.showMessage = this.nList[0]['nTitle'];
            if(!this.hidePer) {
                if(this.showNotification){
                    this.setInt = setInterval(() => {
                        var noties = document.querySelectorAll('.customNotiArea');
                        for(const noti of noties){
                            noti.classList.remove("active");
                        }
                        var list = document.querySelectorAll('.customNotiArea');
                        //this.removeActive();
                        if(document.querySelector('.customNoti .customNotiArea:nth-child('+this.currentNews+')')) {
                            document.querySelector('.customNoti .customNotiArea:nth-child('+this.currentNews+')').classList.add("active");
                            setTimeout(function(){
                                this.setHeight = document.querySelector('.customNoti .customNotiArea.active').offsetHeight+2;
                                if((document.querySelector('.customNoti').offsetHeight) < this.setHeight){
                                    document.querySelector('.customNoti').style.height = (this.setHeight-1)+'px';
                                }
                                console.log("Height: ", this.setHeight);
                            },350);
                        }
                        if(this.currentNews >= list.length){
                            this.currentNews = 1;
                        }else{
                            this.currentNews++;
                        }
                    }, 3000);
                }
            }
            // if(this.showNotification){
            //     this.setInt = setInterval(() => {
            //         var list = document.querySelectorAll('.customNotiArea');
            //         this.removeActive();
            //         document.querySelector('.customNoti .customNotiArea:nth-child('+this.currentNews+')').classList.add("active");
            //         if(this.currentNews >= list.length){
            //             this.currentNews = 1;
            //         }else{
            //             this.currentNews++;
            //         }
            //         // //var noti = document.querySelector('.notiText');
            //         // if(this.currentNews <= list.length){
            //         //     document.querySelector('.customNoti :nth-child('+this.currentNews+')').classList.add("active");
            //         //     //this.showMessage = this.nList[this.currentNews]['nTitle'];
            //         //     //noti.innerHTML = this.nList[this.currentNews]['nTitle'];
            //         //     this.currentNews++;
            //         //     console.log("News: ", this.currentNews);
            //         // }else{
            //         //     this.currentNews = 1;
            //         //     console.log("ELSE News: ", this.currentNews);
            //         //     document.querySelector('.customNoti :nth-child('+this.currentNews+')').classList.add("active");
            //         //     // this.showMessage = this.nList[this.currentNews]['nTitle'];
            //         //     //noti.innerHTML = this.nList[this.currentNews]['nTitle'];
            //         //     console.log("News: ", this.currentNews);
            //         // }
            //     }, 3000);
            // }
        },removeActive(){
            // var noties = document.querySelectorAll('.customNotiArea');
            // for(const noti of noties){
            //     noti.classList.remove("active");
            // }
        }
    }
}
</script>

<style scoped>

</style>
