import {ref} from "vue";
import axios from "axios";
import {log, MsgType} from "@/util/constant";

async function withdrawRequest(address, coinId, amount) {
    const res = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "address": address,
        "coinID": coinId,
        "amount": amount
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'withdraw/requestWithdraw',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('app_token')
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        log(response, MsgType.log);
        res.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {res, error};
}

async function resendOtpReq(withdrawId, type) {
    const resendOTPRes = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "withdrawID": withdrawId,
        "type": type
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'withdraw/requestNewOTP',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('app_token')
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        log(response, MsgType.log);
        resendOTPRes.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {resendOTPRes, error};
}

async function cancelWithdraw(withdrawId) {
    const cancelRes = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "withdrawID": withdrawId,
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'withdraw/cancelWithdrawal',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('app_token')
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        log(response, MsgType.log);
        cancelRes.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {cancelRes, error};
}

async function verifyWithdrawOtp(withdrawId, /*phoneOTP, */emailOTP) {
    const verifyRes = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "withdrawID": withdrawId,
        "emailOTP": emailOTP,
        // "phoneOTP": phoneOTP
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'withdraw/verifyWithdrawalRequest',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('app_token')
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        log(response, MsgType.log);
        verifyRes.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {verifyRes, error};
}

async function getWithdrawList(coinId) {
    const withdrawRes = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "coinID": coinId
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'withdraw/getWithdrawalList',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('app_token')
        },
        data : data
    };

    try {
        const response = await axios.request(config);
        log(response, MsgType.log);
        withdrawRes.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {withdrawRes, error};
}


export { withdrawRequest, resendOtpReq, cancelWithdraw, verifyWithdrawOtp, getWithdrawList }

