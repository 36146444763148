<template>
    <div class="loading-box">
        <div class="lds-roller">
            <!-- <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div> -->
            <svg>
                <g>
                    <path d="M 50,100 A 1,1 0 0 1 50,0"/>
                </g>
                <g>
                    <path d="M 50,75 A 1,1 0 0 0 50,-25"/>
                </g>
                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style="stop-color:#7008D1;stop-opacity:1" />
                    <stop offset="100%" style="stop-color:#D822CF;stop-opacity:1" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        text: {
            required: false,
            type: String,
            default: 'Loading',
        },
    },
};
</script>

<style scoped>
/* SVG Loading */
.loading-box svg {
    overflow: visible;
    width: 100px;
    height: 150px;
  }
  .lds-roller{overflow:hidden;width:150px;height:200px;display: flex;justify-content: center;align-items: end;overflow: hidden;}
.loading-box svg g {
    animation: slide 2s linear infinite;
  }
.loading-box svg g:nth-child(2) {
    animation-delay: 0.5s;
  }
.loading-box svg g:nth-child(2) path {
    animation-delay: 0.5s;
    stroke-dasharray: 0px 158px;
    stroke-dashoffset: 1px;
  }
.loading-box svg path {
    stroke: url(#gradient);
    stroke-width: 10px;
    stroke-linecap: round;
    fill: none;
    stroke-dasharray: 0 157px;
    stroke-dashoffset: 0;
    animation: escalade 2s cubic-bezier(0.8, 0, 0.2, 1) infinite;
}

@keyframes slide {
    0% {
        transform: translateY(-50px);
    }
    100% {
        transform: translateY(50px);
    }
}
@keyframes escalade {
    0% {
        stroke-dasharray: 0 157px;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 157px 157px;
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dasharray: 157px 157px;
        stroke-dashoffset: -156px;
    }
}
.loading-box{background:rgba(0, 0, 0, 0.6);width:100%;height:100%;position:fixed;top:0;left:0;display: flex;justify-content: center;align-items: center;z-index:99999999999;}
/* SVG Loading */
</style>
