<template>
    <app-loader v-if="showLoading"></app-loader>
    <app-header></app-header>
    <app-notification v-if="showNotification"></app-notification>
    <router-view></router-view>
</template>

<script>

import AppLoader from "@/components/parcel/AppLoader.vue";
import {mapActions, mapMutations, mapState} from "vuex";
import AppHeader from "@/components/parcel/AppHeader.vue";
import AppNotification from "@/components/parcel/AppNotification.vue";
import {
    GET_All_FIAT_CURRENCY_ACTION,
    GET_All_NOTIFICATION_ACTION, LOADING_STATE,
} from "@/util/store.constent";
import BigNumber from "bignumber.js";

export default {
    name: 'App',
    components: {AppHeader, AppLoader, AppNotification},
    mounted() {
        document.title = 'BitRish - Cryptocurrency Exchange for MATIC, EQL & Altcoins';
        BigNumber.config({DECIMAL_PLACES: 30})
    },
    computed: {
        ...mapState({
            showLoading: (state) => state.showLoading,
            showNotification: (state) => state.showNotification,
            theme: (state) => state.theme
        }),
    },
    created() {
        document.body.id = this.theme;
        this.hideLoading();
        this.getAllCurrency();
        this.getAllNotification();
    },
    methods: {
        ...mapActions({
            getAllCurrency: GET_All_FIAT_CURRENCY_ACTION,
            getAllNotification: GET_All_NOTIFICATION_ACTION
        }),
        ...mapMutations({
            showLoadingState: LOADING_STATE,
        }),
        hideLoading(){
            this.showLoadingState(false);
        }
    }
}
</script>
