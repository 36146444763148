import { createApp } from 'vue'
import App from './App.vue'
import router from "@/route/routes";
import store from "@/store/store";
import i18n from "@/util/languages";
import VueGtag from  "vue-gtag";
import {vueCountryRegionSelect} from 'vue3-ts-country-region-select';

createApp(App).use(router).use(store).use(i18n).use(vueCountryRegionSelect).use(VueGtag, {
    config: { id: 'G-GLB52FNLQF'},
    router,
    enabled: true,
}).mount('#app')
