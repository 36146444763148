<template>
    <div class="row m-0 p-0 aboutPage textInvert bannerSection">
        <div class="col-md-12 my-5">
            <div class="container">
                <div class="row mx-0 my-5" id="aboutBG">
                    <div class="col-md-6 text-right align-items-center order-sm-2">
                        <img v-if="theme === ''"  src="@/assets/logo-dark.png" class="imgResponsive" alt="">
                        <img v-else src="@/assets/logo-light.png" class="imgResponsive" alt="">
                    </div>
                    <div class="col-md-6 order-sm-1">
                        <h1>Welcome to BitRish</h1>
                        <p>

                            Welcome to BitRish, your premier destination for cryptocurrency trading, and an integral part of the digital currency revolution. At BitRish, we believe in the transformative power of cryptocurrency. Our exchange is designed to be a robust platform for trading, providing access to an extensive suite of financial products and services that revolve around crypto assets. We offer a secure, reliable, and efficient platform that caters to both novice and professional traders alike.

                        </p>
                    </div>
                </div>
                <div class="row m-md-0">
                    <div class="col-md-4">
                        <div class="d-flex justify-content-start align-items-center w-100 bg-dark p-4 br12 mb-4">
                            <div class="iconImg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path fill="currentColor" d="M121.72 32a4 4 0 0 0-3.72 5.56l2.3 5.43l40.7 94.9a4 4 0 0 0 6.88.82L243 38.4a4 4 0 0 0-3.2-6.4Zm298.21 26.06l-41.28 96.37a4 4 0 0 0 3.68 5.57h101a4 4 0 0 0 3.4-6.11L427 57.53a4 4 0 0 0-7.07.53ZM85 57.57l-59.71 96.32a4 4 0 0 0 3.4 6.11h101a4 4 0 0 0 3.67-5.58L92 58.1a4 4 0 0 0-7-.53ZM393.27 32H267.82a1.94 1.94 0 0 0-1.56 3.11l79.92 106.46a1.94 1.94 0 0 0 3.34-.4L391.6 43l3.4-8.34a1.92 1.92 0 0 0-1.7-2.66ZM239 448l-89.43-253.49A3.78 3.78 0 0 0 146 192H25.7a3.72 3.72 0 0 0-2.95 6l216 279.81a5.06 5.06 0 0 0 6.39 1.37a5 5 0 0 0 2.39-6.08Zm247.3-256H366a3.75 3.75 0 0 0-3.54 2.51l-98.2 278.16a5.21 5.21 0 0 0 2.42 6.31a5.22 5.22 0 0 0 6.61-1.39L489.25 198a3.72 3.72 0 0 0-2.95-6ZM259.2 78.93l56 74.67a4 4 0 0 1-3.2 6.4H200a4 4 0 0 1-3.2-6.4l56-74.67a4 4 0 0 1 6.4 0Zm-7 310.31l-67.7-191.91a4 4 0 0 1 3.77-5.33h135.46a4 4 0 0 1 3.77 5.33l-67.73 191.91a4 4 0 0 1-7.54 0Z"/></svg>
                            </div>
                            <div class="d-flex justify-content-start flex-column">
                                <h3>$5.5 BN</h3>
                                <p><strong>Average daily volume</strong></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-start align-items-center w-100 bg-dark p-4 br12 mb-4">
                            <div class="iconImg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 144h112v112"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="m48 368l121.37-121.37a32 32 0 0 1 45.26 0l50.74 50.74a32 32 0 0 0 45.26 0L448 160"/></svg>
                            </div>
                            <div class="d-flex justify-content-start flex-column">
                                <h3>98 BN</h3>
                                <p><strong>Spot transactions</strong></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-start align-items-center w-100 bg-dark p-4 br12 mb-4">
                            <div class="iconImg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><rect width="256" height="480" x="128" y="16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" rx="48" ry="48"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M176 16h24a8 8 0 0 1 8 8h0a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16h0a8 8 0 0 1 8-8h24"/></svg>
                            </div>
                            <div class="d-flex justify-content-start flex-column">
                                <h3>24/7</h3>
                                <p><strong>Customer Support</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container aboutPage textInvert">
        <div class="row my-5 bannerSection">
            <div class="col-md-6">
                <div class="w-100 bg-dark p-5 br12 mb-4">
                    <h2>Our Mission</h2>
                    <p>
                        At BitRish, our mission is to democratize access to digital currencies and foster financial inclusion. We aspire to bring the revolutionary benefits of blockchain technology to individuals and businesses worldwide, regardless of their location or financial background. We are committed to creating a robust, transparent, and secure platform that simplifies the trading of cryptocurrencies, empowering our users to harness the potential of digital assets. Our ultimate aim is to play a pivotal role in driving the global adoption of cryptocurrencies, transforming the financial landscape, and shaping the future of the global economy.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="w-100 bg-dark p-5 br12 mb-4">
                    <h2>Our Vision</h2>
                    <p>
                        Our vision at BitRish is to be a driving force in the global acceptance of cryptocurrencies and blockchain technology. We aim to become the world's leading cryptocurrency exchange platform, setting new standards for security, transparency, and user experience. We envisage a future where digital assets are an integral part of everyday financial transactions, providing a more democratic, decentralized, and efficient alternative to traditional financial systems. As we continue to innovate and grow, our goal is to ensure that every individual across the globe can freely and seamlessly interact with digital economies, thus catalyzing a more equitable and inclusive financial future.
                    </p>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-md-12 aboutEcoSystem">
                <h2>Our Ecosystem</h2>
                <p>
                    At BitRish, our ecosystem is a dynamic convergence of advanced technology, diverse digital assets, and a global community of users. Leveraging cutting-edge blockchain technology, we have created a platform that facilitates seamless trading of a wide range of cryptocurrencies. Our ecosystem extends beyond simple transactions to include features like futures trading, lending, and staking services. The BitRish community, consisting of both novice and professional traders, forms the backbone of our ecosystem, contributing to the vibrant market dynamics and collaborative learning. In essence, our ecosystem is designed to provide a comprehensive, secure, and user-friendly experience that encourages participation in the future of finance - the world of cryptocurrencies.
                </p>
                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex justify-content-start flex-column w-100 bg-dark p-5 br12 mb-4">
                            <div class="iconImg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="m304 48l112 112l-112 112m94.87-112H96m112 304L96 352l112-112m-94 112h302"/></svg>
                            </div>
                            <h3>BitRish Exchange</h3>
                            <div><p>BitRish Exchange - Shaping the Role of Cryptocurrency in Tomorrow's World.</p></div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="d-flex justify-content-start flex-column w-100 bg-dark p-5 br12 mb-4">
                            <div class="iconImg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path fill="currentColor" d="M432 176H320V64a48 48 0 0 0-48-48H80a48 48 0 0 0-48 48v416a16 16 0 0 0 16 16h104a8 8 0 0 0 8-8v-71.55c0-8.61 6.62-16 15.23-16.43A16 16 0 0 1 192 416v72a8 8 0 0 0 8 8h264a16 16 0 0 0 16-16V224a48 48 0 0 0-48-48ZM98.08 431.87a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm0-80a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm0-80a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm0-80a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm0-80a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm80 240a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm0-80a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm0-80a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm0-80a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm80 320a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm0-80a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm0-80a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm0-80a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79Zm0-80a16 16 0 1 1 13.79-13.79a16 16 0 0 1-13.79 13.79ZM444 464H320V208h112a16 16 0 0 1 16 16v236a4 4 0 0 1-4 4Z"/><path fill="currentColor" d="M400 400a16 16 0 1 0 16 16a16 16 0 0 0-16-16Zm0-80a16 16 0 1 0 16 16a16 16 0 0 0-16-16Zm0-80a16 16 0 1 0 16 16a16 16 0 0 0-16-16Zm-64 160a16 16 0 1 0 16 16a16 16 0 0 0-16-16Zm0-80a16 16 0 1 0 16 16a16 16 0 0 0-16-16Zm0-80a16 16 0 1 0 16 16a16 16 0 0 0-16-16Z"/></svg>
                            </div>
                            <h3>BitRish Labs</h3>
                            <div><p>BitRish Labs: Pioneering blockchain breakthroughs to redefine the future of cryptocurrency trading.</p></div>
                        </div>
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="d-flex justify-content-start flex-column w-100 bg-dark p-5 br12 mb-4">
                            <div class="iconImg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path fill="currentColor" d="M5.8 278a2.11 2.11 0 0 0-2 2L0 308.64l3.74 28.16a2.12 2.12 0 0 0 2.05 2a2.14 2.14 0 0 0 2-2l4.44-28.17L7.83 280a2.14 2.14 0 0 0-2-2Zm21.05-15.68a2.13 2.13 0 0 0-4.26 0l-5 46.32l5 45.3a2.13 2.13 0 0 0 4.26 0l5.73-45.31l-5.73-46.32Zm79.32-42.73a4 4 0 0 0-3.87 3.87l-4 85.22l4 55.08a3.88 3.88 0 0 0 7.75 0l4.53-55.08l-4.53-85.22a4 4 0 0 0-3.88-3.87Zm-41.05 29.62a3.09 3.09 0 0 0-3 3l-4.52 56.45l4.51 54.63a3 3 0 0 0 6 0l5.13-54.63l-5.13-56.48a3.1 3.1 0 0 0-2.99-2.97Zm82.76 118.39a4.83 4.83 0 0 0 4.75-4.74l3.93-54.15l-3.93-113.46a4.75 4.75 0 0 0-9.5 0l-3.49 113.45l3.49 54.17a4.81 4.81 0 0 0 4.75 4.73Zm85.4.25a6.6 6.6 0 0 0 6.5-6.52l2.74-52.6l-2.74-131a6.5 6.5 0 1 0-13 0l-2.45 131c0 .08 2.45 52.67 2.45 52.67a6.59 6.59 0 0 0 6.5 6.45Zm-43.02-.2a5.67 5.67 0 0 0 5.62-5.64l3.34-53.33l-3.34-114.28a5.63 5.63 0 1 0-11.25 0l-3 114.29l3 53.32a5.66 5.66 0 0 0 5.63 5.6Zm-104.7-.5a3.53 3.53 0 0 0 3.44-3.41l4.83-55.09l-4.83-52.4a3.44 3.44 0 0 0-6.88 0l-4.26 52.38l4.26 55.08a3.5 3.5 0 0 0 3.44 3.44Zm-40.72-3.02a2.67 2.67 0 0 0 2.57-2.52l5.43-53l-5.42-55a2.57 2.57 0 0 0-5.14 0l-4.78 55l4.78 53a2.62 2.62 0 0 0 2.56 2.53Zm166.85-171.6a6.1 6.1 0 0 0-6.07 6.09l-2.71 110.11l2.71 53a6.07 6.07 0 0 0 12.13 0l3-53l-3-110.13a6.1 6.1 0 0 0-6.06-6.07ZM127 367.71a4.41 4.41 0 0 0 4.31-4.3l4.23-54.71l-4.28-104.7a4.32 4.32 0 0 0-8.63 0l-3.74 104.7l3.75 54.73a4.38 4.38 0 0 0 4.36 4.28Zm47.17-5.17l3.63-53.8l-3.63-117.28a5.19 5.19 0 1 0-10.37 0l-3.23 117.28l3.23 53.83a5.18 5.18 0 0 0 10.36 0ZM449 241.1a62.42 62.42 0 0 0-24.33 4.9c-5-57.18-52.61-102-110.66-102a111.92 111.92 0 0 0-40.28 7.58c-4.75 1.85-6 3.76-6.06 7.46V360.4a7.66 7.66 0 0 0 6.8 7.5l174.56.11c34.78 0 63-28.41 63-63.45s-28.2-63.46-63-63.46Zm-194.21-82.23a7 7 0 0 0-6.94 7L245 308.75l2.85 51.87a6.94 6.94 0 1 0 13.87-.06v.06l3.09-51.87l-3.09-142.93a7 7 0 0 0-6.93-6.95Z"/></svg>
                            </div>
                            <h3>BitRish Research</h3>
                            <div><p>BitRish Research provides institutional-grade analysis, in-depth insights, and unbiased information to all participants in the digital asset industry.</p></div>
                        </div>
                    </div> -->
                    <div class="col-md-4">
                        <div class="d-flex justify-content-start flex-column w-100 bg-dark p-5 br12 mb-4">
                            <div class="iconImg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path fill="currentColor" d="M256 368a16 16 0 0 1-7.94-2.11L108 285.84a8 8 0 0 0-12 6.94V368a16 16 0 0 0 8.23 14l144 80a16 16 0 0 0 15.54 0l144-80a16 16 0 0 0 8.23-14v-75.22a8 8 0 0 0-12-6.94l-140.06 80.05A16 16 0 0 1 256 368Z"/><path fill="currentColor" d="M495.92 190.5v-.11a16 16 0 0 0-8-12.28l-224-128a16 16 0 0 0-15.88 0l-224 128a16 16 0 0 0 0 27.78l224 128a16 16 0 0 0 15.88 0L461 221.28a2 2 0 0 1 3 1.74v144.53c0 8.61 6.62 16 15.23 16.43A16 16 0 0 0 496 368V192a14.76 14.76 0 0 0-.08-1.5Z"/></svg>
                            </div>
                            <div class="d-inline-flex justify-content-start align-items-center">
                                <h3>BitRish Academy</h3><small class="textPrimary ml-3">Coming Soon</small>
                            </div>
                            <div><p>BitRish Academy: Empowering users with knowledge for successful navigation in the world of cryptocurrencies.</p></div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-start flex-column w-100 bg-dark p-5 br12 mb-4">
                            <div class="iconImg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path fill="currentColor" d="M256 112a56 56 0 1 1 56-56a56.06 56.06 0 0 1-56 56Z"/><path fill="currentColor" d="m432 112.8l-.45.12l-.42.13c-1 .28-2 .58-3 .89c-18.61 5.46-108.93 30.92-172.56 30.92c-59.13 0-141.28-22-167.56-29.47a73.79 73.79 0 0 0-8-2.58c-19-5-32 14.3-32 31.94c0 17.47 15.7 25.79 31.55 31.76v.28l95.22 29.74c9.73 3.73 12.33 7.54 13.6 10.84c4.13 10.59.83 31.56-.34 38.88l-5.8 45l-32.19 176.19q-.15.72-.27 1.47l-.23 1.27c-2.32 16.15 9.54 31.82 32 31.82c19.6 0 28.25-13.53 32-31.94s28-157.57 42-157.57s42.84 157.57 42.84 157.57c3.75 18.41 12.4 31.94 32 31.94c22.52 0 34.38-15.74 32-31.94a57.17 57.17 0 0 0-.76-4.06L329 301.27l-5.79-45c-4.19-26.21-.82-34.87.32-36.9a1.09 1.09 0 0 0 .08-.15c1.08-2 6-6.48 17.48-10.79l89.28-31.21a16.9 16.9 0 0 0 1.62-.52c16-6 32-14.3 32-31.93S451 107.81 432 112.8Z"/></svg>
                            </div>
                            <h3>BitRish Charity</h3>
                            <div><p>BitRish Charity: Utilizing blockchain for social good and fostering a more equitable world.</p></div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-start flex-column w-100 bg-dark p-5 br12 mb-4">
                            <div class="iconImg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path fill="currentColor" d="M256 56C145.72 56 56 145.72 56 256s89.72 200 200 200s200-89.72 200-200S366.28 56 256 56Zm0 82a26 26 0 1 1-26 26a26 26 0 0 1 26-26Zm48 226h-88a16 16 0 0 1 0-32h28v-88h-16a16 16 0 0 1 0-32h32a16 16 0 0 1 16 16v104h28a16 16 0 0 1 0 32Z"/></svg>
                            </div>
                            <div class="d-inline-flex justify-content-start align-items-center">
                                <h3>BitRish NFT</h3><small class="textPrimary ml-3">Coming Soon</small>
                            </div>
                            <div><p>BitRish NFT Marketplace: A vibrant platform for trade and discovering unique digital assets and collectibles.</p></div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex justify-content-start flex-column w-100 bg-dark p-5 br12 mb-4">
                            <div class="iconImg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path fill="currentColor" d="M439.91 112h-23.82a.09.09 0 0 0-.09.09V416a32 32 0 0 0 32 32a32 32 0 0 0 32-32V152.09A40.09 40.09 0 0 0 439.91 112Z"/><path fill="currentColor" d="M384 416V72a40 40 0 0 0-40-40H72a40 40 0 0 0-40 40v352a56 56 0 0 0 56 56h342.85a1.14 1.14 0 0 0 1.15-1.15a1.14 1.14 0 0 0-.85-1.1A64.11 64.11 0 0 1 384 416ZM96 128a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v64a16 16 0 0 1-16 16h-64a16 16 0 0 1-16-16Zm208 272H112.45c-8.61 0-16-6.62-16.43-15.23A16 16 0 0 1 112 368h191.55c8.61 0 16 6.62 16.43 15.23A16 16 0 0 1 304 400Zm0-64H112.45c-8.61 0-16-6.62-16.43-15.23A16 16 0 0 1 112 304h191.55c8.61 0 16 6.62 16.43 15.23A16 16 0 0 1 304 336Zm0-64H112.45c-8.61 0-16-6.62-16.43-15.23A16 16 0 0 1 112 240h191.55c8.61 0 16 6.62 16.43 15.23A16 16 0 0 1 304 272Zm0-64h-63.55c-8.61 0-16-6.62-16.43-15.23A16 16 0 0 1 240 176h63.55c8.61 0 16 6.62 16.43 15.23A16 16 0 0 1 304 208Zm0-64h-63.55c-8.61 0-16-6.62-16.43-15.23A16 16 0 0 1 240 112h63.55c8.61 0 16 6.62 16.43 15.23A16 16 0 0 1 304 144Z"/></svg>
                            </div>
                            <h3>BitRish Feed</h3>
                            <div><p>BitRish Feed Web3: Your real-time gateway to crypto news, market trends, and blockchain insights.</p></div>
                        </div>
                    </div>
                    <!-- <div class="col-md-4">
                        <div class="d-flex justify-content-start flex-column w-100 bg-dark p-5 br12 mb-4">
                            <div class="iconImg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path fill="currentColor" d="M95.5 104h320a87.73 87.73 0 0 1 11.18.71a66 66 0 0 0-77.51-55.56L86 94.08h-.3a66 66 0 0 0-41.07 26.13A87.57 87.57 0 0 1 95.5 104Zm320 24h-320a64.07 64.07 0 0 0-64 64v192a64.07 64.07 0 0 0 64 64h320a64.07 64.07 0 0 0 64-64V192a64.07 64.07 0 0 0-64-64ZM368 320a32 32 0 1 1 32-32a32 32 0 0 1-32 32Z"/><path fill="currentColor" d="M32 259.5V160c0-21.67 12-58 53.65-65.87C121 87.5 156 87.5 156 87.5s23 16 4 16s-18.5 24.5 0 24.5s0 23.5 0 23.5L85.5 236Z"/></svg>
                            </div>
                            <h3>Trust Wallet</h3>
                            <div><p>The official wallet of BitRish, Trust Wallet, is a secure, decentralized wallet where users can send, receive and store their digital assets.</p></div>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-md-6 imgRightAbout text-center my-md-2 mb-4 order-sm-2">
                <div class="w-100 d-flex justify-content-start flex-column p-4 bg-dark br12">
                    <h2>Working with Regulators</h2>
                    <p>
                        At BitRish, we understand the importance of 'Working with Regulators' to ensure a secure and reliable cryptocurrency exchange environment. We work closely with regulatory bodies across the globe, adhering to all necessary legal and regulatory standards in each jurisdiction we operate in. This collaborative approach helps us maintain the highest levels of compliance, fostering trust with our users and contributing to the overall stability and growth of the cryptocurrency industry.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-center my-md-2 imgLeftAbout mb-4 order-sm-1">
                <div class="w-100 d-flex justify-content-start flex-column p-4 br12 bg-dark">
                    <h2>Putting Our Users First</h2>
                    <p>
                        At BitRish, we firmly believe in 'Putting Our Users First.' Our platform is built on the ethos of serving our users' needs and interests above all else. We focus on creating a secure, intuitive, and transparent trading environment that prioritizes user satisfaction and trust. By continually refining our services based on user feedback and evolving market dynamics, we strive to deliver a superior trading experience that exceeds expectations.
                    </p>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-md-12">
                <h2>Our Founder</h2>
                <div class="row my-5">
                    <div class="col-md-12 d-flex justify-content-between align-items-center">
                        <div class="info d-inline-flex justify-content-start flex-column">
                            <img src="https://images.pexels.com/photos/428328/pexels-photo-428328.jpeg" alt="" class="ownerImg" />
                            <h3 class="m-0 text-center">Rishi Sharma</h3>
                            <p class="m-0 text-center"><strong>Founder & Chief Executive Officer</strong></p>
                        </div>
                        <p class="mx-5">
                            Rishi Sharma is a serial entrepreneur with an impressive track record of successful startups. He launched BitRish in July 2017 and, within 180 days, grew BitRish into the largest digital asset exchange in the world by trading volume. An pioneer within the blockchain industry, CZ has built BitRish into the leading blockchain ecosystem, comprised of BitRish Exchange, Labs, Launchpad, Academy, Research, Trust Wallet, Charity, NFT, and more. CZ spent his youth flipping burgers before studying at McGill University Montreal. In 2005, CZ quit his role as head of the Bloomberg Tradebook Futures Research & Development team and moved to Shanghai to start Fusion Systems. Soon thereafter, he learned about Bitcoin and joined Blockchain.com as Head of Technology.
                        </p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <app-footer></app-footer>
</template>
<script>
import AppFooter from "@/components/parcel/AppFooter.vue";
import {mapState} from "vuex";

export default {
    name: "AboutUs",
    components: {AppFooter},
    computed: {
        ...mapState({
            theme: (state) => state.theme,
        }),
    },
    mounted() {
        document.title = 'About US - BitRish';
    }
}
</script>
