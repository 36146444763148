import axios from "axios";
import {ref} from "vue";

const FileType = {
    govId: "KYC_GOV_ID",
    govIdBack: "KYC_GOV_ID_BACK",
    addId: "KYC_GOV_ADDRESS_PROOF",
    addIdBack: "KYC_GOV_ADDRESS_PROOF_BACK",
    faceId: "KYC_FACE_IMG",
}

async function uploadKYCImage(path, fileType) {
    const resData = ref(null)
    const error = ref(null)
    let data = new FormData();
    data.append('imageFile', path);
    data.append('fileType', fileType);

    // let config = {
    //     method: 'post',
    //     maxBodyLength: Infinity,
    //     url: 'http://192.168.1.101:8080/api/v1/kyc/uploadImageKYC',
    //     headers: {
    //         'Authorization': 'Bearer {{token}}',
    //         ...data.getHeaders()
    //     },
    //     data: data
    // };

    console.log("Json Data == ", data);
    // console.log("Config Data == ", config);

    await axios.post('http://192.168.1.101:8080/api/v1/kyc/uploadImageKYC',
        data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+localStorage.getItem("app_token"),
            }
        }
    )
        .then((response) => {
            console.log(JSON.stringify(response.data));
            resData.value = JSON.stringify(response.data);
        })
        .catch((error) => {
            console.log(error);
            error.value = 'Error';
        });

    return {resData, error}
}

async function getImageData(id) {
    const imgData = ref(null);
    const error = ref(null);
    let data = JSON.stringify({
        "fID": id
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'getImage',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    console.log("Json Data == ", data);
    console.log("Config Data == ", config);

    await axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
            imgData.value = JSON.stringify(response.data);
        })
        .catch((error) => {
            console.log(error);
            error.value = 'Error';
        });

    return {imgData, error}
}

export {uploadKYCImage, getImageData, FileType}
