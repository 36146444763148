<template>
    <div class="row m-0 p-0">
        <div class="col-xl-2 col-md-2 col-sm-3 my-3">
            <div class="sidebar">
                <ul>
                    <li>
                        <router-link to="/MyProfile" class="customLink">Profile</router-link>
                        <ul class="customDropdown d-none">
                            <li><a href="javascript:void(0)" class="customLink">Personal Information</a></li>
                            <li><a href="javascript:void(0)" class="customLink">KYC</a></li>
                            <li><a href="javascript:void(0)" class="customLink">Change Password</a></li>
                            <li><a href="javascript:void(0)" class="customLink">App Password</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0)" class="customLink active">Wallet</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-xl-10 col-md-10 col-sm-9 my-3">
            <div class="mainContent">
                <section class="customSection active" id="contentFour">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center border-bottom mb-4 customMenu">
                                <h5 class="card-title">Transaction History</h5>
                                <div class="mb-2 d-inline-flex walletPageLinks">
                                    <router-link to="/MyWallet" class="btn btn-secondary">Wallet</router-link>
                                    <router-link to="/MyDeposit" class="btn btn-secondary">Deposit</router-link>
                                    <router-link to="/MyWithdraw" class="btn btn-secondary">Withdraw</router-link>
                                    <!-- <button target="#walletMainTab" class="btn btn-primary">Wallet</button>
                                    <button target="#depositWallet" class="btn btn-secondary">Deposit</button> -->
                                    <!-- <button target="#withdrawWallet" class="btn btn-secondary">Withdraw</button> -->
                                    <button target="#historyWallet" class="btn btn-primary">Transaction History</button>
                                    <!-- <button target="#historyWallet" class="btn btn-secondary" @click="walletPageChange('historyWallet')">Transaction History</button> -->
                                </div>
                            </div>
                            <div class="settings-profile">
                                <div class="row walletTabs0" id="walletMainTab">
                                    <div class="col-md-12 m-4 py-5 text-center d-none" v-if="!coinList.length">
                                        <h3 class="py-5 text-center">No Coin Found</h3>
                                    </div>
                                    <div class="col-md-12 trxFilter gap-1 d-none" v-if="coinList.length">
                                        <div>
                                            <p class="textGray m-0">Type</p>
                                            <select class="form-control">
                                                <option>Deposit</option>
                                                <option>Withdraw</option>
                                                <option>Auto deduction</option>
                                                <option>Auto funding</option>
                                                <option>Arrears repayment</option>
                                                <option>Auto convert</option>
                                            </select>
                                            <!-- <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="icon ion-md-search"></i></span>
                                                </div>
                                                <input type="text" @input="searchCoin()" class="form-control p-0" id="myInput" placeholder="Deposit">
                                            </div> -->
                                        </div>
                                        <div>
                                            <p class="textGray m-0">Time</p>
                                            <!-- <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="icon ion-md-search"></i></span>
                                                </div>
                                                <input type="text" @input="searchCoin()" class="form-control p-0" id="myInput" placeholder="Past 90 days">
                                            </div> -->
                                            <select class="form-control">
                                                <option>Past 7 days</option>
                                                <option>Past 30 days</option>
                                                <option>Past 90 days</option>
                                            </select>
                                        </div>
                                        <div>
                                            <p class="textGray m-0">Status</p>
                                            <select class="form-control">
                                                <option>All</option>
                                                <option>Completed</option>
                                                <option>Pending</option>
                                            </select>
                                        </div>
                                        <div>
                                            <p class="textGray m-0">Asset</p>
                                            <!-- <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="icon ion-md-search"></i></span>
                                                </div>
                                                <input type="text" @input="searchCoin()" class="form-control p-0" id="myInput" placeholder="All">
                                            </div> -->
                                            <div class="selectedCoin d-none">
                                                <div class="selectedCoinItem">
                                                    <img class="walletCoinImage" src="assets/img/logo.png"/><a
                                                        href="javascript:void(0)" class="ml-2">EQL</a>
                                                </div>
                                                <div class="chevron"><i class="fas fa-chevron-down"></i></div>
                                            </div>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="icon ion-md-search"></i></span>
                                                </div>
                                                <input type="text" name="selectCoin" id="selectCoin"
                                                       @input="searchPair()" class="form-control"/>
                                                <div class="input-group-append"><span class="input-group-text"><i
                                                        class="fas fa-times"></i></span></div>
                                                <div class="searchCoinDropdown">
                                                    <div class="CoinList">
                                                        <small class="textGray">Coin List</small>
                                                        <ul class="CoinListUL">
                                                            <li v-for="(coin, index) in coinList" :key="index"
                                                                class="coinListItem">
                                                                <div class="d-flex align-items-center gap-1 CoinSelectValue"
                                                                     :data-pair="coin['coinName']">
                                                                    <img class="walletCoinImage"
                                                                         :src="coin['coinIcon']"/>
                                                                    <a class="d-flex align-items-center m-0"
                                                                       href="javascript:void(0)">{{ coin['coinName'] }}
                                                                        <small class="m-0 ml-2 textGray">{{
                                                                            coin['coinNetwork']
                                                                            }}</small></a>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="textGray m-0">TxID</p>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="icon ion-md-search"></i></span>
                                                </div>
                                                <input type="text" @input="searchCoin()" class="form-control p-0"
                                                       id="myInput" placeholder="Search a coin">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-3" v-if="coinList.length">
                                        <div class="my-3 font-400 d-flex align-items-center textGray pl-3">
                                            <label class="contentBox hideSmallBalances"> Hide Zero Balances
                                                <input type="checkbox" checked="checked">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div> -->
                                    <div class="col-md-12" v-if="coinList.length">
                                        <div class="datatable-container">
                                            <table class="table latestTransaction" id="myTable">
                                                <thead>
                                                <tr>
                                                    <th>Time</th>
                                                    <th>Type</th>
<!--                                                    <th>Deposit wallet</th>-->
                                                    <th>Asset</th>
                                                    <th>Amount</th>
<!--                                                    <th>Destination</th>-->
                                                    <th>TxID</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(list, index) in transactionList" :key="index">
                                                    <td style="min-width:150px">
                                                        <div class="py-3">{{ list['new_date_time'] }}</div>
                                                    </td>
                                                    <td>
                                                        <div class="py-3">{{ list['type'] }}</div>
                                                    </td>
<!--                                                    <td>-->
<!--                                                        <div class="py-3">Spot Wallet</div>-->
<!--                                                    </td>-->
                                                    <td>
                                                        <div class="py-3">{{ list['coinName'] }}</div>
                                                    </td>
                                                    <td>
                                                        <div class="py-3">{{ list['amount'] }}</div>
                                                    </td>
<!--                                                    <td>-->
<!--                                                        <div class="py-3">-->
<!--                                                            <strong>{{ makeShortHash(list['trxHash']) }}</strong>-->
<!--                                                            <span><i class="fas fa-link"></i></span>-->
<!--                                                            <span><i class="fas fa-copy"></i></span>-->
<!--                                                        </div>-->
<!--                                                    </td>-->
                                                    <td>
                                                        <a :href="list['blockExplorerURL']" target="_blank"><div class="py-3">
                                                            <strong>{{ makeShortHash(list['trxHash']) }}</strong>
                                                            <span><i class="fas fa-link"></i></span>
<!--                                                            <span><i class="fas fa-copy"></i></span>-->
                                                        </div></a>
                                                    </td>
                                                    <td>
                                                        <div class="py-2">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <span>Completed</span>
                                                                <span class="chevronBg"><i
                                                                        class="fas fa-chevron-right"></i></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <!--                                                <tr>
                                                                                                    <td style="min-width:150px">
                                                                                                        <div class="py-3">2023-05-26 12:12</div>
                                                                                                    </td>
                                                                                                    <td><div class="py-3">Deposit</div></td>
                                                                                                    <td><div class="py-3">Spot Wallet</div></td>
                                                                                                    <td><div class="py-3">Matic</div></td>
                                                                                                    <td><div class="py-3">6</div></td>
                                                                                                    <td>
                                                                                                        <div class="py-3">
                                                                                                            <strong>0x272...2bfc3</strong>
                                                                                                            <span><i class="fas fa-link"></i></span>
                                                                                                            <span><i class="fas fa-copy"></i></span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="py-3">
                                                                                                            <strong>0x83bc...844af1</strong>
                                                                                                            <span><i class="fas fa-link"></i></span>
                                                                                                            <span><i class="fas fa-copy"></i></span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="py-2">
                                                                                                            <div class="d-flex justify-content-between align-items-center">
                                                                                                                <span>Completed</span>
                                                                                                                <span class="chevronBg"><i class="fas fa-chevron-right"></i></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td style="min-width:150px">
                                                                                                        <div class="py-3">2023-05-03 11:36</div>
                                                                                                    </td>
                                                                                                    <td><div class="py-3">Deposit</div></td>
                                                                                                    <td><div class="py-3">Spot Wallet</div></td>
                                                                                                    <td><div class="py-3">Matic</div></td>
                                                                                                    <td><div class="py-3">6</div></td>
                                                                                                    <td>
                                                                                                        <div class="py-3">
                                                                                                            <strong>0x272...2bfc3</strong>
                                                                                                            <span><i class="fas fa-link"></i></span>
                                                                                                            <span><i class="fas fa-copy"></i></span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="py-3">
                                                                                                            <strong>0xfdc6...ef3a59</strong>
                                                                                                            <span><i class="fas fa-link"></i></span>
                                                                                                            <span><i class="fas fa-copy"></i></span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div class="py-2">
                                                                                                            <div class="d-flex justify-content-between align-items-center">
                                                                                                                <span>Completed</span>
                                                                                                                <span class="chevronBg"><i class="fas fa-chevron-right"></i></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>-->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
  <!-- Dialog Simple datepicker -->
    <div tabindex="-1" class="modal fade" id="datepicker-dialog" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <!-- Inline popup datepicker start -->
                <div id="datepicker-popup-inline"></div>
                <!-- Inline popup datepicker end -->
                <div class="modal-footer">
                    <button type="button" class="btn pmd-ripple-effect btn-light" aria-hidden="true"
                            data-dismiss="modal">Cancel
                    </button>
                    <button type="button" class="btn pmd-ripple-effect btn-primary">Select</button>
                </div>
            </div>
        </div>
    </div>
  <!-- Dialog Simple datepicker -->
  <!-- Wallet Drawer -->
    <div class="drawer">
        <div class="drawerInner">
            <div class="drawerOverlay" @click="closeDrawer()"></div>
            <div class="drawerContent">
                <a href="javascript:void(0)" @click="closeDrawer()" class="closeDrawer"><i class="fas fa-times"></i></a>
                <div class="row h-100 m-0 drawerContentRow">
                    <div class="col-md-12 col-lg-12 p-0 walletContent h-100">
                        <div class="d-flex align-items-center customPadding">
                            <div class="coinImg">
                                <img src="assets/img/logo.png"/>
                            </div>
                            <div class="d-inline-flex flex-column">
                                <h2 class="m-0 textInvert">{{ selectedCoin }}</h2>
                                <p class="m-0 textGray">{{ selectedCoin }}</p>
                            </div>
                        </div>
                        <div class="d-flex flex-column customPadding">
                            <h4 class="textGray">Holdings</h4>
                            <h2 class="textInvert">{{ cryptoBal }}</h2>
                        </div>
                        <div class="d-flex align-items-center customPadding">
                            <a class="bg-dark p-4 d-flex justify-content-between align-items-center br12 w-100">
                                <div class="d-inline-flex flex-column">
                                    <p class="textGray">Today's PNL</p>
                                    <p class="textRed">-0.30</p>
                                </div>
                                <div class="d-inline-flex flex-column">
                                    <p class="textGray">Today's PNL (%)</p>
                                    <p class="textRed">-3.73%</p>
                                </div>
                                <div class="d-inline-flex flex-column">
                                    <div class="chevronBg"><i class="fas fa-chevron-right"></i></div>
                                </div>
                            </a>
                        </div>
                        <div class="d-flex customPadding flex-column mb-4">
                            <div class="d-flex justify-content-between">
                                <p class="textGray m-0">Available</p>
                                <p class="textInvert m-0">{{ cryptoBal }}</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p class="textGray m-0">Withdrawing</p>
                                <p class="textInvert m-0">0.00</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p class="textGray m-0">Spot Orders</p>
                                <p class="textInvert m-0">0.00</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p class="textGray m-0">Convert Orders</p>
                                <p class="textInvert m-0">0.00</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p class="textGray m-0">Frozen</p>
                                <p class="textInvert m-0">0.00</p>
                            </div>
                        </div>
                        <hr/>
                        <div class="d-flex flex-column px-4 mt-2">
                            <div class="selectedCoinHistory">
                                <div class="w-100 d-flex justify-content-between align-items-center">
                                    <h5 class="textInvert">History</h5>
                                    <select class="form-control w-auto">
                                        <option value="0">All</option>
                                        <option value="1">Deposit & Withdraw</option>
                                        <option value="2">Convert</option>
                                        <option value="3">Buy & Sell</option>
                                        <option value="4">Earn</option>
                                        <option value="5">Distribution</option>
                                        <option value="6">Transfer</option>
                                    </select>
                                </div>
                                <div class="selectedCoinHistoryItem mt-4 textInvert">
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Withdraw</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span
                                                    class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Buy</strong>
                                            <small class="textGray">2023-06-24 18:59</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>+7065022 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span
                                                    class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Trading Fee</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span
                                                    class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Sell</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>+1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span
                                                    class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Buy</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span
                                                    class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Withdraw</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span
                                                    class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Buy</strong>
                                            <small class="textGray">2023-06-24 18:59</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>+7065022 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span
                                                    class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Trading Fee</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span
                                                    class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Sell</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>+1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span
                                                    class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Buy</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span
                                                    class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lightbox" v-if="!isKycA">
                            <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                    <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                    <h1 class="text-warning m-0">Warning:</h1>
                                </div>
                                <span class="textInvert m-0 ml-2">Your KYC is still pending</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <!-- Wallet Drawer -->
</template>

<script>
import {
    getAllCoinList
} from "@/restApi/deposit_api";
import {log, MsgType} from "@/util/constant";
import {mapActions, mapMutations, mapState} from "vuex";
import {CHECK_USER_INFO_ACTION, LOADING_STATE, SET_WITHDRAW_DATA_MUTATION} from "@/util/store.constent";
import BigNumber from "bignumber.js";
import {getAllTransactionList} from "@/restApi/transaction.api";

export default {
    name: "TransactionHistory",
    props: ['message'],
    components: {
        // VueQrcode,
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.isLogin,
            userName: (state) => state.userName,
            userEmail: (state) => state.userEmail,
            userNum: (state) => state.userContact,
            isKycA: (state) => state.isKycApproved,
            userAddressP: (state) => state.userAddress,
            withId: (state) => state.stateWithdrawID,
            userCur: (state) => state.userCurrency,
        }),
        ...mapActions({
            checkUser: CHECK_USER_INFO_ACTION,
        }),
    },
    mounted() {
        document.title = 'Profile - Bitrish';
    },
    data: function () {
        return {
            coinList: [],
            transactionList: [],
        };
    },
    async created() {
        await this.checkUser;
        if (!this.isLogin) {
            this.$router.replace('/signIn');
        }
        await this.getAllCoin();
        await this.getAllTransactionList();
    },

    methods: {
        ...mapMutations({
            showLoading: LOADING_STATE,
            withdrawId: SET_WITHDRAW_DATA_MUTATION,
        }),

        searchCoin() {
            // Declare variables
            var input, filter, table, tr, td, i, txtValue;
            input = document.getElementById("myInput");
            filter = input.value.toUpperCase();
            table = document.getElementById("myTable");
            tr = table.getElementsByTagName("tr");

            // Loop through all table rows, and hide those who don't match the search query
            for (i = 0; i < tr.length; i++) {
                td = tr[i].getElementsByTagName("td")[6];
                if (td) {
                    txtValue = td.textContent || td.innerText;
                    // console.log("txtValue: ", txtValue);
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                }
            }
        },

        copyAdd() {
            const input = document.getElementById('depositWA');
            input.select();
            document.execCommand('copy');
        },

        async getAllCoin() {
            const {coinData, error} = await getAllCoinList();
            if (error.value === null) {
                if (JSON.parse(coinData.value)['status'].toString().toLowerCase() === 'true') {
                    const cData = JSON.parse(coinData.value)['data'];
                    // console.log('Coin Data = = ', cData);
                    if (cData.length > 0) {
                        for (let i = 0; i < cData.length; i++) {
                            this.coinList.push(cData[i]);
                        }
                    }
                }
            }
        },

        formattedCurrency(val, round) {
            if (val && val.toString().includes('.')) {
                if (parseFloat(val.toString().split('.')[0]) <= 0) {
                    return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: parseInt(round.toString())});
                } else {
                    return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: 2});
                }
            } else if (val) {
                return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: 2});
            } else {
                return '0.00';
            }
        },

        async getAllTransactionList() {
            const {res, err} = await getAllTransactionList();
            this.transactionList = [];
            if (err === null) {
                try {
                    if (JSON.parse(res)['status'].toString().toLowerCase() === 'true') {
                        const tData = JSON.parse(res)['data']
                        console.log('all transaction Data === ', tData);
                        if (tData.length > 0) {
                            for (let i = 0; i < tData.length; i++) {
                                const mxx = tData[i];
                                const dateString = parseInt(mxx['dateTime'].toString()) * 1000;
                                const d = new Date(dateString);
                                mxx['new_date_time'] = this.getISOTimeFormat(d);
                                this.transactionList.push(mxx);
                            }
                        }
                        console.log('all transaction Data list === ', this.transactionList);
                    }
                } catch (e) {
                    log(e, MsgType.error);
                }
            }
        },

        getBigNumber(val) {
            if (val) {
                return new BigNumber(val.toString());
            } else {
                return new BigNumber('0');
            }
        },

        makeShortHash(hash) {
            const start = 0;
            const end = 6;

            return hash.substring(start, end) + "...." + hash.substring(hash.length - 4)
        },

        getISOTimeFormat(timestamp) {
            const date = new Date(timestamp);
            const isoDate = date.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            const isoTime = date.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            });

            return `${isoDate} ${isoTime}`;
        },
    },
}
</script>
