<template>
    <div class="container">
        <div class="row m-0">
            <div class="col-md-12 my-5 d-flex justify-content-center align-items-center flex-column">
                <h4 class="textInvert">Trade MATIC Easily - Buy and Sell Using Your Favorite Payment Methodst</h4>
                <p class="textInvert">Make P2P Polygon trades today with Zero Fees and your Preferred Payment Method!</p>
            </div>
        </div>
    </div>

    <div class="row m-0 p2p-bg-dark">
        <div class="container">
            <div class="d-flex justify-content-between align-items-start p2pMenu">
                <div class="d-inline-flex justify-content-start align-items-center">
                    <ul class="d-flex">
                        <li><a href="javascript:void(0)">Express</a></li>
                        <li><a href="javascript:void(0)" class="active">P2P</a></li>
                        <li><a href="javascript:void(0)">Block</a></li>
                    </ul>
                </div>
                <div class="d-inline-flex justify-content-start align-items-center">
                    <ul class="d-flex p2pRightMenu">
                        <li><a href="javascript:void(0)"><i class="fa-solid fa-user"></i> User Guide</a></li>
                        <li><a href="javascript:void(0)"><i class="fa-solid fa-file"></i>Orders</a></li>
                        <li><a href="javascript:void(0)"><i class="fa-solid fa-camera-viewfinder"></i>P2P User Center</a></li>
                        <li><a href="javascript:void(0)"><i class="fa-solid fa-ellipsis"></i>More</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <app-footer/>
</template>

<script>
import AppFooter from "@/components/parcel/AppFooter.vue";

export default {
    name: "P2pTrade",
    components: {AppFooter},
}
</script>