import axios from "axios"
import {ref} from "vue";
import {log, MsgType} from "@/util/constant";

async function applyKyc(dob, num, add, proofId, proofIdBack, addId, addIdBack, faceId, tName) {
    const resData = ref(null)
    const error = ref(null)
    let data = JSON.stringify({
        "legalName": "Name",
        "dob": dob,
        "address": add,
        "contactNumber": num,
        "govIdImgID": proofId,
        "govIdImgBackID": proofIdBack,
        "govAddressProofID": addId,
        "govAddressProofBackID": addIdBack,
        "userFaceImgID": faceId,
        "twitterUsername": tName,
    });
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'kyc/applyKYC',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('app_token')
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        resData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {resData, error}
}

async function getKycData() {
    const resData = ref(null)
    const error = ref(null)
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.VUE_APP_DOMAIN + 'kyc/getDataKYC',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('app_token')
        },
    };

    try {
        const response = await axios.request(config);
        resData.value = JSON.stringify(response.data);
    } catch (e) {
        log(e, MsgType.error);
        error.value = 'Error';
    }

    return {resData, error}
}

export {applyKyc, getKycData}
