import {createToast} from "mosha-vue-toastify";

const Type = {
    danger: "danger",
    success: "success",
}

function toast(msg, type) {
    createToast(msg,
        {
            type: type,
            hideProgressBar: true,
            transition: 'bounce',
        });
}

export { toast, Type }