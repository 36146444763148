<template>
    <div class="row m-0 p-0">
        <div class="col-xl-2 col-md-2 col-sm-3 my-3">
            <div class="sidebar">
                <ul>
                    <li>
                        <router-link to="/MyProfile" class="customLink">Profile</router-link>
                        <ul class="customDropdown d-none">
                            <li><a href="javascript:void(0)" class="customLink">Personal Information</a></li>
                            <li><a href="javascript:void(0)" class="customLink">KYC</a></li>
                            <li><a href="javascript:void(0)" class="customLink">Change Password</a></li>
                            <li><a href="javascript:void(0)" class="customLink">App Password</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0)" class="customLink active">Wallet</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-xl-10 col-md-10 col-sm-9 my-3">
            <div class="mainContent">
                <section class="customSection active" id="contentFour">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center border-bottom mb-4 customMenu">
                                <h5 class="card-title">Wallet Information</h5>
                                <div class="mb-2 d-inline-flex walletPageLinks">
                                    <router-link to="/MyWallet" class="btn btn-primary">Wallet</router-link>
                                    <router-link to="/MyDeposit" class="btn btn-secondary">Deposit</router-link>
                                    <router-link to="/MyWithdraw" class="btn btn-secondary">Withdraw</router-link>
                                    <router-link to="/TransactionHistory" class="btn btn-secondary">Transaction History</router-link>
                                    <!-- <button target="#walletMainTab" class="btn btn-primary">Wallet</button>
                                    <button target="#depositWallet" class="btn btn-secondary">Deposit</button> -->
                                    <!-- <button target="#withdrawWallet" class="btn btn-secondary">Withdraw</button> -->
                                    <!-- <button target="#historyWallet" class="btn btn-secondary">Transaction History</button> -->
                                    <!-- <button target="#historyWallet" class="btn btn-secondary" @click="walletPageChange('historyWallet')">Transaction History</button> -->
                                </div>
                            </div>
                            <div class="settings-profile">
                                <div class="row d-none walletTabs0" id="depositWallet">
                                    <div class="col-md-6">
                                        <h2>Deposit Crypto</h2>
                                        <div class="steps d-flex justify-content-start w-100 mt-5">
                                            <div class="stepsContent active">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem">1</span>
                                                </div>
                                                <div class="stepsContentRight pt-2">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                        <div class="searchCoinDropdown">
                                                            <div class="historyCoinList p-3">
                                                                <small class="textGray">Search History</small>
                                                                <div class="hintCoin d-flex gap-1">
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>USDT</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>BTC</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="trendingCoinList p-3">
                                                                <small class="textGray">Trending</small>
                                                                <div class="hintCoin d-flex gap-1">
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>USDT</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>BTC</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>USDT</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>BTC</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>USDT</span>
                                                                    </a>
                                                                    <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm">
                                                                        <span>BTC</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div class="CoinList p-3">
                                                                <ul class="CoinListUL">
                                                                    <li class="coinListItem">Coin 1</li>
                                                                    <li class="coinListItem">Coin 2</li>
                                                                    <li class="coinListItem">Coin 3</li>
                                                                    <li class="coinListItem">Coin 4</li>
                                                                    <li class="coinListItem">Coin 5</li>
                                                                    <li class="coinListItem">Coin 6</li>
                                                                    <li class="coinListItem">Coin 7</li>
                                                                    <li class="coinListItem">Coin 8</li>
                                                                    <li class="coinListItem">Coin 9</li>
                                                                    <li class="coinListItem">Coin 10</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="hintCoin d-flex justify-content-between align-items-center mb-4">
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">USDT</span>
                                                        </a>
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">BTC</span>
                                                        </a>
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">TRX</span>
                                                        </a>
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">ALPAKA</span>
                                                        </a>
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">ALPINE</span>
                                                        </a>
                                                        <a href="javascript:void(0)" class="hintCoinItem btn btn-secondary btn-sm d-inline-flex justify-content-center align-items-center">
                                                            <img src="assets/img/logo.png" alt="USDT" /><span class="ml-2">MATIK</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem">2</span>
                                                </div>
                                                <div class="stepsContentRight pt-2">
                                                    <label>Select Network</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem">3</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-none walletTabs0" id="withdrawWallet">
                                    <div class="col-md-8">
                                        <h2>Withdraw</h2>
                                        <div class="steps d-flex justify-content-start w-100">
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">1</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">2</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">3</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-none walletTabs0" id="historyWallet">
                                    <div class="col-md-8">
                                        <h2>Transaction History</h2>
                                        <div class="steps d-flex justify-content-start w-100">
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">1</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">2</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stepsContent">
                                                <div class="stepsContentLeft">
                                                    <span class="stepsCountItem active">3</span>
                                                </div>
                                                <div class="stepsContentRight">
                                                    <label>Select Coin</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend"><i class="fas fa-search"></i></div>
                                                        <input type="text" name="selectCoin" id="selectCoin" class="form-control" />
                                                        <div class="input-group-append"><i class="fas fa-times"></i></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row active walletTabs0" id="walletMainTab">
                                    <div class="col-md-12 m-4 py-5 text-center" v-if="!coinList.length">
                                        <h3 class="py-5 text-center">No Coin Found</h3>
                                    </div>
                                    <div class="col-md-3" v-if="coinList.length">
                                        <div class="my-3 font-400">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="icon ion-md-search"></i></span>
                                                </div>
                                                <input type="text" @input="searchCoin()" class="form-control p-0" id="myInput" placeholder="Search a coin">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-3" v-if="coinList.length">
                                        <div class="my-3 font-400 d-flex align-items-center textGray pl-3">
                                            <label class="contentBox hideSmallBalances"> Hide Zero Balances
                                                <input type="checkbox" checked="checked">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div> -->
                                    <div class="col-md-12" v-if="coinList.length">
                                        <div class="datatable-container">
                                        <table class="table latestTransaction" id="myTable">
                                            <thead>
                                                <tr>
                                                    <th>Coin</th>
<!--                                                    <th>Total</th>-->
                                                    <th>Available</th>
<!--                                                    <th>In Order</th>-->
                                                    <!-- <th>BTC Value</th> -->
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(coin, index) in coinList" :key="index" v-on:click="onCoinSelect(index)">
                                                    <td style="min-width:150px">
                                                        <div class="d-flex align-items-center walletCoinDetails">
                                                            <img class="walletCoinImage" :src="coin['coinIcon']" />
                                                            <div class="mx-3 d-flex flex-column">
                                                                <strong>{{ coin['coinName'] }}</strong>
                                                                <p class="m-0">Network: {{ coin['coinNetwork'] }}</p>
                                                            </div>
                                                        </div>
                                                    </td>
<!--                                                    <td><div class="d-flex justify-content-center flex-column walletCoinDetails">-->
<!--                                                        {{ coin['total'] }}</div></td>-->
                                                    <td><div class="d-flex justify-content-center flex-column walletCoinDetails">
                                                        {{ coin['avbl'] }}</div></td>
<!--                                                    <td><div class="d-flex justify-content-center flex-column walletCoinDetails">-->
<!--                                                        {{ coin['lock'] }}</div></td>-->
                                                    <!-- <td>
                                                        <div class="d-flex justify-content-center flex-column walletCoinDetails">
                                                        ≈ {{ userCur['fiatSymbol'] }} {{ formattedCurrency(coin['totalInFiat'], 2) }}</div></td> -->
                                                    <td>
                                                        <div class="d-flex align-items-center walletCoinDetails justify-content-between">
                                                            <div>
                                                                <!-- <a href="javascript:void(0)" class="text-primary mr-3">Buy</a> -->
                                                                <router-link to="/MyDeposit" class="text-primary mr-3">Deposit</router-link>
                                                                <router-link to="/MyWithdraw" class="text-primary mr-3">Withdraw</router-link>
                                                                <!-- <a href="javascript:void(0)" class="text-primary mr-3">Deposit</a>
                                                                <a href="javascript:void(0)" class="text-primary mr-3">Withdraw</a> -->
                                                                <!-- <a href="javascript:void(0)" class="text-primary mr-3">Trade</a> -->
                                                                <!-- <a href="javascript:void(0)" class="text-primary mr-3">Earn</a>
                                                                <a href="javascript:void(0)" class="text-primary mr-3">Convert</a> -->
                                                            </div>
                                                            <div>
                                                                <a href="javascript:void(0)" class="gotoLink"><i class="fas fa-chevron-right"></i></a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-4 col-lg-3 pr-0 walletTabs">
                                        <div class="nav nav-pills walletTabLink walletTabsArea" role="tablist"
                                             aria-orientation="vertical">
                                            <a v-for="(coin, index) in coinList" :key="index"
                                               class="nav-link d-flex justify-content-between align-items-center"
                                               data-toggle="pill" role="tab" aria-selected="true"
                                               v-on:click="onCoinSelect(index)">
                                                <div class="d-inline-flex justify-content-start align-items-center">
                                                    <img :src="coin['coinIcon']">
                                                    <div class="mx-3">
                                                        <h2>{{ coin['coinName'] }}</h2>
                                                        <p>Network: {{ coin['coinNetwork'] }}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-8 col-lg-9 pl-0 walletContent">
                                        <div class="tab-content walletTabContent">
                                            <div class="tab-pane fade show active" id="coinBTC" role="tabpanel">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Balances</h5>
                                                        <div class="row">
                                                            <div class="col-md-6 mb-4">
                                                                <div class="d-inline-flex w-100 justify-content-between align-items-center customBadges">
                                                                    <div class="d-inline-flex align-items-center">
                                                                        <i class="icon ion-md-cash"></i>
                                                                        <h2>Balance</h2>
                                                                    </div>
                                                                    <h3>{{ cryptoBal }} {{ selectedCoin }}</h3>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-4">
                                                                <div class="d-inline-flex w-100 justify-content-between align-items-center customBadges">
                                                                    <div class="d-inline-flex align-items-center">
                                                                        <i class="icon ion-md-lock"></i>
                                                                        <h2>Lock Amount</h2>
                                                                    </div>
                                                                    <h3>{{ cryptoLockBal }} {{ selectedCoin }}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div>
                                                        <ul class="nav nav-pills" role="tablist">
                                                            <li class="nav-item">
                                                                <a class="nav-link active" data-toggle="pill" href="#deposit" role="tab" aria-selected="true">Deposit</a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a class="nav-link" data-toggle="pill" href="#withdraw" role="tab" aria-selected="false">Withdraw</a>
                                                            </li>
                                                        </ul>
                                                        <div class="tab-content">
                                                            <div class="tab-pane fade show active" id="deposit" role="tabpanel">
                                                                <div class="card-body">
                                                                    <h5 class="card-title">Wallet Deposit Address</h5>
                                                                    <div class="row wallet-address">
                                                                        <div class="col-md-8">
                                                                            <div>
                                                                                <p>Please note that if you deposit an amount that exceeds the deposit limit, you will not be able to withdraw the entire sum in a single transaction. Withdrawals will be subject to the withdrawal limit corresponding to your account type and compliance with our security protocols.</p>
                                                                                <div class="input-group">
                                                                                    <input type="text" class="form-control" readonly id="depositWA"
                                                                                           v-model="walletAdd">
                                                                                    <div class="input-group-append">
                                                                                        <button class="btn btn-primary" @click="copyAdd">COPY</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="input-group mt-1" v-if="walletExtra !== ''">
                                                                                    <input type="text" class="form-control" readonly id="depositWE"
                                                                                           v-model="walletExtra">
                                                                                    <div class="input-group-append">
                                                                                        <button class="btn btn-primary" @click="copyExtra">COPY</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <small class="textRed mt-2">Minimum Deposit Value:
                                                                                {{ selectedCoinDepositVal }} {{ selectedCoin }}</small>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <vue-qrcode v-bind:value="walletAdd"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-body">
                                                                        <h5 class="card-title">Latest Transactions</h5>
                                                                        <div class="wallet-history walletHistoryTableArea">
                                                                            <table class="table latestTransaction">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>No.</th>
                                                                                        <th>Date/Time</th>
                                                                                        <th class="text-center">Confirmation</th>
                                                                                        <th class="text-center">Status</th>
                                                                                        <th class="text-right">Amount</th>
                                                                                        <th class="text-right">Transaction Hash</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="(transaction, index) in depositList" :key="index">
                                                                                        <td>{{ index + 1}}</td>
                                                                                        <td>{{ transaction['new_date_time'] }}</td>
                                                                                        <td class="text-center">
                                                                                            <span class="text-warning" v-if="parseInt(transaction['confirmations']['current'].toString()) < parseInt(transaction['confirmations']['total'].toString())">
                                                                                                {{ transaction['confirmations']['current'] }}/{{ transaction['confirmations']['total'] }}
                                                                                                <a href="javascript:void(0)" class="text-warning" @click="getDepositTransactionList(walletAdd, sCoinID)">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M19.933 13.041a8 8 0 1 1-9.925-8.788c3.899-1 7.935 1.007 9.425 4.747"/><path d="M20 4v5h-5"/></g></svg>
                                                                                                </a>
                                                                                            </span>
                                                                                            <span class="textGreen" v-else-if="parseInt(transaction['confirmations']['current'].toString()) >= parseInt(transaction['confirmations']['total'].toString())">{{ transaction['confirmations']['current'] }}/{{ transaction['confirmations']['total'] }}</span>
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <span class="text-warning" v-if="transaction['status'].toString() === '0'">Pending</span>
                                                                                            <span class="textGreen" v-else-if="transaction['status'].toString() === '1'">Complete</span>
                                                                                        </td>
                                                                                        <td class="text-right">{{ transaction['amount'] }}</td>
                                                                                        <td class="text-right"><a :href="transaction['blockExplorerURL']" target="_blank">{{ makeShortHash(transaction['trxHash']) }}</a></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="lightbox-deposit" v-if="!isCanDeposit">
                                                                    <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                                                        <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                                                            <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                                                            <h1 class="text-warning m-0">Warning:</h1>
                                                                        </div>
                                                                        <span class="textInvert m-0 ml-2">Deposits suspended up to prior notice</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="tab-pane fade" id="withdraw" role="tabpanel">
                                                                <div class="card-body">
                                                                    <div class="row wallet-address">
                                                                        <div class="col-md-12">
                                                                            <div v-if="!showVerification">
                                                                                <h5 class="card-title">Withdraw</h5>
                                                                                <p>Please be aware that you will not be able to withdraw an amount exceeding the specified withdrawal limit for your account. Withdrawals are subject to the set limit as per your account type and our security measures.</p>

                                                                                <div class="input-group">
                                                                                    <input type="text" class="form-control" ref="withdrawAddress" placeholder="Enter Address" id="walletAddressPaste" v-model="withdrawAddVal" />
                                                                                    <div class="input-group-append">
                                                                                        <button class="btn btn-primary" @click="pasteText">PASTE</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="form-group mt-3">
                                                                                    <input type="text" class="form-control" ref="withdrawAmount" placeholder="Enter Amount" id="walletAmountPaste" v-model="withdrawAmtVal" />
                                                                                </div>
                                                                                <div class="form-group mt-2">
                                                                                    <button type="button" class="btn btn-primary" @click="sendWithdrawRequest">Withdraw</button>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="showVerification">
                                                                                <h3>Verification</h3>
                                                                                <hr />
                                                                                <div class="input-group">
                                                                                    <input type="text" class="form-control" ref="mobileOtp" placeholder="Enter Mobile OTP" v-model="withdrawPOTPVal"/>
                                                                                    <div class="input-group-append">
                                                                                        <button class="btn btn-primary" @click="resendOtp('PHONE')">Resend OTP</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="input-group mt-3">
                                                                                    <input type="text" class="form-control" ref="emailOtp" placeholder="Enter Email OTP" v-model="withdrawEOTPVal"/>
                                                                                    <div class="input-group-append">
                                                                                        <button class="btn btn-primary" @click="resendOtp('EMAIL')">Resend OTP</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="input-group mt-3">
                                                                                    <button class="btn btn-primary" @click="verifyOtp">Verify & Withdraw</button>
                                                                                    <button class="btn btn-primary ml-3" @click="cancelWithdraw">Cancel</button>
                                                                                </div>
                                                                            </div>
                                                                            <small class="textRed">Withdraw Fee: {{ selectedCoinWithdrawFee }} {{ selectedCoin }}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="card">
                                                                    <div class="card-body">
                                                                        <h5 class="card-title">Latest Transactions</h5>
                                                                        <div class="wallet-history">
                                                                            <table class="table latestTransaction">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>No.</th>
                                                                                        <th>Date/Time</th>
                                                                                        <th class="text-center">Status</th>
                                                                                        <th>Fee</th>
                                                                                        <th class="text-right">Amount</th>
                                                                                        <th class="text-right">Transaction Hash</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="(transaction, index) in withdrawList" :key="index">
                                                                                        <td>{{ index + 1}}</td>
                                                                                        <td>{{ transaction['new_date_time'] }}</td>
                                                                                        <td class="text-center">
                                                                                            <span class="text-warning" v-if="transaction['status'].toString() === '0'">Pending</span>
                                                                                            <span class="textGreen" v-else-if="transaction['status'].toString() === '1'">Complete</span>
                                                                                        </td>
                                                                                        <td>{{ transaction['fees'] }}</td>
                                                                                        <td class="text-right">{{ transaction['amount'] }}</td>
                                                                                        <td class="text-right"><a :href="transaction['blockExplorerURL']" target="_blank">{{ makeShortHash(transaction['trxHash']) }}</a></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="lightbox-deposit" v-if="!isCanWithdraw">
                                                                    <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                                                        <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                                                            <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                                                            <h1 class="text-warning m-0">Warning:</h1>
                                                                        </div>
                                                                        <span class="textInvert m-0 ml-2">Withdrawals suspended up to prior notice</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="lightbox" v-if="!isKycA">
                                            <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                                <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                                    <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                                    <h1 class="text-warning m-0">Warning:</h1>
                                                </div>
                                                <span class="textInvert m-0 ml-2">Your KYC is still pending</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
    <!-- Dialog Simple datepicker -->
    <div tabindex="-1" class="modal fade" id="datepicker-dialog" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <!-- Inline popup datepicker start -->
                <div id="datepicker-popup-inline"></div>
                <!-- Inline popup datepicker end -->
                <div class="modal-footer">
                    <button type="button" class="btn pmd-ripple-effect btn-light" aria-hidden="true" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn pmd-ripple-effect btn-primary">Select</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Dialog Simple datepicker -->
    <!-- Wallet Drawer -->
    <div class="drawer">
        <div class="drawerInner">
            <div class="drawerOverlay" @click="closeDrawer()"></div>
            <div class="drawerContent">
                <a href="javascript:void(0)" @click="closeDrawer()" class="closeDrawer"><i class="fas fa-times"></i></a>
                <div class="row h-100 m-0 drawerContentRow">
                    <div class="col-md-12 col-lg-12 p-0 walletContent h-100">
                        <div class="d-flex align-items-center customPadding">
                            <div class="coinImg">
                                <img src="assets/img/logo.png" />
                            </div>
                            <div class="d-inline-flex flex-column">
                                <h2 class="m-0 textInvert">{{ selectedCoin }}</h2>
                                <p class="m-0 textGray">{{ selectedCoin }}</p>
                            </div>
                        </div>
                        <div class="d-flex flex-column customPadding">
                            <h4 class="textGray">Holdings</h4>
                            <h2 class="textInvert">{{ cryptoBal }}</h2>
                        </div>
                        <div class="d-flex align-items-center customPadding">
                            <a class="bg-dark p-4 d-flex justify-content-between align-items-center br12 w-100">
                                <div class="d-inline-flex flex-column">
                                    <p class="textGray">Today's PNL</p>
                                    <p class="textRed">-0.30</p>
                                </div>
                                <div class="d-inline-flex flex-column">
                                    <p class="textGray">Today's PNL (%)</p>
                                    <p class="textRed">-3.73%</p>
                                </div>
                                <div class="d-inline-flex flex-column">
                                    <div class="chevronBg"><i class="fas fa-chevron-right"></i></div>
                                </div>
                            </a>
                        </div>
                        <div class="d-flex customPadding flex-column mb-4">
                            <div class="d-flex justify-content-between">
                                <p class="textGray m-0">Available</p>
                                <p class="textInvert m-0">{{ cryptoBal }}</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p class="textGray m-0">Withdrawing</p>
                                <p class="textInvert m-0">0.00</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p class="textGray m-0">Spot Orders</p>
                                <p class="textInvert m-0">0.00</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p class="textGray m-0">Convert Orders</p>
                                <p class="textInvert m-0">0.00</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p class="textGray m-0">Frozen</p>
                                <p class="textInvert m-0">0.00</p>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex flex-column px-4 mt-2">
                            <div class="selectedCoinHistory">
                                <div class="w-100 d-flex justify-content-between align-items-center">
                                    <h5 class="textInvert">History</h5>
                                    <select class="form-control w-auto">
                                        <option value="0">All</option>
                                        <option value="1">Deposit & Withdraw</option>
                                        <option value="2">Convert</option>
                                        <option value="3">Buy & Sell</option>
                                        <option value="4">Earn</option>
                                        <option value="5">Distribution</option>
                                        <option value="6">Transfer</option>
                                    </select>
                                </div>
                                <div class="selectedCoinHistoryItem mt-4 textInvert">
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Withdraw</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Buy</strong>
                                            <small class="textGray">2023-06-24 18:59</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>+7065022 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Trading Fee</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Sell</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>+1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Buy</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Withdraw</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Buy</strong>
                                            <small class="textGray">2023-06-24 18:59</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>+7065022 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Trading Fee</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Sell</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>+1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center w-100 mb-3">
                                        <div class="d-inline-flex flex-column">
                                            <strong>Buy</strong>
                                            <small class="textGray">2023-06-24 19:02</small>
                                        </div>
                                        <div class="d-inline-flex flex-column text-right">
                                            <strong>-1988891 PEPE</strong>
                                            <small class="textGray d-flex justify-content-end align-items-center gap-1"><span class="dotSuccess"></span>Completed</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lightbox" v-if="!isKycA">
                            <div class="d-flex justify-content-center align-items-center flex-column warning text-warning">
                                <div class="d-flex justify-content-center align-items-center w-100 my-4">
                                    <i class="icon ion-md-information-circle m-0 mr-2"></i>
                                    <h1 class="text-warning m-0">Warning:</h1>
                                </div>
                                <span class="textInvert m-0 ml-2">Your KYC is still pending</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Wallet Drawer -->
</template>

<script>
import {
    getAllCoinList, getBalByCoinId,
    getTransactionListByCoinId,
    getWalletByCoinId,
    startDepositListenByCoinId,
} from "@/restApi/deposit_api";
// import VueQrcode from 'vue-qrcode';
import {toast, Type} from "@/util/toast";
import { getBalance, log, MsgType} from "@/util/constant";
import {mapActions, mapMutations, mapState} from "vuex";
import {CHECK_USER_INFO_ACTION, LOADING_STATE, SET_WITHDRAW_DATA_MUTATION} from "@/util/store.constent";
import WithdrawreqValidation from "@/services/withdrawreq.validation";
import {
    cancelWithdraw,
    getWithdrawList,
    resendOtpReq,
    verifyWithdrawOtp,
    withdrawRequest
} from "@/restApi/withdraw.api";
import Validations from "@/services/Validations";
import {useRoute} from "vue-router";
import {reactive, watch} from "vue";
import BigNumber from "bignumber.js";

let selectCoinId;
export default {
        name:"WalletPage",
        props: ['message'],
        components: {
            // VueQrcode,
        },
    computed: {
        ...mapState({
            isLogin: (state) => state.isLogin,
            userName: (state) => state.userName,
            userEmail: (state) => state.userEmail,
            userNum: (state) => state.userContact,
            isKycA: (state) => state.isKycApproved,
            userAddressP: (state) => state.userAddress,
            withId: (state) => state.stateWithdrawID,
            userCur: (state) => state.userCurrency,
        }),
        ...mapActions({
            checkUser: CHECK_USER_INFO_ACTION,
        }),
    },
        mounted() {
            document.title = 'Profile - Bitrish';
        },
        data: function () {
        return {
            kCity: '',
            getState: true,
            country: '',
            region: '',
            kycStatus: '',
            coinList: [],
            depositList: [],
            withdrawList: [],
            cryptoBal: '0.00',
            cryptoLockBal: '0.00',
            walletAdd: '',
            walletExtra: '',
            selectedCoin: 'MATIC',
            sCoinID: '',
            selectedCoinDepositFees: '0',
            selectedCoinDepositVal: '0',
            selectedCoinWithdrawFee: '0',
            isOtpSend: false,
            readyChangePass: false,
            op: '',
            oTP: '',
            np: '',
            rp: '',
            updateBtnState: false,
            showVerification: false,
            withdrawAddVal: '',
            withdrawAmtVal: '',
            withdrawPOTPVal: '',
            withdrawEOTPVal: '',
            isCanDeposit: true,
            isCanWithdraw: true,
            isSendPassOTP: true,
        };
    },
    async created() {
        await this.checkUser;
        if (!this.isLogin) {
            this.$router.replace('/signIn');
        }
        await this.getAllCoin();
        if(this.withId !== '') {
            this.showVerification = true;
        }
        this.refreshBalAndOrderList();
    },

    methods: {
        ...mapMutations({
                showLoading: LOADING_STATE,
                withdrawId: SET_WITHDRAW_DATA_MUTATION,
            }),

        closeDrawer(){
                //document.querySelector(".drawerContent").style.right = "-50%";
                document.querySelector(".drawer").classList.remove("active");
                // setTimeout(function(){
                //     document.querySelector(".drawerContent").style.right = '';
                // },500);
            },

        searchCoin() {
            // Declare variables
                var input, filter, table, tr, td, i, txtValue;
                input = document.getElementById("myInput");
                filter = input.value.toUpperCase();
                table = document.getElementById("myTable");
                tr = table.getElementsByTagName("tr");

                // Loop through all table rows, and hide those who don't match the search query
                for (i = 0; i < tr.length; i++) {
                    td = tr[i].getElementsByTagName("td")[0];
                    if (td) {
                    txtValue = td.textContent || td.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        tr[i].style.display = "";
                    } else {
                        tr[i].style.display = "none";
                    }
                    }
                }
            },

        async onCoinSelect(index) {
            if(this.isLogin && this.isKycA) {
                if(selectCoinId !== this.coinList[index].coinId) {
                    this.selectedCoin = this.coinList[index].coinName;
                    selectCoinId = this.coinList[index].coinId;
                    this.sCoinID = this.coinList[index].coinId;
                    this.selectedCoinWithdrawFee = this.coinList[index]['coinWithdrawalFees'].toString();
                    this.selectedCoinDepositVal = this.coinList[index]['coinDepositMin'].toString();
                    this.isCanDeposit = this.coinList[index]['coinDepositAllowed'];
                    this.isCanWithdraw = this.coinList[index]['coinWithdrawalAllowed'];
                    this.showLoading(true);
                    const {resData, error} = await getWalletByCoinId(this.coinList[index].coinId);
                    this.showLoading(false);
                    if (error.value === null) {
                        if (JSON.parse(resData.value)['status'].toString() === 'true') {
                            this.cryptoBal = JSON.parse(resData.value)['data']['walletBalance'];
                            this.walletAdd = JSON.parse(resData.value)['data']['walletAddress'];
                            this.walletExtra = JSON.parse(resData.value)['data']['extraData'];
                            this.cryptoLockBal = JSON.parse(resData.value)['data']['walletBalanceLock'];
                            await startDepositListenByCoinId(this.walletAdd, this.coinList[index].coinId, this.walletExtra);
                        }
                    }
                    await this.getDepositTransactionList(this.walletAdd, selectCoinId);
                    await this.getWithdrawTransactionList(selectCoinId);
                    // document.querySelector(".drawer").classList.add("active");
                }
            }
        },

        copyAdd() {
                const input = document.getElementById('depositWA');
                input.select();
                document.execCommand('copy');
            },

        copyExtra() {
                const input = document.getElementById('depositWE');
                input.select();
                document.execCommand('copy');
            },

        async pasteText() {
                try {
                    const text = await navigator.clipboard.readText()
                    document.getElementById('walletAddressPaste').value += text;
                } catch (error) {
                    try {
                        document.getElementById('walletAddressPaste').focus();
                        document.execCommand('paste')
                    } catch (e) {
                        toast('Unable to get clipboard content', Type.danger);
                    }
                }
            },

        async sendWithdrawRequest() {
                const validation = new WithdrawreqValidation(this.$refs.withdrawAddress.value, this.$refs.withdrawAmount.value, this.cryptoBal, this.isKycA);
                const err = validation.checkValidation();
                if (err === '') {
                    this.showLoading(true);
                    const {
                        res,
                        error
                    } = await withdrawRequest(this.$refs.withdrawAddress.value, selectCoinId, this.$refs.withdrawAmount.value);
                    try {
                        if (error.value === null) {
                            const msg = JSON.parse(res.value)['message'];
                            if (JSON.parse(res.value)['status'].toString().toLowerCase() === 'true') {
                                this.withdrawId({
                                    'id': JSON.parse(res.value)['data'].toString(),
                                    'add': this.$refs.withdrawAddress.value,
                                    'amt': this.$refs.withdrawAmount.value,
                                });
                                this.withdrawAddVal = '';
                                this.withdrawAmtVal = '';
                                this.showVerification = true;
                                this.showLoading(false);
                                toast(msg, Type.success);
                            } else {
                                this.showLoading(false);
                                toast(msg, Type.danger);
                            }
                        } else {
                            this.showLoading(false);
                            toast('Something Went Wrong', Type.danger);
                        }
                    } catch (e) {
                        this.showLoading(false);
                        log(e, MsgType.error);
                        toast('Something Went Wrong', Type.danger);
                    }
                } else {
                    this.showLoading(false);
                    toast(err, Type.danger);
                }
            },

        async verifyOtp() {
                const pOTPValid = Validations.checkOTP(this.$refs.mobileOtp.value);
                const eOTPValid = Validations.checkOTP(this.$refs.emailOtp.value);
                if (pOTPValid && eOTPValid) {
                    this.showLoading(true);
                    const {
                        verifyRes,
                        error
                    } = await verifyWithdrawOtp(this.withId, this.$refs.mobileOtp.value, this.$refs.emailOtp.value);
                    try {
                        if (error.value === null) {
                            const msg = JSON.parse(verifyRes.value)['message'];
                            if (JSON.parse(verifyRes.value)['status'].toString() === 'true') {
                                this.cryptoBal = (await getBalance(selectCoinId)).bal;
                                this.withdrawId({
                                    'id': '',
                                    'add': '',
                                    'amt': '',
                                });
                                this.withdrawAddVal = '';
                                this.withdrawAmtVal = '';
                                this.withdrawPOTPVal = '';
                                this.withdrawEOTPVal = '';
                                this.showLoading(false);
                                this.showVerification = false;
                                toast(msg, Type.success);
                            } else {
                                this.showLoading(false);
                                toast(msg, Type.danger);
                            }
                        } else {
                            this.showLoading(false);
                            toast('Something Went Wrong', Type.danger);
                        }
                    } catch (e) {
                        this.showLoading(false);
                        toast('Something Went Wrong', Type.danger);
                    }
                }
            },

        async cancelWithdraw() {
                this.showLoading(true);
                const {cancelRes, error} = await cancelWithdraw(this.withId);
                try {
                    if (error.value === null) {
                        const msg = JSON.parse(cancelRes.value)['message'];
                        if (JSON.parse(cancelRes.value)['status'].toString() === 'true') {
                            this.withdrawId({
                                'id': '',
                                'add': '',
                                'amt': '',
                            });
                            this.withdrawAddVal = '';
                            this.withdrawAmtVal = '';
                            this.withdrawPOTPVal = '';
                            this.withdrawEOTPVal = '';
                            this.showLoading(false);
                            this.showVerification = false;
                            toast(msg, Type.success);
                        } else {
                            this.showLoading(false);
                            toast(msg, Type.danger);
                        }
                    } else {
                        this.showLoading(false);
                        toast('Something Went Wrong', Type.danger);
                    }
                } catch (e) {
                    this.showLoading(false);
                    toast('Something Went Wrong', Type.danger);
                }
            },

        async resendOtp(type) {
                this.showLoading(true);
                const {resendOTPRes, error} = await resendOtpReq(this.withId, type);
                try {
                    if (error.value === null) {
                        const msg = JSON.parse(resendOTPRes.value)['message'];
                        if (JSON.parse(resendOTPRes.value)['status'].toString() === 'true') {
                            this.showLoading(false);
                            toast(msg, Type.success);
                        } else {
                            this.showLoading(false);
                            toast(msg, Type.danger);
                        }
                    } else {
                        this.showLoading(false);
                        toast('Something Went Wrong', Type.danger);
                    }
                } catch (e) {
                    this.showLoading(false);
                    toast('Something Went Wrong', Type.danger);
                }
            },

        async getAllCoin() {
                const {coinData, error} = await getAllCoinList();
                if (error.value === null) {
                    if (JSON.parse(coinData.value)['status'].toString().toLowerCase() === 'true') {
                        const cData = JSON.parse(coinData.value)['data'];
                        // console.log('Coin Data = = ', cData);
                        if (cData.length > 0) {
                            for (let i = 0; i < cData.length; i++) {
                                let bData = await this.getBalance(cData[i]['coinId']);

                                // bData['bal'] = convertValueAccordingDecimal(bData['bal'], cData[i]['coinDecimal']);
                                // bData['lock'] = convertValueAccordingDecimal(bData['lock'], cData[i]['coinDecimal']);

                                cData[i]['avbl'] = bData['bal'];
                                cData[i]['lock'] = bData['lock'];
                                if(this.getBigNumber(bData['bal']).isGreaterThan(this.getBigNumber('0')) || this.getBigNumber(bData['lock']).isGreaterThan(this.getBigNumber('0'))) {
                                    cData[i]['total'] = this.getBigNumber(bData['bal']).plus(this.getBigNumber(bData['lock'])).toFixed();
                                    cData[i]['totalInFiat'] = this.getBigNumber(bData['total']).multipliedBy(this.getBigNumber(this.userCur['fiatPriceOneUSD'])).toFixed();
                                } else {
                                    cData[i]['total'] = '0';
                                    cData[i]['totalInFiat'] = '0';
                                }
                                // console.log('BAlance data === ' , bData);
                                this.coinList.push(cData[i]);
                            }
                            selectCoinId = cData[0]['coinId'];
                            this.selectedCoinWithdrawFee = cData[0]['coinWithdrawalFees'].toString();
                            this.selectedCoin = cData[0]['coinName'];
                            this.selectedCoinDepositFees = cData[0]['coinDepositFees'];
                            this.selectedCoinDepositVal = cData[0]['coinDepositMin'];
                            this.isCanDeposit = cData[0]['coinDepositAllowed'];
                            this.isCanWithdraw = cData[0]['coinWithdrawalAllowed'];
                            if(this.isLogin && this.isKycA) {
                                const {resData, error} = await getWalletByCoinId(selectCoinId);
                                if (error.value === null) {
                                    if (JSON.parse(resData.value)['status'].toString().toLowerCase() === 'true') {
                                        const wData = JSON.parse(resData.value)['data'];
                                        this.cryptoBal = wData['walletBalance'];
                                        this.walletAdd = wData['walletAddress'];
                                        this.cryptoLockBal = wData['walletBalanceLock'];
                                        this.walletExtra = wData['extraData']
                                        await startDepositListenByCoinId(this.walletAdd, selectCoinId, this.walletExtra);
                                        await this.getDepositTransactionList(this.walletAdd, selectCoinId);
                                        await this.getWithdrawTransactionList(selectCoinId);
                                    }
                                }
                            }
                        }
                    } else {
                        toast(JSON.parse(coinData.value).message, Type.danger);
                    }
                } else {
                    toast("Something Went Wrong.", Type.danger);
                }
            },

        formattedCurrency(val, round) {
            if(val && val.toString().includes('.')) {
                if(parseFloat(val.toString().split('.')[0]) <= 0) {
                    return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: parseInt(round.toString())});
                } else {
                    return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: 2});
                }
            } else if(val) {
                return parseFloat(val.toString()).toLocaleString(undefined, {minimumFractionDigits: 2});
            } else {
                return '0.00';
            }
        },

        async getDepositTransactionList(walletAdd, coinId) {
                const {transactionData, error} = await getTransactionListByCoinId(walletAdd, coinId);
                this.depositList = [];
                if(error.value === null) {
                    try {
                        if(selectCoinId.toString() === coinId.toString()) {
                            if (JSON.parse(transactionData.value)['status'].toString().toLowerCase() === 'true') {
                                const tData = JSON.parse(transactionData.value)['data']
                                if (tData.length > 0) {
                                    for (let i = 0; i < tData.length; i++) {
                                        const mxx = tData[i];
                                        const dateString = parseInt(mxx['dateTime'].toString()) * 1000;
                                        const d = new Date(dateString);
                                        const da = d.getDate().toString().length > 1 ? d.getDate() : '0' + d.getDate();
                                        const mo = (d.getMonth() + 1).toString().length > 1 ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1);
                                        const y = d.getFullYear().toString().length > 1 ? d.getFullYear() : '0' + d.getFullYear();
                                        const h = d.getHours().toString().length > 1 ? d.getHours() : '0' + d.getHours();
                                        const m = d.getMinutes().toString().length > 1 ? d.getMinutes() : '0' + d.getMinutes();
                                        const s = d.getSeconds().toString().length > 1 ? d.getSeconds() : '0' + d.getSeconds();
                                        mxx['new_date_time'] = (da + '/' + mo + '/' + y + ' ' + h + ':' + m + ':' + s);
                                        this.depositList.push(mxx);
                                        console.log('geting Deposite Api ', this.depositList);
                                    }
                                }
                            }
                        }
                    } catch (e) {
                        log(e, MsgType.error);
                    }
                }
            },

        async getWithdrawTransactionList(coinId) {
                const {withdrawRes, error} = await getWithdrawList(coinId);
                this.withdrawList = [];
                if(error.value === null) {
                    try {
                        if(selectCoinId.toString() === coinId.toString()) {
                            if (JSON.parse(withdrawRes.value)['status'].toString().toLowerCase() === 'true') {
                                const tData = JSON.parse(withdrawRes.value)['data']
                                if (tData.length > 0) {
                                    for (let i = 0; i < tData.length; i++) {
                                        const mxx = tData[i];
                                        const dateString = parseInt(mxx['dateTime'].toString()) * 1000;
                                        const d = new Date(dateString);
                                        const da = d.getDate().toString().length > 1 ? d.getDate() : '0' + d.getDate();
                                        const mo = (d.getMonth() + 1).toString().length > 1 ? (d.getMonth() + 1) : '0' + (d.getMonth() + 1);
                                        const y = d.getFullYear().toString().length > 1 ? d.getFullYear() : '0' + d.getFullYear();
                                        const h = d.getHours().toString().length > 1 ? d.getHours() : '0' + d.getHours();
                                        const m = d.getMinutes().toString().length > 1 ? d.getMinutes() : '0' + d.getMinutes();
                                        const s = d.getSeconds().toString().length > 1 ? d.getSeconds() : '0' + d.getSeconds();
                                        mxx['new_date_time'] = (da + '/' + mo + '/' + y + ' ' + h + ':' + m + ':' + s);
                                        this.withdrawList.push(mxx);
                                    }
                                }
                            }
                        }
                    } catch (e) {
                        log(e, MsgType.error);
                    }
                }
            },

        getBigNumber(val) {
            if(val) {
                return new BigNumber(val.toString());
            } else  {
                return new BigNumber('0');
            }
        },

        makeShortHash(hash) {
                const start = 0;
                const end = 6;

                return hash.substring(start, end) + "...." + hash.substring(hash.length - 4)
            },

        getISOTimeFormat(timestamp) {
                const date = new Date(timestamp);
                const isoDate = date.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                });
                const isoTime = date.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                });

                return `${isoDate} ${isoTime}`;
            },

        refreshBalAndOrderList() {
                this.refreshDataTimeout = setTimeout(async () => {
                    const {resData, error} = await getWalletByCoinId(selectCoinId);
                    if (error.value === null) {
                        if (JSON.parse(resData.value)['status'].toString() === 'true') {
                            this.cryptoBal = JSON.parse(resData.value)['data']['walletBalance'];
                            this.cryptoLockBal = JSON.parse(resData.value)['data']['walletBalanceLock'];
                        }
                    }
                    await this.getDepositTransactionList(this.walletAdd, selectCoinId);
                    await this.getWithdrawTransactionList(selectCoinId);
                    this.refreshBalAndOrderList();
                }, 10000);
            },

        async getBalance(coinId) {
            const {res, err} = await getBalByCoinId(coinId);
            // console.log('Balance Data === ', res.value, err.value);
            if (err.value === null) {
                if (JSON.parse(res.value)['status'].toString().toLowerCase() === 'true') {
                    const data = JSON.parse(res.value)['data'];
                    // console.log('Balance Data === ', data);
                    const wData = {
                        'bal': data['walletBalance'],
                        'lock': data['walletBalanceLock']
                    };
                    return wData;
                } else {
                    const wData = {
                        'bal': '0',
                        'lock': '0'
                    };
                    return wData;
                }
            } else {
                const wData = {
                    'bal': '0',
                    'lock': '0'
                };
                return wData;
            }
        }

    },
        setup() {

            const route = useRoute()
            const queryParams = reactive(route.query)

            watch(
                () => route.query,
                () => {
                    Object.assign(queryParams, route.query)
                    let allLink;
                    let allList;
                    let allid;
                    setTimeout(function(){
                        if(route.query.type){
                            if(route.query.type.toString() === '1') {
                                allLink = document.querySelectorAll(".customLink");
                                for(const cl of allLink){
                                    cl.classList.remove("active");
                                }
                                allList = document.querySelectorAll(".sidebar li");
                                for(const li of allList){
                                    li.classList.remove("active");
                                }
                                document.querySelector(".sidebar li:nth-child(1) a:nth-child(1)").classList.add("active");
                                document.querySelector(".sidebar li:nth-child(1) li:nth-child(1) a:nth-child(1)").classList.add("active");
                                allid = document.querySelectorAll(".customSection");
                                for(const mi of allid){
                                    mi.classList.remove("active");
                                }
                                document.getElementById('contentOne').classList.add("active");
                                document.title = 'Personal Information - Bitrish';
                            }
                            else if(route.query.type.toString() === '2') {
                                allLink = document.querySelectorAll(".customLink");
                                for(const cl of allLink){
                                    cl.classList.remove("active");
                                }
                                allList = document.querySelectorAll(".sidebar li");
                                for(const li of allList){
                                    li.classList.remove("active");
                                }
                                document.querySelector(".sidebar li:nth-child(1) li:nth-child(2) a:nth-child(1)").classList.add("active");
                                allid = document.querySelectorAll(".customSection");
                                for(const mi of allid){
                                    mi.classList.remove("active");
                                }
                                document.getElementById('contentTwo').classList.add("active");
                                document.title = 'KYC - Bitrish';
                            }
                            else if(route.query.type.toString() === '3') {
                                allLink = document.querySelectorAll(".customLink");
                                for(const cl of allLink){
                                    cl.classList.remove("active");
                                }
                                allList = document.querySelectorAll(".sidebar li");
                                for(const li of allList){
                                    li.classList.remove("active");
                                }
                                document.querySelector(".sidebar li:nth-child(1) li:nth-child(3) a:nth-child(1)").classList.add("active");
                                allid = document.querySelectorAll(".customSection");
                                for(const mi of allid){
                                    mi.classList.remove("active");
                                }
                                document.getElementById('contentThree').classList.add("active");
                                document.title = 'Security - Bitrish';
                            }
                            else if(route.query.type.toString() === '4') {
                                allLink = document.querySelectorAll(".customLink");
                                for(const cl of allLink){
                                    cl.classList.remove("active");
                                }
                                allList = document.querySelectorAll(".sidebar li");
                                for(const li of allList){
                                    li.classList.remove("active");
                                }
                                document.querySelector(".sidebar>ul>li:nth-child(2) a").classList.add("active");
                                allid = document.querySelectorAll(".customSection");
                                for(const mi of allid){
                                    mi.classList.remove("active");
                                }
                                document.getElementById('contentFour').classList.add("active");
                                document.title = 'Wallet - Bitrish';
                            }
                        }
                    },500);
                },
                { immediate: true }
            )

            return {
                queryParams
            }
        },
    }
</script>
