<template>
    <div class="row m-0 dark-bg-wallpaper">
        <div class="col-md-6 ch-100 d-inline-flex justify-content-center align-items-center">
            <img v-if="theme === ''" src="/assets/img/logo-dark.png" alt="Bitrish Logo" /><img v-else src="/assets/img/logo-light.png" alt="Bitrish Logo" />
        </div>
        <div class="col-md-6 ch-100 d-inline-flex justify-content-start align-items-center">
            <div class="p-3 w-100 border-left d-flex justify-content-center align-items-center">
                <div class="form-access my-auto">
                    <form id="signInForm" @submit.prevent="signIn()">
                        <h2 class="text-center formHeading">{{ $t('signIn') }}</h2>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email Address" ref="email" :disabled="showOTPField">
                        </div>
                        <div class="input-group mb-2">
                            <input type="password" id="pass" class="form-control" placeholder="Password" ref="password" :disabled="showOTPField">
                            <div class="input-group-append">
                                <button class="btn btn-primary" @click="togglePassword('pass')" type="button"><i class="icon ion-md-eye"></i></button>
                            </div>
                        </div>
                        <div class="form-group" v-if="showOTPField">
                            <input type="number" class="form-control" maxlength="6" placeholder="OTP" ref="otp">
                        </div>
                        <div class="text-right">
                            <router-link to="/forgetPassword">Forgot Password?</router-link>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                    </form>
                    <h2>Don't have an account?
                        <router-link to="/signUp">Sign up here</router-link>
                    </h2>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</template>

<script>
import {getUserDataApi, loginApi} from "@/restApi/api";
import {toast, Type} from "@/util/toast";
import {log, MsgType} from "@/util/constant";
import SignInValidation from "@/services/signin.validation";
import {mapActions, mapMutations, mapState} from "vuex";
import {
    CHECK_USER_INFO_ACTION,
    GET_All_NOTIFICATION_ACTION,
    LOADING_STATE,
    SET_IS_LOGIN_DATA_MUTATION
} from "@/util/store.constent";
import AppFooter from "@/components/parcel/AppFooter.vue";

export default {
    name: "SignIn",
    components: {AppFooter},
    computed:{
        ...mapState({
            theme: (state) => state.theme,
            isLogin: state => state.isLogin,
        }),
    },
    mounted() {
        document.title = 'Sign In';
        if(this.isLogin) {
            this.$router.replace('/');
        }
    },
    data() {
        return {
            showOTPField: false,
        }
    },
    methods: {
        ...mapMutations({
            showLoading: LOADING_STATE,
            setLoginData: SET_IS_LOGIN_DATA_MUTATION,
        }),
        ...mapActions({
            checkUser: CHECK_USER_INFO_ACTION,
            getAllNotification: GET_All_NOTIFICATION_ACTION,
        }),
        async signIn() {
            if(!this.showOTPField) {
                let validation = new SignInValidation(this.$refs.email.value, this.$refs.password.value);
                const error = validation.checkValidation();
                if (error === '') {
                    this.showLoading(true);
                    const {resData, error} = await loginApi(this.$refs.email.value, this.$refs.password.value, 'NEW');
                    try {
                        // log(error.value, MsgType.log);
                        // log(resData.value, MsgType.log);
                        if (error.value === null) {
                            const msg = JSON.parse(resData.value)['message'].toString();
                            if(JSON.parse(resData.value)['status'].toString().toLowerCase() === 'true') {
                                this.showOTPField = true;
                                this.showLoading(false);
                                toast(msg, Type.success);
                            } else {
                                this.showLoading(false);
                                toast(msg, Type.danger);
                            }
                        } else {
                            this.showLoading(false);
                            log(error, MsgType.error);
                            toast("Something Went Wrong", Type.danger);
                        }
                    } catch (e) {
                        this.showLoading(false);
                        log(e, MsgType.error)
                    }
                } else {
                    toast(error, Type.danger);
                }
            } else {
                if(this.$refs.otp.value.length === 6) {
                    this.showLoading(true);
                    const {resData, error} = await loginApi(this.$refs.email.value, this.$refs.password.value, this.$refs.otp.value);
                    try {
                        // log(error.value, MsgType.log);
                        // log(resData.value, MsgType.log);
                        if (error.value === null) {
                            const msg = JSON.parse(resData.value)['message'].toString();
                            if(JSON.parse(resData.value)['status'].toString().toLowerCase() === 'true') {
                                const token = JSON.parse(resData.value)['data']['token'].toString();
                                localStorage.setItem("app_token", token);
                                const {res, err} = await getUserDataApi();
                                try {
                                    if (err.value === null) {
                                        if (JSON.parse(res.value).status.toString().toLowerCase() === 'true') {
                                            this.showLoading(false);
                                            toast(msg, Type.success);
                                            const data = JSON.parse(res.value).data;
                                            const loginData = {
                                                isLogin: true,
                                                userName: JSON.parse(res.value)['data']['userName'],
                                                userImage: JSON.parse(res.value)['data']['userDP'],
                                                userEmail: JSON.parse(res.value)['data']['userEmail'],
                                                userContact: JSON.parse(res.value)['data']['userPhone'],
                                                userAddress: JSON.parse(res.value)['data']['userAddress'],
                                                isKycApproved: (JSON.parse(res.value)['data']['userStatusKYC'].toString() === '1'),
                                            }
                                            this.setLoginData(loginData);
                                            if (JSON.parse(res.value)['data']['userStatusKYC'].toString() !== '1') {
                                                const routeParams = {
                                                    path: '/MyProfile',
                                                    query: {
                                                        type: 2,
                                                    }
                                                };
                                                this.$router.replace(routeParams);
                                            } else {
                                                this.$router.replace('/');
                                            }
                                            localStorage.setItem("user_data", JSON.stringify(data));
                                            await this.checkUser();
                                            await this.getAllNotification();
                                        }
                                    }
                                } catch (e) {
                                    this.showLoading(false);
                                    log(e, MsgType.error)
                                }
                            } else {
                                this.showLoading(false);
                                toast(msg, Type.danger);
                            }
                        } else {
                            this.showLoading(false);
                            toast("Something Went Wrong", Type.danger);
                        }
                    } catch (e) {
                        this.showLoading(false);
                        toast("Something Went Wrong", Type.danger);
                        log(e, MsgType.error)
                    }
                } else {
                    toast('Please Enter OTP', Type.danger);
                }
            }
        },
        togglePassword(id) {
            var x = document.getElementById(id);
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        },
    }
}

</script>

<style scoped>

</style>
